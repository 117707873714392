import React, { useState, useEffect } from 'react';
import {
  useLocation
} from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { IMenuListItems } from 'models/Menus';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FeatureFlagState } from 'store/featureflags/types';
import { connect, useSelector } from 'react-redux';
import FeatureGate from '../../FeatureGate';
import { globalSelector } from "store/global/globalReducer";
import { GradeStatus, SkillStatus } from 'models/Grade';
import { WeekType } from 'models/WeeklyGrowth';
import { RouteTypes } from 'models/enums/Routes';
import { isFinalsWeek } from "utils/helpers/generalHelpers";
import { isSkillType } from "utils/helpers/skillsHelpers";
import { ScoreNumbers } from 'models/enums/Standards';
import WarningIcon from '@mui/icons-material/Warning';
import RemoveIcon from '@mui/icons-material/Remove';
import { convertScoreToInitial } from 'utils/helpers/stringHelpers';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ContentCopy } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SkillProficiency } from 'models/enums/ToolTips';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';


interface IMenuDropDown {
  menuTitle: string;
  menuSecondaryTitle?:string;
  buttonText?: string;
  secondaryButtonText: string;
  disableButton?:boolean;
  menuId: string;
  menuItems: IMenuListItems[];
  suggestedProficiencyScore?: number;
  isSkillOverWritten?: boolean | undefined;
  updateSkillProficiencyFunction: (skillProficiencyOverideId:number, isSkillOveride:boolean) => void;
  viewSkillsFunction?: () => void;
}

const EditSkillProficiencyMenu = ({ menuTitle,menuSecondaryTitle, buttonText, secondaryButtonText, disableButton, menuId, menuItems, suggestedProficiencyScore, isSkillOverWritten, updateSkillProficiencyFunction, viewSkillsFunction }: IMenuDropDown) => {

  //#region Variables:
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const [isGradebook, setIsGradebook] = useState(false);
  const open = Boolean(anchorEl);
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const { termIsEditable, skillOverridesAllowed} = useSelector(globalSelector);
  const canEditSkills = FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) && 
                          termIsEditable && 
                          isGradebook && skillOverridesAllowed && 
                          // TODO: test admin still has the ability to edit skill proficiency regardless of week skill override
                          // Task: https://dev.azure.com/StevensonIT/IRC/_workitems/edit/1421
                          // (isFinalsWeek(currentWeekTaskId) || isFinalWeekMode) && 
                          // viewingWeekType === WeekType.FinalWeek &&
                          (disableButton !== true);

  const scoreOverWritten = (buttonText === convertScoreToInitial(suggestedProficiencyScore ? suggestedProficiencyScore.toLocaleString() : '')) && 
                            (isSkillOverWritten === false) 
                              ? false : true;
  const theme = useTheme();
  const suggestedMenuItem = menuItems.find(( item: IMenuListItems ) => item.id === suggestedProficiencyScore);
  //#endregion Variables:

  //#region Hooks:

  useEffect(() => {
    if( location.pathname === RouteTypes.GradeBookRoute)
      setIsGradebook(true);
  }, [location]);
  //#endregion Hooks:

  //#region Functions:
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const updateSkillProficiency = (skillProficiencyOverideId:number, isSkillOveride: boolean): void => {
    updateSkillProficiencyFunction(skillProficiencyOverideId, isSkillOveride);
    closeMenu();
  };

  const viewSkillsClick = (): void => {
    if (viewSkillsFunction) {
      viewSkillsFunction();
    }
    closeMenu();
  };
  //#endregion Functions:
 
 
  return canEditSkills ? (
    <>
      <Button
        variant="contained"
        color="secondary"
        style={{ color: "#212529", fontSize: "1.2rem", position: "absolute", zIndex: "200",height: "36px" }}
        id={`menu-button-${menuId}`}
        aria-controls={open ? `menu-${menuId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event: any) => openMenu(event) }
        disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
 
      >
        <Tooltip  title={scoreOverWritten ? 'Suggested skill proficiency is overwritten.' : ''} placement="right" enterDelay={800}>
          <Badge 
            variant="dot"  
            invisible={!scoreOverWritten}
            sx={{
              '.MuiBadge-badge':{
                color: '#fff',
                backgroundColor: theme.palette.gold.main,
              }
            }}
          >
            {buttonText ? (
              (buttonText === GradeStatus.LoadingGrade || buttonText === SkillStatus.LoadingSkill.toLocaleString()) ? (
                  <CircularProgress size={19} color="inherit" />
                ) : buttonText === ScoreNumbers.Five ? (
                  <WarningIcon color='warning'/>
                ) : buttonText === ScoreNumbers.Zero ? (
                  <RemoveIcon color='info'/>
                ) : buttonText
            ) : !termIsEditable ? (
              <LaunchIcon color="info" fontSize="small" />
            ) : (
              ""
            )}
            
          </Badge>
        </Tooltip>
      </Button>
      <Menu
        id={`menu-${menuId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": `menu-button-${menuId}`,
        }}
        style={{ maxHeight: "580px" }}
      >
        <MenuList sx={{ width: "100%", maxWidth: 360, minWidth: 280, bgcolor: "background.paper" }} dense>
          {menuTitle && (
            <Tooltip 
              title={SkillProficiency.Suggested}
              placement="right"
              enterDelay={1000}
              enterNextDelay={1000}  
            >
            <span>
              <MenuItem disabled>{menuTitle} 
                <ListItemIcon style={{ paddingLeft: '8px'}}>
                  <InfoOutlinedIcon style={{ fontSize: '14px'}} />
                </ListItemIcon>
              </MenuItem>
            </span>
          </Tooltip>)}

          {menuItems.length && suggestedMenuItem && (
                <ListItem
                  key={-1}
                  disablePadding
                >
                  <ListItemButton 
                    onClick={() => updateSkillProficiency(suggestedMenuItem.id ? suggestedMenuItem.id : -1, false)} 
                    selected={
                      isSkillType(buttonText ? buttonText : '', suggestedMenuItem.primaryText) && (isSkillOverWritten === false)}
                  >
                    <ListItemText 
                      id='suggestedMenuItem' 
                      primary={`${suggestedMenuItem.primaryText}`}
                    />
                  </ListItemButton>
                </ListItem>
             
          )}

          <Divider style={{marginTop:'15px'}}/>
 
          {menuSecondaryTitle && (
            <Tooltip 
              title={SkillProficiency.Override}
              placement="right"
              enterDelay={1000}
              enterNextDelay={1000} 
            >
            <span>
              <MenuItem disabled>{menuSecondaryTitle} 
                <ListItemIcon style={{ paddingLeft: '8px'}}>
                  <InfoOutlinedIcon style={{ fontSize: '14px'}} />
                </ListItemIcon>
              </MenuItem>
            </span>
          </Tooltip>)}

          {menuItems.length ? (
            menuItems.map((item: IMenuListItems, index) => {
              return (
                <ListItem            
                  key={index}
                  disablePadding
                >
                  <ListItemButton 
                    onClick={() => updateSkillProficiency(item.id ? item.id : -1, true)} 
                    selected={isSkillType(buttonText ? buttonText : '',item.primaryText) && (isSkillOverWritten === true)}
                  >
   
                    <ListItemText 
                      id={index.toString()} 
                      primary={`${item.primaryText}`}  
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem key={"1"} disabled>
              <ListItemText id={"missing-options"} primary={`No skills options available`} />
            </ListItem>
          )}

          {/* Secondary Menu Actions  */}
          <Divider style={{marginTop:'15px'}}/>

          {secondaryButtonText && (
            <MenuItem onClick={viewSkillsClick} style={{ marginTop: "15px" }}>
              <Typography variant="body2" color="text.secondary" style={{ marginRight: "10px" }}>
                <LaunchIcon fontSize="small" />
              </Typography>
              {secondaryButtonText}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  ) : (
    <Button
        color="secondary"
        style={{ color: "#212529", fontSize: "1.3rem", position: "absolute", zIndex: "1",height: "100%", width: "100%" }}
        id={`menu-button-${menuId}`}
        onClick={(event: any) => viewSkillsClick() }
        disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
      >
        {buttonText ? (
          buttonText === GradeStatus.LoadingGrade ? (
            <CircularProgress size={19} color="inherit" />
          ) : buttonText === ScoreNumbers.Five ? (
            <WarningIcon color='warning'/>
          ) : buttonText === ScoreNumbers.Zero ? (
            <RemoveIcon color='info'/>
          ) : buttonText
        ) : !termIsEditable ? (
          <LaunchIcon color="info" fontSize="small" />
        ) : (
          ""
        )}
    </Button>
  );
};
export interface EditWeeklyGrowthStoreProps {  
  features: FeatureFlagState;
}   
function mapStateToProps(state: any): EditWeeklyGrowthStoreProps{
  return {
    features: state.featureFlags,
  };
}
export default connect<EditWeeklyGrowthStoreProps>(mapStateToProps)(EditSkillProficiencyMenu);