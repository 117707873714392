import React from 'react';
import '../shared/Shared.css';
import Typography from '@mui/material/Typography';

export interface ProjectedGradeProps{
    projectedGrade: string;
    closeOnMouseExit?: boolean;
    showWarningIcon?:boolean;
}
 
export const ProjectedGradeInitials = (props: ProjectedGradeProps) => {
    const hasMissingAssignment: boolean | undefined = props.showWarningIcon;
    return(   
        <div style={{textAlign:'right', display: 'flex', justifyContent: 'end', alignItems:'center' }}>
            {hasMissingAssignment ? (
                <>
                    {/* removed per clients request */}
                    {/* <WarningIcon color='warning'/>&nbsp; */}
                    <Typography variant="h6" sx={{ color: 'alert.main', lineHeight: '0px' }}>
                        {props.projectedGrade}
                    </Typography>
                </>
            ) : (
                <Typography variant="h6" sx={{ lineHeight: '24px' }}>
                    {props.projectedGrade}
                </Typography>
            )}
            
        </div>
    )
}
  
 