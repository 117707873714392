import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MessageIcon from '@mui/icons-material/Message';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { IMenuListItems } from '../../models/Menus';
import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import AddCommentIcon from '@mui/icons-material/AddComment';

interface IFilterableMenu {
  menuId: string;
  menuItems: IMenuListItems[];
  filterBy: string | number;
  filterPlaceholder: string;
  onListItemClickFunction: (preDefinedComment:string) => void;
}

export const FilterableMenu = ( {menuId, menuItems,filterBy,filterPlaceholder, onListItemClickFunction}:IFilterableMenu) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterSeachText, setFilterSeachText] = React.useState<string>(``);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const onItemClick = (preDefinedComment:string):void => {
        onListItemClickFunction(preDefinedComment);
        closeMenu();
    };

    const onFilterSearchChange = (e) => {
        setFilterSeachText(e.target.value); 
    };

  return (<>   
    <IconButton 
        id={`menu-button-${menuId}`} 
        aria-controls={open ? `menu-${menuId}` : undefined} 
        aria-haspopup="true" 
        aria-expanded={open ? 'true' : undefined} 
        onClick={handleClick} 
        aria-label="delete"
        color='primary'
    >
        <AddCommentIcon />
    </IconButton>
    <Menu id={`menu-${menuId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': `menu-button-${menuId}`, }}
        style={{paddingBottom:'0'}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right',}}
        sx={{padding:'0 !important',
            '& .MuiList-root': {paddingBottom:'0 !important'}
        }}
    >
        <MenuItem autoFocus divider style={{marginBottom:0}} sx={{'&:hover': {backgroundColor:'none'}}}>
            <TextField
                label={filterPlaceholder}
                id="filter-search"
                sx={{ m: 0, width: '100%',
                    '& .MuiOutlinedInput-input': {
                        padding: '10px 12px',
                    },
                    '& #filter-search-label': {transform:'translate(14px, -9px) scale(0.75)'},
                    '& legend': {maxWidth:'100%'},
                    '& legend span': {background:'#fff'}
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}
                onChange={onFilterSearchChange}
            />
        </MenuItem>
        <Paper elevation={0} style={{maxHeight: 240, height:240, overflow: 'auto',padding:'10px 0'}}>
            <MenuList sx={{ width: 360, maxWidth: 360, minWidth:280, bgcolor: 'background.paper',padding:'0 !important' }} dense>
                {menuItems.map(( item: any, index) => {
                    if (filterSeachText === "" || item[`${filterBy}`].toLowerCase().includes(filterSeachText.toLowerCase())) {
                        return (
                            <ListItem
                                key={index}
                                disablePadding
                            >
                            <ListItemButton onClick={() => onItemClick(item.primaryText)}>
                                <ListItemIcon sx={{minWidth:'40px'}}>
                                    <MessageIcon />
                                </ListItemIcon>
                                <ListItemText id={index.toString()} primary={`${item.primaryIntials} : ${item.primaryText}`} />
                            </ListItemButton>
                            </ListItem>
                        );
                    }
                        return null;
                    })
                }
            </MenuList>
        </Paper>
        <MenuItem sx={{ justifyContent: 'flex-end', padding: '11px 7px'   }} onClick={closeMenu} >
            <CancelIcon /> 
        </MenuItem>
    </Menu>
    </>
  );

};