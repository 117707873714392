
import { http } from '../utils/helpers/apiHelpers'
import { IInterventionApi, IInterventionExitApi, IMttStatus } from '../models/MandatoryTargetedTutoring'
import { AxiosResponse } from 'axios';

/**  Request to update the Active intervention with a new comment */
export const addStudentInterventionComment = async (body:any): Promise<any> => {
    return await http.post<any>(`mtt/InterventionComment`, body);
};

/**  Request to add a new intervention and mark the mtt status to Either Active or Skipped  for a student*/
export const enrollOrSkipIntervention = async (body:IInterventionApi[]): Promise<any> => {    
    return await http.post<any>(`mtt/intervention`, body);
};



/**  Request to update the Active intervention skills or enrollment comment for a student */
 export const editStudentActiveIntervention = async (interventionId: number, body:IInterventionApi): Promise<any> => {
    return await http.put<any>(`mtt/intervention/${interventionId}`, body);
};

/**  Request to update the Active intervention to Exit, Add End Date and Exit Comment */
export const exitStudentOutOfIntervention = async (interventionId: number, body: IInterventionExitApi): Promise<any> => {
    return await http.post<any>(`mtt/intervention/${interventionId}/Exit`, body);
};

export const continueStudentActiveIntervention = async (interventionId: number, body:IInterventionApi): Promise<any> => {
    return await http.put<any>(`mtt/intervention/${interventionId}`, body);
};


/**  Request to get all interventions active or not for a student  */
export const getStudentInterventions = async (studentId: number, sectionIds: Array<number>, excludeStatus: string | null = null ): Promise<any> => {
    return await http.get<any>(`mtt/intervention/byStudentId/${studentId}?sectionIds=${sectionIds}${excludeStatus ? '&excludeStatus=' + excludeStatus : ''}`);
    };

/**  Request to retrieve all students with active by interventions   */
export const getStudentsInMtt = async (personId: number): Promise<any> => {
    return await http.get<any>(`mtt/intervention/ByTutorId/${personId}`);
};

export const getStudentInterventionEntry = async (sectionId: number,studentId: number,ebrFlag:boolean ): Promise<any> => {
    return await http.get<any>(`mtt/GetStudentInterventionEntry?sectionId=${sectionId}&periodId=${studentId}&studentPersonId=${studentId}&isEBR=${ebrFlag}`);
};

/** Get the latest Mtt status based on new projected grade*/
export const getStudentMttStatus = async (mttStatus:IMttStatus, sectionId: number, termId: number): Promise<any>  => {
    return await http.get<any>(`mtt/mttStatus?termId=${termId}&sectionId=${sectionId}&studentId=${mttStatus.studentId}&mttEligibleStudent=${mttStatus.mttEligibleStudent}&currentMTTStatus=${mttStatus.currentMTTStatus}&projectedGrade=${mttStatus.projectedGrade}&currentWeeklyGrowth=${mttStatus.currentWeeklyGrowth}`);   
};
 
 