import * as React from 'react';
import { ReactNode } from "react";
import { ThemeProvider as StevensonThemeProvider } from "styled-components";
import { createTheme, ThemeProvider as MUThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

interface IProps {
  children: ReactNode;
}

declare module '@mui/material/styles' {
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
  interface Palette {
    neutral: Palette['primary'];
    black: Palette['primary'];
    blueGrey:Palette['primary'];
    warning:Palette['primary'];
    alert:Palette['primary'];
    gold:Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    blueGrey: PaletteOptions['primary'];
    alert:Palette['primary'];
    gold:Palette['primary'];
  }
}

const theme = {
  colors: {
    powderWhite: "#FFFDF9",
    white: "#FFFFFF",
    persianGreen: "#06B49A",
    lightBlue: "#AFDBD2",
    onyx: "#36313D",
    darkGrey: "#333333",
    greens: {
      main: '#275D38',
      light: '#3F7850',
      xlight: '#c5d6ca'
    },
    gold: {
      alert: '#c99700',
      lightAlert:`#f0e6c5`,
      default: '#e2bf54',
      dark: '#ccc19e'
    },
    greys: {
      dark: '#5b606e',
      xdark: '#3c404a',
      xxdark: '#80848e', 
      medium: '#7b7f8b',
      xmedium: '#f0f2f7',
      light: '#babfce',
      xlight: '#d0d0d0',
      xxlight: '#e7e7e7'
    }
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    smallest: ".7em",
    small: ".9em",
    medium: "2em",
    large: "3em",
    titleSmall: '1.2em',
    titleSmaller: '1em',
    titleLarger: '1.9em'
  },
  deviceSizes: {
    mobile: `(min-width: 360px)`,
    mobileL: `(min-width: 425px)`,
    mobileXL: `(min-width: 576px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    laptopL: `(min-width: 1440px)`,
    desktop: `(min-width: 2560px)`,
    print:`print`
  }
};

export const muiTheme = createTheme({
  status: {
    danger: orange[500]
  },
  palette: {
    primary: {
      main: '#275D38',
      light:'#62A476',
    },
    secondary: {
      main: '#E4E5E8',
      contrastText: '#374668',
    },
    info: {
      main: '#626671',
      contrastText: '#ffcc00',
    },
    neutral: {
      main: '#F1F1F1',
      light:'#DBDBDB',
      contrastText: '#3c404a',
    },
    blueGrey:{
      main: '#cacfe1',
      dark:'#5b606e',
      light:'#BABFCE',
      contrastText: '#3c3c3c',
    },
    black:{
      main: '#303132',
      dark:'#000',
      contrastText: '#3c404a',
    },
    warning:{
      main: '#C99700',
      dark:'#C99700',
      contrastText: '#3c404a',
    },
    alert:{
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    gold:{
      light: '#f0e6c5',
      main: '#c99700',
      dark: '#e2bf54',
      contrastText: '#544414',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,

      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          padding: '6px 9px',
          '&.Mui-disabled' : {backgroundColor: 'rgba(0, 0, 0, .03)'},
          '.MuiButton-startIcon' : { margin: '4px' },
        },
        containedSecondary:{
          color:'#303132',
          '&.Mui-disabled' : { color: 'rgba(0, 0, 0, .5) !important'},
        },
        outlined:{
          '&.MuiButton-outlinedInfo' : { color: '#000', textTransform:'none', fontSize:'.8rem' },
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '20px 0',
          '.MuiCardHeader-root' : {padding: '13px 16px 10px'},
          '.MuiTypography-root.MuiCardHeader-title' : {fontSize: '1.1rem'},
          '.MuiTypography-root.MuiListItemText-secondary' : {fontSize: '.9rem'},
          '.MuiTypography-root.MuiCardHeader-subheader' : {fontSize: '.8rem'},
          '.MuiCardHeader-action .MuiCheckbox-root.MuiButtonBase-root' : {background: '#f0f0f0'},
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
          padding: '10px',
          '.MuiTypography-root.MuiCardHeader-title' : {fontSize: '1.1rem'},
          '.MuiTypography-root.MuiListItemText-secondary' : {fontSize: '.9rem'},
          '.MuiTypography-root.MuiCardHeader-subheader' : {fontSize: '.9rem'},
          '.MuiSelect-nativeInput' : {fontSize: '.9rem'},
          '.MuiInputLabel-root' : { top: '-6px'},
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          '.MuiStepLabel-label' : {fontSize: '0.8rem'},
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          '.MuiTableRow-root' : {height: '20px',minHeight: '20px'},
          '.MuiTableCell-root' : {border: 'none',padding: '0.1rem !important', fontSize: '0.8rem',},
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '.MuiBadge-badge' : {padding: '0 4px'},
          '.MuiBadge-badge .MuiSvgIcon-root' : {fontSize: '0.8rem',},
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: '0px 4px',
          '&.MuiToggleButton-sizeSmall' : {backgroundColor: 'rgba(0, 0, 0, .03)'},
          '.MuiButtonBase-root': {padding: '0px 6px'},
          '.MuiButtonBase-root .MuiSvgIcon-root': {width: '.8em', height: '.8em', fontSize: '1.8rem'},
          '.MuiToggleButton-root.Mui-selected svg': {color: '#275D38 !important'},
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialogTitle-root' : {fontSize: '1rem', padding: '16px 24px 6px'},
          '.MuiDialogContentText-root' : {fontSize: '0.9rem',marginBottom: '10px'},
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiList-root:focus' : {outline: 'none'},
          '.split-menu .MuiListItemButton-root:hover' : { background: 'transparent', cursor:'default' },
          '.split-menu .MuiListItemButton-root' : {paddingTop: '0',  paddingBottom: '0',},
          '.split-menu .MuiButtonBase-root .MuiListItemText-root' : { paddingTop: '8px',  paddingBottom: '8px', paddingLeft: '8px' ,margin:'0' },
          '.split-menu .MuiButtonBase-root .MuiListItemText-root:hover' : { backgroundColor: 'rgba(0, 0, 0, 0.04)', cursor:'pointer' },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: '50px',
          '&.main-header' : { boxShadow: 'none' },
          '&.main-header .MuiMenuItem-root' : {
            color: '#fff', fontSize: '14px', backgroundColor: '#214D2E', borderRadius: '4px', margin:'0 4px' , fontWeight: '500',
          },
          '&.main-header .MuiMenuItem-root:hover, &.main-header .MuiMenuItem-root.Mui-selected' : { backgroundColor: '#62A476', color: '#303132',fontWeight: '500', textDecoration: 'none'  },
          '&.main-header .MuiMenuItem-root a:hover, &.main-header .MuiMenuItem-root.Mui-selected a' : {color: '#303132', fontWeight: '500', textDecoration: 'none' },
          '&.main-header .MuiMenuItem-root .MuiSvgIcon-root' : { color: '#9E9E9F !important' },
          '&.main-header .MuiMenuItem-root:hover .MuiSvgIcon-root' : { color: '#303132 !important' },
          '.MuiFormControlLabel-label, .MuiFormControlLabel-root': {fontSize: '13px', margin: '0', color: '#4c515f'},
          '&.MuiAppBar-positionStatic' : {backgroundColor: '#cacfe1'},
          '.MuiSelect-select.MuiSelect-outlined' : {backgroundColor: '#fff'},
          '.MuiFormControl-root' : {backgroundColor: '#babfce',minHeight: '50px'},
          '.MuiToolbar-root': {minHeight: '50px', padding:'0'},
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '.MuiListItemText-multiline' : {flex: 'auto', display:'flex',flexDirection:'row', justifyContent: 'space-between'},
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: '30px',
          alignItems: 'center'
        },
      }
    }

  }
});

// #cacfe1
const Theme = ({ children }: IProps) => (
  <MUThemeProvider theme={muiTheme}>
    <StevensonThemeProvider theme={theme}>
      {children}
    </StevensonThemeProvider>
  </MUThemeProvider>
);

export default Theme;
