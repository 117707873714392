import React, { useEffect, useState } from 'react';
import { Course } from '../../models/Course';
import { TeacherStudent } from '../../models/TeacherStudent';
import Period from '../../models/Period';
import { DropDown } from '../inputs-elements/DropDown';
import { GradebookEntry, IStudentGradeBook } from '../../models/GradebookEntry';
import { FlexRow, FlexCol, TextOverflow } from '../layout/FlexHelpers';
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft, PanelChildRight } from '../layout/PanelTitleBar';
import { WeekType } from 'models/WeeklyGrowth';
import HoverModal, { HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import ProjectedGradeLetter from '../layout/ProjectedGradeLetter';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import './Shared.css';
import { ISelectableItem } from '../inputs-elements/ActionBar';
import Checkbox from '@mui/material/Checkbox';
import { FeatureFlagState } from '../../store/featureflags/types';
import FeatureGate from '../../FeatureGate';
import { Assessment } from 'models/Assessment';
import { IMenuListItems } from 'models/Menus';
import { connect, useDispatch, useSelector } from 'react-redux';
import { globalSelector } from "../../store/global/globalReducer";
import { updateStudentsGradeMode, getDirectGradeOptions } from 'api/gradebookApi';
import { GradeMode } from 'models/Student';
import { IActiveStudent } from 'models/Student';
import { IDirectGradeOption } from 'models/DirectGradeOption';


import { NotificationsType, updateNotificationsAction } from 'store/notifications/notificationsReducer';
import { AxiosResponse } from "axios";
import { initializeGradeModeAction, updateGradeModeAction } from 'store/student/gradebookReducer';
import { gradebooksSelector, updateFinalGradeStatusAction, initializeFinalVerificationAction, updateStudentAssessmentAction, initializeStudentAssessmentAction } from "../../store/student/gradebookReducer";
import Tooltip from '@mui/material/Tooltip';
import { CustomVerificationSwitch } from 'components/inputs-elements/CustomVerificationSwitch';
import { VerificationSwitch } from 'components/inputs-elements/VerificationSwtich';
import { DirectGradeVerificationSwitch } from 'components/inputs-elements/DirectGradeVerificationSwitch';
import { postStudentsFinalGradeStatus, postDGStudentsFinalGradeStatus, getStudentAssesments } from 'api/gradebookApi';
import { convertDgToMenuList, hasWeeklyScoreAlready } from "../../utils/helpers/generalHelpers";
import { CommentDialog } from 'components/layout/CommentDialog';
import { isEmpty } from 'utils/helpers/stringHelpers';


export interface StudentSelectStateProps {
  features: FeatureFlagState;
}

export interface StudentSelectCardProps {
  course: Course;
  grades: GradebookEntry[];
  onPeriodChanged: Function;
  onSorting: Function;
  onStudentSelect: Function;
  periods: Period[];
  selectableGradebookEntries: Array<ISelectableItem<IStudentGradeBook>>;
  selectedPeriod: number;
  selectedStudent?: TeacherStudent;
  selectionChange: (selections: Array<ISelectableItem<IStudentGradeBook>>) => void;
  onWeeklySelect: (selectedGradeBook: GradebookEntry) => void;
  sortOrder: boolean;
}

const StudentSelectCard = (props: StudentSelectCardProps) => {

  //#region : Variables
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const dispatch = useDispatch();
  const [commentDialogIsOpen, setCommentDialogIsOpen] = useState(false);
  const [assignWOutComments, setAssignWOutComments] = useState(false);
  const [directGradeOptions, setDirectGradeOptions] = useState<Array<IMenuListItems>>([]);
  const [studentCurrentWeekScore, setStudentCurrentWeekScore] = useState({ currentScoreComment: '', currentScore: '', hasScoreAlready: false });
  const [activeStudent, setActiveStudent] = useState<IActiveStudent>({ studentName: '', studentId: -1, directGradeEligible: false, studentProjectedGrade: '', studentFinalGrade: '', studentFinalGradeStatus: undefined, studentMttStatus: '', sectionId: -1, scoreText: '', scoreIntials: '' });
  const { termIsEditable, currentWeekHideProjectedGrade, currentWeekTaskId, viewingWeekType, currentTermId, calendarId, gradeMode, isFinalWeekMode } = useSelector(globalSelector);
  const { activeSectionId, activeTeacherId, allGradebooks } = useSelector(gradebooksSelector);
  const icon = renderIcon();

  const teacherName: string = props.course.teacherName !== null ? props.course.teacherName : props.course.teacherDisplay !== null ? props.course.teacherDisplay : '';
  const [toggleIsDisabled, setToggleIsDisabled] = React.useState<boolean>(false);

  //#endregion

  //#region : Hooks
  useEffect(() => {
    if (isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) && activeSectionId !== -1) {
      fetchDirectGradeOptions(activeSectionId);
    }
  }, [viewingWeekType, isFinalWeekMode, activeSectionId]);

  useEffect(() => {
    if (assignWOutComments && isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek)) {
      activeStudent.directGradeEligible ? updateDGStudentsFinalGradeStatus(undefined, true) : updateStudentsFinalGradeStatus(undefined, true);
    }

  }, [assignWOutComments, activeStudent.directGradeEligible, viewingWeekType, isFinalWeekMode]);


  //#endregion

  //#region : Async Functions
  async function updateStudentGradeMode(event: React.ChangeEvent<HTMLInputElement>, studentId: number, /*gradeMode: GradeMode,*/ sectionId: number) {

    dispatch(initializeGradeModeAction([studentId]));
    setToggleIsDisabled(true);

    const response = await updateStudentsGradeMode([{
      studentId: studentId,
      gradeMode: event.target.checked ? GradeMode.SingleGrade : GradeMode.DoubleGrade
    }], currentWeekTaskId, sectionId, currentTermId, calendarId).catch(error => {
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: ${error.statusText} | Student's Grade Mode`
      }));
    });

    if (response && response.data) {
      const correctStudent = response.data.find(student => student.studentId === studentId);
      if (correctStudent) {
        dispatch(updateGradeModeAction([{
          studentId: correctStudent.studentId,
          gradeMode: correctStudent.gradeMode,
          projectedGrade: correctStudent.projectedGrade
        }]));
        dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: `Student's Grade Mode Updated` }));
      }
    } else {
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Student's Grade Mode`
      }));
    }
    setToggleIsDisabled(false);

  }

  async function updateStudentsFinalGradeStatus(newCommentText?: string, updateFinalGradeStatus: boolean | undefined = undefined) {

    const finalGradeStatusChanged: boolean = (updateFinalGradeStatus !== undefined) && activeStudent.studentFinalGradeStatus !== undefined ? true : false;
    let bodyRequest: IStudentGradeBook = { studentPersonId: -1, sectionId: -1 };
    let successMessage: string = ``;

    dispatch(initializeFinalVerificationAction([{ studentId: activeStudent.studentId }]));

    // Update Status and Add Comment
    if (finalGradeStatusChanged && newCommentText) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
        comment: newCommentText,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade & comment`;
    }

    // Only add comment
    if (!finalGradeStatusChanged && newCommentText) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        comment: newCommentText
      };
      successMessage = `Successfully updated comment`;
    }
    // Only update status
    if (finalGradeStatusChanged && !newCommentText) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade`;
    }


    const response: AxiosResponse<IStudentGradeBook[]> = await postStudentsFinalGradeStatus([bodyRequest], activeTeacherId, currentTermId, finalGradeStatusChanged).catch(error => {
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
      // TODO : Add App Insights error
    });

    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);


      if (correctStudent) {
        setCommentDialogIsOpen(false);
        setAssignWOutComments(false);

        // If a final grade status is UNVERIFIED, then we need to get the latest assessment that may have been updated from Infinite Campus,
        if (correctStudent.isVerified === false && finalGradeStatusChanged) {
          fetchUpdatedStudentAssesment(activeStudent.sectionId, activeStudent.studentId);

        } else {

          dispatch(updateFinalGradeStatusAction([{
            studentPersonId: activeStudent.studentId,
            sectionId: activeStudent.sectionId,
            isVerified: updateFinalGradeStatus == undefined ? activeStudent.studentFinalGradeStatus : activeStudent.studentFinalGradeStatus ? false : true,
            finalGrade: activeStudent.studentFinalGrade,
            comment: correctStudent.comment ? correctStudent.comment : undefined,
            sequence: correctStudent ? correctStudent['seq'] : 0,
            taskId: currentWeekTaskId,
          }]));
          dispatch(updateNotificationsAction({
            notificationStatus: NotificationsType.Success,
            notificationMessage: successMessage
          }));

        }



      }
    } else {
      // reset to pre error state
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
    }
  }

  async function updateDGStudentsFinalGradeStatus(newCommentText?: string, updateFinalGradeStatus: boolean | undefined = undefined) {
    const newDirectGradeAssigned: boolean = (activeStudent.directGradeEligible && activeStudent.scoreIntials !== '') ? true : false;
    const verificationStatusChanged: boolean = (updateFinalGradeStatus !== undefined) && (activeStudent.studentFinalGradeStatus !== undefined) && !newDirectGradeAssigned ? true : false;

    let bodyRequest: IStudentGradeBook = { studentPersonId: -1, sectionId: -1 };
    let successMessage: string = ``;

    dispatch(initializeFinalVerificationAction([{ studentId: activeStudent.studentId }]));

    // Assign Direct Grade only
    if (!verificationStatusChanged && !newCommentText && newDirectGradeAssigned) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        // scoreIntials is the new final grade we want to assign 
        finalGrade: activeStudent.scoreIntials,
        isVerified: verificationStatusChanged ? (activeStudent.studentFinalGradeStatus ? false : true) : activeStudent.studentFinalGradeStatus
      };
      successMessage = `Successfully assigned a new Final Direct Grade`;
    }

    // Assign a Direct Grade and Comment via dg menu
    if (newCommentText && newDirectGradeAssigned && !verificationStatusChanged) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        finalGrade: activeStudent.scoreIntials,
        isVerified: activeStudent.studentFinalGradeStatus,
        comment: newCommentText
      };
      successMessage = `Successfully assigned a new final grade and comment`;
    }

    // Assign a comment only / comment via switch
    if (newCommentText && !verificationStatusChanged && !newDirectGradeAssigned) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        finalGrade: '',
        isVerified: activeStudent.studentFinalGradeStatus,
        comment: newCommentText
      };
      successMessage = `Successfully updated comment`;
    }

    // Update Verification Status only
    if (verificationStatusChanged && !newCommentText && !newDirectGradeAssigned) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible: activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.scoreIntials !== '' ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade`;
    }

    const response: AxiosResponse<IStudentGradeBook[]> = await postDGStudentsFinalGradeStatus([bodyRequest], activeTeacherId, currentTermId, verificationStatusChanged).catch(error => {
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
      // TODO : Add App Insights error
    });

    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);

      if (correctStudent) {
        setCommentDialogIsOpen(false);
        setAssignWOutComments(false);

        if (correctStudent.isVerified === false && verificationStatusChanged) {
          fetchUpdatedStudentAssesment(activeStudent.sectionId, activeStudent.studentId);

        } else {
          dispatch(updateFinalGradeStatusAction([{
            studentPersonId: activeStudent.studentId,
            sectionId: activeStudent.sectionId,
            isVerified: correctStudent.isVerified,
            finalGrade: (newDirectGradeAssigned && activeStudent.directGradeEligible) ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
            comment: correctStudent.comment ? correctStudent.comment : undefined,
            sequence: correctStudent ? correctStudent['seq'] : 0,
            taskId: currentWeekTaskId,
          }]));
          dispatch(updateNotificationsAction({
            notificationStatus: NotificationsType.Success,
            notificationMessage: successMessage
          }));

        }

      }
    } else {
      // reset to pre error state
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
    }
  }


  async function fetchUpdatedStudentAssesment(sectionId: number, studentId: number) {

    dispatch(initializeStudentAssessmentAction([{ studentId: activeStudent.studentId }]));

    const response: AxiosResponse<Assessment[]> = await getStudentAssesments(sectionId, [studentId]).catch(error => {
      // TODO : Add App Insights error
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Getting Student(s) Assessment`
      }));
    });


    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonID === activeStudent.studentId);

      if (correctStudent) {
        dispatch(updateStudentAssessmentAction([{
          studentPersonID: activeStudent.studentId,
          sectionID: activeStudent.sectionId,
          isVerified: correctStudent.isVerified,
          finalGrade: correctStudent.finalGrade,
          projectedGrade: correctStudent.projectedGrade,
          standards: correctStudent.standards,
          weeklyGrowth: '',
          // TODO REVIEW
          isFinal: false,
          gradeMode: correctStudent.gradeMode
        }]));
        dispatch(updateNotificationsAction({
          notificationStatus: NotificationsType.Success,
          notificationMessage: `Successfully unverified the final grade & retrieved latest Skills`
        }));
      }
    } else {
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Getting Student(s) Assessment`
      }));
    }
  }

  async function fetchDirectGradeOptions(currentSectionId: number) {
    const response = await getDirectGradeOptions(currentSectionId).catch(error => {
    });

    if (response && response.data) {
      const responseDirectGradeOptions: IDirectGradeOption[] = response.data.map(option => ({
        score: option.score,
        seq: option.seq
      }));
      setDirectGradeOptions(convertDgToMenuList(responseDirectGradeOptions));
    }
  }

  //#endregion


  //#region : Functions
  const showCheckBox = (isDirectGradeStudent: boolean): boolean => {
    if (!isDirectGradeStudent)
      return true;
    if (isDirectGradeStudent && viewingWeekType == WeekType.FinalWeek)
      return false;
    return true;
  }

  const errorFinalGradeStatus = (studentId: number, sectionId: number, finalGrade: string | undefined, isVerified: boolean | undefined): void => {
    dispatch(updateFinalGradeStatusAction([{
      studentPersonId: studentId,
      sectionId: sectionId,
      isVerified: isVerified,
      finalGrade: finalGrade
    }]));
    dispatch(updateNotificationsAction({
      notificationStatus: NotificationsType.Error,
      notificationMessage: `Error: Failed to update the Final Grade Status`
    }));
  }

  const updateStudentAndScore = (student: TeacherStudent, projectedGrade: string, currentMTTStatus: string = '', newScoreText: string = '', newScoreIntials: string = '', openWGCommentsDialog: boolean, finalGrade: string = '', finalGradeCurrenlyVerified?: boolean) => {
    setActiveStudent({
      studentName: student.lastName + ', ' + student.firstName,
      studentId: student.personId,
      sectionId: student.sectionId,
      directGradeEligible: student.directGradeEligible,
      scoreText: newScoreText,
      scoreIntials: newScoreIntials,
      studentProjectedGrade: projectedGrade,
      studentFinalGrade: student.directGradeEligible && newScoreIntials && !finalGrade ? newScoreIntials : finalGrade,
      studentFinalGradeStatus: finalGradeCurrenlyVerified,
      studentMttStatus: currentMTTStatus
    });

    const activeStudentGradebook: GradebookEntry = allGradebooks.find((gradeBook: GradebookEntry) => gradeBook.student.personId === student.personId);
    if (activeStudentGradebook) {
      // Auto fill comments and score if there is an exsiting one already for the week.
      const { hasWeeklyScore, score, comment } = hasWeeklyScoreAlready(activeStudentGradebook.weeklyGrowth, currentWeekTaskId);
      if (hasWeeklyScore)
        setStudentCurrentWeekScore({ currentScoreComment: comment, currentScore: score, hasScoreAlready: true });
    }
    openWGCommentsDialog ? setCommentDialogIsOpen(true) : setAssignWOutComments(true);
  };
  //#endregion

  return (
    <>
      <PanelTitleBar toolBarType={PanelTitleTypes.Secondary}>
        <PanelChildLeft>
          {renderHeaderDisplay(teacherName, props.course.courseName, icon, props.course.teacherEmail, props.course.teacherPhone)}
        </PanelChildLeft>
        <PanelChildRight>
          {renderPeriodDropdown(props.periods, props.selectedPeriod, props.onPeriodChanged)}
        </PanelChildRight>
      </PanelTitleBar>

      {props.selectedStudent &&
        <>
          <div className="courseListContainer">
            <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel">
              <thead className="gradebookTableHeaderRow">
                <tr>
                  <th style={{ width: '56px' }}>&nbsp;</th>
                  <th style={{ minWidth: '120px', fontSize: '13px' }}>
                    <a onClick={() => props.onSorting()} style={{ cursor: 'pointer', display: 'block' }}>
                      Student Name
                      {props.sortOrder ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
                    </a>
                  </th>
                  {viewingWeekType !== WeekType.FinalWeek && (
                    <th style={{ width: '86px', fontSize: '13px' }} >
                      {!currentWeekHideProjectedGrade && (
                        <FlexRow justifyContent='flex-end' flexDirection='column'>
                          <FlexCol textAlign='center'>
                            <HoverModal title='Projected Grade' type={HoverModalTypes.Third} content={HoverModalContent.projectedgrade} />
                          </FlexCol>
                        </FlexRow>
                      )}
                    </th>
                  )}
                  {viewingWeekType === WeekType.FinalWeek && (
                    <th style={{ width: '86px', fontSize: '13px' }} >
                      <FlexRow justifyContent='flex-end' flexDirection='column'>
                        <FlexCol textAlign='center'>
                          <HoverModal title='Final Grade' type={HoverModalTypes.Third} content={HoverModalContent.finalWeek} />
                        </FlexCol>
                      </FlexRow>
                    </th>
                  )}

                </tr>
              </thead>
              <tbody>
                {props.grades.map((grade: GradebookEntry, index: number) => {
                  const isChecked = props.selectableGradebookEntries.some(sgbe => sgbe.selected && sgbe.id === grade.student.personId.toString());
                  const selectedStudentItem: IStudentGradeBook = {
                    studentPersonId: grade.student.personId,
                    sectionId: grade.assessment.sectionID,
                    weeklyGrowthScore: grade.assessment.weeklyGrowth,
                    projectedGrade: grade.assessment.projectedGrade,
                    finalGrade: grade.assessment.finalGrade,
                    currentMTTStatus: grade.mandatoryTargetedTutoring.currentStudentStatus,
                    taskId: currentWeekTaskId
                  };
                  const isDirectGradeAssigned:boolean = grade.assessment.finalGrade !== '' && 
                                                        grade.assessment.finalGrade !== null && 
                                                        grade.student.directGradeEligible
                                                          ? true : false;


                  return (
                    <tr className={`studentRow`} key={grade.student.personId}>
                      <td>
                        {
                          (
                            !grade.student.isDropped &&
                            showCheckBox(grade.student.directGradeEligible) &&
                            FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) &&
                            termIsEditable
                          ) &&
                          (
                            <Checkbox
                              checked={isChecked}
                              onChange={(e) => {
                                props.selectionChange([{ id: grade.student.personId.toString(), selected: isChecked, item: selectedStudentItem }])
                              }}
                            />
                          )
                        }
                      </td>
                      <td onClick={(e) => props.onStudentSelect(grade)}>
                        <div className="gradebookTableRow" style={{ display: 'inline' }}>
                          {grade.student.lastName}, {grade.student.firstName}
                        </div>
                      </td>



                      <td>
                        <div className="gradebookTableRow" style={{ display: 'flex', justifyContent: 'center', height: '70px', alignItems: 'center', flexDirection: 'column', }}>
                          {/* Not viewing a Final week */}
                          {viewingWeekType !== WeekType.FinalWeek && (
                            <ProjectedGradeLetter parentRange={currentWeekHideProjectedGrade ? '-' : grade.assessment.projectedGrade} />
                          )}


                          {/* Single Grade mode enabled and not Final week and has a projected grade*/}
                          {gradeMode === GradeMode.SingleGrade && !grade.student.isDropped && !isFinalWeekMode && (isEmpty(grade.assessment.projectedGrade) === false)  && (
                            <Tooltip enterDelay={1000} title={grade.assessment.gradeMode === GradeMode.SingleGrade ? 'Disable single grade mode' : 'Enable single grade mode'} >
                              <CustomVerificationSwitch
                                {...{ inputProps: { 'aria-label': grade.assessment.gradeMode === GradeMode.SingleGrade ? 'Disable single grade mode' : 'Enable single grade mode' } }}
                                checked={grade.assessment.gradeMode === GradeMode.SingleGrade ? true : false}
                                onChange={(event) => updateStudentGradeMode(event, grade.student.personId, grade.student.sectionId)}
                                inputProps={
                                  { 'aria-label': grade.assessment.gradeMode === GradeMode.SingleGrade ? 'Disable single grade mode' : 'Enable single grade mode' }
                                }
                                disabled={toggleIsDisabled}
                              />
                            </Tooltip>
                          )}

                          {/* Is either a real Final Week, or App is set to Final week mode */}
                          {isFinalWeekMode && viewingWeekType === WeekType.FinalWeek && (
                            !grade.student.isDropped && !grade.student.directGradeEligible ? (
                                <VerificationSwitch
                                  buttonId={grade.student.personId + `-` + index}
                                  labelText={grade.assessment.finalGrade}
                                  isVerified={grade.assessment.isVerified}
                                  switchPrimaryFunction={(withComments: boolean = false) =>
                                    updateStudentAndScore(
                                      grade.student,
                                      grade.assessment.projectedGrade,
                                      grade.mandatoryTargetedTutoring.currentStudentStatus,
                                      grade.assessment.finalGrade,
                                      grade.assessment.finalGrade,
                                      withComments,
                                      grade.assessment.finalGrade,
                                      grade.assessment.isVerified
                                    )
                                  }
                                  viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                                />
                            ) : (

                              !grade.student.isDropped && grade.student.directGradeEligible && (
                                  <DirectGradeVerificationSwitch
                                    buttonId={grade.student.personId + `-` + index}
                                    labelText={
                                      grade.assessment.finalGrade !== '' && grade.assessment.finalGrade !== null ?
                                        grade.assessment.finalGrade :
                                        (grade.assessment.projectedGrade !== "" && grade.assessment.projectedGrade !== null) ? grade.assessment.projectedGrade :
                                          '-'
                                    }
                                    isVerified={grade.assessment.isVerified}
                                    isVerificationSwitchHidden={isDirectGradeAssigned ? false : true}
                                    updateDirectGradeFunction={(directGrade: string, withComments: boolean = false) =>
                                      updateStudentAndScore(
                                        grade.student,
                                        grade.assessment.projectedGrade,
                                        grade.mandatoryTargetedTutoring.currentStudentStatus,
                                        directGrade,
                                        directGrade,
                                        withComments,
                                        grade.assessment.finalGrade,
                                        grade.assessment.isVerified

                                      )
                                    }
                                    updateVerificationStatus={(withComments: boolean = false) =>
                                      updateStudentAndScore(
                                        grade.student,
                                        grade.assessment.projectedGrade,
                                        grade.mandatoryTargetedTutoring.currentStudentStatus,
                                        ``,
                                        ``,
                                        withComments,
                                        grade.assessment.finalGrade,
                                        grade.assessment.isVerified
                                      )
                                    }
                                    menuTitle='Assign a Direct Grade'
                                    menuItems={directGradeOptions}
                                    viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                                  />
                              )
                            )
                          )}


                        </div>
                      </td>


                    </tr>
                  )
                }
                )}
              </tbody>
            </table>
          </div>

          <CommentDialog
            title={
              // TODO: check if needed 
              isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
                activeStudent.directGradeEligible ?
                  activeStudent.scoreIntials == `` ? `Add a Final Comment for  ${activeStudent.studentName}` : `Assign a Direct Grade \\ Final Comment for  ${activeStudent.studentName}` :
                  `Add a Final Comment for  ${activeStudent.studentName}`
                : ``
              // `Assign ${activeStudent.studentName} a (${activeStudent.scoreText}) with comments`
            }
            descriptionText={
              isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
                activeStudent.directGradeEligible ?
                  activeStudent.scoreIntials == `` ? `` : `You are assigning ${activeStudent.studentName} a final grade of (${activeStudent.scoreIntials})` :
                  `You can also ${activeStudent.studentFinalGradeStatus ? `unverify` : `verify`} the final grade of (${activeStudent.studentFinalGrade}) by toggling the switch`
                : ``
            }

            commentPlaceholder={`${isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? `Enter a final comment or` : `Enter a weekly comment or`}  choose from the picklist`}
            submitBtnText='Save'
            cancelBtnText='Cancel'
            isOpen={commentDialogIsOpen}
            showCommentOptions
            commentValue={
              (studentCurrentWeekScore.currentScoreComment && (activeStudent.scoreIntials === studentCurrentWeekScore.currentScore)) ||
                (studentCurrentWeekScore.currentScoreComment && (activeStudent.directGradeEligible === true)) ?
                studentCurrentWeekScore.currentScoreComment : ''
            }
            isFinalCommentMode={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? true : false}
            currentFinalGradeStatus={activeStudent.studentFinalGradeStatus}
            directGradeStudentData={
              (activeStudent.directGradeEligible) ? {
                currentFinalGrade: activeStudent.studentFinalGrade !== undefined ? activeStudent.studentFinalGrade : '',
                newFinalDirectGrade: activeStudent.scoreIntials
              } : undefined
            }
            cancelFunction={() => setCommentDialogIsOpen(false)}
            submitFunction={(comment: string, finalGradeStatus?: boolean | undefined) =>
              // TODO: check if needed 
              isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) &&
              (activeStudent.directGradeEligible ? updateDGStudentsFinalGradeStatus(comment, finalGradeStatus) : updateStudentsFinalGradeStatus(comment, finalGradeStatus))


            }
          />

        </>
      }
    </>
  )
}


const renderPeriodDropdown = (periods: Period[], selectedPeriod: number, onPeriodSelect: Function) => {

  return <DropDown
    textColor='#ffffff'
    isPeriod items={periods}
    selectedItem={selectedPeriod}
    text="periodName"
    subTitle="courseNumber"
    description={"courseName"}
    closeOnMouseExit={true}
    onSelect={onPeriodSelect}
    idPath="periodId" />
}

const renderIcon = () => {
  return <ContactMailIcon />
}

const renderHeaderDisplay = (teacherName: string, courseName: string, icon: JSX.Element, teacherEmail: string, teacherPhone: string) => {

  return (
    <>
      <TextOverflow>
        {courseName}
      </TextOverflow>
      <HoverModal
        title={teacherName}
        type={HoverModalTypes.Teacher}
        preventHeadlineWrap={true}
        teacherContactInfo={{
          email: teacherEmail,
          phone: teacherPhone
        }}
      />
    </>
  );

}

function mapStateToProps(state: any): StudentSelectStateProps {
  return {
    features: state.featureFlags,
  };
}
export default connect<StudentSelectStateProps>(mapStateToProps)(StudentSelectCard);