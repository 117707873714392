import moment, { Moment } from "moment";
import { Course } from "./Course";
import { GradebookEntry } from "./GradebookEntry";
import { IStudentInterventionEntry } from "./MandatoryTargetedTutoring";

export interface TutorViewJson {
    interventionId: number;
    sectionId: number;
    
    studentPersonId: number;
    studentLastName: string;
    studentFirstName: string;
    studentName: string;

    tutorPersonId: number; 
    tutorName: string;
    
    teacherPersonId: number; 
    teacherName: string;

    sstPersonID:number;
    commenterPersonId?: number; 
    commenterName?: string;
    latestComment?: string;
    latestCommentDate?: string;

    courseCode: string;
    coursePeriod: number;
    courseId: number;
    courseName: string;

    divisionCode:string;
    divisionName:string;

    weeklyGrade: string;
    projectedGrade: string;
    skills?: string;
    availableSkills: string;
}

export class TutorView {
    public static fromJson = (data: TutorViewJson) => {
        if (data) {
            return new TutorView(
                Number(data.sectionId), 
                Number(data.studentPersonId),
                data.studentLastName,
                data.studentFirstName,
                data.teacherName,
                (data.commenterName !== undefined && data.latestComment !== null) ? data.commenterName : '',
                (data.latestComment !== undefined && data.latestComment !== null) ? data.latestComment : '',
                data.courseName,
                data.courseId,
                data.divisionCode,
                data.divisionName,
                
                (data.commenterPersonId !== undefined && data.commenterPersonId !== null) ? Number(data.commenterPersonId) : -1, 
                Number(data.tutorPersonId), 
                data.tutorName,
                Number(data.teacherPersonId),

                Number(data.sstPersonID), 
                
                Number(data.interventionId), 

                (data.courseCode !== undefined && data.courseCode !== null) ? data.courseCode : '', 
                (data.coursePeriod !== undefined && data.coursePeriod !== null) ? data.coursePeriod : -2,
                (data.weeklyGrade !== undefined && data.weeklyGrade !== null) ? data.weeklyGrade : '', 
                (data.projectedGrade !== undefined && data.projectedGrade !== null) ? data.projectedGrade : '', 
                true,
                (data.availableSkills !== undefined && data.availableSkills !== null) ? 
                    data.availableSkills.replace(/\[/g, "").replace(/\]/g, "").replace(/\'/g, "").split(",")
                    : (data.skills !== undefined && data.skills !== null) ? data.skills.replace(/\[/g, "").replace(/\]/g, "").replace(/\'/g, "").split(",") // TechDebt: Remove this fallback measure once https://dev.azure.com/StevensonIT/IRC/_workitems/edit/250 is complete
                    : [],
                (data.skills !== undefined && data.skills !== null) ? data.skills.replace(/\[/g, "").replace(/\]/g, "").replace(/\'/g, "").split(",") : [],
                (data.latestCommentDate !== undefined && data.latestCommentDate !== null) ? moment(data.latestCommentDate) : undefined,
            );
        }
        return new TutorView();
    }

    public static toStudentInterventionEntry = (tutorView: TutorView) => {
        const selectedCourse = new Course();
        selectedCourse.courseName = tutorView.courseName;
        selectedCourse.courseNumber = tutorView.courseCode;
        selectedCourse.ebrFlag = tutorView.courseEbrFlag;
        selectedCourse.periodName = tutorView.coursePeriod.toString();
        selectedCourse.teacherName = tutorView.teacherName;
        selectedCourse.courseId = tutorView.courseId;

        const selectedGradeBookEntry = GradebookEntry.fromTutorView(tutorView);
        
        const selectedMttEntry: IStudentInterventionEntry = {
            studentGradebook: selectedGradeBookEntry,
            course: selectedCourse
        }

        return selectedMttEntry;
    }

    constructor(
        public sectionId: number = -1,
        public studentPersonId: number = -1,
        public studentLastName: string = '',
        public studentFirstName: string = '',
        public teacherName: string = '',
        public commenterName: string = '',
        public latestComment: string = '',
        public courseName: string = '',
        public courseId: number = -1,
        public divisionCode: string = '',
        public divisionName: string = '',
        public commenterPersonId: number = -1,
        public tutorPersonId: number = -1,
        public tutorName: string = '',
        public teacherPersonId: number = -1,
        public sstPersonID: number = -1,
        public interventionId: number = -1,
        public courseCode: string = '',
        public coursePeriod: number = -1,
        public weeklyGrowth: string = '',
        public projectedGrade: string = '',
        public courseEbrFlag: boolean = true,  
        public availableSkills: Array<string> = [], 
        public skills: Array<string> = [],     
        public latestCommentDate?: Moment,                
    ) {}
}