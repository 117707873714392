import React from 'react';
import '../shared/Shared.css';
 
export interface SubHeaderProps{
    leftChild?: JSX.Element;
    rightChild?: JSX.Element;
}
export const SubHeader = (props: SubHeaderProps) => {
    return (
    <div className="termHeader">
        <div className="secondDropdown" style={{ textAlign: "right"}}>
            {props.rightChild}   
        </div>
    </div>
    );
  }