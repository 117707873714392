import * as React from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { Teacher } from '../../models/Teacher';
import { Row, Col } from '../layout/ResponsiveGrid';
import { DropDown } from './DropDown'; 
import { SubHeader } from '../layout/SubHeader';
import { Term } from '../../models/Term';
import { Student } from '../../models/Student';
import { TeacherStudent } from '../../models/TeacherStudent';

export enum SearchType {
    Teacher = 0,
    Student = 1
}

export interface SearchTeacherStudentProps {
    // Required Props
    alternateSearch?: (e: SearchType) => void;
    hideAlternateSearch?: boolean;
    hideTitle?: boolean;
    loading: boolean;
    searchType: SearchType;
    selectedStudent?: Student | TeacherStudent;
    selectedTerm: number;
    selectedTermChanged?: Function;
    terms: Term[];

    // Optional Props for searching students
    selectedStudentChanged?: (selection: any) => any;
    studentList?: Student[];

    // Optional Props for searching Teachers
    selectedTeacher?: Teacher;
    selectedTeacherChanged?: (selection: any) => any;
    teacherList?: Teacher[];
}

interface searchTypeOption {
    id: number;
    name: string;
}

export interface ISearchTeacherStudentState {
    searchTerm: string;
    searchTypeOptions: Array<searchTypeOption>;
    studentOptions: Array<OptionTypeBase>;
    teacherOptions: Array<OptionTypeBase>;
}

export interface IStudentOption extends OptionTypeBase {
    label: string;
    value: Student;
}

export class SearchTeacherStudent extends React.Component<SearchTeacherStudentProps, ISearchTeacherStudentState> {
    teacherSearchList = this.props.teacherList ? this.props.teacherList.map((teacher: Teacher)=>
    {
        return {
            value: teacher,
            label: `${teacher.lastName}, ${teacher.firstName}`
        }
    }) : [];

    studentSearchList: Array<IStudentOption> = this.props.studentList ? this.props.studentList.map((student: Student)=>
        {
            return {
                value: student,
                label: `${student.lastName}, ${student.firstName} (${student.studentNumber})`
            }
        }) : [];
    
    // TODO: Once student search is implemented, update this so that teachers is only an option for those with the ability to search for teachers
    searchTypeOptions: Array<searchTypeOption> = [
        { "id": 0, "name": "Teachers" },
        { "id": 1, "name": "Students" },
    ];

    constructor(props: any) {
        super(props);                
        this.state = {
            searchTerm: '',
            searchTypeOptions: [],
            studentOptions: [],
            teacherOptions: [],            
        };
    }

    componentDidMount() {
        // let searchTypeOptions = [{ "id": 0, "name": "Teachers" }, { "id": 1, "name": "Students" },];
        // If/when client opts to leverage redirects between search types, uncomment the line above & remove the line below 
        const searchTypeOptions = this.props.searchType === SearchType.Teacher ? [{ "id": 0, "name": "Teachers" }] : [{ "id": 1, "name": "Students" }];
        this.setState({
            searchTypeOptions,
            teacherOptions: this.getTeacherOptions(this.props.teacherList),
            studentOptions: this.getStudentOptions(this.props.studentList),
        });
    }

    componentDidUpdate(prevProps: SearchTeacherStudentProps) {
        if (prevProps.teacherList && (prevProps.teacherList?.length !== this.props.teacherList?.length)) {
            this.setState({
                teacherOptions: this.getTeacherOptions(this.props.teacherList)
            });
        }
        if (prevProps.studentList && (prevProps.studentList?.length !== this.props.studentList?.length)) {
            this.setState({
                studentOptions: this.getStudentOptions(this.props.studentList)
            });
        }
    }

    render() {
        let selectedOption : any = null;
        if (this.props.searchType === SearchType.Student) {
            selectedOption = (this.props.selectedStudent === null || this.props.selectedStudent === undefined || (this.props.selectedStudent.personId === -1)) ? null : {
                value: this.props.selectedStudent,
                label: `${this.props.selectedStudent.lastName}, ${this.props.selectedStudent.firstName} (${this.props.selectedStudent.studentNumber})`
            };
        } else if (this.props.searchType === SearchType.Teacher) {
            selectedOption = (this.props.selectedTeacher === null || this.props.selectedTeacher === undefined || (this.props.selectedTeacher.personId === -1)) ? null : {
                value: this.props.selectedTeacher,
                label: `${this.props.selectedTeacher.lastName}, ${this.props.selectedTeacher.firstName}`
            };
        }

        let searchSuffix: string = "";
        if (this.state.searchTypeOptions.length) {
            switch (this.props.searchType) {
                case SearchType.Teacher: searchSuffix = "Teachers"; break;
                case SearchType.Student: searchSuffix = "Students"; break;
                default: break;
            }
        }

        return(
            <Row>                 
                {!this.props.hideAlternateSearch &&  
                    <>
                        {(this.props.hideTitle === false || this.props.hideTitle === undefined) && (
                            <Col xs={(this.state.searchTypeOptions.length > 1) ? 6 : 12}>
                                <h5>Search for {searchSuffix}</h5>
                            </Col>
                        )}
                        

                        {this.state.searchTypeOptions.length > 1 && 
                            <Col xs={6} style={{marginBottom:'14px', textAlign: 'right' }}>
                                <DropDown                   
                                    items={this.state.searchTypeOptions} 
                                    selectedItem={this.props.searchType}
                                    text="name" 
                                    onSelect={(e: SearchType) => this.props.alternateSearch ? this.props.alternateSearch(e) : ({})} 
                                    idPath="id" 
                                    closeOnMouseExit={true}                        
                                />                    
                            </Col>                          
                        }

                        <Col xs={12} >
                            <Select
                                            
                                filterOption={this.filterOptions}
                                isClearable={true}
                                isSearchable
                                noOptionsMessage={(inputField) => this.getNoOptionMessage(inputField, this.props.loading)}
                                onChange={((this.props.searchType === SearchType.Student) && (this.props.selectedStudentChanged !== undefined)) ? 
                                    this.props.selectedStudentChanged : (this.props.selectedTeacherChanged !== undefined) ? 
                                    this.props.selectedTeacherChanged : () => ({})}
                                openMenuOnClick={false}
                                options={this.props.searchType === SearchType.Student ? this.state.studentOptions : this.state.teacherOptions}
                                placeholder={`Search ${this.props.searchType === SearchType.Student ? "Students" : "Teachers"}...`}
                                value={selectedOption}
                            /> 
                        </Col> 
                    </>
                 }



                <Col xs={12}>
                    <hr/>
                    <SubHeader leftChild={this.renderHeaderDisplay()} rightChild={this.renderTermDropdown(this.props.terms,this.props.selectedTerm, this.props.selectedTermChanged ? this.props.selectedTermChanged : undefined)}/>
                </Col>
 
            </Row>
        );
    }

    private getNoOptionMessage = (inputField: any, isLoading: boolean): string => {
        let message = "No Options"
        if (inputField.inputValue.length < 2) {
            message = "Please type at least 2 characters...";
        } else if (isLoading) {
            message = "Loading Results...";
        }
        return message;
    }

    private getTeacherOptions = (teachers?: Array<Teacher>): Array<OptionTypeBase> => {
        const allTeachers = teachers ? [...teachers] : [];
        const options = allTeachers.map((teacher: Teacher)=>
        {
            return {
                value: teacher,
                label: `${teacher.lastName}, ${teacher.firstName}`
            }
        });
        return options;
    }

    private getStudentOptions = (students?: Array<Student>): Array<IStudentOption> => {
        const allStudents = students ? [...students] : [];
        const options = allStudents.map((student: Student)=>
        {
            return {
                value: student,
                label: `${student.lastName}, ${student.firstName} (${student.studentNumber})`
            }
        });
        return options;
    }

    private filterOptions = (candidate: OptionTypeBase, searchTerm: string): boolean => {
        let result = false;
        if (searchTerm && (searchTerm.length > 1)) {
            const candidateLabel: string = candidate.label;
            result = candidateLabel.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
        }       
        return result;
    }
    private renderHeaderDisplay = () =>{
        return <div/>
    }
    private renderTermDropdown = (terms: Term[], selectedTerm: number, onTermSelect: Function | undefined) => {
      if (terms === undefined || terms.length === 0) {
        return;
      } else if (!this.props.selectedStudent || this.props.selectedStudent.personId !== -1) {
          return <span />;     
      } else if (this.props.selectedTeacher !== undefined) {
        return < DropDown 
            items={terms} 
            selectedItem={selectedTerm} 
            text="termName" 
            onSelect={onTermSelect} 
            idPath="termId" 
            closeOnMouseExit={true}
        />;
      } else {
        return;      
      }       
    }
}
 