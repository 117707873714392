import React from 'react';
import { globalSelector } from "store/global/globalReducer";
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MessageIcon from '@mui/icons-material/Message';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import { GradeStatus } from 'models/Grade';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import { CustomVerificationSwitch } from './CustomVerificationSwitch';
import Tooltip from '@mui/material/Tooltip';

interface IMttButton {
  labelText: string;
  buttonId: string;
  isVerified: boolean;
  isDisabled?: boolean;
  switchPrimaryFunction: (withComments: boolean) => void;
  viewWeeklyGrowthFunction?: () => void;
}

const CustomCheckbox = styled('div')(({ theme }) => ({
  color: theme.palette.blueGrey['light'],
}));

export const VerificationSwitch = ({ labelText, buttonId, isDisabled, isVerified, switchPrimaryFunction, viewWeeklyGrowthFunction }: IMttButton) => {
  const { termIsEditable } = useSelector(globalSelector);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  //#region Functions:
  const onUpdateFinalGradeStatus = (withComments: boolean = false): void => {
    switchPrimaryFunction(withComments);
    closeMenu();
  };
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  //#endregion Functions:

  return !termIsEditable ? (
    // If Term is no longer editable then we only want the ability to view past weekly growths.
    <Button
      variant="contained"
      color='secondary'
      style={{ color: '#212529' }}
      sx={{ minWidth: '36px', minHeight: '36px' }}
      id={`closed-button-${buttonId}`}
      aria-controls={`closed-button-${buttonId}`}
      onClick={(event: any) => viewWeeklyGrowthFunction ? viewWeeklyGrowthFunction() : null}
    >
      {labelText ? labelText : ''}
    </Button>
  ) : labelText === GradeStatus.LoadingGrade ? (
    <CustomCheckbox>
      <CircularProgress color='inherit' />
    </CustomCheckbox>
  ) : (
    // If Term is still editable
    <FormControl >

      <Button
        variant="text"
        size="large"
        onClick={(event: any) => openMenu(event)}
        id={`menu-button-${buttonId}`}
        aria-controls={open ? `menu-${buttonId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ padding: '0' }}
      >
        <FormControlLabel
        
          value="top"
          sx={{
            m: 0,
            '& .MuiTypography-root': {
              fontSize:'15px', fontWeight:'500'
            },
          }}
          control={
            <Tooltip enterDelay={1000} title={isVerified ? 'Unverify final grade' : 'Verify final grade'} >
              <CustomVerificationSwitch
                {...{ inputProps: { 'aria-label': 'Switchss' } }}
                checked={isVerified}
                inputProps={
                  {
                    'aria-label': 'Switch'
                  }
                }
                disabled={isDisabled}
              />
            </Tooltip>
          }
          label={labelText}
          labelPlacement="top"
        />
      </Button>
      <Menu id={`menu-${buttonId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': `menu-button-${buttonId}`,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ maxHeight: '580px' }}
      >
        <MenuList sx={{ width: '100%', maxWidth: 360, minWidth: 280, bgcolor: 'background.paper' }} dense>
          <ListItem
            className='split-menu'
            key={'index'}
            secondaryAction={
              <IconButton onClick={() => onUpdateFinalGradeStatus(true)} edge="end" aria-label="with comments">
                <MessageIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton onClick={() => onUpdateFinalGradeStatus()}>
              <ListItemText id={'index.toString()'} primary={isVerified ? `Unverify Final Grade` : `Verify Final Grade`} />
            </ListItemButton>
          </ListItem>
        </MenuList>
      </Menu>

    </FormControl>
  );
};