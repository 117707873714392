import * as React from 'react';
import styled from "styled-components"
import { ReactNode } from "react";

export enum PanelTitleTypes{
  Primary = 'primary',
  Secondary = 'secondary',
  Third = 'third',
  Both = 'both'
}

interface IncomingProps {
    mainTitle?: string;
    mainSubTitle?: string;
    secondaryTitle?: string;
    secondarySubTitle?: string; 
    children?: ReactNode;
    textAlign?: string;
    // any other props that come into the component
}

interface IncomingPropsChild {
  children: JSX.Element;
  // any other props that come into the component
}

// Styling ======================
const ScrollerHolderStyled = styled.div`
height: 100%;
display: flex;

    align-items: start;
    flex-direction: column;
 
    
`;
  
const ChildLeftStyled = styled.div`
    flex-grow: 0; width:100%;
`;

const ChildRightStyled = styled.div`
  flex-grow: 3;overflow-y: scroll;width:100%; padding:8px;
`;

 
  
export const ScrollHeader = ({ children }: IncomingPropsChild) => (
  <ChildLeftStyled>
      <>
      {children}
      </>
  </ChildLeftStyled>
);

export const ScrollerArea = ({ children }: IncomingPropsChild) => (
  <ChildRightStyled>
      <>
      {children}
      </>
  </ChildRightStyled>
);

const ScrollerHolder = ({ ...props }: IncomingProps) => (
    <ScrollerHolderStyled>
        {/* ======== PRIMARY TOOLBAR ======= */}
    
        {props.children}
 
    </ScrollerHolderStyled>
);

export default ScrollerHolder;