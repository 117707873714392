import * as React from 'react';
import './Shared.css'; 
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft, PanelFlyoutHeader } from '../layout/PanelTitleBar';
import ScrollerHolder, { ScrollHeader,  ScrollerArea,  } from '../layout/ScrollerArea';
import { Row, Col } from '../layout/ResponsiveGrid';
import { Table, Tbody, Thead, Tr, Td, Th } from '../layout/Tables';
import { GradebookEntry } from '../../models/GradebookEntry';
import {Course} from '../../models/Course';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { MdArrowBack } from "react-icons/md";
import { Assignment } from '../../models/Assignment';
import { getAllHomeworkAssignments, getAssignedHomeworkAssignments } from '../../utils/helpers/generalHelpers';
import HoverModal, {  HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import { convertHomeworkScoreToWord, areCompletedAssignments } from '../../utils/helpers/stringHelpers';
import { getActiveAssignments, hasMissingActiveAssignments } from '../../utils/helpers/generalHelpers';

// Props coming from Parent Components
interface IHomeworkReportCardProps {
    selectedGradeBookEntry: GradebookEntry;
    activeCourse: Course;
    isFiltering: boolean;
    breadCrumbNav?: boolean;
    onStudentSelect: (gradebook:GradebookEntry) => void;
    isReportCard?: boolean;
}

// Component States 
interface IHomeworkReportCardState {
    sortDescending: boolean;
    sortField: string;
    viewIncompleteOnly: boolean; 
}
 
class HomeworkReportCard extends React.Component<IHomeworkReportCardProps, IHomeworkReportCardState> {

    constructor(props: any) {
        super(props);
        // Components Defualt States Values
        this.state = {
            sortDescending: false,
            sortField: 'description',
            viewIncompleteOnly: false
        };
    }

    render() {
        
        const period: string = this.props.activeCourse.periodName;

        const courseName: string = this.props.activeCourse.courseName;
        const studentFullName: string = this.props.selectedGradeBookEntry.student.lastName + ', ' + this.props.selectedGradeBookEntry.student.firstName;
 
        const teacherName: string = this.props.activeCourse.teacherName !== null ? this.props.activeCourse.teacherName : this.props.activeCourse.teacherDisplay !== null ? this.props.activeCourse.teacherDisplay : '';

        const teacherEmail: string = this.props.activeCourse.teacherEmail !== null ? this.props.activeCourse.teacherEmail : '';
        const teacherPhone: string = this.props.activeCourse.teacherPhone !== null ? this.props.activeCourse.teacherPhone : '';

        const projectedGrade: string = this.props.selectedGradeBookEntry.assessment.projectedGrade;
        const allAssignments:Assignment[] = getAllHomeworkAssignments(this.props.selectedGradeBookEntry);
        const assignedAssignments:Assignment[] = getAssignedHomeworkAssignments(this.props.selectedGradeBookEntry);
        const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(this.props.selectedGradeBookEntry));

        return (
            <>  
                <ScrollerHolder>
                    <ScrollHeader>
                        <>
                            {!this.props.isReportCard &&
                                 <>
                                    <PanelFlyoutHeader
                                        mainTitle={courseName}
                                        mainSubTitle={period}
                                        secondaryTitle={studentFullName}
                                        secondarySubTitle={projectedGrade}
                                        isFinal={this.props.selectedGradeBookEntry.assessment.isVerified}
                                        teacherArray={[teacherName, teacherEmail, teacherPhone]}
                                        toolBarType={PanelTitleTypes.Primary}
                                        showWarningIcon={hasMissingAssignment}  
                                    />
                                </>
                            }

                            {this.props.breadCrumbNav && 
                                <>
                                    {/* ==== Return ====  */}
                                    <PanelTitleBar toolBarType={PanelTitleTypes.Fourth}>
                                        <PanelChildLeft>
                                            <h6 style={{marginBottom:0}} onClick={ () => this.props.onStudentSelect(this.props.selectedGradeBookEntry) } >
                                                <MdArrowBack size='24'/> Overview
                                            </h6>
                                        </PanelChildLeft>
                                    </PanelTitleBar>
                                </>
                            }
                        </>
                    </ScrollHeader>
                    <ScrollerArea>
                        <>
                            <Row>
                                <Col xs={12} paddingMd> 
                                    <HoverModal title='Practice/Preparation' type={HoverModalTypes.Fifth} content={HoverModalContent.homework}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} paddingMd>
 
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>
                                                    Date
                                                </Th>
                                                <Th>
                                                    Event
                                                </Th>
                                                <Th>
                                                    Status
                                                </Th>
                                            </Tr>
                                        </Thead>

                                        {this.props.isFiltering ? (
                                            // Filter Assignment / Homework to show only Incomplete Assigments          
                                            assignedAssignments.filter((homework:Assignment) => {
                                                return !areCompletedAssignments(convertHomeworkScoreToWord(homework.score));
                                            }).map(( homework: Assignment, index, array) => (
                                                <Tbody key={index} alertBackground={true}>              
                                                    <Tr noBorder={ homework.comments ? true : false }>
                                                        <Td>
                                                            {this.formatDate(homework.dueDate)}
                                                        </Td>
                                                        <Td>           
                                                                <strong>{homework.activityName}</strong>
                                                        </Td>
                                                        <Td>
                                                            <strong><span>{convertHomeworkScoreToWord(homework.score)}</span></strong>         
                                                        </Td>                                
                                                    </Tr>
                                                    {homework.comments ?
                                                        <Tr> 
                                                            <Td colSpan={3}>
                                                                {homework.comments} 
                                                            </Td>
                                                        </Tr>
                                                    :null}
                                                </Tbody>
                                            ))
                                        ) : (
                                            // Show all Assignments with no filtering
                                            allAssignments.map(( homework: Assignment, index) => (
    
                                                <Tbody key={index} alertBackground={homework.isNotAssigned === true ? false : !areCompletedAssignments(convertHomeworkScoreToWord(homework.score))}>              
                                                    <Tr noBorder={ homework.comments ? true : false }>
                                                        <Td>
                                                            {this.formatDate(homework.dueDate)}
                                                        </Td>
                                                        <Td> 
                                                            {areCompletedAssignments(convertHomeworkScoreToWord(homework.score)) ? (
                                                                <span>{homework.activityName}</span>
                                                            ) : (
                                                                <strong>{homework.activityName}</strong>
                                                            )}
                                                        </Td>
                                                        <Td>
                                                            {homework.isMissing && (
                                                                <strong>
                                                                    {/* Incomplete */}
                                                                    <span>{convertHomeworkScoreToWord(homework.score)}</span>
                                                                </strong>
                                                            )}
                                                            {homework.isMissing === false && homework.isNotAssigned !== true && (
                                                                <strong>
                                                                    {/* Complete */}
                                                                    <span>{convertHomeworkScoreToWord(homework.score)}</span>
                                                                </strong>
                                                            )}
                                                        </Td>                                
                                                    </Tr>
                                                    {homework.comments ?
                                                        <Tr> 
                                                            <Td colSpan={3}>
                                                                {homework.comments} 
                                                            </Td>
                                                        </Tr>
                                                    :null}
                                                </Tbody>
                                            ))

                                        )}
                                         

                             
                                    </Table>
 
                                </Col>
                            </Row>
                        </>
                    </ScrollerArea>   
                </ScrollerHolder>
                
            </>
        );
    }
 
    private renderIcon = () => {
        return <ContactMailIcon/>
    }

    private formatDate = (dateString: Date) : string => {
        const timeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        } as const;

        return new Date(dateString).toLocaleDateString([],timeFormatOptions);
    }
     
    
}

export default HomeworkReportCard;