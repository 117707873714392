import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {  styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface IToggleSwitch {
    currentStatus:boolean | undefined;
    togglePrimaryFunction: (status:boolean) => void;
}
 
export const ToggleSwitch = ( { currentStatus,  togglePrimaryFunction }:IToggleSwitch) => {

  //#region Variables:
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    background: theme.palette.neutral.light,
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-selected':{
        background:'#fff',

      },
      '&.Mui-selected:hover':{
        background:'#fff',

      },
      '&.Mui-selected svg[data-testid="DoneIcon"] ':{
        color: theme.palette.primary.main
      },
      '&.Mui-selected svg[data-testid="PriorityHighIcon"] ':{
        color: theme.palette.warning.main
      },
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));
  //#endregion

  //#region Functions:
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: boolean,
  ) => {
    togglePrimaryFunction(newStatus);
  };
  //#endregion

  return (
      <FormControl > 
        <StyledToggleButtonGroup
          size="small"
          value={currentStatus}
          exclusive
          onChange={handleChange} 
          aria-label="text alignment"
        >
          <ToggleButton value={true} aria-label="verified">
            <DoneIcon />
          </ToggleButton>
          <ToggleButton value={false} aria-label="unverified">
            <PriorityHighIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>   
      </FormControl>
  );
};