export enum ProficiencyType {
    Word,
    Initial
}

export enum ScoreWords {
    Exceeds = `Exceeds`,
    Meets = `Meets`,
    Approaching = `Approaching`,
    Developing = `Developing`,
    TurnedIn = `Turned in`,
    TurnedInLate = `Turned in Late`,
    Missing = `Missing`,
    NotCompleted = `Not Completed`,
    Prepared = `Prepared`,
    PartiallyPrepared = `Partially Prepared`,
    NotPrepared = `Not Prepared`,
    Insufficient = `Insufficient`,
    Incomplete = `Incomplete`,
    Null = ``
}

export enum ScoreInitials {
    E = `E`,
    M = `M`,
    A = `A`,
    D = `D`,
    T = `T`,
    TL = `TL`,
    P = `P`,
    PP = `PP`,
    NP = `NP`,
    N = `N`,
    I = `I`
}
 
export enum ScoreNumbers {
    Zero = `0`,
    One = `1`,
    Two = `2`,
    Three = `3`,
    Four = `4`,
    Five = `5`,
    Six = `6`,
    Seven = `7`
}