import {  styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const CustomVerificationSwitch = styled(Switch)(({ theme }) => ({
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 9,
    paddingRight: 9,
    height:"30px",
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      opacity: '0.9 !important',
      backgroundColor:'#9e9e9f',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M479.743-158Q452-158 432.5-177.757q-19.5-19.756-19.5-47.5Q413-253 432.757-272.5q19.756-19.5 47.5-19.5Q508-292 527.5-272.243q19.5 19.756 19.5 47.5Q547-197 527.243-177.5q-19.756 19.5-47.5 19.5ZM414-386v-421h132v421H414Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: '#fff'
    },
    '& .MuiSwitch-switchBase': {
      padding: '5px 9px'
    }
}));