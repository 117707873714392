import React from "react";
import { PrintableCourseReportCard } from "./PrintableCourseReportCard";
import { Term } from "../models/Term";
import { Course } from "../models/Course";
import { GradebookEntry } from "../models/GradebookEntry";
import { Student } from "../models/Student";
import { TeacherStudent } from "../models/TeacherStudent";
import { getActiveAssignments, hasMissingActiveAssignments } from '../utils/helpers/generalHelpers';
import { IMttInterventions } from "../models/MandatoryTargetedTutoring";

export interface IPrintableReportCardProps {
    allInterventions: Array<IMttInterventions>;
    courses: Array<Course>;    
    gradebookEntries: Array<GradebookEntry>;
    student: Student;
    hideProjectedGrade: boolean;
    term?: Term;
    togglePrintableReportCard: () => void;
}

export interface IPrintableReportCardState {
    student: TeacherStudent;
}

export class PrintableReportCard extends React.Component<IPrintableReportCardProps, IPrintableReportCardState> {
  
    componentDidMount() {
        window.print();
    }

    render() {        
        return (            
            <div style={{padding: '1rem 3.8rem' }}>
                <style>
                    {`@media print {
                        .navbar, .back-button {display: none;}
                        .MuiToolbar-root{display: none !important;}                    
                        .studentReportCard {page-break-after: always;}
                        .reportCardRow {page-break-inside: avoid;} 
                    }`}
                </style>
                {this.props.courses.map((course, courseIndex) => {
                    let gradebookEntry = this.props.gradebookEntries.find(gbe => gbe.assessment !== null && gbe.assessment.sectionID === course.sectionId);
                    if (gradebookEntry === undefined) {
                        gradebookEntry = new GradebookEntry();
                    }
                    const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(gradebookEntry));
                    const hasVerifiedFinalGrade = gradebookEntry.assessment.finalGrade && gradebookEntry.assessment.isVerified ? true : false;

                    return (
                        <PrintableCourseReportCard
                            allInterventions={this.props.allInterventions.filter(i => i.sectionId === course.sectionId)}
                            course={course}               
                            isFinal={hasVerifiedFinalGrade}
                            key={courseIndex}
                            projectedGrade={            
                                hasVerifiedFinalGrade ? gradebookEntry.assessment.finalGrade : gradebookEntry.assessment.projectedGrade
                            }
                            hideProjectedGrade={this.props.hideProjectedGrade}
                            reportCardKey={courseIndex}
                            showWarningIcon={hasMissingAssignment}
                            standards={gradebookEntry.assessment.standards}
                            student={this.props.student}
                            term={this.props.term}
                            togglePrintableCourseReportCard={this.props.togglePrintableReportCard}
                            weeklyGrowth={gradebookEntry.assessment.weeklyGrowth}
                        />
                    );
                })}
            </div>
        );
    }
}