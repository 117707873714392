import { GradebookEntry } from '../../models/GradebookEntry';
import { IStudentWeeklyGrowthScores } from '../../models/Student';
import { Assignment } from '../../models/Assignment';
import { Standard } from '../../models/Standard';
import { ScoreInitials , ScoreWords} from '../../models/enums/Standards';
import { IMenuListItems, IWeekOptions } from '../../models/Menus';
import { WeeklyGrowth, FinalsWeekId } from '../../models/WeeklyGrowth';
import {  IStudentWeeklyGrowth, } from '../../models/Student';
import {Term} from 'models/Term';
import { IDirectGradeOption } from '../../models/DirectGradeOption';

interface IWeeklyGrowthScore {
    hasWeeklyScore: boolean;
    score: string;
    comment:string;
}

export function getAllHomeworkAssignments(gradeItem: GradebookEntry) : any[] {
    let assignmentList: Assignment[] = [];
    for(let i = 0; i < gradeItem.assessment.standards.length; i++){
        assignmentList = assignmentList.concat(gradeItem.assessment.standards[i].assignments.filter(x=>x.isHomework));
    }
    return assignmentList.sort(y=>y.dueDate.valueOf()).reverse();
}

// Used for the Assignments Graphs
export function getAssignedHomeworkAssignments(gradeItem: GradebookEntry) : any[] {
    let assignmentList: Assignment[] = [];
    for(let i = 0; i < gradeItem.assessment.standards.length; i++){
        assignmentList = assignmentList.concat(gradeItem.assessment.standards[i].assignments.filter(x=>x.isHomework && x.isNotAssigned === false));
    }
    return assignmentList.sort(y=>y.dueDate.valueOf()).reverse();
}

export function getHomeworkMissingBooleans (gradeItem: GradebookEntry) : boolean[] {
    let assignmentList: Assignment[] = [];
    for(let i = 0; i < gradeItem.assessment.standards.length; i++){
        assignmentList = assignmentList.concat(gradeItem.assessment.standards[i].assignments.filter(x=>x.isHomework && x.isNotAssigned === false));
    }
    return assignmentList.sort(y=>y.dueDate.valueOf()).map(z=>z.isMissing);
}

export function getHomeworkMissingStandardsBooleans (standard: Standard) : boolean[] {
    let assignmentList: Assignment[] = [];
    assignmentList = standard.assignments.filter(x=>x.isHomework && x.isNotAssigned === false);
    return assignmentList.sort(y=>y.dueDate.valueOf()).map(z=>z.isMissing);
}

export function roundNumber(value:number, precision:number) : number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function assignmentsAreHomework(assignments: Assignment[]) : boolean {
    const isHomework:boolean = assignments.some((assignment:Assignment) => assignment.isHomework === true);
    return isHomework;
}

export function getActiveAssignments(gradeItem: GradebookEntry) : any[] {
    let assignmentList: Assignment[] = [];
    for(let i = 0; i < gradeItem.assessment.standards.length; i++){
        assignmentList = assignmentList.concat(gradeItem.assessment.standards[i].assignments.filter(x => x.isNotAssigned === false && x.standardEventActive === 1));
    }
    return assignmentList;
}

export function hasMissingActiveAssignments(assignments: Assignment[]) : boolean {
    // Assignments for Skills only
    const hasMissingAssignment: boolean = assignments.some((assignment: Assignment) => {
            if(assignment.score != null){
                return (assignment.score.toUpperCase() === ScoreInitials.M || assignment.score.toUpperCase() === ScoreInitials.N) && assignment.isHomework === false
            }
            return false;
    });
    return hasMissingAssignment;  
}

export function getMissingProficiencyScore(assignments: Assignment[], score: number) : string {
    // Change Score based on if an assigment is "M" and Active || "N" and Active;
    const newProficiencyScore:string = assignments.find(a => a.score != null && 
        ((a.score.toUpperCase() == ScoreInitials.M && a.standardEventActive === 1 ) || (a.score.toUpperCase() == ScoreInitials.N && a.standardEventActive === 1)) ) ? ScoreInitials.M : score.toString();
    return newProficiencyScore;
}
 
export function arrayIsEmpty(array: any[]) : boolean {
    if (!Array.isArray(array) || !array.length) {
        return true;
    }
    return false;
}

export const removeDuplicatesInArray = (array: any[], key:any): any[] => { 
    return [
        ...new Map(array.map(x => [key(x),x])).values()
    ]
}

export const removeDuplicatesObjectsInArray = (array: any[], key:any): any[] => { 
    return array.reduce((unique, o) => {
        if(!unique.some(obj => obj[key] === o[key] )) {
          unique.push(o);
        }
        return unique;
    },[]);
}

// TODO TASK: https://dev.azure.com/StevensonIT/IRC/_workitems/edit/350
export const convertToWGOptionsToMenusList = (response:any): IMenuListItems[] => { 
    return response.data.map((gradeOption) => {
        const options: IMenuListItems = {
            id: gradeOption.taskId,
            primaryIntials: gradeOption.weeklyGrade,
            primaryText: gradeOption.description === ScoreWords.Insufficient ? ScoreWords.Incomplete : gradeOption.description
        };
        return options;
    })
    .filter(function( option:IMenuListItems ) {
        return option.primaryIntials !== null && option.primaryText !== null;
    })
    .sort((option1, option2) => {
        return option1.id - option2.id;
    });
}

export const convertDgToMenuList = (directGradeOptions:IDirectGradeOption[]): IMenuListItems[] => { 
    return directGradeOptions.map((option) => {
        const options: IMenuListItems = {
            id: option.seq,
            primaryIntials: option.score,
            primaryText: option.score
        };
        return options;
    })
    .filter(function( option:IMenuListItems ) {
        return option.primaryIntials !== null && option.primaryText !== null;
    })
    .sort((option1, option2) => {
        return option1.id && option2.id ? option1.id - option2.id : 0;
    });
}

/**  Used to extract and generate week options for the growth array */
export const getWeeklyGrowthOptions = (studentsWeeklyGrowth:IStudentWeeklyGrowth[]): IWeekOptions[] => { 
    const options:IWeekOptions[] = [];
     
    studentsWeeklyGrowth.map((studentWeeklyGrowth) => {
        studentWeeklyGrowth.weeklyGrowthArray.forEach( weeklyGrowth => {
            options.push({
                taskId: weeklyGrowth.taskID,
                task: weeklyGrowth.task,
                code: shortenWeeklyGrowthValue(weeklyGrowth.task)
            });
        });
    });
    
    return removeDuplicatesObjectsInArray(options,'code');
}

/**  Used to generate a one level array of all of the growth items for all of the students gradebook array */
export const getWeeklyGrowthScores = (studentsWeeklyGrowth:IStudentWeeklyGrowth[], activeWeek:string): IStudentWeeklyGrowthScores[] => { 
    const updatedWGScores:IStudentWeeklyGrowthScores[] = [];
    studentsWeeklyGrowth.map((studentWeeklyGrowth) => {
        studentWeeklyGrowth.weeklyGrowthArray.forEach( weeklyGrowth => {
            if(weeklyGrowth.task === activeWeek){
                updatedWGScores.push({
                    studentPersonId: studentWeeklyGrowth.studentId,
                    weeklyGrowthScore: weeklyGrowth.score
                });
            }
        });
    });
    return updatedWGScores;
}
 
/** Get the lastest growth object parameter based off sequence */
export const getWeeklyGrowthDefaultItem = (gradeBookArray:GradebookEntry[], key:string): any => { 
    const emptyWeeklyGrowth:WeeklyGrowth = {
        studentPersonId: -1,
        sectionId: -1,
        task: '',
        taskID:-1,
        score: '',
        comments: ''
    }

    if((gradeBookArray.length > 0) &&  gradeBookArray[0].weeklyGrowth.length > 0){
        const latestWeeklyGrowth:WeeklyGrowth = gradeBookArray[0].weeklyGrowth.reduce(
            (prev, current) => {
                return ( prev.sequence && current.sequence ) ? (prev.sequence > current.sequence ? prev : current ) : emptyWeeklyGrowth
            }
        );
        return latestWeeklyGrowth[key];
        
    }
    return emptyWeeklyGrowth[key];
}


/** Returns a list of students that can be verified during the final week */
export const getVerifiableStudents = (gradeBookArray:GradebookEntry[]): GradebookEntry[] => { 
    // const emptyWeeklyGrowth:WeeklyGrowth = {
    //     studentPersonId: -1,
    //     sectionId: -1,
    //     task: '',
    //     taskID:-1,
    //     score: '',
    //     comments: ''
    // }

    if(gradeBookArray.length > 0){
        const filteredStudents:GradebookEntry[] = gradeBookArray.filter((gradebook) => gradebook.student.isDropped == false);
        return filteredStudents;
    }
    return gradeBookArray;
}

export const shortenWeeklyGrowthValue = (weeklyGrowthOption:string): string => { 
    let newShortenOption:string = '';

    if(weeklyGrowthOption){
        if(weeklyGrowthOption.toLocaleLowerCase().includes('progress')){
            const numberString:string = weeklyGrowthOption.charAt(0);
            newShortenOption = 'Pr ' + numberString;
        } 
        else if(weeklyGrowthOption.toLocaleLowerCase().includes('final')){
            newShortenOption = weeklyGrowthOption.slice(3).toLocaleLowerCase().replace(/final grade/gi, "Final");
        }
        else if(weeklyGrowthOption.toLocaleLowerCase().includes('week')){
            newShortenOption = weeklyGrowthOption.slice(3).toLocaleLowerCase().replace(/week/gi, "Wk");
        }
    } 
 
    return newShortenOption;
}

export function hasWeeklyScoreAlready (weeklyGrowthArray: WeeklyGrowth[], currentTaskId:number) : IWeeklyGrowthScore {

    const hasScoreThisWeek:boolean = weeklyGrowthArray.some(selectedItem => selectedItem.taskID === currentTaskId); 
    const currentWeeklyGrowth:WeeklyGrowth | undefined = weeklyGrowthArray.find((weeklyGrowth:WeeklyGrowth) => {
        return  weeklyGrowth.taskID ? (weeklyGrowth.taskID === currentTaskId) : undefined
    });

    return {
        hasWeeklyScore:hasScoreThisWeek, 
        score:(currentWeeklyGrowth ? currentWeeklyGrowth.score : ''),
        comment:(currentWeeklyGrowth ? currentWeeklyGrowth.comments : '')  
    }
}

 /**
   * @returns True if the week(weekTaskId) being viewed is considered a final week of the semester 
   *
*/
export function isFinalsWeek(weekTaskId:number) : boolean {
    switch(weekTaskId) {
        case FinalsWeekId.S1_Final_Grade:
        case FinalsWeekId.S2_Final_Grade:           
            return true;     
        default:
            return false;
    }
}

export const addDays = (date: Date, additionalDays: number = 0): string => {
    const convertedDate = new Date(date);
    const dateCopy = new Date(convertedDate);
    dateCopy.setDate(convertedDate.getDate() + additionalDays);
    return dateCopy.toISOString().substring(0, 10);
}

 /**
   * @returns The termId:number of the selected term from an array of Terms[]   
   *
*/
export const getSelectedTermId = (terms:Term[], selectedTerm:number): number | undefined =>{
    for (let i = 0; i < terms.length; i++) {
        if (i === selectedTerm) {
            return terms[i].termId;
        }
    }   
  return undefined;
}