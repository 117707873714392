import * as React from 'react';
import { ReactNode } from "react";
import styled from "styled-components"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface IncomingProps {
    children: ReactNode;
    accordionTitle: string;
}

// Component States 
interface IAccordionPanelState {
  isVisible: boolean;
}
interface AccordionPanelProps {
  fullHeight?: boolean;
  flexDirection?: string;
  justifyContent?: string;
}
const AccordionPanelStyled = styled.div<AccordionPanelProps>`
  display: flex;  flex-wrap:wrap; box-sizing:border-box;justify-content: flex-start;
  ${ props => props.fullHeight ? 'height:100%' : ''}; margin:10px 0px 0;
  flex-direction: ${ props => props.flexDirection ? props.flexDirection : 'row' };
  justify-content: ${ props => props.justifyContent ? props.justifyContent : 'flex-start' };
  border: 1px solid ${({ theme: { colors } }) => colors.greys.light};

`;
const AccordionPanelTitleStyled = styled.div`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.titleSmall };
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 

  float:right;padding:10px;
  background: ${({ theme: { colors } }) => colors.greys.xxlight}; 
cursor: pointer;

`;

interface AccordionPanelContainerProps {
  isHeading?: boolean;
  maxWidth?: string;
}
const AccordionPanelContainerStyled = styled.div<AccordionPanelContainerProps>`
  font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.large : fontSizes.small};
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 'none'};
  float:right;
  
`;
 

class AccordionPanel extends React.Component<IncomingProps, IAccordionPanelState> {

  constructor(props: any) {
      super(props);
      // Components Defualt States Values
      this.state = {
          isVisible: true, 
      };
  }

  render() {
      return (
          <AccordionPanelStyled>
              <AccordionPanelTitleStyled>
                  <div  onClick={() => this.accordionClick() }>
                    {this.state.isVisible ? (<KeyboardArrowDownIcon sx={{marginRight:'10px'}} />) : (<KeyboardArrowRightIcon sx={{marginRight:'10px'}}/>)}
                    {this.props.accordionTitle}
                  </div>
              </AccordionPanelTitleStyled>

              {this.state.isVisible  ?
                <AccordionPanelContainerStyled className='AccordionPanelContainer'>
                    {this.props.children}
                </AccordionPanelContainerStyled>
              : null}
          </AccordionPanelStyled>     
      );
  }
  

  // private  = (dateString: Date) : string => {
  //     var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  //     return new Date(dateString).toLocaleDateString([],options);
  // }
 
  private accordionClick = () : void => {
 
    this.setState({ 
      isVisible: !this.state.isVisible
    });
  }
   

  
  
}

export default AccordionPanel;