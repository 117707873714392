import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export interface TabContentProps {
  tabName: string;
  tabContent: JSX.Element;
}

interface TabbedContentContainerProps {
  title: string;
  content: Array<TabContentProps>;
  defaultTab: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabbedContentContainer(props: TabbedContentContainerProps) {
  const { title, content, defaultTab } = props;
  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', paddingBottom: `48px` }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label={title} sx={{ margin: `0 10px` }}>
          {content.map(((c, index) => 
            <Tab 
              key={index}
              label={c.tabName} 
              {...a11yProps(index)} 
            />
          ))}
        </Tabs>
      </Box>
      {content.map(((c, index) => 
        <TabPanel 
          key={index}
          value={value} 
          index={index}
        >
          {c.tabContent}
        </TabPanel>
      ))}
    </Box>
  );
}
