import React, { useEffect, useState } from 'react';
import { SkillSelect } from '../inputs-elements/SkillSelect';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface ICommentDialog {
    title?: string;
    subheader?: string;
    commentPlaceholder?:string;
    commentValue?:string;
    skillsList?:string[];
    selectedSkills?:string[];
    submitBtnText:string;
    cancelBtnText?:string;
    isOpen:boolean;
    saveFunction: (commentText:string, skills?:string[] | undefined) => void;
    cancelFunction: () => void;
}

interface IDialogState {
    isDisabled: boolean,
    hasCorrectValue: boolean,
    commentTextValue:string,
    selectedSkills:string[]
}
export const UpdateInterventionDialog = ( {title, subheader, commentPlaceholder, commentValue, skillsList, selectedSkills, submitBtnText,cancelBtnText, isOpen, saveFunction, cancelFunction}:ICommentDialog) => {
    // States
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [submitingInProcess, setSubmitingInProcess] = useState(false);
    const [dialogState, setDialogState] = useState<IDialogState>({
        isDisabled: true,
        hasCorrectValue:false,
        commentTextValue: '',
        selectedSkills: []
    });

    // React Hooks
    useEffect(() => {
        if(isOpen){
            setDialogIsOpen(true);
            if(commentValue){
                setDialogState(prevState => ({ ...prevState,
                    commentTextValue: commentValue,
                    selectedSkills: selectedSkills ? selectedSkills : []
                }));
            }
        }else{
            setDialogIsOpen(false);
        }
    }, [isOpen,commentValue]);
 
    const handleClose = () => {
        setDialogIsOpen(false);
    };

    const onSubmitBtn = (commentText:string, skills?:string[]):void => {
        setSubmitingInProcess(true);
        const comment = commentText ? commentText : '';
        if (skills) {
            saveFunction(comment, skills);
        } else {
            saveFunction(comment);
        }
    };

    const onCommentTextAreaChange = (e) => {
        setDialogState(prevState => ({
            ...prevState,
            commentTextValue: e.target.value
        })); 
    };
    const updateSelectedSkills = (skills:string[]):void => {
        setDialogState(prevState => ({
            ...prevState,
            selectedSkills: skills
        })); 
    };

    const disableSubmitBtn = (skillsList:string[] | undefined):boolean => {
        if(skillsList) {
            return dialogState.commentTextValue.length <= 0 && dialogState.selectedSkills.length === 0;
        }else{
            return dialogState.commentTextValue.length <= 0;
        }
    }

  return (
      <Dialog open={dialogIsOpen} onClose={handleClose}>
            {title && (
                <DialogTitle>{title}</DialogTitle>
            )}
        <DialogContent>
            {subheader && (
                <DialogContentText>
                    {subheader}
                </DialogContentText>
            )}
            {skillsList && (
                <SkillSelect
                    skillsLabel='Edit Selected Skills'
                    skillsList={skillsList}
                    prevSelectedSkills={selectedSkills ? selectedSkills : []}
                    onSkillSelectItem={(newSelectedSkills:string[]) => { updateSelectedSkills(newSelectedSkills)} }   
                />
            )}
            <TextField
                value={dialogState.commentTextValue ? dialogState.commentTextValue : ''}
                autoFocus
                multiline
                margin="dense"
                id="commentTextField"
                label={commentPlaceholder ? commentPlaceholder : ''}
                type="text"
                fullWidth
                variant="outlined"
                onChange={onCommentTextAreaChange}
            />
        </DialogContent>
        <DialogActions>
            <Button size="large" variant='outlined' color='primary' onClick={() => cancelFunction()}>
                {cancelBtnText ? cancelBtnText : 'Cancel'}
            </Button>
            <>
                {submitingInProcess === false ? (
                    <Button 
                        onClick={() => { onSubmitBtn(dialogState.commentTextValue, dialogState.selectedSkills) }} 
                        size="large"
                        variant='contained'
                        disabled={disableSubmitBtn(skillsList)}
                    >
                        {submitBtnText ? submitBtnText : 'Submit'}
                    </Button>
                ) : (
                    <LoadingButton loading loadingPosition="start"startIcon={<SaveIcon />} variant="outlined">
                        {submitBtnText ? submitBtnText : 'Submit'}
                    </LoadingButton>
                )}
            </>
        </DialogActions>
      </Dialog>
  );
}