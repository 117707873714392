import styled from "styled-components";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled as muiStyled } from '@mui/material/styles'; 
import { Typography } from '@mui/material';

interface IncomingProps {
    isActive?: boolean;
 
}
  
export const Th = styled.th<IncomingProps>`
display: table-cell;
text-align: left;padding: 15px 10px;
vertical-align: middle;
background-color: ${({ theme: { colors } }) => colors.greys.xlight}; 
`;

interface TdProps extends IncomingProps {
  borderRight?: boolean;
}
export const Td = styled.td<TdProps>`
   
  ${ props => props.isActive ? 'cursor:pointer' : ''};
  display: table-cell;
  text-align: left;padding: 0.4rem !important;
  vertical-align: middle;
  ${({ borderRight, theme: { colors } }) => borderRight ?  'border-right:1px solid ' + colors.greys.xxlight : 'none' };

`;
 
interface TrProps extends IncomingProps {
  borderRight?: boolean;
  noBorder?: boolean;
}
export const Tr = styled.tr<TrProps>`
  transition: background-color .25s ease;
  ${({ noBorder, theme: { colors } }) => noBorder ? 'none' : ' border-bottom:1px solid ' + colors.greys.xxlight };

  ${Td}{
    ${({ borderRight, theme: { colors } }) => borderRight ?  'border-right:1px solid ' + colors.greys.xxlight : 'none' };
  }


`; 

interface TheadProps extends IncomingProps {
  noBg?: boolean;
}
export const Thead = styled.thead<TheadProps>`
  ${Th}{
    ${({ noBg, theme: { colors } }) => noBg ?  'background-color:transparent;border-bottom:none;border-top:1px solid ' + colors.greys.xxlight : 'none' };
  }
  ${Tr}{
    ${({ noBg, theme: { colors } }) => noBg ?  'border-bottom:none;' : 'none' };
  }
`; 

interface TbodyProps extends IncomingProps {
  minHeight?: boolean;
  alertBackground?: boolean;
}
export const Tbody = styled.tbody<TbodyProps>`
border-collapse: collapse;
border-spacing: 0;
  ${({ alertBackground, theme: { colors }}) => alertBackground ?  'background:' + colors.gold.lightAlert : '' };

  ${Tr}{
    ${({ minHeight }) => minHeight ?  'height:60px;min-height:60px' : 'height: auto;min-height: auto;' };
  }
  ${Td}{
    ${({ minHeight }) => minHeight ?  'padding:0.4rem !important;' : 'padding:0.7rem 0.4rem !important;' };
  }
`;
  
interface TableProps extends IncomingProps {
  striped?: boolean;
  stripedMulti?: boolean;
}
export const Table = styled.table<TableProps>`
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;border: none;

  ${Tr}:nth-of-type(2n+1) {
    ${({ striped, theme: { colors }  }) => striped ?  'background-color:' + colors.greys.xmedium : 'background-color:none' };
  }


  ${Tr}:nth-of-type(2n+1) {
    ${({ stripedMulti, theme: { colors }  }) => stripedMulti ?  'background-color:' + colors.greys.xmedium : 'background-color:none' };
  }

`;

export const StyledParagraph = muiStyled(Typography)(({ theme }) => ({
  width: '100%',
  marginBottom: '.5em',
  [`&.course-info`]: {    
    maxWidth: '280px',
  }
}));

export const StyledSpanContainer = muiStyled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  marginBottom: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  [`& > span`]: {
    display: 'inline-block',
    fontSize: '.8em',
    color:theme.palette.blueGrey.contrastText,
  }
}));

export const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    height: '60px',
    padding: '10px !important',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.head}.show-border`]: {
    borderRight: '1px solid #ccc',
  },
  [`&.${tableCellClasses.body}`]: {    
    fontSize: 14,
    padding:'10px 10px !important',
  },
  [`&.${tableCellClasses.body}.show-border`]: {
    borderRight: '1px solid #ccc',
  },
}));

export const StyledTableCellMain = muiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.blueGrey.dark,
    color: theme.palette.common.white,
    height: '60px',
    padding:'0 10px !important',    
  },
  [`&.${tableCellClasses.head}.show-border`]: {
    borderRight: '1px solid #ccc',
  },
}));

export const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
  border: '1px solid #d5d7dc',
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f2f7',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));