import * as React from 'react';
import './Shared.css'; 
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft, PanelFlyoutHeader } from '../layout/PanelTitleBar';
import ScrollerHolder, { ScrollHeader,  ScrollerArea,  } from '../layout/ScrollerArea';
import { Row, Col } from '../layout/ResponsiveGrid'; 
import Button from '../inputs-elements/Button';
import { GradebookEntry } from '../../models/GradebookEntry';
import { Standard } from '../../models/Standard';
import { PanelTypes } from '../ReportCard';

import {Course} from '../../models/Course';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { MdArrowBack } from "react-icons/md";
import HoverModal, {  HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import { convertScoreToWord } from '../../utils/helpers/stringHelpers';
import { getHomeworkMissingBooleans, getMissingProficiencyScore} from '../../utils/helpers/generalHelpers';
import AssignmentBar from '../charts/AssignmentBar';
import { getActiveAssignments, hasMissingActiveAssignments } from '../../utils/helpers/generalHelpers';
import { IMttInterventions } from '../../models/MandatoryTargetedTutoring';
import { MttStatus } from '../../models/enums/Mtt';
import { SupervisedUserCircle } from '@mui/icons-material';

// Props coming from Parent Components
interface IStudentSelectReportCardProps {
    activeCourse: Course;
    allInterventions?: Array<IMttInterventions>;
    breadCrumbNav?: boolean;
    isHomeworkFiltering: boolean;
    isReportCard?: boolean;
    onCoursesBackSelect?: Function ;
    onHomeworkAllSelect: (selectedGradeBook: GradebookEntry, filtering: boolean, breadCrumbNav: boolean) => void;
    onInterventionHistorySelect?: (selectedGradeEntry: GradebookEntry, breadCrumbNav?: boolean) => void; 
    onStandardSelect: (studentGradeEntry: GradebookEntry, selectedStandard: Standard, breadCrumbNav: boolean) => void;
    onWeeklySelect: (selectedGradeBook: GradebookEntry, breadCrumbNav: boolean) => void;
    panelActiveType?: PanelTypes;
    selectedGradeBookEntry: GradebookEntry;
    selectedStandardProps?: Standard;
    isViewingFinalWeek?:boolean;
}

// Component States 
interface IStudentSelectReportCardState {
    allInterventions: Array<IMttInterventions>;
    activeGradeBookEntry: GradebookEntry; 
    sortDescending: boolean;
    sortField: string;
}
 
 
class StudentSelectReportCard extends React.Component<IStudentSelectReportCardProps, IStudentSelectReportCardState> {


    static getDerivedStateFromProps(nextProps: IStudentSelectReportCardProps, prevState: IStudentSelectReportCardState) {
 
        if (nextProps.selectedGradeBookEntry !== prevState.activeGradeBookEntry) {           
            return { 
                activeGradeBookEntry: nextProps.selectedGradeBookEntry
            };
        }
 
        return null;
    }


    constructor(props: any) {
        super(props);
        // Components Defualt States Values
        this.state = {
            allInterventions: [],
            sortDescending: false,
            sortField: 'description',
            activeGradeBookEntry: new GradebookEntry(),
        };
    }

    componentDidMount() {
        this.updateInterventions();
    }

    componentDidUpdate(prevProps: IStudentSelectReportCardProps, prevState: IStudentSelectReportCardState) {
        if (prevProps.allInterventions !== this.props.allInterventions) {
            this.updateInterventions();
        }
    }

    updateInterventions() {
        if (this.props.allInterventions === undefined) {
            // Enable code below (and expand upon it) if/when there's a desire to access interventionHistory from within the gradebook's report card panel
            // if ((this.props.selectedGradeBookEntry.student.personId !== -1) && (this.props.activeCourse.sectionId !== -1)) {
            //     getStudentInterventions(this.props.selectedGradeBookEntry.student.personId,this.props.activeCourse.sectionId).then(response => {
            //         const allInterventions:IMttInterventions[] = convertInterventionsFromServerResponse(response, this.props.selectedGradeBookEntry);  
            //         this.setState(prevState => ({ ...prevState,
            //             allInterventions
            //         }));
            //     }).catch(error => {
            //         console.error(error);
            //     });
            // } 
        } else {
            const propInterventions = this.props.allInterventions.map(i => i);
            this.setState(prevState => ({ ...prevState,
                allInterventions: propInterventions
            }));            
        }
    }

    public getSubTitleIcon = (standardName: string, isActiveButton: boolean): JSX.Element => {
        const showIcon = this.state.allInterventions.some(i => {
            const isActive = (i.currentSessionStatus === MttStatus.ActiveMtt);
            const matchingSkill = i.skills.reduce((s1, s2) => {
                return s1 || s2.skillsOption === standardName;
            }, false);           
            return isActive && matchingSkill;
        });
        
        return this.props.isReportCard && showIcon ? <SupervisedUserCircle sx={{ fontSize: 30 }}  style={isActiveButton ? { color: '#5b606e' } : { color: '#e2bf54' }}/> : <></>;
    }

    render() {
        const courseName: string = this.props.activeCourse.courseName;
        const period: string = this.props.activeCourse.periodName;
        const teacherName: string = this.props.activeCourse.teacherName !== null ? this.props.activeCourse.teacherName : this.props.activeCourse.teacherDisplay !== null ? this.props.activeCourse.teacherDisplay : '';

        const teacherEmail: string = this.props.activeCourse.teacherEmail !== null ? this.props.activeCourse.teacherEmail : '';
        const teacherPhone: string = this.props.activeCourse.teacherPhone !== null ? this.props.activeCourse.teacherPhone : '';
        const studentFullName: string = this.state.activeGradeBookEntry.student.lastName + ', ' + this.state.activeGradeBookEntry.student.firstName;
        
        const grade: string = this.state.activeGradeBookEntry.assessment.isVerified && this.state.activeGradeBookEntry.assessment.finalGrade != ''
                                ? this.state.activeGradeBookEntry.assessment.finalGrade : this.state.activeGradeBookEntry.assessment.projectedGrade;


        const fullStandardList: Array<Standard> = this.state.activeGradeBookEntry.assessment.standards.filter((standardItem) => 
            standardItem.assignments.some((subElement) => subElement.isHomework === false))
        .map(element => {
            const newElt = Object.assign({}, element); // copies element
            return newElt;
        });

        const weeklyGrowth: string = this.state.activeGradeBookEntry.assessment.weeklyGrowth ? this.convertWG(this.state.activeGradeBookEntry.assessment.weeklyGrowth) : '';
        const missingHomeworkBooleans: boolean[] =  getHomeworkMissingBooleans(this.state.activeGradeBookEntry);
        const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(this.state.activeGradeBookEntry));

        return (
            <>  
                <ScrollerHolder>
                    <ScrollHeader>
                        <>
                            {this.props.breadCrumbNav && this.props.onCoursesBackSelect && 
                                <>
                                    {/* ==== Return ====  */}
                                    <PanelTitleBar toolBarType={PanelTitleTypes.Fourth}>
                                        <PanelChildLeft>
                                            <h6 style={{marginBottom:0}} onClick={ () => this.props.onCoursesBackSelect != undefined ? this.props.onCoursesBackSelect() : undefined } >
                                                <MdArrowBack size='24'/> Courses List
                                            </h6>
                                        </PanelChildLeft>
                                    </PanelTitleBar>
                                </>
                            }

                            {/*========== If being used in Report Card Do Nothing? ==========*/}
                            {!this.props.isReportCard &&
                                <>
                                    {/* ========== If being used anywhere else ========== */}
  
                                    <PanelFlyoutHeader
                                        mainTitle={courseName}
                                        mainSubTitle={period}
                                        secondaryTitle={studentFullName}                                        
                                        secondarySubTitle={grade}
                                        isFinal={this.props.selectedGradeBookEntry.assessment.isVerified}
                                        teacherArray={[teacherName, teacherEmail, teacherPhone]}
                                        toolBarType={PanelTitleTypes.Primary}
                                        showWarningIcon={hasMissingAssignment} 
                                    />  
 
                                </>
                            }
                        </>
                    </ScrollHeader>
                    <ScrollerArea>
                        <>
                            <Row borderBottom>
                                {this.props.isViewingFinalWeek !== undefined && this.props.isViewingFinalWeek === true ? 
                                (<></>) : 
                                (<>
                                    <Col xs={6} paddingLgRight> 
                                        <HoverModal title='Growth' type={HoverModalTypes.Primary} content={HoverModalContent.weeklygrowth}/>
                                    </Col>
                                    <Col xs={6} paddingLgY alignRight>                                 
                                        <h5>{weeklyGrowth} 
                                        
                                        
                                        </h5>  
                                    </Col>
                                </>)}
                                

                                <Col xs={12} paddingMd>
                                    <Button 
                                        activeButton={this.props.panelActiveType === PanelTypes.WeeklyPanel ? true : false} 
                                        mainTitle='Review All with Comments' 
                                        icon  
                                        onClick={() => this.props.onWeeklySelect(this.state.activeGradeBookEntry, true)} 
                                        isRectangular={true}
                                    />
                                </Col>
                            </Row>

                            <Row borderBottom>
                                <Col xs={6} paddingLgY> 
                                    <HoverModal title='Skills' type={HoverModalTypes.Primary} content={HoverModalContent.standard}/>
                                </Col>
                                <Col xs={6} paddingLgY alignRight>
                                    <h6>Proficiency Level </h6> 
                                </Col>
                                <Col xs={12} paddingMd>
                                    {fullStandardList.length > 0 ? (
                                        fullStandardList.map((standard: Standard, index) => {

                                                // We check what panel and if the selected standard is active
                                                let isActiveButton: boolean = false;
                                                if (this.props.selectedStandardProps && this.props.panelActiveType === PanelTypes.StandardsPanel){
                                                    const selectedStandard: string = this.props.selectedStandardProps.standardName.toLowerCase().replace(/\s/g, '');
                                                    const currentStandard: string = standard.standardName.toLowerCase().replace(/\s/g, '');
                                                    if(currentStandard === selectedStandard){
                                                        isActiveButton = true;
                                                    } else {
                                                        isActiveButton = false;
                                                    }
                                                } 
                                                const proficiencyScore = getMissingProficiencyScore(standard.assignments, standard.proficiency.proficiencyScore);

                                                return( 
                                                    <Button
                                                        activeButton={isActiveButton} 
                                                        key={index} 
                                                        mainTitle={standard.standardName} 
                                                        subTitleIcon={this.getSubTitleIcon(standard.standardName, isActiveButton)}
                                                        subTitle={convertScoreToWord(proficiencyScore)}
                                                        icon
                                                        onClick={() => this.props.onStandardSelect(this.state.activeGradeBookEntry, standard, true)}
                                                        isRectangular={true}
                                                    />                              
                                                ); 
                                            }
                                        )

                                    ): ( <h5 style={{ padding: '20px 0px', textAlign: 'center', color:'#babfce' }}>No Skills Found.</h5> )  }                         
                                </Col>
                            </Row>                            
                            {(this.state.allInterventions.length > 0) && this.props.isReportCard &&
                                <Row borderBottom>
                                    <Col xs={12} paddingLgRight style={{ display: 'flex', flexDirection: 'row'}}>
                                        <SupervisedUserCircle sx={{ fontSize: 26, color: '#5b606e', marginRight: '5px', marginLeft:'-25px' }}/>
                                        <h5>Interventions</h5>
                                    </Col>                                
                                    <Col xs={12} paddingMd >
                                        <Button
                                            activeButton={this.props.panelActiveType === PanelTypes.MttInterventionHistory} 
                                            mainTitle={`View Interventions`} 
                                            icon
                                            onClick={this.props.onInterventionHistorySelect ? this.props.onInterventionHistorySelect : () => ({})}
                                            isRectangular={true}
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row borderBottom>
                                <Col xs={12} paddingLgY>
                                    <HoverModal title='Practice/Preparation' type={HoverModalTypes.Primary} content={HoverModalContent.homeworkGraph}/>
                                </Col> 
                                <Col xs={12} padding="0px 8px 8px" >
                                    <AssignmentBar assignmentList={missingHomeworkBooleans} />
                                </Col>
                                <Col xs={6} paddingMd>  
                                    <Button 
                                        activeButton={this.props.panelActiveType === PanelTypes.HomeworkPanel && !this.props.isHomeworkFiltering ? true : false} 
                                        onClick={() => this.props.onHomeworkAllSelect(this.state.activeGradeBookEntry, false, true)} 
                                        mainTitle='Review All' 
                                        icon 
                                        isRectangular={true}
                                    />
                                </Col>
                                <Col xs={6} paddingMd >
                                    <Button 
                                        activeButton={this.props.panelActiveType === PanelTypes.HomeworkPanel && this.props.isHomeworkFiltering ? true : false} 
                                        onClick={() => this.props.onHomeworkAllSelect(this.state.activeGradeBookEntry, true, true)} 
                                        mainTitle='Incomplete' 
                                        icon
                                        isRectangular={true}
                                    />
                                </Col>
                            </Row>
                        </>
                    </ScrollerArea>   
                </ScrollerHolder>
                
            </>
        );
    }

    private renderIcon = () => {
        return <ContactMailIcon/>
    }

    private convertWG = (weeklyGrowth:string): string => {
        let letterType: string = '';

        switch(weeklyGrowth.toLowerCase()) {
            case 'ag':            
                letterType = 'Appropriate Growth';
                break;        
            case 'mg':                 
                letterType = 'Minimal Growth';
                break;
            case 'fg':            
                letterType = 'Failing to Grow';
                break;
            case 'ip':            
                letterType = 'In Progress';
                break;    
            default:
                letterType = weeklyGrowth;
            break;
        }
 
        return letterType;
    }
     
    
}

export default StudentSelectReportCard;