import * as React from 'react';
import { connect, useSelector,useDispatch} from 'react-redux';
import PanelTitleBar, { PanelTitleTypes } from 'components/layout/PanelTitleBar';
import FlexLabel,{ FlexRow, FlexCol } from 'components/layout/FlexHelpers';
import { Td, Tr } from 'components/layout/Tables';
import './Shared.css';
import { FeatureFlagState, FeatureFlag } from 'store/featureflags/types';
import { postSkillProficiency } from 'api/gradebookApi';
import { AxiosResponse } from "axios";
import { GradebookEntry, IStudentGradeBook } from 'models/GradebookEntry';
import {Course} from 'models/Course';
import { GradeStatus, SkillStatus } from 'models/Grade';
import { Standard } from 'models/Standard';
import { IMenuListItems } from 'models/Menus';
import SkillProficiencyGraph from '../charts/SkillProficiencyGraph';
import { ProficiencyType, ScoreWords, ScoreInitials } from 'models/enums/Standards';
import { updateNotificationsAction, NotificationsType } from "store/notifications/notificationsReducer";
import { overrideSkillProficiencyAction, initializeSkillProficiencyAction, updateMttStatusAction } from "store/student/gradebookReducer";
import { globalSelector } from "../../store/global/globalReducer";
import { filterSkillIncompleteOptions } from 'utils/helpers/skillsHelpers';
import { hasMissingActiveAssignments } from 'utils/helpers/generalHelpers';
import { axiosResponseValid } from 'utils/helpers/apiHelpers';
import { getStudentMttStatus } from 'api/interventionApi';
import { MttStatus } from 'models/enums/Mtt';
import { getEnumFromString, getMttEnumString } from 'utils/helpers/mttHelpers';


interface StandardsGradebookCardProps {
    grades: GradebookEntry[];
    course: Course;
    onStandardSelect: (values: GradebookEntry, selectedStandard: Standard) => void;
}


export const StandardsGradebookCard = ( {grades, course, onStandardSelect}:StandardsGradebookCardProps) => {
    //#region Variables:
    const features = (state) => state.featureFlags;
    const activeFeatures = useSelector(features);
    const { currentTermId, isFinalWeekMode } = useSelector(globalSelector);
 

    const dispatch = useDispatch();
    const canAccessDrawer = (flags: FeatureFlag[]): boolean => {
        const filterFlags = flags.filter((flag)=>{
                    return flag.value;
                }).map((flag)=>flag.key.toLocaleLowerCase());
    
        const canAccess: boolean =  filterFlags.some(flag => flag == 'standardsdrawerenabled' || flag == 'canaccessstandardsdrawer');
        return canAccess;
                    
    }
    const filteredGradebook: GradebookEntry[] = grades.map(gradebookItem => {
        const modifiedGradebook: GradebookEntry =  {...gradebookItem, assessment: {...gradebookItem.assessment, standards: [...gradebookItem.assessment.standards]}}
        modifiedGradebook.assessment.standards = gradebookItem.assessment.standards.filter(s => s.assignments.some(a => a.isHomework === false));
        return modifiedGradebook;
    });
    
    const getHeaders = (grades: GradebookEntry[]): any => {
        const headers = Object.assign({});
        for(let i = 0; i < grades.length; i++){
            for(let j = 0; j < grades[i].assessment.standards.length; j++){
                // eslint-disable-next-line no-prototype-builtins
                if(headers.hasOwnProperty(grades[i].assessment.standards[j].standardName))
                    continue;
                else{
                    headers[grades[i].assessment.standards[j].standardName] = true;
                }         
            }
        }
        return headers;
    }

    const headerObject: any = getHeaders(filteredGradebook);
    const headers: string[] = [];
    for(const header in headerObject){
        // eslint-disable-next-line no-prototype-builtins
        if(headerObject.hasOwnProperty(header)){
            headers.push(header);
        }
    }
    let tableWidth: string;
    if(headers.length === 0){
        tableWidth = '100%'
    }else{
        tableWidth = (headers.length * 200) + 'px';
    }
    const staticSkillProficiencyMenuOptions:IMenuListItems[] = [     
        {
            primaryText: ScoreWords.Exceeds,
            primaryIntials: ScoreInitials.E,
            id: 4
        },
        {
            primaryText: ScoreWords.Meets,
            primaryIntials: ScoreInitials.M,
            id: 3
        },
        {
            primaryText: ScoreWords.Approaching,
            primaryIntials: ScoreInitials.A,
            id: 2
        },
        {
            primaryText: ScoreWords.Developing,
            primaryIntials: ScoreInitials.D,
            id: 1
        },
        {
            primaryText: ScoreWords.Incomplete,
            primaryIntials: ScoreInitials.I,
            id: 5
        }
    ];
    //#endregion Variables:

    //#region Functions:
    async function OverrideSkillProficiencyAsync(studentPersonId: number, sectionId: number, standardTaskId: number, stdMatrixId: number, oldStdMatrixId: number, isSkillOveride:boolean, intialFinalGrade: string, intialProjectedGrade: string, weeklyGrowth: string, currentMTTStatus: MttStatus) {
        // Intialize Skill Proficiency
        dispatch(
          initializeSkillProficiencyAction({
            studentPersonId: studentPersonId,
            sectionId: sectionId,
            standardTaskId: standardTaskId,
            stdMatrix: SkillStatus.LoadingSkill,
            finalGrade: GradeStatus.LoadingGrade,
            projectedGrade: GradeStatus.LoadingGrade,
          })
        );

        const response: AxiosResponse<IStudentGradeBook> = await postSkillProficiency(
          { studentPersonId: studentPersonId, sectionId: sectionId, standardTaskId: standardTaskId, stdMatrix: stdMatrixId, isSkillOveride: isSkillOveride },
          currentTermId, isFinalWeekMode
        ).catch((error) => {
          errorSkillProficiency(studentPersonId, sectionId, standardTaskId, oldStdMatrixId, intialFinalGrade, intialProjectedGrade);
        });

        if (axiosResponseValid(response)) {
            dispatch(
              overrideSkillProficiencyAction({
                studentPersonId: studentPersonId,
                sectionId: sectionId,
                standardTaskId: standardTaskId,
                stdMatrix: stdMatrixId,
                finalGrade: response.data.finalGrade,
                isVerified: response.data.isVerified,
                projectedGrade: response.data.projectedGrade,
                isSkillOveride: isSkillOveride
              })
            );
            dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: `Successfully overwritten skill proficiency` }));
            dispatch(updateMttStatusAction({studentId:studentPersonId, mttStatus: MttStatus.LoadingEligiblity}));

            // Get new mtt status based on new projected grade
            const responseMttStatus: AxiosResponse<string> = await getStudentMttStatus({
                studentId: studentPersonId,
                mttEligibleStudent: true,
                currentMTTStatus: currentMTTStatus,
                projectedGrade: response.data.projectedGrade ? response.data.projectedGrade : '',
                currentWeeklyGrowth: weeklyGrowth
            }, sectionId, currentTermId).catch(error => { 
                dispatch(updateNotificationsAction({
                  notificationStatus: NotificationsType.Error,
                  notificationMessage: `Error: ${error.statusText} | Could not update MttStatus`
                }));
              });

              if (axiosResponseValid(responseMttStatus)) {
                dispatch(updateMttStatusAction({studentId:studentPersonId, mttStatus: getEnumFromString(MttStatus, getMttEnumString(responseMttStatus.data)) }));
                dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: `Successfully updated Mtt Status` }));
              }else{
                dispatch(updateNotificationsAction({
                    notificationStatus: NotificationsType.Error,
                    notificationMessage: `Error:Could not update MttStatus`
                }));
 
                dispatch(updateMttStatusAction({studentId:studentPersonId, mttStatus: currentMTTStatus}));
              }
        } else {
            errorSkillProficiency(studentPersonId, sectionId, standardTaskId, oldStdMatrixId, intialFinalGrade, intialProjectedGrade);
        }
    }

    const errorSkillProficiency = (studentPersonId: number, sectionId: number, standardTaskId: number, stdMatrixId: number, intialFinalGrade: string, intialProjectedGrade: string): void => {
        dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Error, notificationMessage: 'Error overriding skill proficiency' }));
        // Reset Skill Proficiency if there is an error
        dispatch(
            initializeSkillProficiencyAction(
                {
                    studentPersonId: studentPersonId,
                    sectionId: sectionId,
                    standardTaskId: standardTaskId,
                    stdMatrix: stdMatrixId,
                    finalGrade: intialFinalGrade,
                    projectedGrade: intialProjectedGrade
                }
            )
        );
    }

    //#endregion Functions:

    return (
        <>
            <div style={{ minWidth: tableWidth }} >
                <PanelTitleBar mainTitle={course.ebrFlag == true ? 'Skills' : 'Skills / Grading Task'} toolBarType={PanelTitleTypes.Secondary} />
            </div>
            {canAccessDrawer(activeFeatures.featureFlags)}

            <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel" style={{ width: tableWidth }} >
                <thead className="gradebookTableHeaderRow">
                    <tr>
                        {headers.map((header: string, index) => {
                            return (
                                <th key={index} style={{ width: '200px' }} align='center'>

                                    <FlexRow justifyContent='flex-end' flexDirection='column'>
                                        <FlexCol textAlign='center' restrictHeight style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', paddingBottom: '2px', fontSize: '13px' }}>
                                            {header}
                                        </FlexCol>
                                        <FlexCol>
                                            <FlexLabel maxWidth={'50%'} >
                                                <span>E</span>
                                                <span>M</span>
                                                <span>A</span>
                                                <span>D</span>
                                            </FlexLabel>
                                        </FlexCol>
                                    </FlexRow>

                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {filteredGradebook.map((gradebook: GradebookEntry, index) => {

                        const items = headers.map((header: string, index) => {
                            const standard = gradebook.assessment.standards.filter(x => x.standardName == header);
                            const graphLevelsArray: Array<string> = standard.length > 0 ? standard[0].assignments.filter(x => x.score !== null && x.standardEventActive == 1).sort(function compare(subItemA: any, subItemB: any) {
                                const dateA = +new Date(subItemA['dueDate']);
                                const dateB = +new Date(subItemB['dueDate']);
                                return dateA - dateB;
                            }).map(function (item) { return item.score }) : ['0', '0'];


                            if (standard.length > 0 && !gradebook.student.isDropped) {

                                const proficiencyArray: Array<number> = [standard[0].proficiency.exceedsCount, standard[0].proficiency.meetsCount, standard[0].proficiency.approachingCount, standard[0].proficiency.developingCount];
                                const isOverWritten: boolean = standard[0].proficiency.isOverWritten;

                                return (
                                    <Td 
                                        key={`${index}-${gradebook.student.personId}`}
                                        isActive={canAccessDrawer(activeFeatures.featureFlags)}      
                                    >
                                        <SkillProficiencyGraph 
                                            proficiencyScore={standard[0].proficiency.proficiencyScore}
                                            proficiencyType={ProficiencyType.Initial}
                                            proficiencyArray={proficiencyArray}
                                            graphLevels={graphLevelsArray}
                                            skillMenuOptions={filterSkillIncompleteOptions(staticSkillProficiencyMenuOptions,hasMissingActiveAssignments(standard[0].assignments))}
                                            suggestedProficiencyScore={ Number(standard[0].proficiencyLevel)}
                                            isSkillOverWritten={isOverWritten}
                                            viewSkillProficienies={ () => { 
                                                canAccessDrawer(activeFeatures.featureFlags) && 
                                                    onStandardSelect(gradebook,standard[0]) }
                                            }
                                            disableSkillMenu={gradebook.student.directGradeEligible}
                                            overrideSkillProficieny={(stdMatrixId:number, isSkillOveride:boolean) => { 
                                                OverrideSkillProficiencyAsync(
                                                    gradebook.student.personId,
                                                    gradebook.student.sectionId,
                                                    standard[0].standardTaskID,
                                                    stdMatrixId,
                                                    standard[0].proficiency.proficiencyScore,
                                                    isSkillOveride,
                                                    gradebook.assessment.finalGrade,
                                                    gradebook.assessment.projectedGrade,
                                                    gradebook.assessment.weeklyGrowth,
                                                    gradebook.mandatoryTargetedTutoring.currentStudentStatus
                                                    
                                                )
                                            }}
                                        /> 
  

                                    </Td>
                                );
 
                        }
                        else{
                            return <Td key={`${index}-${gradebook.student.personId}`} style={{borderRight: '1px solid #cdcdcd'}}> </Td>
                        }   
                        
                    });
                    return <Tr className="studentRow" borderRight key={`items-${gradebook.student.personId}`}>{items}</Tr>
                    }
                )}
                </tbody>
            </table>
        </>
    );
 
 
}
interface StoreProps {
    features: FeatureFlagState;
}
function mapStateToProps(state: any ): StoreProps {
    return {
        features: state.featureFlags
    };
}
  
export default connect<StoreProps>(mapStateToProps)(StandardsGradebookCard);
