import { Assignment } from "./Assignment";
import { Proficiency } from "./Proficiency";

export interface StandardJson {
    gradingTask: string;
    standardName: string;
    standardTaskID:number;
    proficiencyLevel: string;
    proficiency: Proficiency;
    assignments: Assignment[];
    isHomeworkStandard: boolean;
}

export class Standard {

    public static fromJson = (standard: StandardJson) => {
        if (standard) {
            return new Standard(
                standard.gradingTask,
                standard.standardName,
                standard.standardTaskID,
                standard.proficiencyLevel,
                standard.proficiency,
                standard.assignments,
                standard.isHomeworkStandard
            );
        }
        return new Standard();
    }

    constructor(
        public gradingTask: string = "",
        public standardName: string = "",
        public standardTaskID: number = -1,
        public proficiencyLevel: string = "",
        public proficiency: Proficiency = new Proficiency(),
        public assignments: Assignment[] =[],
        public isHomeworkStandard: boolean = false,
        
    ) {}
}