export interface CourseJson {
    courseId: number;
    courseName: string;
    courseNumber: string;
    ebrFlag: boolean;
    isDirectGradeSection: boolean;
    sectionId: number;
    periodId: number;
    teacherDisplay: string;
    teacherPersonID: number;
    periodName: string;
    periodStart: Date;
    teacherName: string;
    teacherEmail: string;
    teacherPhone: string;
    isDropped: number;
}
  
export class Course {
    public static fromJson = (course: CourseJson) => {
        if (course) {
            return new Course(
                course.courseId,
                course.courseName,
                course.courseNumber,
                course.ebrFlag,
                course.isDirectGradeSection,
                course.sectionId,
                course.periodId,
                course.teacherDisplay,
                course.teacherPersonID,
                course.periodName,
                course.periodStart,
                course.teacherName ? course.teacherName : '',
                course.teacherEmail ? course.teacherEmail : '',
                course.teacherPhone ? course.teacherPhone : '',
                course.isDropped,
            );
        }
        return new Course();
    }

    constructor(
        public courseId: number = -1,
        public courseName: string = '',
        public courseNumber: string = '',
        public ebrFlag: boolean = false,
        public isDirectGradeSection: boolean = false,
        public sectionId: number = -1,
        public periodId: number = -1,
        public teacherDisplay: string = '',
        public teacherPersonID: number = -1,
        public periodName: string = '',
        public periodStart: Date = new Date(),
        public teacherName: string = '',
        public teacherEmail: string = '',
        public teacherPhone: string = '',
        public isDropped: number = -1,
    ) {}
}
