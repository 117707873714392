import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { GradeStatus } from '../../models/Grade';
import CircularProgress from '@mui/material/CircularProgress';

interface IncomingProps {
    parentRange: string; 
}
  
export enum GradeType{
    EvidenceBased,
    TraditionalBased,
    Incomplete,
}
 
const checkGradeType = ( letterType: string ): GradeType => {
    if(!letterType) letterType = "";

    let updatedletterType:string = letterType.toLowerCase();
 
    // Check Evidience Based
    if (updatedletterType.indexOf('/') > -1 && updatedletterType.indexOf('i') === -1 ) { 
        updatedletterType = 'evidence'

    // Check If I or If * or Empty String or If P 
    } else if (
            (updatedletterType.indexOf('i') > -1) ||
            (updatedletterType.indexOf('*') > -1) ||
            (updatedletterType === "") || 
            (updatedletterType.indexOf('p') > -1) ||
            (updatedletterType.match(/[`!@#$%^&()_=\[\]{};':"\\|,.<>\?~]/)) ||
            (!isNaN(parseInt(updatedletterType)))
        ) 
    { 
        updatedletterType = 'incomplete'

    //  Else its Traditional
    } else {
        updatedletterType = 'traditional'
    }
 
    let gradeType: GradeType;
    switch(updatedletterType) {
        case 'evidence':
            gradeType = GradeType.EvidenceBased;
            break;
        case 'incomplete':
            gradeType = GradeType.Incomplete;
            break;
        case 'traditional':
            gradeType = GradeType.TraditionalBased;
            break;    
        default:
            gradeType = GradeType.Incomplete;
        break;
        }
    return gradeType;

}

const getTextColor = (grade:string): string => {
    const projectedGrades = grade.toLowerCase();
    if( 
        projectedGrades === 'p' || 
        projectedGrades === '' || 
        projectedGrades === 'i/p' || 
        projectedGrades === '*' ||
        !isNaN(parseInt(projectedGrades))
    ){
        return '#000000';
    }else {
        return '#FF0000';
    }
}

const ProjectedGradeLetter = ({ ...props }: IncomingProps) => (
    <>
        {props.parentRange === GradeStatus.LoadingGrade ? 
            <CircularProgress size={19} color="inherit" /> :  
            checkGradeType(props.parentRange) == GradeType.Incomplete ? (
                // ======= Show only if Incomplete Based ========== //
               <>
                    {props.parentRange === null || props.parentRange === '' ? ( <></>) :
                    ( 
                            <Avatar sx={{ bgcolor: 'transparent', color: getTextColor(props.parentRange), fontWeight: 'bold', fontSize: '1.2rem',height:'30px' }} variant="rounded">
                                {
                                    props.parentRange.toLowerCase().indexOf('i') > -1 &&
                                        props.parentRange.toLowerCase() !== 'i/fg' &&
                                        props.parentRange.toLowerCase().indexOf('p') === -1 ?
                                        (<>I</>) : (props.parentRange)
                                }
                            </Avatar>
                    )}
                </>
            ) : (
                // ======= Show if Traditional Based || Evidence Based ========== //
                <Avatar sx={{ bgcolor: 'transparent', color:'#000000', fontSize:'1rem', height:'30px',  alignItems:'center' }} variant="rounded">
                    {props.parentRange}
                </Avatar>
            )
        }
    </>
);

export default ProjectedGradeLetter;