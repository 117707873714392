import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { GradebookEntry } from 'models/GradebookEntry';
import { IStudentWeeklyGrowthScores } from 'models/Student';
import { TeacherStudent } from 'models/TeacherStudent';
import { HeaderSelectInput } from '../inputs-elements/CustomInputs';
import PanelTitleBar, { PanelTitleTypes } from '../layout/PanelTitleBar';
import EditWeeklyGrowthMenu, { MenuLayout } from '../navigation/EditWeeklyGrowthMenu';
import { CommentDialog } from '../layout/CommentDialog';
import { updateStudentsGrowth } from 'api/gradebookApi';
import { IWeekDate } from 'models/WeekDate';
import { WeekType } from 'models/WeeklyGrowth';
import './Shared.css';

import { IMenuListItems, IWeekOptions } from 'models/Menus';
import { IActiveStudent, IStudentWeeklyGrowth } from 'models/Student';
import { NotificationsType, updateNotificationsAction } from "../../store/notifications/notificationsReducer";
import { updateWeeklyGrowthAction, updateMttEligibiltyAction, gradebooksSelector, initializeEligibiltyAndGradeAction, viewPreviousWGScoresAction} from "../../store/student/gradebookReducer";
import { globalSelector, updateCurrentWeekDateAction, updateViewingWeekTypeAction } from "../../store/global/globalReducer";
import { hasWeeklyScoreAlready, getWeeklyGrowthOptions, getWeeklyGrowthScores, shortenWeeklyGrowthValue, isFinalsWeek } from "../../utils/helpers/generalHelpers";
import HoverModal, { HoverModalTypes, HoverModalContent } from '../layout/HoverModal';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from "styled-components";

export interface IWeeklyGrowthColumnProps {
  grades: GradebookEntry[];
  onWeeklySelect: (selectedGradeBook: GradebookEntry) => void;
  onCurrentWeekChange: (taskId: number) => void;
  availableOptions: IMenuListItems[];
  disableWeeklyGrowthButton: boolean;
  termIsEditable: boolean;
  selectedTerm: number;
}

export const WeeklyGrowthColumn = (props: IWeeklyGrowthColumnProps) => {
  //#region Variables:
  const [commentDialogIsOpen, setCommentDialogIsOpen] = useState(false);
  const [activeStudent, setActiveStudent] = useState<IActiveStudent>({ studentName: '', studentId: -1, directGradeEligible: false, studentProjectedGrade: '', studentFinalGrade: '', studentFinalGradeStatus: undefined, studentMttStatus: '', sectionId: -1, scoreText: '', scoreIntials: '' });
  const [assignWOutComments, setAssignWOutComments] = useState(false);
  const [studentCurrentWeekScore, setStudentCurrentWeekScore] = useState({ currentScoreComment: '', currentScore: '', hasScoreAlready: false });
  const [weeklyGrowthSelectOptions, setWeeklyGrowthSelectOptions] = useState<Array<IWeekOptions>>([]);
  const [activeWeeklyGrowthOption, setActiveWeeklyGrowthOption] = useState<IWeekOptions>({ code: '', task: '', taskId: -1 });
  const [studentsWeeklyGrowth, setStudentsWeeklyGrowth] = useState<Array<IStudentWeeklyGrowth>>([]);
  const dispatch = useDispatch();
  const { allGradebooks} = useSelector(gradebooksSelector);
  const { currentWeekTaskId, currentTermId, isFinalWeekMode, viewingWeekType } = useSelector(globalSelector);
  //#endregion Variables:

  //#region Styles:
  const TableBody = styled.tbody<{ weekType: WeekType }>`
    background: ${({ weekType, theme: { colors } }) => weekType === WeekType.CurrentWeek ? '' : weekType === WeekType.FinalWeek ? colors.gold.lightAlert : weekType === WeekType.PastWeek ? colors.greens.xlight : ''};
    tr{ background-color:${({ weekType, theme: { colors } }) => weekType === WeekType.CurrentWeek ? '' : weekType === WeekType.FinalWeek ? colors.gold.lightAlert + '!important' : weekType === WeekType.PastWeek ? colors.greens.xlight + '!important' : ''}; 
    td{ border-top:1px solid ${({ weekType, theme: { colors } }) => weekType === WeekType.FinalWeek ? colors.gold.dark + '!important' : '#dee2e6'};
  `;
  //#endregion

  //#region Hooks:
  useEffect(() => {
    // If Assigning a new score with out comments we call the api directly
    if (assignWOutComments && (viewingWeekType !== WeekType.FinalWeek)) {
      updateWeeklyGrowthScore();
    }
  }, [assignWOutComments, activeStudent.directGradeEligible, viewingWeekType, isFinalWeekMode]);

  useEffect(() => {
    if (currentWeekTaskId && isFinalsWeek(currentWeekTaskId)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    }

    if (currentWeekTaskId && !isFinalsWeek(currentWeekTaskId) && isFinalWeekMode && (viewingWeekType !== WeekType.PastWeek)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    }
  }, [currentWeekTaskId, viewingWeekType]);

  useEffect(() => {
    if (props.grades.length > 0) {
      const studentsWg: Array<IStudentWeeklyGrowth> = [];
      props.grades.forEach(gradebookItem => {
        studentsWg.push({
          studentId: gradebookItem.student.personId,
          weeklyGrowthArray: gradebookItem.weeklyGrowth
        })
      });
      const weeklyGrowthOptions: IWeekOptions[] = getWeeklyGrowthOptions(studentsWg);

      // We only want to update the options and growth scores once on course gradebook load            
      if (weeklyGrowthOptions.length > 0 && activeWeeklyGrowthOption.code === '') {
        setWeeklyGrowthSelectOptions(weeklyGrowthOptions);
        setStudentsWeeklyGrowth(studentsWg);
        populateWeeklyGrowthScores(weeklyGrowthOptions[0].code, weeklyGrowthOptions[0], studentsWg);
      }

    }
  }, [props.grades, activeWeeklyGrowthOption]);

  useEffect(() => {
    const newActiveWeek: IWeekDate = { ...activeWeeklyGrowthOption };
    newActiveWeek.termId = props.selectedTerm;
    dispatch(updateCurrentWeekDateAction(newActiveWeek));
  }, [activeWeeklyGrowthOption, weeklyGrowthSelectOptions]);

  //#endregion Hooks:

  //#region Async Functions:
  async function updateWeeklyGrowthScore(newCommentText?: string) {
    dispatch(initializeEligibiltyAndGradeAction([{ studentId: activeStudent.studentId }]));
    const response = await updateStudentsGrowth([{
      studentPersonId: activeStudent.studentId,
      sectionId: activeStudent.sectionId,
      weeklyGrowthScore: activeStudent.scoreIntials,
      projectedGrade: activeStudent.studentProjectedGrade,
      currentMTTStatus: activeStudent.studentMttStatus,
      comment: newCommentText ? newCommentText : '',
      taskId: currentWeekTaskId,
      termId: currentTermId
    }]).catch(error => {
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: ${error.statusText} | Updating Student Growth / Eligibilty`
      }));
      setCommentDialogIsOpen(false);
    });
    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);
      dispatch(updateWeeklyGrowthAction([{
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        weeklyGrowthScore: activeStudent.scoreIntials,
        scoreText: activeStudent.scoreText,
        projectedGrade: correctStudent ? correctStudent['projectedGrade'] : '',
        comment: newCommentText ? newCommentText : '',
        currentWeek: correctStudent ? correctStudent['currentWeek'] : '',
        taskId: currentWeekTaskId,
        sequence: correctStudent ? correctStudent['seq'] : 0
      }]));
      setCommentDialogIsOpen(false);
      setAssignWOutComments(false);
      // Based on the response we also update local store Eligibilty / Notifications
      dispatch(updateMttEligibiltyAction([{
        studentId: activeStudent.studentId,
        mttStatus: correctStudent ? correctStudent['eligiblityStatus'] : 0
      }]));
      dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: 'Growth / Eligibilty Updated' }));
    }
  }
  //#endregion Async Functions:

  //#region Functions:
  const updateStudentAndScore = (student: TeacherStudent, projectedGrade: string, currentMTTStatus: string = '', newScoreText: string = '', newScoreIntials: string = '', openWGCommentsDialog: boolean, finalGrade: string = '', finalGradeCurrenlyVerified?: boolean) => {

    setActiveStudent({
      studentName: student.lastName + ', ' + student.firstName,
      studentId: student.personId,
      sectionId: student.sectionId,
      directGradeEligible: student.directGradeEligible,
      scoreText: newScoreText,
      scoreIntials: newScoreIntials,
      studentProjectedGrade: projectedGrade,
      studentFinalGrade: student.directGradeEligible && newScoreIntials && !finalGrade ? newScoreIntials : finalGrade,
      studentFinalGradeStatus: finalGradeCurrenlyVerified,
      studentMttStatus: currentMTTStatus
    });

    const activeStudentGradebook: GradebookEntry = allGradebooks.find((gradeBook: GradebookEntry) => gradeBook.student.personId === student.personId);
    if (activeStudentGradebook) {
      // Auto fill comments and score if there is an exsiting one already for the week.
      const { hasWeeklyScore, score, comment } = hasWeeklyScoreAlready(activeStudentGradebook.weeklyGrowth, currentWeekTaskId);
      if (hasWeeklyScore)
        setStudentCurrentWeekScore({ currentScoreComment: comment, currentScore: score, hasScoreAlready: true });
    }

    openWGCommentsDialog ? setCommentDialogIsOpen(true) : setAssignWOutComments(true);
  };

  const populateWeeklyGrowthScores = (weekName: string, activeWGOption: IWeekOptions, studentsWg?: IStudentWeeklyGrowth[]): void => {
    const newActiveWGOption = activeWGOption ? activeWGOption : weeklyGrowthSelectOptions.find(option => option.task === weekName);
    const newStudentsWg = studentsWg ? studentsWg : studentsWeeklyGrowth;
    if (newActiveWGOption) {
      setActiveWeeklyGrowthOption(newActiveWGOption);
      const weeklyGrowthStudentScores: IStudentWeeklyGrowthScores[] = getWeeklyGrowthScores(newStudentsWg, newActiveWGOption.task);
      dispatch(viewPreviousWGScoresAction(weeklyGrowthStudentScores));
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Success,
        notificationMessage: `Viewing ${newActiveWGOption.task} Scores`
      }));
      const updatedWeek: IWeekDate = {
        taskId: newActiveWGOption.taskId,
        task: newActiveWGOption.task,
        termId: props.selectedTerm
      }
      dispatch(updateCurrentWeekDateAction(updatedWeek));
      props.onCurrentWeekChange(newActiveWGOption.taskId);
    }
  }

  const viewPastWeeklyGrades = (weekName: string): void => {
    const newActiveWGOption: IWeekOptions | undefined = weeklyGrowthSelectOptions.find(option => option.task === weekName);
    if (newActiveWGOption)
      populateWeeklyGrowthScores(weekName, newActiveWGOption);
    if (newActiveWGOption !== weeklyGrowthSelectOptions[0]) {
      dispatch(updateViewingWeekTypeAction(WeekType.PastWeek));
    } else if (newActiveWGOption === weeklyGrowthSelectOptions[0] && isFinalsWeek(newActiveWGOption.taskId)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    } else {
      dispatch(updateViewingWeekTypeAction(WeekType.CurrentWeek));
    }
  }

  const renderOptionValue = (valueText: string): string => {
    if (isFinalWeekMode && (weeklyGrowthSelectOptions['0'].task === valueText || weeklyGrowthSelectOptions['0'].code === valueText))
      return 'Final';
    return valueText;
  }

  //#endregion Functions:


  //#region Render:
  return (
    <>
      <PanelTitleBar
        toolBarType={PanelTitleTypes.Seventh}
        textAlign="center"
      >
        <Select
          id="demo-simple-select"
          onChange={(event: SelectChangeEvent) => viewPastWeeklyGrades(event.target.value)}
          label="Growth"
          value={activeWeeklyGrowthOption.task}
          renderValue={(value) => `${renderOptionValue(shortenWeeklyGrowthValue(value))}`}
          input={<HeaderSelectInput />}
          disabled={!props.termIsEditable}
        >
          {weeklyGrowthSelectOptions.map((item: IWeekOptions, index) => {
            return (
              <MenuItem key={index} value={item.task}>{renderOptionValue(item.task)}</MenuItem>
            );
          })}
        </Select>
      </PanelTitleBar>
      <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel">
        <thead className="gradebookTableHeaderRow">
          <tr>
            <th style={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'center' }}>
              {viewingWeekType === WeekType.PastWeek && (
                <HoverModal
                  title={shortenWeeklyGrowthValue(activeWeeklyGrowthOption ? activeWeeklyGrowthOption.task : '')}
                  type={HoverModalTypes.Third}
                  content={HoverModalContent.pastWeek}
                />
              )}

              {viewingWeekType === WeekType.FinalWeek && (
                <HoverModal
                  title={shortenWeeklyGrowthValue(activeWeeklyGrowthOption ? activeWeeklyGrowthOption.task : '')}
                  type={HoverModalTypes.Sixth}
                  content={HoverModalContent.finalWeek}
                />
              )}
            </th>
          </tr>
        </thead>
        <TableBody weekType={viewingWeekType} >
          {props.grades.map((grade: GradebookEntry, index: number) => {
            return <tr className={`studentRow`} key={grade.student.personId} >
              <td>
                <div className="gradebookTableRow" style={{ textAlign: 'center', cursor: 'default' }}>

                  {/* Viewing Regular non Final week in Week Select Dropdown */}
                  {viewingWeekType !== WeekType.FinalWeek && !grade.student.isDropped && (
                    <EditWeeklyGrowthMenu
                      buttonText={grade.assessment.weeklyGrowth}
                      secondaryButtonText={`View Growth`}
                      menuTitle={`Assign Growth`}
                      menuId={grade.student.personId.toLocaleString()}
                      menuItems={props.availableOptions}
                      menuLayout={MenuLayout.ButtonModalLayout}
                      updateGradeFunction={(updatedWGScoreText: string, updatedWGScoreInitials: string, withComments: boolean) =>
                        updateStudentAndScore(grade.student, grade.assessment.projectedGrade, grade.mandatoryTargetedTutoring.currentStudentStatus, updatedWGScoreText, updatedWGScoreInitials, withComments)
                      }
                      viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                      disableButton={props.disableWeeklyGrowthButton}
                    />
                  )}

                  {/* Viewing Final week in Week Select Dropdown / User should not have the ability to add weekly growth, only view */}
                  {viewingWeekType === WeekType.FinalWeek && !grade.student.isDropped && (
                    <EditWeeklyGrowthMenu
                      buttonText={'-'}
                      secondaryButtonText={`View Growth`}
                      menuTitle={`View Weekly Growth`}
                      menuId={grade.student.personId.toLocaleString()}
                      menuLayout={MenuLayout.ViewLayout}
                      viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                    />
                  )}

                </div>
              </td>
            </tr>
          }
          )}
        </TableBody>
      </table>

      <CommentDialog
        title={
          `Assign ${activeStudent.studentName} a (${activeStudent.scoreText}) with comments`
        }
        helperText={
          studentCurrentWeekScore.currentScore ? `This student has been assigned a (${studentCurrentWeekScore.currentScore}) score this week already` : ''
        }
        commentPlaceholder={`Enter a weekly comment or choose from the picklist`}
        submitBtnText='Save'
        cancelBtnText='Cancel'
        isOpen={commentDialogIsOpen}
        showCommentOptions
        commentValue={
          (studentCurrentWeekScore.currentScoreComment && (activeStudent.scoreIntials === studentCurrentWeekScore.currentScore)) ||
            (studentCurrentWeekScore.currentScoreComment && (activeStudent.directGradeEligible === true)) ?
            studentCurrentWeekScore.currentScoreComment : ''
        }
        isFinalCommentMode={false}
        currentFinalGradeStatus={activeStudent.studentFinalGradeStatus}
        directGradeStudentData={undefined}
        cancelFunction={() => setCommentDialogIsOpen(false)}
        submitFunction={(comment: string) =>
          updateWeeklyGrowthScore(comment)
        }
      />

    </>
  )
  //#endregion Render:
}