import * as React from 'react';
// import { SizeMe } from 'react-sizeme'
import { WeeklyGrowth } from '../../models/WeeklyGrowth';
import { GradebookEntry } from '../../models/GradebookEntry';
import {Course} from '../../models/Course';
import { Row, Col } from '../layout/ResponsiveGrid';
import { MdArrowBack } from "react-icons/md";
import HoverModal, {  HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import { Table, Tbody, Thead, Tr, Td, Th } from '../layout/Tables';
import './Shared.css'; 
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft,  PanelChildRight, PanelFlyoutHeader } from '../layout/PanelTitleBar';
import ScrollerHolder, { ScrollHeader,  ScrollerArea,  } from '../layout/ScrollerArea';
import { getActiveAssignments, hasMissingActiveAssignments } from '../../utils/helpers/generalHelpers';

// Props coming from Parent Components
interface IWeeklyGrowthReportCardProps {
    selectedGradeBookEntry: GradebookEntry;
    activeCourse: Course;
    breadCrumbNav?: boolean;
    onStudentSelect: Function;
    isReportCard?: boolean;
}

// Component States 
interface IWeeklyGrowthReportCardState {
    sortDescending: boolean;
    sortField: string; 
}
 
class WeeklyGrowthReportCard extends React.Component<IWeeklyGrowthReportCardProps, IWeeklyGrowthReportCardState> {
 
    constructor(props: any) {
        super(props);
        // Components Defualt States Values
        this.state = {
            sortDescending: false,
            sortField: 'description'
        };
    }

    render() {
        const courseName: string = this.props.activeCourse.courseName;
        const period: string = this.props.activeCourse.periodName;
 
        const teacherName: string = this.props.activeCourse.teacherName !== null ? this.props.activeCourse.teacherName : this.props.activeCourse.teacherDisplay !== null ? this.props.activeCourse.teacherDisplay : '';

        const teacherEmail: string = this.props.activeCourse.teacherEmail !== null ? this.props.activeCourse.teacherEmail : '';
        const teacherPhone: string = this.props.activeCourse.teacherPhone !== null ? this.props.activeCourse.teacherPhone : '';
        const studentFullName: string = this.props.selectedGradeBookEntry.student.lastName + ', ' + this.props.selectedGradeBookEntry.student.firstName;
        const weeklyGrowthArray: WeeklyGrowth[] = this.props.selectedGradeBookEntry.weeklyGrowth ? [...this.props.selectedGradeBookEntry.weeklyGrowth].sort((a,b) => {
            if(b.sequence && a.sequence){
                return b.sequence - a.sequence
            }
            return 1;
        }) : [];
 
        const grade: string = this.props.selectedGradeBookEntry.assessment.isVerified && this.props.selectedGradeBookEntry.assessment.finalGrade != ''
        ? this.props.selectedGradeBookEntry.assessment.finalGrade : this.props.selectedGradeBookEntry.assessment.projectedGrade;


        const weeklyGrowthInitials: string = this.props.selectedGradeBookEntry.assessment.weeklyGrowth ? this.convertWG(this.props.selectedGradeBookEntry.assessment.weeklyGrowth) : '';

        const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(this.props.selectedGradeBookEntry));

        return (
            <>  
 
                <ScrollerHolder>
                    <ScrollHeader>
                        <>


                            {/* ============ Is Not Report Card Show the following ================== */}
                            {!this.props.isReportCard &&
                                <>
                                    <PanelFlyoutHeader
                                        mainTitle={courseName}
                                        mainSubTitle={period}
                                        secondaryTitle={studentFullName}
                                        secondarySubTitle={grade}
                                        isFinal={this.props.selectedGradeBookEntry.assessment.isVerified}
                                        teacherArray={[teacherName, teacherEmail, teacherPhone]}
                                        toolBarType={PanelTitleTypes.Primary}
                                        showWarningIcon={hasMissingAssignment} 
                                    /> 
                                </>
                            }
                            {this.props.breadCrumbNav && 
                                <>
                                    {/* ==== Return ====  */}
                                    <PanelTitleBar toolBarType={PanelTitleTypes.Fourth}>
                                        <PanelChildLeft>
                                            <h6 style={{marginBottom:0}} onClick={ () => this.props.onStudentSelect(this.props.selectedGradeBookEntry) } >
                                                <MdArrowBack size='24'/> Overview
                                            </h6>
                                        </PanelChildLeft>
                                    </PanelTitleBar>
                                </>
                            }

                            <PanelTitleBar toolBarType={PanelTitleTypes.Third}>
                                <PanelChildLeft>
                                    <HoverModal title='Growth' type={HoverModalTypes.Fifth} content={HoverModalContent.weeklygrowth}/>
                                </PanelChildLeft>
                                <PanelChildRight>
                                    <h6>{weeklyGrowthInitials}</h6>
                                </PanelChildRight>
                            </PanelTitleBar>
                        </>
                    </ScrollHeader>
                    <ScrollerArea>
                        <>
                            <Row>
                                <Col xs={12}>
                                    {weeklyGrowthArray.filter(( weeklyGrowth: WeeklyGrowth ) => weeklyGrowth.score !== null && weeklyGrowth.score !== '').map(( weeklyGrowth: WeeklyGrowth, index, array) => (
                                        <Table key={index}>
                                            <Thead noBg>
                                                <Tr>
                                                    <Th>{weeklyGrowth.isFinalComment ? 'Final Comment' : weeklyGrowth.task + ` Comment` } </Th>
                                                    <Th style={{textAlign:'right'}}> 
                                                        {this.convertWG(weeklyGrowth.score)}
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>                                         
                                                <Tr>
                                                    <Td colSpan={2}>
                                                        {weeklyGrowth.comments === '' || weeklyGrowth.comments === null ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                                {weeklyGrowth.comments}
                                                            </>
                                                        )}

                                                    </Td>                                                
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    </ScrollerArea>   
                </ScrollerHolder>
 
            </>
        );
    }
    

    private convertWG = (weeklyGrowth:string): string => {
        let letterType: string = '';
        const score:string = weeklyGrowth ? weeklyGrowth.toLowerCase() : ''

        switch(score) {
            case 'ag':            
                letterType = 'Appropriate Growth';
                break;        
            case 'mg':                 
                letterType = 'Minimal Growth';
                break;
            case 'fg':            
                letterType = 'Failing to Grow';
                break;
            case 'ip':            
                letterType = 'In Progress';
                break;    
            default:
                letterType = weeklyGrowth;
            break;
        }
 
        return letterType;
    }
 


}


export default WeeklyGrowthReportCard;