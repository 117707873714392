export const SAVE_TOKEN : string = 'SAVE_TOKEN';
export const DELETE_TOKEN : string = 'DELETE_TOKEN';
export const INITIATE_LOGIN : string = 'INITIATE_LOGIN';
export const INITIATE_GOOGLELOGIN : string = 'INITIATE_GOOGLELOGIN';
export const INITIATE_LOGOUT : string = 'INITIATE_LOGOUT';
export const LOGIN_ERROR : string = 'LOGIN_ERROR';
export const LOGIN_SUCCESS : string = 'LOGIN_SUCCESS';
export const LOGOUT_ERROR : string = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS : string = 'LOGOUT_SUCCESS';


export interface Authentication {
    username: string;
    password: string;
}

export interface SessionToken {
    token: string;
    authorized: boolean;
}

export interface User {
    userId: string;
    username: string;
    roles: UserRole[];
    staffNumber: string;
    studentNumber: string;
    personId: string;
    identityId: string;
    firstName: string;
    lastName: string;
}
export enum UserRole{
    Student = 0,
    Teacher = 1,
    Parent = 2,
    SST = 3,
    Admin = 4,
    Tutor = 5,
    TeacherTutor = 6,
    Director = 7,
    SpecialEd = 8,
    GuidedStudy = 9,
    ViewAllReportCards = 10
}
export interface ErrorResponse{
    message: string;
}

export interface SessionState {
    token: SessionToken;
    user: User;
    isLoggingIn: boolean;
    isLoggedIn: boolean;
    isError: boolean;
    errorMessage: string;
}

interface LoginAction {
    type: typeof INITIATE_LOGIN;
    payload: Authentication;
}
interface LogoutAction{
    type: typeof INITIATE_LOGOUT;
    payload: any;
}
interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: User;
}
interface LoginErrorAction {
    type: typeof LOGIN_ERROR;
    payload: ErrorResponse;
}
interface LogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS;
    payload: any;
}
interface LogoutErrorAction {
    type: typeof LOGOUT_ERROR;
    payload: any;
}
interface SaveTokenAction {
    type: typeof SAVE_TOKEN;
    payload: SessionToken;
}

interface DeleteTokenAction {
    type: typeof DELETE_TOKEN;
    payload: any;
}

export type SessionTokenActionTypes = SaveTokenAction | DeleteTokenAction | LoginAction | LoginSuccessAction | LoginErrorAction | LogoutAction | LogoutSuccessAction | LogoutErrorAction;