import React, { useState,useEffect } from 'react';
import { connect,useDispatch,useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './NavMenu.css';

import Logo from 'components/layout/Logo';
import { FeatureFlagState, FeatureFlag } from 'store/featureflags/types';
import { FeatureFlagType } from 'models/enums/FeatureFlag';
import { SessionState } from 'store/session/types';
import { updateIsFinalWeekModeAction, updateUserTypesAction, globalSelector, updateViewingWeekTypeAction } from 'store/global/globalReducer';
import { WeekType } from 'models/WeeklyGrowth';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { palette } from '@mui/system';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {LocalStorageType} from 'models/enums/LocalStorage';
import { Label } from '@mui/icons-material';
 

interface StateProps {
  session: SessionState;
  flags: FeatureFlagState;
}

interface LinkType{
  to: string;
  label: string;
  permission: string;
}

export const NavMenu = (props: StateProps) => {

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [environmentName, setEnvironmentName] = useState<string>('');
  const { currentTermId, currentWeekHideProjectedGrade, skillOverridesAllowed,gradeMode, isFinalWeekMode } = useSelector(globalSelector);


  useEffect(() => {    
    const environmentFlag = props.flags.featureFlags.find((flag) => flag.key.toLowerCase() == 'environment' && flag.valueString);
   
    if(environmentFlag && (environmentFlag.valueString.toLowerCase() !== `prod` && environmentFlag.valueString.toLowerCase() !== `production`)){
      setEnvironmentName(`${environmentFlag.valueString == 'Stage' ? 'Dev' : environmentFlag.valueString}`);
    }
  }, [props.flags.featureFlags]);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
 
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const EnvironmentTitle = styled(Typography)({
    margin:'0 0 0 14px',
    lineHeight:'14px',
    fontWeight:'500',
    fontSize: '.6rem',
    alignSelf: 'center',
    background: '#214d2e',
    padding: '3px 10px',
    borderRadius: '4px'
  });
  const EnvironmentSpan = styled('span')({
    margin:'0 0 0',
 
    fontWeight:'500',
    fontSize: '.6rem',
    color:'#aaa'

  });
 
  return (
    <AppBar className='main-header' position="relative" color='primary' sx={{ backgroundColor: palette['primary'] }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>

          {/* Logo for Large Devices*/}
          <Box sx={{  display: { xs: 'none', md: 'flex' }, mr: 0 }}>
            <Logo />

            {/* Will only show on lower envrionments */}
            {environmentName !== '' && (
              <EnvironmentTitle variant='h6'>
                <EnvironmentSpan>Env: </EnvironmentSpan>
                {environmentName}
                <EnvironmentSpan> Term: </EnvironmentSpan>
                {currentTermId} <br/>
                <EnvironmentSpan> Mode: </EnvironmentSpan>
                {currentWeekHideProjectedGrade ? `Hide Projected Grade |` : `Show Projected Grade |`}
                {skillOverridesAllowed ? ` Allow Skill Overrides |` : ` No Skill Overrides |`}
                {gradeMode ? ` Show Single Grade Toggle ` : ` Hide Single Grade Toggle `}
                {isFinalWeekMode ? `| Final Week ` : ``}


              </EnvironmentTitle>  
            )}
            
          </Box>

          {/* Menu for Small devices*/}
          <Box sx={{  display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {RenderNavlinks(props.flags.featureFlags, props.session.token.authorized, props)}
              {RenderAccountSettings(props.session.token.authorized, true, props, environmentName)}
            </Menu>
          </Box>

          {/* Logo for Small devices*/}
          <Box sx={{ flexGrow: 1, justifyContent: 'center', display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Logo />
          </Box>

          {/* Menu for Large devices*/}
          <Box sx={{ flexGrow: 1, justifyContent:'end', display: { xs: 'none', md: 'flex' } }}>
            <ul
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                margin: 0,
                paddingLeft: 0
              }}
            >
              {RenderNavlinks(props.flags.featureFlags, props.session.token.authorized, props)}
              {RenderAccountSettings(props.session.token.authorized, false, props, environmentName)}

            </ul>
          </Box>
 
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const RenderNavlinks = (flags: FeatureFlag[], isLoggedIn: boolean, props: StateProps) => {

  const location = useLocation();

  const linkTypes: LinkType[] = [
    {   
      to: "/",
      label: "Home", 
      permission: ""
    },
    {
      to: "/reportCard",
      label: "Report Card",
      permission: "canaccessreportcards"
    }, 
    {
      to: "/mandatory-targeted-tutoring",
      label: "Mandatory Targeted Tutoring",
      permission: "canaccessmtt"
    },
    {
      to: "/gradebook",
      label: "Gradebook",
      permission: "canaccessgradebook"
    }  
  ];

  const filterFlags = flags.filter((flag)=>{
      return flag.value;
    }).map((flag)=>flag.key.toLocaleLowerCase());
 
  return( 
    linkTypes.filter((link)=>{ return filterFlags.includes(link.permission.toLocaleLowerCase()); }).map((link:LinkType, i: number)=>{
      return (
        <MenuItem 
          key={i}
          selected={location.pathname == link.to ? true : false}
        > 
          <Link to={link.to}>
            {link.label}
          </Link>
        </MenuItem>
      );
    })
  );
}



const RenderAccountSettings = (isAuthorized: boolean,isMobile: boolean, props: StateProps, environment:string) => {
  const [anchorProfileMenu, setAnchorProfileMenu] = React.useState(null);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [requirePageRefresh, setRequirePageRefresh] = React.useState(false);
  const open = Boolean(anchorProfileMenu);
  const history = useHistory();
  const dispatch = useDispatch();
  const {  isFinalWeekMode} = useSelector(globalSelector);
  
  // #region : Hooks
  useEffect(() => {    
    if(!settingsOpen && requirePageRefresh){
      setRequirePageRefresh(false);
      history.push("/");
    }
  }, [isFinalWeekMode,requirePageRefresh,settingsOpen]);

  useEffect(() => {    
    if(props.flags && props.flags.featureFlags.length){
      const filteredFlags = props.flags.featureFlags.filter((flag)=>{
        return flag.value === true;
      }).map((flag)=>{
        return flag.key.toLocaleLowerCase();
      });

      
      dispatch(updateUserTypesAction(filteredFlags));
 
    }
  }, [props.flags.featureFlags]);
  // #endregion : Hooks
  
  // #region : Functions
  const handleClick = (event) => {
    setAnchorProfileMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorProfileMenu(null);
  };

  const toggleSettingsModal = () => {
    setSettingsOpen(prevState => !prevState);
  };

  const updateFinalWeekMode = (triggerFinalWeekMode:boolean, isAdmin:boolean) :void => {
    if(!adminFlag)
      return;
    dispatch(updateIsFinalWeekModeAction(triggerFinalWeekMode));
    setRequirePageRefresh(true);
    // Store value in case of browser refresh
    switch (triggerFinalWeekMode) {
      case true:
          localStorage.setItem(LocalStorageType.FinalWeekMode, JSON.stringify(triggerFinalWeekMode));
        break;
      case false:
          localStorage.removeItem(LocalStorageType.FinalWeekMode);
          dispatch(updateViewingWeekTypeAction(WeekType.CurrentWeek));
        break;
    } 
  }
  // #endregion : Functions


  const filteredFlags = props.flags.featureFlags.filter((flag)=>{
    return flag.value === true;
  }).map((flag)=>{
    return flag.key.toLocaleLowerCase();
  });
 
  const adminFlag:boolean = filteredFlags.includes(FeatureFlagType.admin);
 
  return ( 
      isAuthorized ? 
        isMobile ? 
          (
            <span key={'profile-Link-span'}>
              <Divider />
              <MenuItem><Link to={"/logout"}>Logout</Link></MenuItem>
            </span> 
          ) : 
          ( 
            <>
              <span key={'profile-Link-span'}>
                <MenuItem  key={'profile-Link'} onClick={handleClick}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" color='info'/>
                  </ListItemIcon>
                  <span>{props.session.user.lastName},{props.session.user.firstName}</span>
                </MenuItem>
                <Menu
                  id="profile-btn-menu"
                  anchorEl={anchorProfileMenu}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem><Link to={"/logout"}>Logout</Link></MenuItem>  
                </Menu>
              </span>
              
              {adminFlag && (
                <li>
                  {/* Settings Modal*/}
                  <IconButton aria-label="open settings" onClick={toggleSettingsModal}>
                    <SettingsIcon style={{fill:'#fff !important'}} />
                  </IconButton>
                  <Dialog open={settingsOpen} >
                    <DialogTitle>Settings</DialogTitle>
                    <DialogContent>
                      <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={`final-week-panel`}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList aria-label="lab API tabs example">
                              <Tab label="Final Week Edit Mode" value="final-week-panel" />
                              <Tab disabled label="Other" value="other" />
                            </TabList>
                          </Box>
                          <TabPanel value="final-week-panel" style={{ padding: '24px 0' }}>
                            <DialogContentText >
                              {`Final Week Edit Mode is currently ${isFinalWeekMode ? `ON`: `OFF`}. Turning this on will set the current week to the final week of 
                              an active semester for all sections. Final Week Edit Mode is for admin purposes.`}   
                            </DialogContentText>

                            <FormControlLabel
                                value="top"
                                sx={{ m: 0 }}
                                control={
                                    <Switch 
                                        {...{ inputProps: { 'aria-label': 'Is Final Week Mode' } }}
                                        checked={isFinalWeekMode}
                                        onChange={
                                          () => updateFinalWeekMode(!isFinalWeekMode,adminFlag)
                                        } 
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={isFinalWeekMode ? `ON` : `OFF`}
                                labelPlacement="end"
                            />
                          </TabPanel>
                          <TabPanel value="other">Other</TabPanel>
                        </TabContext>
                      </Box>     
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={toggleSettingsModal}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </li>
              )}    
              
            </> 
          ) 
    : 
      <MenuItem key={'profile-Link'} onClick={handleClick}>
        <Link to={"/login"}>
          Login
        </Link>
      </MenuItem>
    );
}
 
function mapStateToProps(state: any): StateProps {
  return {
    session: state.session, 
    flags: state.featureFlags
  };
}

export default connect<StateProps>(mapStateToProps)(NavMenu);