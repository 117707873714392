import React from "react";
import { globalSelector } from "store/global/globalReducer";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import MessageIcon from "@mui/icons-material/Message";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import { GradeStatus } from "models/Grade";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { CustomVerificationSwitch } from "./CustomVerificationSwitch";
import { IMenuListItems } from "../../models/Menus";
import Tooltip from "@mui/material/Tooltip";

interface IDirectGradeVerificationSwitch {
  labelText: string;
  buttonId: string;
  isVerified: boolean;
  isVerificationSwitchHidden: boolean;
  menuItems: IMenuListItems[];
  menuTitle?: string;
  updateDirectGradeFunction: (directGrade: string, withComments: boolean) => void;
  updateVerificationStatus: (withComments: boolean) => void;
  viewWeeklyGrowthFunction?: () => void;
}

const CustomCheckbox = styled("div")(({ theme }) => ({
  color: theme.palette.blueGrey["light"],
}));

export const DirectGradeVerificationSwitch = ({
  labelText,
  buttonId,
  isVerified,
  isVerificationSwitchHidden,
  menuItems,
  menuTitle,
  updateDirectGradeFunction,
  updateVerificationStatus,
  viewWeeklyGrowthFunction,
}: IDirectGradeVerificationSwitch) => {
  const { termIsEditable } = useSelector(globalSelector);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const open = Boolean(anchorMenu);

  const [anchorVerificationMenu, setAnchorVerificationMenu] = React.useState(null);
  const openedVerificationMenu = Boolean(anchorVerificationMenu);

  const [checked, setChecked] = React.useState(true);

  //#region Functions:

  const openDirectGradeMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const closeDirectGradeMenu = () => {
    setAnchorMenu(null);
  };
  const onUpdateDirectGradeClick = (directGrade: string, withComments: boolean): void => {
    updateDirectGradeFunction(directGrade, withComments);
    closeDirectGradeMenu();
  };

  const openVerificationMenu = (event) => {
    setAnchorVerificationMenu(event.currentTarget);
  };
  const closeVerificationMenu = () => {
    setAnchorVerificationMenu(null);
  };
  const onUpdateFinalGradeStatus = (withComments: boolean = false): void => {
    updateVerificationStatus(withComments);
    closeVerificationMenu();
  };

  //#endregion Functions:

  return !termIsEditable ? (
    // If Term is no longer editable then we only want the ability to view past weekly growths.
    <Button
      variant="contained"
      color="secondary"
      style={{ color: "#212529" }}
      sx={{ minWidth: "36px", minHeight: "36px" }}
      id={`closed-button-${buttonId}`}
      aria-controls={`closed-button-${buttonId}`}
      onClick={(event: any) => (viewWeeklyGrowthFunction ? viewWeeklyGrowthFunction() : null)}
    >
      {labelText ? labelText : ""}
    </Button>
  ) : labelText === GradeStatus.LoadingGrade ? (
    <CustomCheckbox>
      <CircularProgress color="inherit" />
    </CustomCheckbox>
  ) : (
    // If Term is still editable
    <FormControl
      style={{ width: "100%", alignItems:'center' }}
    >
      {/* region: Direct Grade Menu */}
      
      <Tooltip enterDelay={1000} title={'Assign a direct grade'} >
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={(event: any) => openDirectGradeMenu(event)}
          id={`menu-button-${buttonId}`}
          aria-controls={open ? `menu-${buttonId}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ padding: "0px 8px", minWidth:'54px' }}
        >
          {labelText}
        </Button>
      </Tooltip>
      <Menu
        id={`menu-${buttonId}`}
        anchorEl={anchorMenu}
        open={open}
        onClose={closeDirectGradeMenu}
        MenuListProps={{
          "aria-labelledby": `menu-button-${buttonId}`,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style:{maxHeight: "unset !important"}
          },
        }}
      >
        <MenuList sx={{ width: "100%", maxWidth: 360, minWidth: 280, bgcolor: "background.paper" }} dense>
          {menuTitle ? <MenuItem disabled>{menuTitle}</MenuItem> : null}
          {menuItems.length ? (
            menuItems.map((item: IMenuListItems, index) => {
              if (!item.primaryText || item.primaryText === "" || item.primaryIntials === "") return;

              return (
                <ListItem
                  key={item.id}
                  secondaryAction={
                    <IconButton onClick={() => onUpdateDirectGradeClick(item.primaryText, true)} edge="end" aria-label="with comments">
                      <MessageIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton onClick={() => onUpdateDirectGradeClick(item.primaryText, false)}>
                    <ListItemText id={index.toString()} primary={`${item.primaryText}`} />
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem key={"1"}>
              <ListItemText id={"missing-options"} primary={`No options available`} />
            </ListItem>
          )}
        </MenuList>
      </Menu>


      {/* If no Direct Final Grade has been assigned, then a final grade cannot be verified. So we hide it until a Final Direct Grade assigned  */}
      {isVerificationSwitchHidden === false && (
        <Tooltip enterDelay={1000} title={isVerified ? `Unverify Final Grade` : `Verify Final Grade`} >
          <CustomVerificationSwitch 
            checked={isVerified} 
            onClick={(event: any) => openVerificationMenu(event)} 
            inputProps={{ "aria-label": isVerified ? `Unverify Final Grade` : `Verify Final Grade` }}
            disabled={isVerificationSwitchHidden} 
          />
        </Tooltip>
      )}    
      
      <Menu
        id={`menu-${buttonId}`}
        anchorEl={anchorVerificationMenu}
        open={openedVerificationMenu}
        onClose={closeVerificationMenu}
        MenuListProps={{
          "aria-labelledby": `menu-button-${buttonId}`,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ maxHeight: "580px" }}
      >
        <MenuList sx={{ width: "100%", maxWidth: 360, minWidth: 280, bgcolor: "background.paper" }} dense>
          <ListItem
            className="split-menu"
            key={"index"}
            secondaryAction={
              <IconButton onClick={() => onUpdateFinalGradeStatus(true)} edge="end" aria-label="with comments">
                <MessageIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton onClick={() => onUpdateFinalGradeStatus()}>
              <ListItemText id={"index.toString()"} primary={isVerified ? `Unverify Final Grade` : `Verify Final Grade`} />
            </ListItemButton>
          </ListItem>
        </MenuList>
      </Menu>
    </FormControl>
  );
};
