import * as React from 'react';
import { GradebookEntry } from '../../models/GradebookEntry';
import {Course} from '../../models/Course';
import { Row, Col } from '../layout/ResponsiveGrid';
import { MdArrowBack } from "react-icons/md";
import './Shared.css'; 
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft,  PanelChildRight } from '../layout/PanelTitleBar';
import ScrollerHolder, { ScrollHeader,  ScrollerArea,  } from '../layout/ScrollerArea';
import { IMttInterventions } from '../../models/MandatoryTargetedTutoring';
import { MttInterventionHeader } from './MttInterventionHeader';
import { AttendanceIndicator } from './AttendanceIndicator';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getMttCommentDate } from '../../utils/helpers/mttHelpers';
import { IMttSessionStartWeek } from '../../models/enums/Mtt';

// Props coming from Parent Components
interface IInterventionHistoryReportCardProps {
    selectedGradeBookEntry: GradebookEntry;
    activeCourse: Course;
    breadCrumbNav?: boolean;
    onStudentSelect: (gradebook:GradebookEntry) => void;
    interventionHistory: Array<IMttInterventions>;
}

export const InterventionHistoryReportCard = ({ selectedGradeBookEntry, activeCourse, onStudentSelect, interventionHistory, breadCrumbNav }: IInterventionHistoryReportCardProps) => {
    const [mttSelectOptions, setMttSelectOptions] = React.useState<IMttSessionStartWeek[]>(interventionHistory.map(ih => {
        return {
            selectedSessionDate: ih.startWeek,
            selectedSessionStartWeekNum: ih.startWeekNum ? ih.startWeekNum : '',
            selectedSessionStatus: ih.currentSessionStatus
        }
    }));
    
    return (
        <>   
            <ScrollerHolder>
                <ScrollHeader>
                    <>
                        {breadCrumbNav && 
                            <>
                                {/* ==== Return ====  */}
                                <PanelTitleBar toolBarType={PanelTitleTypes.Fourth}>
                                    <PanelChildLeft>
                                        <h6 style={{marginBottom:0}} onClick={ () => onStudentSelect(selectedGradeBookEntry) } >
                                            <MdArrowBack size='24'/> Overview
                                        </h6>
                                    </PanelChildLeft>
                                </PanelTitleBar>
                            </>
                        }

                        <PanelTitleBar toolBarType={PanelTitleTypes.Third}>
                            <PanelChildLeft>
                                <h4>Interventions</h4>
                            </PanelChildLeft>
                            <PanelChildRight>
                                <></>
                            </PanelChildRight>
                        </PanelTitleBar>
                    </>
                </ScrollHeader>
                <ScrollerArea>
                    <>
                        {interventionHistory.map((intervention, interventionIndex) => {
                            return (
                                <Row key={interventionIndex}>
                                    <Col xs={12} style={{padding: '0 0 20px', borderBottom: `2px solid lightgray`, marginBottom: '20px'}}>
                                        <MttInterventionHeader
                                            selectedSessionWeek={mttSelectOptions[interventionIndex]}
                                            mttSelectOptions={mttSelectOptions}
                                            selectedSession={intervention}
                                        />
                                        <>
                                            {intervention.comments.map((comment, commentIndex) => {   
                                                return (
                                                    <Table key={commentIndex}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <span><strong>{comment.commentAuthorName ? comment.commentAuthorName : ''}</strong></span>
                                                                </TableCell>
                                                                <TableCell style={{textAlign:'right'}}>
                                                                    <span>
                                                                        <strong>{getMttCommentDate(comment)}</strong>
                                                                        <AttendanceIndicator attended={comment.attendance ? true : false} />
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell colSpan={2}>
                                                                    <span style={{display: 'block', paddingBottom: '20px'}}>{comment.comments}</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                );
                                            })}
                                        </>
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                </ScrollerArea>   
            </ScrollerHolder>
        </>
    );
}