import * as React from 'react';
import { TeacherStudent } from '../../models/TeacherStudent';
import AssignmentBar from '../charts/AssignmentBar';
import PanelTitleBar, { PanelTitleTypes } from '../layout/PanelTitleBar';
import  FlexLabel from '../layout/FlexHelpers';

import './Shared.css';
import { GradebookEntry } from '../../models/GradebookEntry';
import { Assignment } from '../../models/Assignment';

// Props coming from Store
interface IHomeworkStoreProps {
    onHomeworkSelect: (selectedHomework: GradebookEntry, filtering: boolean) => void;
}

// Props coming from Parent Components
interface IHomeworkCardProps {
    studentsList: TeacherStudent[];
    ableToEditProduct: boolean;    
    grades: GradebookEntry[];
}

// Component States Types
interface IHomeworkCardState {
    sortDescending: boolean;
    sortField: string;
}

export enum ProductPanelType {
    CreateRangePanel = "CreateRangePanel",
    None = "None",
}
 
class HomeworkCard extends React.Component<IHomeworkStoreProps & IHomeworkCardProps, IHomeworkCardState> {

    constructor(props: any) {
        super(props);
        // Components States Values
        this.state = {
            sortDescending: false,
            sortField: 'description',
        };
    }

    render() {
        
        return (
            <>
            <PanelTitleBar  mainTitle='Practice/Preparation' toolBarType={PanelTitleTypes.Secondary} />
 
            <table className='table table-striped courseListTable' aria-labelledby="tabelLabel">
                <thead className="gradebookTableHeaderRow">
                    <tr> 
                        <th style={{fontSize:'13px'}} >  
                            Date  <br/>
                            <FlexLabel>
                                <span>Initial</span>
                                <span>Current</span> 
                            </FlexLabel>
                         
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.grades.map((item: GradebookEntry) => {
                        const homeworkMissingValues: Array<boolean> = this.getHomeworkMissingValues(item);
                            return( 
                                <tr className="studentRow"
                                    onClick={() => this.props.onHomeworkSelect(item,false) }
                                    key={item.student.personId}> 
                                    <td>
                                    <div className="gradebookTableRow">
                                        <AssignmentBar assignmentList={homeworkMissingValues} />
                                        </div>
                                    </td>        
                                </tr>                                
                            ); 
                        }
                    )}
                </tbody>
                </table>
                   
            </>
        );
    }
  
    private  getHomeworkMissingValues = (gradeItem: GradebookEntry) : boolean[] =>{
        let assignmentList: Assignment[] = [];
        for(let i = 0; i < gradeItem.assessment.standards.length; i++){
            assignmentList = assignmentList.concat(gradeItem.assessment.standards[i].assignments.filter(x=>x.isHomework && x.isNotAssigned === false));
        }
        return assignmentList.sort(y=>y.dueDate.valueOf()).map(z=>z.isMissing);
    }


}

export default HomeworkCard;