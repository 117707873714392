import React from "react";
import { Term } from "../../models/Term";
import { Course } from "../../models/Course";
import { GradebookEntry } from "../../models/GradebookEntry";
import { Teacher } from "../../models/Teacher";
import FlexLabel, { FlexRow, FlexCol } from "../layout/FlexHelpers";
import HoverModal, { HoverModalTypes, HoverModalContent } from "../layout/HoverModal";
import "./Shared.css";
import { LoadingIcon } from "../../utils/LoadingIcon";
import Button from "../inputs-elements/Button";
import IconButton from "@mui/material/IconButton";
import { Row, Col } from "../layout/ResponsiveGrid";
import { IMttInterventions } from "../../models/MandatoryTargetedTutoring";
import { DroppedType } from "../../models/enums/Course";
import { SupervisedUserCircle } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";

// Props coming from Store
export interface CourseSelectCardProps {
  terms: Term[];
  courses: Course[];
  activeCourse?: Course;
  allGradeBookList: GradebookEntry[];
  activeInterventions: Array<IMttInterventions>;
  selectedTerm: number;
  selectedTermChanged: Function;
  onCourseSelect: Function;
  isParentCollapsed: boolean;
  toggleAction?: (isCollapsed: boolean) => any;
  openSection?: Function;
  teacherList?: Teacher[];
  selectedTeacher?: Teacher;
  hideProjectedGrade: boolean;
  isLoading?: boolean;
  togglePrintableReportCard: () => void;
}

// Component States Types
interface CourseSelectCardState {
  sortDescending: boolean;
  sortField: string;
  isCollapsed: boolean;
  gradeBooklist: GradebookEntry[];
}

export enum ProductPanelType {
  CreateRangePanel = "CreateRangePanel",
  None = "None",
}

class CourseSelectCardReportCard extends React.Component<CourseSelectCardProps, CourseSelectCardState> {
  static getDerivedStateFromProps(nextProps: CourseSelectCardProps, prevState: CourseSelectCardState) {
    if (nextProps.allGradeBookList !== prevState.gradeBooklist) {
      return {
        gradeBooklist: nextProps.allGradeBookList,
      };
    }
    return null;
  }

  constructor(props: any) {
    super(props);
    // Components States Values
    this.state = {
      sortDescending: false,
      sortField: "description",
      isCollapsed: false,
      gradeBooklist: [],
    };
  }

  public getSubTitleIcon = (isActiveButton: boolean, sectionId: number): JSX.Element => {
    const showIcon = sectionId > 0 && this.props.activeInterventions.some((i: IMttInterventions) => i.sectionId === sectionId);
    return showIcon ? <SupervisedUserCircle sx={{ fontSize: 30 }} style={isActiveButton ? { color: "#5b606e" } : { color: "#e2bf54" }} /> : <></>;
  };

  render() {
    const droppedCourses: Course[] = this.props.courses.filter((course, index) => {
      return course.isDropped === DroppedType.True;
    });
    const activeCourses: Course[] = this.props.courses.filter((course, index) => {
      return course.isDropped === DroppedType.False;
    });

    return this.props.isLoading ? (
      <LoadingIcon text="Courses Loading, Please wait...." />
    ) : (
      <>
        <Row>
          <Col xs={12} padding="24px 14px 0">
            <>
              <div className="courseListContainer" style={{ minHeight: 700 }}>
                {this.props.children}
                <FlexRow justifyContent="space-between" flexDirection="row">
                  <FlexCol maxWidth={"80%"}>
                    <h3 style={{ padding: "0px 20px" }}>
                      Welcome to the <br /> Interactive Report Card{" "}
                    </h3>
                  </FlexCol>
                </FlexRow>
                <FlexRow justifyContent="flex-end" flexDirection="column" style={{ padding: "10px 20px" }}>
                  <FlexCol>
                    <div style={{ justifyContent: `flex-start`, alignItems: `center`, display: `flex` }}>
                      <div style={{ flexGrow: 1, alignItems: `center`, display: `flex` }}>
                        <h5>Course(s) </h5>
                        {this.props.courses.length > 0 && ( // Prevent Print button from showing up when there are no courses displayed
                          <IconButton aria-label="delete" size="large" onClick={() => this.props.togglePrintableReportCard()}>
                            <PrintIcon />
                          </IconButton>
                        )}
                      </div>
                      {this.props.hideProjectedGrade == false && (
                        <HoverModal title={this.showFinalLabel(this.props.allGradeBookList)} type={HoverModalTypes.Third} content={HoverModalContent.projectedgrade} />
                      )}
                    </div>
                  </FlexCol>
                </FlexRow>

                {Array.isArray(activeCourses) && activeCourses.length
                  ? activeCourses.map((course, index) => {
                      let isActiveButton: boolean = false;
                      if (this.props.activeCourse) {
                        if (course.sectionId === this.props.activeCourse.sectionId) {
                          isActiveButton = true;
                        } else {
                          isActiveButton = false;
                        }
                      }

                      const correctGradebook: GradebookEntry | undefined =
                        this.state.gradeBooklist.length > 0
                          ? this.state.gradeBooklist.find((gradeBook) => gradeBook.assessment && gradeBook.assessment.sectionID === course.sectionId)
                          : new GradebookEntry();

                      let ProjectedGrade: string;
                      let FinalGrade: string;
                      // removed per clients request
                      // let hasMissingAssignment:boolean = false;
                      let hasVerifiedFinalGrade: boolean = false;

                      if (correctGradebook) {
                        ProjectedGrade = correctGradebook.assessment.projectedGrade;
                        FinalGrade = correctGradebook.assessment.finalGrade ? correctGradebook.assessment.finalGrade : "";
                        hasVerifiedFinalGrade = correctGradebook.assessment.finalGrade && correctGradebook.assessment.isVerified ? true : false;
                        // hasMissingAssignment = hasMissingActiveAssignments(getActiveAssignments(correctGradebook));
                      } else {
                        ProjectedGrade = "";
                        FinalGrade = "";
                      }

                      return (
                        <Button
                          key={`${course.courseId}-${course.sectionId}-${index}`}
                          onClick={() => this.props.onCourseSelect(course)}
                          mainTitle={course.courseName}
                          subTitle={hasVerifiedFinalGrade ? FinalGrade : ProjectedGrade}
                          subTitleIcon={this.getSubTitleIcon(isActiveButton, course.sectionId)}
                          activeButton={isActiveButton}
                          showWarningIcon={false}
                          icon
                          isRectangular={true}
                        />
                      );
                    })
                  : null}

                {droppedCourses.length > 0 && (
                  <FlexRow justifyContent="flex-end" flexDirection="column" style={{ padding: "10px 20px" }}>
                    <FlexCol>
                      <FlexLabel maxWidth={"100%"}>
                        <h5>Dropped / Ended</h5>
                      </FlexLabel>
                    </FlexCol>
                  </FlexRow>
                )}
                {Array.isArray(droppedCourses) && droppedCourses.length
                  ? droppedCourses.map((course, index) => {
                      let isActiveButton: boolean = false;
                      if (this.props.activeCourse) {
                        if (course.sectionId === this.props.activeCourse.sectionId) {
                          isActiveButton = true;
                        } else {
                          isActiveButton = false;
                        }
                      }
                      return (
                        <Button
                          key={`${course.courseId}-${course.sectionId}-${index}`}
                          onClick={() => this.props.onCourseSelect(course)}
                          mainTitle={course.courseName}
                          subTitle={""}
                          subTitleIcon={this.getSubTitleIcon(isActiveButton, course.sectionId)}
                          activeButton={isActiveButton}
                          isDropped={true}
                          icon
                          isRectangular={true}
                        />
                      );
                    })
                  : ""}
              </div>
            </>
          </Col>
        </Row>
      </>
    );
  }

  private showFinalLabel = (grades: GradebookEntry[]): string => {
    let label: string = "";
    const includesFinalGrades = grades.some((g) => g.assessment !== null && g.assessment.isVerified === true && g.assessment.finalGrade);
    const onlyFinalGrades = grades.every((g) => g.assessment !== null && g.assessment.isVerified === true && g.assessment.finalGrade);
    const includesProjectGrades = grades.some((g) => g.assessment !== null && g.assessment.isVerified === false && g.assessment.projectedGrade);
    const onlyProjectGrades = grades.every((g) => g.assessment !== null && g.assessment.isVerified === false && g.assessment.projectedGrade);
    if (includesFinalGrades && includesProjectGrades) {
      label = "Projected / Final Grade";
    } else if (onlyProjectGrades) {
      label = "Projected Grade";
    } else if (includesProjectGrades) {
      label = "Projected Grade";
    } else if (includesFinalGrades) {
      label = "Final Grade";
    } else if (onlyFinalGrades) {
      label = "Final Grade";
    }

    return label;
  };
}

export default CourseSelectCardReportCard;
