import ReactGA from "react-ga4";

const studentReportCardFunction = (studentId:number, studentName:string ) => ReactGA.event("Report_Card_Page_View", {
    action: "Report Card Viewed",
    event_category: "Report Card",
    event_label: "Report Card Viewed",
    // debug_mode: true,
    ...{ studentId: studentId, studentName: studentName}
});

const studentReportCardCourseFunction = (studentId:number, studentName:string, courseName:string, courseId:number, termId:number = 0 ) => ReactGA.event(
"Report_Card_Course_View", 
{
    action: "Student Viewed Course",
    event_category: "Report Card",
    event_label: "Student Viewed Course",
    // debug_mode: true,
    ...{ studentId: studentId, studentName: studentName, courseName:courseName, courseId: courseId, termId:termId }
});

const studentReportCardPrintedFunction = (studentId:number, studentName:string, termId:number = 0 ) => ReactGA.event( 
"Report_Card_Printed", 
{
    action: "Student Printed Report Card",
    event_category: "Report Card",
    event_label: "Student Printed Report Card",
    // debug_mode: true,
    ...{ studentId: studentId, studentName: studentName, termId:termId }
});

const studentReportCardInterventionFunction = (studentId:number, studentName:string, courseName:string, courseId: number, interventionHistory:number[], termId:number = 0 ) => ReactGA.event(
"Report_Card_Intervention_View", {
    action: "Student Viewed Intervention",
    event_category: "Report Card",
    event_label: "Student Viewed Intervention",
    // debug_mode: true,
    ...{ studentId: studentId, studentName: studentName, courseName:courseName, courseId: courseId, interventionHistory : interventionHistory,termId:termId  }
});


const gaEvent = {
    studentReportCardView : studentReportCardFunction,
    studentReportCardCourse : studentReportCardCourseFunction,
    studentReportCardPrinted : studentReportCardPrintedFunction,
    studentReportCardInterventionViewed : studentReportCardInterventionFunction,
}
export {gaEvent}