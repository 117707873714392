import React, {  useState } from 'react';
import styled from "styled-components"
 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface DropDownProps{
    items: any[];
    selectedItem: number;
    onSelect: any;
    displayPath: string;
    idPath: string;
    closeOnMouseExit?: boolean;
    textColor?: string;
    isPeriod?: boolean;
}

const DropDownDisplay = styled.div<DropDownProps>`
    height: 36px;
    cursor: pointer;

    outline: 0;
    display: inline-flex;
    justify-content: end;
    background:  rgba(255, 255, 255, 0.05); 
    
    min-width: 100px;width: 100%;
    border: 1px solid ${({ theme: { colors } }) => colors.greys.light};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 11px 11px;
 
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    line-height: 11px;
 
    color: ${({ textColor, theme: { colors } }) => textColor ? textColor : colors.greys.dark };


`;

const DropDownList = styled.ul`
 
    position: absolute;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  list-style: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  padding:0; z-index:10;

  opacity: 0;
  visibility: hidden;
  z-index:200;
`;


const DropDownItem = styled.li`

    z-index: 1;
    position: relative;
    background: #FFFFFF;
    padding: 10px 20px;
    color: #666;

`;

interface DropDownContainerProps {
    isOpen?: boolean;
}
const DropDownContainer = styled.div<DropDownContainerProps>`
position: relative;

    ${DropDownList}{
        ${({ isOpen }) => isOpen ? 'visibility: visible;opacity: 1;' : '' };
    }
`;

const DropDownText = styled.span`
display: block;
width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;

`;

export const DropDownStudents = (props: DropDownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
     
    const toggleMenu = () => {
        setIsOpen(toggleIsOpen(isOpen));
    }

    const onMouseEnter = () =>{
        setIsHovered(true);
    }

    const onMouseLeave = () => {
        setIsHovered(false);
        if(props.closeOnMouseExit)
            setIsOpen(false);
    }
 
    return(
        <DropDownContainer isOpen={(isOpen ? true : false)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <DropDownDisplay 
                className={(isOpen ? " clicked": "")} 
                onClick={toggleMenu}
                textColor={props.textColor}
                items={props.items}
                onSelect={props.onSelect}
                selectedItem={props.selectedItem}
                idPath={props.idPath}
                displayPath={props.displayPath}
            >
                <DropDownText>
                    {props.items === null || props.items === undefined || props.items.length < 1 ? "" : (props.items[props.selectedItem] === undefined) ? 'Other' :
                    props.items[props.selectedItem]['firstName'] + ',' + props.items[props.selectedItem]['lastName']}      
                </DropDownText>
                 
                <ArrowDropDownIcon style={{marginTop:'-5px',marginRight:'-10px'}} />
                
                {isOpen && (
                    <DropDownList className="dropdown-list">
                        {renderDropDownItems(props.items, props.onSelect, props.selectedItem, props.displayPath, props.idPath)}
                    </DropDownList>
                )}

            </DropDownDisplay>
        </DropDownContainer>  
    )
}

const toggleIsOpen = (prevOpenState: boolean) => (!prevOpenState);

const renderDropDownItems = (items: any[], onClick: Function, selectedItem: number, displayPath: string, idPath: string) =>{
    const elements : any[] = [];
    items = items === null || items === undefined  ? [] : items;

    for (let i = 0; i < items.length; i++) {
      if(i==selectedItem)
        continue;
      const item = items[i];
      elements.push(
      <DropDownItem key={`${item[idPath]}-${i}`} onClick={()=>onClick(item)}>
        <span> {item['firstName']}, {item['lastName']}</span>
      </DropDownItem>);
    }
    return elements;
}