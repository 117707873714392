import React, { useState  } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { initLogin, initGoogleLogin } from '../store/session/actions';
import { SessionState } from 'store/session/types';
import {LoadingIcon} from '../utils/LoadingIcon';
import { Row, Col } from './layout/ResponsiveGrid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
interface IUserState {
  username: string;
  password: string;
  isLoading: boolean;
  authComplete: boolean;
}
 
interface LoginStateProps {
  session: SessionState;
}
 
const GoogleLoginButton = styled(Button)({
  margin:'0px 27px 0px 9px',
  backgroundColor: '#ffffff',
  padding:'6px 30px 6px 9px',
  color:'#757575',
  '&:hover': {
    backgroundColor: '#eeeeee',
    color:'#6d6d6d',
  },
  '&:active': {
    backgroundColor: '#eeeeee',
    color:'#6d6d6d',
  },
  'span': {
    margin: '0px !important',
  },
});


export const Login = () => {
    // Retrieve Store
  const dispatch = useDispatch();
  const session = (state) => state.session;
  const activeSession = useSelector(session);
 
  // Default States
  const [state, setState] = useState<IUserState>({
    username: "",
    password: "",
    isLoading: false,
    authComplete: false,
  });
  
  const handleSubmit = ():void => {
    dispatch(initLogin({username: state.username, password: state.password}));
  }

  const googleLogin = useGoogleLogin({
      flow: "auth-code",
      onSuccess: async codeResponse => {
        const codeBlob = new Blob([JSON.stringify( codeResponse.code, null, 2)], { type: 'application/json' });
          const options = {
          method: 'POST',
          body: codeBlob,
          mode: 'cors',
          cache: 'default'
        };
        dispatch(initGoogleLogin(options));
      }
  })

  const handleUsernameUpdate = (value:string):void => {
    setState((prevState) => {
      return({
        ...prevState,
        username: value
      });
    });
  }
  const handlePasswordUpdate = (value:string):void => {
    setState((prevState) => {
      return({
        ...prevState,
        password: value
      });
    });
  }

 
  if(activeSession.isLoggingIn){
    return (<LoadingIcon/>)
  } else if(!activeSession.isLoggedIn){
    return (
      <>
      <Row fullHeight style={{justifyContent:'center', padding:'20px'}}>
          <Col xs={12} md={7} >
          
            <Row style={{justifyContent:'center'}}>
              <Col xs={12} style={{textAlign:'center'}}>
                <Typography variant="h4" gutterBottom>
                  Welcome to Interactive Report Card Portal
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Please login using either your username / password or your gmail account.
                </Typography>

              </Col>
            </Row> 

            <Row fullHeight style={{padding:'20px', height:'auto'}}>       
                <Col xs={12} md={6} style={{ margin: '0 auto'}}>
                  <Card sx={{ minWidth: 275, marginX:'14px'}}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14, marginBottom:'14px' }} color="text.secondary" gutterBottom>
                        Login
                        {activeSession.isError ? <p style={{ color: 'red' }}>{activeSession.errorMessage}</p> : <></>}
                      </Typography>
                                       
                      <FormControl variant="outlined" style={{width:'100%'}}>
                        <TextField id="username" name="username" label="Please Enter Username" variant="outlined" onChange={(e)=>{ handleUsernameUpdate(e.target.value )}} size="small" style={{marginBottom:'14px'}}/>
                        <TextField id="password" name="password" label="Please Enter Password" variant="outlined" onChange={(e)=>{ handlePasswordUpdate(e.target.value) }} type='password' size="small" />                   
                      </FormControl>
                    </CardContent>
                    <CardActions>
                      <Button onClick={() => handleSubmit()}>Sign In</Button>
                    </CardActions>
                  </Card>
              </Col>
              <Col xs={12} md={6} style={{textAlign: 'center'}}>

                <Card sx={{ minWidth: 275, marginX:'14px', boxShadow:'none' }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14, marginBottom:'14px' }} color="text.secondary" gutterBottom>
                      Google Login
                    </Typography>                
                    <GoogleLoginButton 
                      startIcon={<svg style={{margin: '0px !important'}} xmlns="http://www.w3.org/2000/svg" width="46" height="46"><g fill="none"><g filter="url(#a)" transform="translate(3 3)"><use fill="#FFF"/><use/><use/><use/></g><path fill="#4285F4" d="M31.64 23.205c0-.639-.057-1.252-.164-1.841H23v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615Z"/><path fill="#34A853" d="M23 32c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0 0 23 32Z"/><path fill="#FBBC05" d="M17.964 24.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0 0 14 23c0 1.452.348 2.827.957 4.042l3.007-2.332Z"/><path fill="#EA4335" d="M23 17.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C27.463 14.891 25.426 14 23 14a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71Z"/><path d="M14 14h18v18H14V14Z"/></g></svg>} 
                      aria-label="Exit MTT"
                      onClick={() => googleLogin()}
                      sx={{'&.Mui-disabled svg': {color: '#b7b7b7 !important'}}}
                      variant="contained"
                      color='info'
                  >  
                    Sign in with Google
                  </GoogleLoginButton>
  
                  </CardContent>
                </Card>
                  
                
                  
              </Col>  
            </Row>
          
        </Col>
      </Row>
      </>
    );
  }
  else{
    return (
      <Redirect to="/"/>
    )
  }
 
}

function mapStateToProps(state: any): LoginStateProps{
  return {
    session: state.session
  };
}

export default connect<LoginStateProps>(mapStateToProps)(Login);