import React, { useState } from 'react';
import styled from "styled-components"
import '../shared/Shared.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface DropDownProps{
    items: any[];
    selectedItem: number;
    onSelect: any;
    text: string;
    subTitle?: string;
    description?: string;
    idPath: string;
    closeOnMouseExit?: boolean;
    textColor?: string;
    isPeriod?: boolean;
}

export interface DropDownItemsProps{
    isActive?: boolean;
}

//#region StyledComponents
const DropDownDisplay = styled.div<DropDownProps>`
    height: 36px;
    cursor: pointer;

    outline: 0;
    display: inline-flex;
    justify-content: end;
    background:  rgba(255, 255, 255, 0.05); 
    
    min-width: 100px;width: 100%;
    border: 1px solid ${({ theme: { colors } }) => colors.greys.light};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 11px 11px;
 
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    line-height: 11px;
 
    color: ${({ textColor, theme: { colors } }) => textColor ? textColor : colors.greys.dark };


`;

const DropDownList = styled.ul`
 
    position: absolute;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  list-style: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  padding:0;

  opacity: 0;
  visibility: hidden;
  z-index:200;

`;

const DropDownItem = styled.li<DropDownItemsProps>`

    z-index: 1;
    position: relative;
    background: #FFFFFF;
    padding: 10px 10px;
    color: #666;
    & > span.wide { white-space: nowrap; }

    &:hover{background: ${({ theme: { colors } }) => colors.greys.dark}; color: ${({ theme: { colors } }) => colors.white};}

    
    ${({ isActive, theme: { colors } }) => isActive ?  'background:' + colors.greys.xxdark : '' };
    ${({ isActive, theme: { colors } }) => isActive ?  'color:' + colors.white : '' };

`;

interface DropDownContainerProps {
    isOpen?: boolean;
    showDescription?: boolean;
}

const DropDownContainer = styled.div<DropDownContainerProps>`
position: relative; z-index: 100;
    ${DropDownList}{
        ${({ isOpen }) => isOpen ? 'visibility: visible;opacity: 1;' : '' };
        ${({ showDescription }) => showDescription ? 'width: auto; left: 0; right: auto;' : '' };
    }
`;

const DropDownText = styled.span`
display: block;
width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis; text-align:left;

`;
//#endregion

export const DropDown = (props: DropDownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showAllText, setShowAllText] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
     
    const toggleMenu = () => {
        setIsOpen(toggleIsOpen(isOpen));
    }

    const onMouseEnter = () =>{
        setIsHovered(true);
    }

    const onMouseLeave = () => {
        setIsHovered(false);
        if (props.closeOnMouseExit) {
            setIsOpen(false);
        }        
    }

    if (!showAllText && props.isPeriod && (props.description !== undefined && props.subTitle !== undefined)) {
        setShowAllText(true);
    }
 
    return(
        
        <DropDownContainer 
            isOpen={(isOpen ? true : false)}
            showDescription={(showAllText ? true : false)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <DropDownDisplay 
                className={(isOpen ? " clicked  customDropdown": "customDropdown")} 
                onClick={toggleMenu}
                textColor={props.textColor}
                items={props.items}
                subTitle={props.subTitle ? props.subTitle : ''}
                onSelect={props.onSelect}
                selectedItem={props.selectedItem}
                idPath={props.idPath}
                text={props.text}
            >
                <DropDownText>
                    {((props !== undefined) && (props.items !== null || props.items !== undefined ) && (!props.subTitle)) &&     
                        <>{props.items[props.selectedItem][props.text]} &nbsp;</>  
                    }
                    {((props !== undefined) && (props.items !== null || props.items !== undefined ) && (props.subTitle && props.items[props.selectedItem][props.subTitle] !== '' )) && (
                        <>{props.items[props.selectedItem][props.text]} - {props.items[props.selectedItem][props.subTitle]} &nbsp;</> 
                    )} 
                </DropDownText>
                <ArrowDropDownIcon style={{marginTop:'-5px',marginRight:'-10px'}} />
                {isOpen ? <DropDownList className={`dropdown-list`}>
                    {renderDropDownItems(props.items, props.onSelect, props.selectedItem, props.text, props.idPath, props.description, props.subTitle)}
                </DropDownList>: null}

            </DropDownDisplay>

        </DropDownContainer>  
    )
}

const toggleIsOpen = (prevOpenState: boolean) => (!prevOpenState);

const renderDropDownItems = (items: any[], onClick: Function, selectedItem: number, text: string, idPath: string, description?: string, subTitle?: string) =>{
    const elements : any[] = [];
    items = items === null || items === undefined  ? [] : items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      elements.push(
        <DropDownItem 
                key={`${item[idPath]}-${i}`} 
                onClick={() => i !== selectedItem ? (onClick(item[idPath], subTitle ? item[subTitle] : null)) : null }
                isActive={i === selectedItem ? true : false }
            >
                <span className={(description && subTitle) ? 'wide' : ''}>
                    {(description && subTitle) ? `${item[text]} - ${item[subTitle]}: ${item[description]}` : item[text]}
                </span>
        </DropDownItem>);
    }
    return elements;
}