import * as React from 'react';
import { createRef } from 'react'
import styled from "styled-components"
import { Row, Col } from '../layout/ResponsiveGrid';
import  FlexLabel, { FlexTitle } from '../layout/FlexHelpers';
import {  convertScoreToInitial } from 'utils/helpers/stringHelpers';
import { ProficiencyType, ScoreInitials } from 'models/enums/Standards';
import { IMenuListItems } from 'models/Menus';
import EditSkillProficiencyMenu from 'components/navigation/EditSkillProficiencyMenu';

interface IncomingProps {
    proficiencyScore: number;
    proficiencyArray: Array<number>;
    proficiencyType: ProficiencyType;
    suggestedProficiencyScore?:number;
    graphLevels: Array<string>;
    isSkillOverWritten?:boolean;
    forPrint?:boolean;
    showScoreIntials?:boolean;
    skillMenuOptions?:IMenuListItems[];
    disableSkillMenu?:boolean;
    viewSkillProficienies:() => void;
    overrideSkillProficieny?:(stdMatrixId:number, isSkillOveride:boolean) => void;

}

// Component States Types
interface IStandardLineGraphState {
    isHovering: boolean;
}

const GraphHolder = styled.div`
    background: '#ccc';width:100%;height:auto;display: flex;
    justify-content: center; position: relative;
`;

const Canvas = styled.canvas` 
    transform: rotate(180deg) scaleX(-1); z-index:0;
    width:150px; height:40px;
`;
 
class SkillProficiencyGraph extends React.Component<IncomingProps, IStandardLineGraphState > {
    constructor(props: any) {
        super(props);
        // Components States 
        this.state = {
            isHovering: false,
        };
        
    }

    private canvasLineGraph = createRef<HTMLCanvasElement>();
   
    componentDidMount() {
        this.drawGraph();
    }
    componentDidUpdate() {  
        this.drawGraph();     
    }
 
    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col xs={6} style={{cursor:'default'}}>
                        <GraphHolder>
                            
                            <EditSkillProficiencyMenu
                                buttonText={
                                    convertScoreToInitial(this.props.proficiencyScore.toString())
                                }
                                secondaryButtonText={`View Skills`}
                                menuTitle={`Suggested Skill Proficiency`}
                                menuSecondaryTitle={`Override Skill Proficiency`}
                                menuId={'grade.student.personId.toLocaleString()'}
                                menuItems={this.props.skillMenuOptions ? this.props.skillMenuOptions : []}
                                updateSkillProficiencyFunction={(skillProficiencyOverideId:number, isSkillOveride:boolean) =>
                                    this.props.overrideSkillProficieny && this.props.overrideSkillProficieny(skillProficiencyOverideId, isSkillOveride)
                                }
                                suggestedProficiencyScore={this.props.suggestedProficiencyScore ? this.props.suggestedProficiencyScore : -1}
                                isSkillOverWritten={this.props.isSkillOverWritten}
                                viewSkillsFunction={() => this.props.viewSkillProficienies()}
                                disableButton={this.props.forPrint ? true : this.props.disableSkillMenu}
                            />

                            {this.props.forPrint ? (
                                <Canvas ref={this.canvasLineGraph} style={{height:`40px`,width:`110px`,marginRight:`5px`}}/>
                            ):(
                                <Canvas ref={this.canvasLineGraph} height={400} width={500}/>
                            )}
                            
                        </GraphHolder>
                    </Col>
                    <Col xs={6} displayFlex={true} onClick={() => this.props.viewSkillProficienies()}>

                        <FlexTitle showScoreIntials={this.props.showScoreIntials}>
                            {this.props.showScoreIntials && (
                                <FlexLabel maxWidth={'100%'}>
                                    <span>{ScoreInitials.E}</span>
                                    <span>{ScoreInitials.M}</span>
                                    <span>{ScoreInitials.A}</span>
                                    <span>{ScoreInitials.D}</span>
                                </FlexLabel>
                            )}
                                <FlexLabel maxWidth={'100%'} >
                                    {this.props.proficiencyArray.map((item: number, index)=>{
                                        return (
                                            <span key={index} >
                                                {item}
                                            </span>
                                        );
                                    })}
                                </FlexLabel>
                        </FlexTitle>

                    </Col>
                </Row>
            </React.Fragment>
        );
    }
 
    //
 

    private drawGraph = (): void => {
        const ctx: CanvasRenderingContext2D | null = this.canvasLineGraph.current ? this.canvasLineGraph.current.getContext('2d') : null;
     
        if(ctx && this.canvasLineGraph.current){
            ctx.clearRect(0, 0, this.canvasLineGraph.current.width, this.canvasLineGraph.current.height);
        }

        const standardsArrayNumber: Array<number> = this.props.graphLevels.map(n => parseInt(n, 10) * (this.props.forPrint ? 40 : 100));
        const pontos: Array<number> = [];
        const diferenca: Array<number> = [];

        ctx!.save();
        for (let c=0; c < standardsArrayNumber.length; c++) { 
                if(isNaN(pontos[c])){
                pontos[c]=0;
            }
            const graphHeight:number = this.props.forPrint ? 300 : 500;
            const larg: number = graphHeight / (standardsArrayNumber.length -1);
            diferenca[c] = standardsArrayNumber[c] - pontos[c];
            pontos[c]+= diferenca[c]/(c+1);
            ctx!.fillStyle="#c5d6ca";
            
            switch(this.props.proficiencyScore.toString()) {
                case '5':
                case '4':
                case '3':                    
                    ctx!.fillStyle = "#c5d6ca";
                    break;        
                case '2':
                case '1':                 
                    ctx!.fillStyle="#c99700";
                    break;              
                default:
                    ctx!.fillStyle ="#ccc";
                break;
            }
            this.drawingLines(larg,diferenca,c);     
        }

    }

    private drawingLines = (width: number, points: Array<number>, c: number): void => {
        const ctx: CanvasRenderingContext2D | null = this.canvasLineGraph.current ? this.canvasLineGraph.current.getContext('2d') : null;
        if(ctx != null){
            ctx.beginPath();
            ctx.globalAlpha = c*0.3; 
            ctx.moveTo(((c-1)*width+10),points[c-1]);
            ctx.lineTo(c*width+10,points[c]);
            ctx.lineTo(c*width+10,0);
            ctx.lineTo(((c-1)*width+10),0);
            ctx.lineTo(((c-1)*width+10),points[c-1]);
            ctx.fill();
            ctx.save();
            ctx.restore();
        }
    }

}

export default SkillProficiencyGraph;