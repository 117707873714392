import { WeeklyGrowth } from '../models/WeeklyGrowth';

export interface StudentJson {
    parentID?:          number;
    childID?:           number;
    currentIdentityID?: number;
    firstName:         string;
    lastName:          string;
    personId?:     number;
    staffNumber?: number;
    studentNumber?: number;
}
  
export class Student {

    public static fromJson = (student: StudentJson) => {
        if (student) {
            return new Student(
                student.parentID,
                student.childID,
                student.currentIdentityID,
                student.firstName,
                student.lastName,
                student.personId,
                student.staffNumber,
                student.studentNumber
            );
        }
        return new Student();
    }

    constructor(
        public parentID: number = -1,
        public childID:  number = -1,
        public currentIdentityID: number = -1,
        public firstName: string = '',
        public lastName: string = '',
        public personId: number = -1,
        public staffNumber: number = -1,
        public studentNumber: number = -1
    ) {}
}
 
export interface IActiveStudent {
    studentName:string;
    studentId:number;
    studentProjectedGrade:string;
    studentMttStatus:string;
    directGradeEligible:boolean;
    sectionId:number;
    scoreText:string;
    scoreIntials:string;
    studentFinalGrade?:string;
    studentFinalGradeStatus?:boolean | undefined;
}

export interface IStudentWeeklyGrowth {
    studentId:number;
    weeklyGrowthArray:Array<WeeklyGrowth>;
}

export interface IStudentWeeklyGrowthScores {
    studentPersonId: number;
    weeklyGrowthScore: string;
}

export enum GradeMode{
    SingleGrade = 1,
    DoubleGrade = 0,
}

export interface IStudentGradeMode {
    studentId: number;
    gradeMode: GradeMode;
    projectedGrade?: string;
}