export enum MttStatus {
    // Student is currently in a an Active MTT Intervention
    ActiveMtt = `Active`,
    ActiveWCommentsMtt = `ActiveWComments`,
    EligibleToExit = `EligibleToExit`,
    // Student is eligible to be entered into a MTT Intervention
    EligibleMtt = `Eligible`,
    // Inactives
    ExitedMtt = `Exit`,
    ExitedDroppedMtt = `ExitDropped`,
    NotActvieMtt = `NotActive`,
    NotEligible = `NotEligible`,
    SkippedMtt = `Skipped`,

    LoadingEligiblity = `LoadingEligiblity`,
}

export enum MttParentView{
    GradebookView = `GradebookView`,
    MandatoryTargetedTutoringView = `MandatoryTargetedTutoringView`
}

export interface IMttSelectedSessionWeek {
    selectedSessionDate: string;
    selectedSessionStatus: string;
}

export interface IMttSessionStartWeek {
    selectedSessionDate: string;
    selectedSessionStartWeekNum?: string;
    selectedSessionStatus: MttStatus;
}