export enum FilterType {
    Pagination,
    SearchStudent,
    SearchTeacher,
    Division,
    None
}

export enum SortByType {
    StudentName,
    CourseName
}

export enum OrderByType {
    Ascending,
    Descending
}

export interface ISortBy{
    sortBy: SortByType;
    order: OrderByType;
}


export enum FilteringType {
    Division,
    MyStudents
}

export interface IFilterOptions {
    filterName: string | number;
    filterType: FilteringType;
}