import { FeatureFlag } from "./store/featureflags/types";

export default function FeatureGate(featureName: string, featureFlags: FeatureFlag[]): boolean {
    const feature = featureName.toLocaleLowerCase();
    for(let i = 0; i < featureFlags.length; i ++){
        const flag = featureFlags[i];
        if(flag.key.toLocaleLowerCase() === feature){
            return flag.value;
        }
    }
    return false;
}