import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  GradebookEntry, IStudentGradeBook, IStudentSkillProficiency } from 'models/GradebookEntry';
import { IStudentGradeMode, IStudentWeeklyGrowthScores } from 'models/Student';
import { IEnrollMandatoryTargetedTutoringAction, INewComment, IComments,IUpdateMandatoryTargetedTutoring  } from 'models/MandatoryTargetedTutoring';
import { MttStatus } from 'models/enums/Mtt';
import { GradeStatus, SkillStatus } from 'models/Grade';
import { WeeklyGrowth } from 'models/WeeklyGrowth'; 
import { getDatesTimes } from "utils/helpers/mttHelpers";
import { IMttEligibilty } from 'models/MandatoryTargetedTutoring';
import { Assessment }from 'models/Assessment'; 
 
import moment from 'moment'; 
const { currentDate } = getDatesTimes();

export interface IGradebookState {
    loadingGradebooks: boolean;
    errorsLoadingGradebooks: boolean;
    allGradebooks: GradebookEntry[];
    activeSectionId: number;
    activeTeacherId: number;
    selectedStudentGradebook:GradebookEntry;
}

export const initialState:IGradebookState = {
    loadingGradebooks: false,
    errorsLoadingGradebooks: false,
    allGradebooks: [],
    activeSectionId: -1,
    activeTeacherId: -1,
    selectedStudentGradebook: new GradebookEntry()
};

const gradebooksSlice = createSlice({
  name: `gradebooks`,
  initialState,
  reducers: {
    initializeGradebooksAction: state => {
      state.loadingGradebooks = true;
    },
    updateGradebooksAction: (state, action: PayloadAction<GradebookEntry[]>) => {
      state.allGradebooks = action.payload;

      const firstGradebook:GradebookEntry|undefined = action.payload.find((x) => x.student.sectionId != null || x.student.sectionId != undefined );
      if(firstGradebook != undefined)
        state.activeSectionId = firstGradebook.student.sectionId;

      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = false;
    },
    enrollOrSkipInterventionAction: (state, action: PayloadAction<Array<IEnrollMandatoryTargetedTutoringAction>>) => {
        action.payload.forEach(eligibleStudent => {
          const { studentId, currentStudentStatus, mttInterventions } = eligibleStudent;
          state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentId && currentStudentStatus && (gradebookItem.student.personId === studentId)) {
                return {
                    ...gradebookItem,  // copy the existing item
                    mandatoryTargetedTutoring: {
                        ...gradebookItem.mandatoryTargetedTutoring,
                        currentStudentStatus: currentStudentStatus,
                        mttInterventions: gradebookItem.mandatoryTargetedTutoring.mttInterventions ? [...gradebookItem.mandatoryTargetedTutoring.mttInterventions, mttInterventions] : [mttInterventions]
                    }
                }
            }
            // Leave every other item unchanged
            return gradebookItem;
          })
        });

        state.loadingGradebooks = false;
        state.errorsLoadingGradebooks = false; 
    },

    /** Reducer Action to update active teacher ID */
    updateTeacherIdAction: (state, action: PayloadAction<number>) => {
      state.activeTeacherId = action.payload;
    },


    /** Reducer Action to update either the skills or enrollment comment for an Active MTT intervention */
    updateActiveMttAction: (state, action: PayloadAction<IUpdateMandatoryTargetedTutoring>) => {
      const { studentId, updatedEnrollmentComment, updatedSkills } = action.payload

      state.allGradebooks = state.allGradebooks.map((gradebookItem) => {
        if(gradebookItem.student.personId === studentId) {
            return {
                ...gradebookItem, 
                mandatoryTargetedTutoring: {
                    ...gradebookItem.mandatoryTargetedTutoring,
                    mttInterventions: [
                      ...gradebookItem.mandatoryTargetedTutoring.mttInterventions.map((session) => {
                        if(session.currentSessionStatus === MttStatus.ActiveMtt) {
                          const oldEnrollComment:any = session.initialComment;
                          return { ...session,
                            enrollmentComment:updatedEnrollmentComment ? updatedEnrollmentComment : oldEnrollComment,
                            skills: updatedSkills ? updatedSkills : [...session.skills]
                          }
                        }
                        // Return all others sessions unchanged
                        return session;
                      })
                    ]
                }
            }
        }
        // Leave every other Gradebook item unchanged
        return gradebookItem;
      });
      
      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = false; 
    },
    updateAndExitMttAction: (state, action: PayloadAction<INewComment>) => {
      const { studentId, newComment } = action.payload
      state.allGradebooks = state.allGradebooks.map((gradebookItem) => {
          if(studentId && newComment && (gradebookItem.student.personId === studentId)) {
              // Return a new Gradebook object
              return {
                  ...gradebookItem, 
                  mandatoryTargetedTutoring: {
                      // Update Current status to Not Active,
                      currentStudentStatus: MttStatus.NotActvieMtt,
                      mttInterventions: [
                        ...gradebookItem.mandatoryTargetedTutoring.mttInterventions.map((session) => {
                          // Add a new end comment to Active Session
                          if(session.currentSessionStatus === MttStatus.ActiveMtt) {
                            const sortedCommentsByRecent:IComments[] = [...session.comments, newComment].sort((a, b) => {
                              return moment(b.commentDate).valueOf() - moment(a.commentDate).valueOf();
                            });
                            return { ...session,endDate:currentDate, currentSessionStatus:MttStatus.NotActvieMtt, comments: sortedCommentsByRecent }
                          }
                          // Return all others sessions unchanged
                          return session;
                        })
                      ]
                  }
              }
        }
        // Leave every other Gradebook item unchanged
        return gradebookItem;
      })
      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = false; 
    },
    addInterventionCommentAction: (state, action: PayloadAction<INewComment>) => {
      const { studentId, newComment } = action.payload
      state.allGradebooks = state.allGradebooks.map((gradebookItem) => {
          if(studentId && newComment && (gradebookItem.student.personId === studentId)) {
              // Return a new Gradebook object
              return {
                  ...gradebookItem, 
                  mandatoryTargetedTutoring: {
                      ...gradebookItem.mandatoryTargetedTutoring,
                      mttInterventions: [
                        ...gradebookItem.mandatoryTargetedTutoring.mttInterventions.map((session) => {
                          // Add new comment in a sesssion marked as Active
                          if(session.currentSessionStatus === MttStatus.ActiveMtt) {
                            const sortedCommentsByRecent:IComments[] = [...session.comments, newComment].sort((a, b) => {
                              return moment(b.commentDate).valueOf() - moment(a.commentDate).valueOf();
                            });
                            return { ...session, comments: sortedCommentsByRecent }
                          }
                          // Return all others not active unchanged
                          return session;
                        })
                      ]
                  }
              }
        }
        // Leave every other Gradebook item unchanged
        return gradebookItem;
      })
      const udpatedSelectedStudentGradebook = state.allGradebooks.filter(gradebookItem => gradebookItem.student.personId === studentId)[0];
      if(studentId && newComment && udpatedSelectedStudentGradebook)  {
        state.selectedStudentGradebook = state.allGradebooks.filter(gradebookItem => gradebookItem.student.personId === studentId)[0];
      }

      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = false; 
    },
    /** Reducer Action to indicate Eligibilty is being calculated or Projected Grade*/
    initializeEligibiltyAndGradeAction: (state, action: PayloadAction<Array<IMttEligibilty>> ) => {
      action.payload.forEach((studentEligibilty:IMttEligibilty) => {
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
          if(gradebookItem.student.personId === studentEligibilty.studentId) {
              return {
                  ...gradebookItem,
                  assessment: {
                    ...gradebookItem.assessment,
                      // finalGrade : GradeStatus.LoadingGrade,
                      projectedGrade : GradeStatus.LoadingGrade,
                      weeklyGrowth : GradeStatus.LoadingGrade,
                  },
                  mandatoryTargetedTutoring: {
                    ...gradebookItem.mandatoryTargetedTutoring,
                    currentStudentStatus: MttStatus.LoadingEligiblity
                  }
              }
          }
          return gradebookItem;
        });

      });
    },

    /** Update Mtt Status for a student */ 
    updateMttStatusAction: (state, action: PayloadAction<{studentId:number, mttStatus:MttStatus }>) => {
      const { studentId, mttStatus } = action.payload
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
          if(gradebookItem.student.personId === studentId) {
              return {
                  ...gradebookItem,
                  mandatoryTargetedTutoring: {
                    ...gradebookItem.mandatoryTargetedTutoring,
                    currentStudentStatus: mttStatus
                  }
              }
          }
          return gradebookItem;
        });
    },

    /** Reducer Action to intialize Projected Grade Mode loader*/
    initializeGradeModeAction: (state, action: PayloadAction<Array<number>> ) => {
      action.payload.forEach((studentId:number) => {
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
          if(gradebookItem.student.personId === studentId) {
              return {
                  ...gradebookItem,
                  assessment: {
                    ...gradebookItem.assessment,
                      projectedGrade : GradeStatus.LoadingGrade,
                  }
              }
          }
          return gradebookItem;
        });
      });
    },


     /** Reducer Action to update Projected Grade Mode*/
    updateGradeModeAction: (state, action: PayloadAction<Array<IStudentGradeMode>>) => {

      action.payload.forEach(studentAssessment => {
        const { studentId, gradeMode, projectedGrade} = studentAssessment;
        
       
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentId && (gradebookItem.student.personId === studentId)) {
                return {
                    ...gradebookItem,
                    assessment: {
                    ...gradebookItem.assessment,
                      gradeMode: gradeMode,
                      projectedGrade: projectedGrade ? projectedGrade : ''
                    } 
                    
                }
            }
          // Leave every other item unchanged
          return gradebookItem;
        });
      });
    },

    initializeFinalVerificationAction: (state, action: PayloadAction<Array<IMttEligibilty>> ) => {
      action.payload.forEach((studentEligibilty:IMttEligibilty) => {
        state.allGradebooks = state.allGradebooks.map((gradebookItem) => {
          if(gradebookItem.student.personId === studentEligibilty.studentId) {
              return {
                  ...gradebookItem,
                  assessment: {
                    ...gradebookItem.assessment,
                      finalGrade : GradeStatus.LoadingGrade,
                  },
              }
          }
          return gradebookItem;
        });
      });
    },

    /** Reducer Action to a growth item within the Gradebook store */
    updateWeeklyGrowthAction: (state, action: PayloadAction<Array<IStudentGradeBook>>) => {
      action.payload.forEach(studentWeeklyGrowth => {
          const { studentPersonId, sectionId, sequence, weeklyGrowthScore, comment, currentWeek, taskId, projectedGrade} = studentWeeklyGrowth;
        
        // If the Teacher undos a final grade then the score should be blank.
        const updatedWeeklyGrowthScore:string | undefined = weeklyGrowthScore && weeklyGrowthScore.toLowerCase() === 'undo' ? '' : weeklyGrowthScore;
        const newWeeklyGrowth:WeeklyGrowth = {
            studentPersonId: studentPersonId,
            sectionId: sectionId,
            task: currentWeek ? currentWeek : '',
            taskID: taskId ? taskId : -1,
            score: updatedWeeklyGrowthScore ? updatedWeeklyGrowthScore : '',
            comments: comment ? comment : '',
            sequence: sequence ? sequence : -1
        };
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentPersonId && (gradebookItem.student.personId === studentPersonId)) {
                // If there is already a weekly score for the same week then we want to overwrite within the list. 
                const hasWeeklyScoreAlready:boolean = [...gradebookItem.weeklyGrowth].some(wg => wg.sequence === sequence);
                return {
                    ...gradebookItem,
                    assessment: {
                    ...gradebookItem.assessment,
                      projectedGrade : projectedGrade ? projectedGrade : '',
                      weeklyGrowth : weeklyGrowthScore ? weeklyGrowthScore : '',
                    },
                    weeklyGrowth: hasWeeklyScoreAlready ?
                      [...gradebookItem.weeklyGrowth].map(weeklyGrowth => {
                        if (weeklyGrowth.sequence === sequence) { return newWeeklyGrowth; }
                        return weeklyGrowth;
                      })
                      : 
                      [...gradebookItem.weeklyGrowth, newWeeklyGrowth]
                }
            }
          // Leave every other item unchanged
          return gradebookItem;
        });
      });
    },


    initializeStudentAssessmentAction: (state, action: PayloadAction<Array<IMttEligibilty>> ) => {

      action.payload.forEach((student:IMttEligibilty) => {
        state.allGradebooks = state.allGradebooks.map((gradebookItem) => {

          
          if(gradebookItem.student.personId === student.studentId) {
              return {
                  ...gradebookItem,
                  assessment: {
                    ...gradebookItem.assessment,
                      standards: 
                         [...gradebookItem.assessment.standards.map((standard) => {
                            // This will trigger a loading icon in the skills proficiency
                            standard.proficiency.proficiencyScore = SkillStatus.LoadingSkill;
                            return standard;
                        })]  
                  }
              }
          }
          return gradebookItem;
        });
        
      });
   
    },

    /** Reducer Action to update student Assessment, typically in the final week */
    updateStudentAssessmentAction: (state, action: PayloadAction<Array<Assessment>>) => {

      action.payload.forEach(studentAssessment => {
        const { studentPersonID, sectionID, finalGrade, projectedGrade, standards, isVerified} = studentAssessment;
        
       
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentPersonID && (gradebookItem.student.personId === studentPersonID) && (gradebookItem.student.sectionId === sectionID)) {
                return {
                    ...gradebookItem,
                    assessment: {
                    ...gradebookItem.assessment,
                      isVerified: isVerified,
                      projectedGrade : projectedGrade,
                      finalGrade : finalGrade,
                      standards: standards
                      
                    } 
                    
                }
            }
          // Leave every other item unchanged
          return gradebookItem;
        });
      });
    },



    /** Reducer to intialize override student skill proficiency */
    initializeSkillProficiencyAction: (state, action: PayloadAction<IStudentSkillProficiency>) => {
      const { studentPersonId, sectionId, standardTaskId, stdMatrix,  finalGrade, projectedGrade } = action.payload;
 
      state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
          if(studentPersonId && (gradebookItem.student.personId === studentPersonId) && (gradebookItem.assessment.sectionID === sectionId)) {
            return {
                ...gradebookItem,
                assessment: {
                ...gradebookItem.assessment,
                  finalGrade: (finalGrade === GradeStatus.LoadingGrade) ? GradeStatus.LoadingGrade : (finalGrade !== undefined) ? finalGrade : '',
                  projectedGrade: (projectedGrade === GradeStatus.LoadingGrade) ? GradeStatus.LoadingGrade : (projectedGrade !== undefined) ? projectedGrade : '',
                  standards: [
                    ...gradebookItem.assessment.standards.map((standard) => {
                      if(standard.standardTaskID === standardTaskId) {
                        return { 
                          ...standard, 
                          proficiency: {
                            ...standard.proficiency,
                            proficiencyScore: stdMatrix
                          }  
                        }
                      }
                      // Return all others not changed
                      return standard;
                    })
                  ]
                }
          }
        }
        // Leave every other item unchanged
        return gradebookItem;
      });
    },
    /** Reducer Action to override student skill proficiency */
    overrideSkillProficiencyAction: (state, action: PayloadAction<IStudentSkillProficiency>) => {
      const { studentPersonId, sectionId, standardTaskId, stdMatrix, finalGrade, projectedGrade, isVerified, isSkillOveride } = action.payload;
      state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
          if(studentPersonId && (gradebookItem.student.personId === studentPersonId) && (gradebookItem.assessment.sectionID === sectionId)) {
            return {
                ...gradebookItem,
                assessment: {
                ...gradebookItem.assessment,
                  finalGrade: finalGrade ? finalGrade : '',
                  projectedGrade: projectedGrade ? projectedGrade : '',
                  isVerified: isVerified !== undefined ? isVerified : false, 
                  standards: [
                    ...gradebookItem.assessment.standards.map((standard) => {
                      // Update selected Proficiency Level
                      if(standard.standardTaskID === standardTaskId) {
                        return { 
                          ...standard, 
                          proficiency: {
                            ...standard.proficiency,
                            proficiencyScore: stdMatrix,
                            isOverWritten: isSkillOveride !== undefined ? isSkillOveride : false
                          }  
                        }
                      }
                      // Return all others not changed
                      return standard;
                    })
                  ]
                }
          }
        }
        // Leave every other item unchanged
        return gradebookItem;
      });
    },

    /** Reducer Action to view previous growth scores giving the user option to update  */
    viewPreviousWGScoresAction: (state, action: PayloadAction<Array<IStudentWeeklyGrowthScores>>) => {

      action.payload.forEach(studentWeeklyGrowth => {
        const { studentPersonId, weeklyGrowthScore } = studentWeeklyGrowth;
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentPersonId && (gradebookItem.student.personId === studentPersonId)) {
                return {
                    ...gradebookItem,
                    assessment: {
                    ...gradebookItem.assessment,
                      weeklyGrowth : weeklyGrowthScore,
                    }
                }
            }
          // Leave every other item unchanged
          return gradebookItem;
        });
      });
      
      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = false; 
    },

    /** Reducer Action to update MTT eligibilty once response from Growth Score update is complete */
    updateMttEligibiltyAction: (state, action: PayloadAction<Array<IMttEligibilty>>) => {
      action.payload.forEach(mttEligibilty => {
        const { studentId, mttStatus } = mttEligibilty;
        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentId && (gradebookItem.student.personId === studentId)) {
                return {
                    ...gradebookItem,
                    mandatoryTargetedTutoring: {
                      ...gradebookItem.mandatoryTargetedTutoring,
                      currentStudentStatus: mttStatus ? mttStatus : MttStatus.NotActvieMtt
                    }
                }
            }
            return gradebookItem;
        });
      });

    },
    /** Reducer Action to Update a Final Grade Status and Comments for an array of students during the final week of a semester */
    updateFinalGradeStatusAction: (state, action: PayloadAction<Array<IStudentGradeBook>>) => {
      action.payload.forEach(studentWeeklyGrowth => {
        const { studentPersonId, sectionId,sequence, isVerified, finalGrade, comment, taskId} = studentWeeklyGrowth;
        const newWeeklyGrowth:WeeklyGrowth = {
          studentPersonId: studentPersonId,
          sectionId: sectionId,
          task: 'Final Comment',
          taskID: taskId ? taskId : -1,
          score: finalGrade ? finalGrade : '',
          comments: comment ? comment : '',
          sequence: sequence ? sequence : -1,
          isFinalComment: true
        };

        state.allGradebooks = state.allGradebooks.map((gradebookItem, index) => {
            if(studentPersonId && (gradebookItem.student.personId === studentPersonId)) {
                const hasWeeklyScoreAlready:boolean = [...gradebookItem.weeklyGrowth].some(wg => wg.sequence === sequence);
                if(comment){
                  return {
                      ...gradebookItem,
                      assessment: {
                      ...gradebookItem.assessment,
                        isVerified : isVerified != undefined ? isVerified : gradebookItem.assessment.isVerified,
                        finalGrade : finalGrade ? finalGrade : '',
                      },
                      weeklyGrowth: hasWeeklyScoreAlready ?
                        [...gradebookItem.weeklyGrowth].map(weeklyGrowth => {
                          if (weeklyGrowth.sequence === sequence) { return newWeeklyGrowth; }
                          return weeklyGrowth;
                        })
                        : 
                        [...gradebookItem.weeklyGrowth, newWeeklyGrowth]
                  }
                }

                return {
                  ...gradebookItem,
                  assessment: {
                  ...gradebookItem.assessment,
                    isVerified : isVerified != undefined ? isVerified : gradebookItem.assessment.isVerified,
                    finalGrade : finalGrade ? finalGrade : '',
                  }
                }

            }
          // Leave every other item unchanged
          return gradebookItem;
        });
      });
    },

    getGradebooksActionFailure: state => {
      state.loadingGradebooks = false;
      state.errorsLoadingGradebooks = true;
    },

  
  },
});

export const { initializeGradebooksAction, initializeFinalVerificationAction, initializeSkillProficiencyAction,initializeStudentAssessmentAction, updateGradebooksAction, enrollOrSkipInterventionAction, updateTeacherIdAction, updateActiveMttAction, updateAndExitMttAction, addInterventionCommentAction, updateWeeklyGrowthAction,updateStudentAssessmentAction, viewPreviousWGScoresAction, overrideSkillProficiencyAction, initializeEligibiltyAndGradeAction,updateMttStatusAction, initializeGradeModeAction, updateGradeModeAction,  updateMttEligibiltyAction, getGradebooksActionFailure, updateFinalGradeStatusAction} = gradebooksSlice.actions;
export const gradebooksSelector = state => state.gradebooks;
export default gradebooksSlice.reducer;
