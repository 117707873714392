import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// set intial state for users
export enum NotificationsType {
  Success = `Success`,
  Error = `Error`,
  Warning = `Warning`,
}

export interface INotifications {
  notificationStatus: NotificationsType,
  notificationMessage: string
}

export interface INotificationsState {
    loading: boolean;
    hasErrors: boolean;
    allNotifications: INotifications | null;
}

export const initialState:INotificationsState = {
  loading: false,
  hasErrors: false,
  allNotifications: null,
};
 
const notificationsSlice = createSlice({
  name: `notifications`,
  initialState,
  reducers: {
    initializeNotificationsAction: state => {
      state.loading = true;
    },
    updateNotificationsAction: (state, action: PayloadAction<INotifications>) => {
      state.allNotifications = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    resetNotificationsAction: state => {
      state.allNotifications = null;
      state.loading = false;
      state.hasErrors = false;
    },
    getNotificationsActionFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { initializeNotificationsAction, updateNotificationsAction, resetNotificationsAction, getNotificationsActionFailure} = notificationsSlice.actions;
export const notificationsSelector = state => state.notifications;
export default notificationsSlice.reducer;
