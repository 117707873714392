import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import PersonIcon from "@mui/icons-material/Person";

import { FilterType } from "../../models/enums/Filter";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandIcon from "@mui/icons-material/Expand";
import CompressIcon from "@mui/icons-material/Compress";
import ToggleButton from "@mui/material/ToggleButton";
import { IFilterOptions, FilteringType } from "../../models/enums/Filter";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ListItemIcon from "@mui/material/ListItemIcon";
import Alert from "@mui/material/Alert";
import FlagIcon from "@mui/icons-material/Flag";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { globalSelector } from "../../store/global/globalReducer";
import { gradebooksSelector } from "../../store/student/gradebookReducer";
import { getVerifiableStudents } from "../../utils/helpers/generalHelpers";
import { WeekType } from 'models/WeeklyGrowth';

export enum PaginationAction {
  Prev,
  Next,
}

export enum MessageType {
  Information = "info",
  Alert = "error",
  Warning = "warning",
  Success = "success",
}

export interface IPagination {
  currentPage: number;
  action: PaginationAction;
  defaultRowsPerPage: number;
}

export interface IMessage {
  type: MessageType | undefined;
  message: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface ISelectableItem<T> {
  id: string;
  selected: boolean;
  item: T;
}

interface IActionBar {
  menuTitle: string;

  // Pagination
  defaultRowsPerPage?: number;
  paginationFunction?: (paginationStep: number) => void;
  totalRowCount?: number;
  filteredRowCount?: number;
  showPagination?: boolean;

  // Filtering
  divisionFunction?: (divisions: string[]) => void;
  divisions?: IFilterOptions[];
  filterType?: FilterType;
  hideFilter?: boolean;

  // Selection
  selectionChange?: (selections: Array<ISelectableItem<any>>) => void;
  selectionHide?: boolean;
  selectionEditBtn?: JSX.Element;
  selectionItems?: Array<ISelectableItem<any>>;
  selectionLabel?: string;
  selectionDisabled?: boolean;
  setPaginateBy?: (val: number) => void;
  setCurrentPaginationStep?: (val: number) => void;

  // Messaging
  message?: IMessage;
}

export const ActionBar = ({
  menuTitle,
  hideFilter,
  showPagination,
  totalRowCount,
  filteredRowCount,
  divisions,
  defaultRowsPerPage,
  paginationFunction,
  divisionFunction,
  filterType,
  selectionHide,
  selectionLabel,
  selectionItems,
  selectionChange,
  selectionEditBtn,
  setPaginateBy,
  setCurrentPaginationStep,
  selectionDisabled,
  message,
}: IActionBar) => {
  // Default States
  const [showFilters, setShowFilters] = React.useState(!hideFilter);
  const [currentStep, setCurrentStep] = React.useState(0);
  const initialRowsPerPage: number = defaultRowsPerPage ? defaultRowsPerPage : 25;
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [pagination, setPagination] = React.useState(true);
  const paginationToggleEnabled = defaultRowsPerPage !== undefined && setPaginateBy !== undefined && setCurrentPaginationStep !== undefined;
  const [showPaginationToggle, setShowPaginationToggle] = React.useState(paginationToggleEnabled);
  const totalRows: number = totalRowCount ? totalRowCount : 0;
  const pageCount: number = Math.ceil(totalRows / rowsPerPage);
  const pageStartNumber: number = currentStep * rowsPerPage + 1;
  const priorResults: number = pageStartNumber - 1;
  const remainingResults: number = totalRowCount ? totalRowCount - priorResults : 0;
  const leftoverResults: number = remainingResults % rowsPerPage;
  const pageEndNumber: number = remainingResults >= rowsPerPage ? rowsPerPage * (currentStep + 1) : priorResults + leftoverResults;
  const paginationLegend = `${pageStartNumber} - ${pageEndNumber} of ${totalRowCount}`;
  const isPaginationFilter: boolean = filterType === FilterType.Pagination ? true : false;
  const divisionsList: IFilterOptions[] = divisions ? divisions : [];
  const [selectedDivisions, setSelectedDivisions] = React.useState<string[]>([]);
  const [verifiedStudentsCount, setVerifiedStudentsCount] = React.useState<number>(0);
  const theme = useTheme();

  const { isFinalWeekMode, viewingWeekType } = useSelector(globalSelector);
  const { allGradebooks } = useSelector(gradebooksSelector);

  const verifiableStudents = getVerifiableStudents(allGradebooks);

  useEffect(() => {
    if (verifiableStudents.length > 0) {
      setVerifiedStudentsCount(verifiableStudents.filter((student) => student.assessment.isVerified === true).length);
    }
  }, [verifiableStudents]);

  useEffect(() => {
    if (divisionFunction) {
      divisionFunction(selectedDivisions);
    }
  }, [selectedDivisions]);

  useEffect(() => {
    if (showPaginationToggle && filterType !== FilterType.Pagination) {
      setShowPaginationToggle(false);
    } else if (filterType === FilterType.Pagination && paginationToggleEnabled) {
      setShowPaginationToggle(true);
    }
  }, [filterType]);

  useEffect(() => {
    if (setPaginateBy !== undefined && setCurrentPaginationStep !== undefined) {
      if (pagination) {
        setRowsPerPage(initialRowsPerPage);
        setPaginateBy(initialRowsPerPage);
      } else {
        setRowsPerPage(totalRows);
        setPaginateBy(totalRows);
      }
      setCurrentStep(0);
      setCurrentPaginationStep(0);
    }
  }, [pagination]);

  // Funtions
  const onDivisionItemClick = (event: SelectChangeEvent<typeof selectedDivisions>) => {
    const {
      target: { value },
    } = event;
    setSelectedDivisions(typeof value === "string" ? value.split(",") : value);
  };

  const onPaginationClick = (currentPaginationStep: number): void => {
    if (paginationFunction) {
      paginationFunction(currentPaginationStep);
      setCurrentStep(currentPaginationStep);
    }
  };

  const selectedItemsCount = selectionItems ? selectionItems.filter((item) => item.selected).length : 0;

  return (
    <AppBar
      sx={{
        "& .MuiToolbar-root": {
          backgroundColor: message && message.type == MessageType.Warning ? theme.palette.gold.light : "transparent",
        },
      }}
      position="static"
    >
      <Toolbar>
        {!selectionHide && selectionLabel !== undefined && selectedItemsCount !== undefined && (
          <Box component="div" sx={{ 
              p: 1, 
              bgcolor: isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? '#ddd4b6' : 'blueGrey.light', 
              display: "flex", 
              paddingRight: "26px", 
              justifyContent: "space-between", 
              alignItems: "center", 
              color: isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? 'black.contrastText' : 'blueGrey.dark'   
            }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <>
                    <Checkbox checked={selectedItemsCount > 0} onChange={selectionItems && selectionChange ? () => selectionChange(selectionItems) : () => ({})} disabled={selectionDisabled} />
                    {selectionEditBtn}
                    <span style={{ width: "10px" }}>&nbsp;</span>
                  </>
                }
                label={`${selectedItemsCount} ${selectionLabel} Selected`}
                sx={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "0.8125rem", color: "rgba(0, 0, 0, 0.87)" }}
              />
            </FormGroup>
          </Box>
        )}

        {isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) && getVerifiableStudents(allGradebooks).length > 0 && (
          <Chip
            sx={{
              marginLeft: "18px",
            }}
            icon={<PersonIcon />}
            size="medium"
            key={23}
            label={`${verifiedStudentsCount} verified / ${verifiableStudents.length}`}
          />
        )}

        {message && message.type && message.message && (
          <Alert
            sx={{
              border: "none",
            }}
            severity={message.type}
            iconMapping={{
              info: <InfoOutlinedIcon fontSize="inherit" />,
              warning: <FlagIcon fontSize="inherit" />,
            }}
            variant="outlined"
          >
            {message.message}
          </Alert>
        )}

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

        {showFilters && (
          <Box
            component="div"
            sx={{
              p: 1,
              bgcolor: "blueGrey.light",
              display: "flex",
              minWidth: "105px",
              justifyContent: "space-between",
              alignItems: "center",
              color: "blueGrey.dark",
              ".MuiFormControl-root": { height: "40px", minHeight: "40px !important" },
              "#filtersInputChip": { padding: "7px 10px 4px !important", minHeight: "30px" },
              "#filtersInputLabel": { margin: 0, fontSize: ".9rem" },
              "#filtersInputLabel:not(.MuiFormLabel-filled)": { transform: "translate(14px, 10px) scale(1)" },
              ".MuiOutlinedInput-root": { minWidth: "90px" },
            }}
          >
            <FormControl>
              <InputLabel id="filtersInputLabel">
                Filter
                <FilterAltOutlinedIcon />
              </InputLabel>
              <Select
                sx={{
                  minWidth: `100px`,
                }}
                IconComponent={() => <span />}
                labelId="filtersInputLabel"
                id="filtersInputChip"
                multiple
                value={selectedDivisions}
                onChange={onDivisionItemClick}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selectedDivision) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedDivision.map((division) =>
                      isNaN(parseInt(division)) ? <Chip key={division} label={division.toString().substring(0, 15) + `...`} /> : <Chip key={division} icon={<FolderSharedIcon />} />
                    )}
                  </Box>
                )}
                MenuProps={MenuProps}
                autoWidth={false}
              >
                {divisionsList.map((division) => (
                  <MenuItem key={division.filterName} value={division.filterName.toString()}>
                    <Checkbox checked={selectedDivisions.indexOf(division.filterName.toString()) > -1} />

                    {division.filterType === FilteringType.MyStudents ? (
                      <>
                        <ListItemText secondary={"My Students"} />
                        <ListItemIcon>
                          <FolderSharedIcon fontSize="small" />
                        </ListItemIcon>
                      </>
                    ) : (
                      <ListItemText secondary={division.filterName} />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {showPagination && paginationFunction && (
          <Box component="div" sx={{ p: 1, bgcolor: "blueGrey.light", display: "flex", minWidth: "180px", justifyContent: "space-between", alignItems: "center", color: "blueGrey.dark" }}>
            <Chip label={isPaginationFilter ? paginationLegend : `${filteredRowCount} of ${totalRowCount}`} size="small" variant="outlined" />
            <IconButton color="inherit" aria-label="Previous" onClick={() => onPaginationClick(currentStep - 1)} edge="start" disabled={currentStep <= 0 || !isPaginationFilter}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton color="inherit" aria-label="Next" onClick={() => onPaginationClick(currentStep + 1)} edge="start" disabled={currentStep >= pageCount - 1 || !isPaginationFilter}>
              <ChevronRightIcon />
            </IconButton>
            {showPaginationToggle && (
              <ToggleButton value={!pagination ? "Show All" : "Show Paginated"} selected={!pagination} onChange={() => setPagination(!pagination)} style={{ maxHeight: 40, maxWidth: 40 }}>
                {!pagination ? <CompressIcon /> : <ExpandIcon />}
              </ToggleButton>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
