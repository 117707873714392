// import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

export const HeaderSelectInput = styled(InputBase)(({ theme }) => ({
    width:'86% !important',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 0,
      position: 'relative',
      backgroundColor: 'rgba(255,255,255,0.05)',
      border: '1px solid #ccc',
      fontSize: '15px !important',
      padding: '6px !important',
      color:'#fff',
      '&:focus': {
        borderRadius: 0,
      },
    },
    '& .Mui-disabled': {
      border: 'none',
      fontSize: '15px !important',
      color:'#fff !important',
      textFillColor:'#fff !important'
    },
    '& .Mui-disabled.MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
        right:'-9px',
        color:'#fff'

    }


  }));
  