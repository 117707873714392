import { combineReducers } from "redux";
import { sessionReducer } from "./session/reducers";
import { featureFlagReducer } from "./featureflags/reducers";
import { SessionState } from "./session/types";
import { FeatureFlagState } from "./featureflags/types";
import { DefaultRootState } from "react-redux";
import notificationsReducer, {INotificationsState} from './notifications/notificationsReducer';
import gradebookReducer, {IGradebookState} from './student/gradebookReducer';
import globalReducer, {IGlobalState} from './global/globalReducer';
import mandatoryTargetedTutoringReducer, {IMandatoryTargetedTutoringState} from './student/mandatoryTargetedTutoringReducer';

export interface ReduxState extends DefaultRootState {
    featureFlags: FeatureFlagState;
    session: SessionState;
    notifications: INotificationsState;
    gradebooks:IGradebookState;
    mandatoryTargetedTutoringStudents:IMandatoryTargetedTutoringState;
    global:IGlobalState;
}

export default combineReducers({
    featureFlags: featureFlagReducer,
    session: sessionReducer,
    notifications: notificationsReducer,
    gradebooks:gradebookReducer,
    mandatoryTargetedTutoringStudents:mandatoryTargetedTutoringReducer,
    global:globalReducer
});