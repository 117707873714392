import { FeatureFlag, ADD_FEATURE_FLAG, REMOVE_FEATURE_FLAG, SET_FEATURE_FLAGS, CLEAR_FEATURE_FLAGS, SET_INITIALIZED }from "./types";
import * as Redux from 'redux'
import { LOGIN_SUCCESS, User } from "../session/types";

export function setFeatureFlags(newFlags: FeatureFlag[]) {
  return {
    type: SET_FEATURE_FLAGS,
    payload: newFlags
  };
}

export function clearFeatureFlags(payload: any){
    return {
        type: CLEAR_FEATURE_FLAGS,
        payload: payload,
    }
}

export function addFeatureFlag(newFlag: FeatureFlag){
    return{
        type: ADD_FEATURE_FLAG,
        payload: newFlag
    };
}

export function removeFeatureFlag(removedFlag: FeatureFlag){
    return{
        type: REMOVE_FEATURE_FLAG,
        payload: removedFlag
    };
}

export function retrivePermissions(){
    return (dispatch: Redux.Dispatch<any>) => {
      fetch('/clientflags', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      })
      .then((response)=> {
        if(response.ok){
          return response.json();
        }
      })
      .then((flags: FeatureFlag[])=>{
 
        dispatch({type: SET_FEATURE_FLAGS, payload: flags});
        dispatch({type: SET_INITIALIZED, payload: true});
        fetch('/users/authenticate', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
        })
        .then((response)=> {
          if(response.ok){
            return response.json();
          }
          else{
            throw new Error(response.statusText);
          }
        })
        .then((user: User)=>{
          dispatch({type: LOGIN_SUCCESS, payload: user});
      })
      .catch(error=>{
        
      });
    });
  }
}