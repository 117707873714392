import React from 'react';
import './Shared.css';
import { IMttInterventions } from '../../models/MandatoryTargetedTutoring';
import { IMttSelectedSessionWeek, IMttSessionStartWeek } from '../../models/enums/Mtt';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import moment from 'moment';
import { defaultDateFormat, readableDateFormat } from '../../common/config';
import { getMttAttendanceValue, getMttSessionLabel, getMttSkillsValue } from '../../utils/helpers/mttHelpers';

export interface IMttInterventionHeader {
    selectedSessionWeek: IMttSelectedSessionWeek;
    onSelectedSessionChange?: (event: any) => void;
    mttSelectOptions: Array<IMttSessionStartWeek>;
    selectedSession: IMttInterventions | undefined;
}
export const MttInterventionHeader = ( { selectedSessionWeek, onSelectedSessionChange, mttSelectOptions, selectedSession }:IMttInterventionHeader) => {    
    return (
        <Paper elevation={0} sx={{ backgroundColor: 'neutral.main', padding: '10px 18px' }}>
            <Table size="small" aria-label="Mtt Intervention Overview">
                <TableBody>
                    <TableRow>
                        {(onSelectedSessionChange !== undefined) ? (
                            <TableCell align="left" colSpan={2}>                          
                                <Select
                                    id="session-select"
                                    value={`${selectedSessionWeek.selectedSessionDate},${selectedSessionWeek.selectedSessionStatus}`}
                                    onChange={onSelectedSessionChange}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ width: '100%', marginBottom:'8px',
                                        '.MuiSelect-outlined':{padding: '6px 10px'},
                                        '.MuiOutlinedInput-notchedOutline legend':{display:'none'},
                                        '.MuiSelect-select':{padding:'0px 10px 6px'},
                                    }}
                                    displayEmpty
                                    autoWidth={false}
                                >
                                    {mttSelectOptions.map((session,index) => {
                                        return (
                                            <MenuItem key={session.selectedSessionDate + index} value={`${session.selectedSessionDate},${session.selectedSessionStatus}`}>    
                                                <ListItemText secondary={getMttSessionLabel(session)} />
                                            </MenuItem>
                                        )
                                    })}
                                </Select>                            
                            </TableCell>    
                        ) : (selectedSession && (
                            <>
                                <TableCell align="left"><strong>{`Week ${selectedSession.startWeekNum}`} :</strong></TableCell>
                                <TableCell align="left">{`${moment(selectedSession.startDate).format(readableDateFormat)} | ${selectedSession.currentSessionStatus}`}</TableCell>
                            </>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell align="left"><strong>Start Date :</strong></TableCell>    
                        <TableCell align="left">{selectedSession ? `${moment(selectedSession.startDate).format(defaultDateFormat)}` : ''}</TableCell>    
                    </TableRow>
                    <TableRow >
                        <TableCell align="left"><strong>Score :</strong></TableCell>    
                        <TableCell align="left">{selectedSession ? selectedSession.weeklyGrowthScore : ''}</TableCell>
                    </TableRow>

                    {(selectedSession && (selectedSession.skills.length > 0)) && (
                        <TableRow>
                            <TableCell align="left"><strong>Skill(s) :</strong></TableCell>    
                            <TableCell align="left">{getMttSkillsValue(selectedSession)}</TableCell>    
                        </TableRow>
                    )}
                    
                    <TableRow >
                        <TableCell align="left"><strong>Attendance:</strong></TableCell>
                        <TableCell align="left">{getMttAttendanceValue(selectedSession)}</TableCell>
                    </TableRow>
                    {(selectedSession && selectedSession.initialComment) && (
                        <TableRow>
                            <TableCell align="left"><strong>Enrollment Comment:</strong></TableCell>    
                            <TableCell align="left">{selectedSession.initialComment}</TableCell>    
                        </TableRow>
                    )}                        
                </TableBody>
            </Table>
        </Paper> 
    )
}