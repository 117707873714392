
import React, { useState  } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IMenuListItems } from '../../models/Menus';
import EditIcon from '@mui/icons-material/Edit';
import { FeatureFlagState } from '../../store/featureflags/types';
import { connect, useSelector } from 'react-redux';
import FeatureGate from '../../FeatureGate';
import { globalSelector } from "../../store/global/globalReducer";
import ListItemIcon from '@mui/material/ListItemIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface IMenuDropDown {
  disableButton?:boolean;
  menuId: string;
  menuItems: IMenuListItems[];
  // subMenuContent:JSX.Element;
  // itemClickFunction: () => void;
}

const BulkEditMenu = ( {disableButton, menuId, menuItems}:IMenuDropDown) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuIsOpen = Boolean(anchorEl);
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const { termIsEditable } = useSelector(globalSelector);

  const [showingSubMenuContent, setShowingSubMenuContent] = useState(false);
  const [subMenuContentId, setsubMenuContentId] = useState<number>(-1);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleSubMenuContent = (id:number|undefined):void => {
    if(id){
      setsubMenuContentId(id);
    }
    setShowingSubMenuContent(currentBoolean => !currentBoolean);
  };

  const hasSubMenuContent = menuItems.find(item => {
    return item.id === subMenuContentId;
  });

  return (
    <>
      <Button
        variant="contained"
        color='secondary'
        style={{color:'#212529'}}
        sx={{minWidth:'36px',minHeight: '36px'}}
        id={`menu-button-${menuId}`}
        aria-controls={menuIsOpen ? `menu-${menuId}` : undefined}
        aria-haspopup="true"
        aria-expanded={menuIsOpen ? 'true' : undefined}
        onClick={(event:any) => 
          FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) && termIsEditable && 
            openMenu(event)
        }
        disabled={disableButton ? disableButton : false}
      >
        <EditIcon />
      </Button>
      <Menu id={`menu-${menuId}`}
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': `menu-button-${menuId}`,
        }}
      >

        {!showingSubMenuContent && (
          <MenuList sx={{ width: '100%', maxWidth: 360, minWidth:280, bgcolor: 'background.paper' }} dense>
            {menuItems.map(( item: IMenuListItems, index) => {
                return (   
                  <ListItem
                    key={index}
                    disablePadding
                  >
                    <ListItemButton 
                      onClick={() => 
                        item.subMenuContent ? 
                          toggleSubMenuContent(item.id) : 
                          (item.onClickFunction ? ( item.onClickFunction(), closeMenu() ) : null ) 
                      }
                    >
 
                      {item.secondaryIcon && (
                        <ListItemIcon style={{minWidth:'40px'}}>
                          {item.secondaryIcon}
                        </ListItemIcon>
                      )}
                      
                      <ListItemText id={index.toString()} primary={`${item.primaryText}`} />

                      {item.subMenuContent && (
                        <ListItemIcon>
                          <ChevronRightIcon />
                        </ListItemIcon>
                      )}
                      

                    </ListItemButton>
                  </ListItem>
                )
              })
            }
          </MenuList>
        )}

        {(showingSubMenuContent && hasSubMenuContent) && (
          <div>
              <MenuItem onClick={() => toggleSubMenuContent(undefined) }>
                <ListItemIcon>
                  <ChevronLeftIcon />
                </ListItemIcon>
              </MenuItem>
            {hasSubMenuContent.subMenuContent}
          </div>
        )}


      </Menu>
    </>
  );

};
export interface BulkEditStoreProps {  
  features: FeatureFlagState;
}   
function mapStateToProps(state: any): BulkEditStoreProps{
  return {
    features: state.featureFlags,
  };
}
export default connect<BulkEditStoreProps>(mapStateToProps)(BulkEditMenu);