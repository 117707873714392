import React from "react";

export default function useDimensions() {
  const [dimensions, setDimensions] = React.useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  function changeDimensions() {
    setDimensions({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeDimensions);

    return () => {
      window.removeEventListener("resize", changeDimensions);
    };
  }, []);

  return dimensions;
}