import { IMttSessionStartWeek, MttStatus } from "../../models/enums/Mtt";

import { TutorView } from "../../models/TutorView";
import { Student } from "../../models/Student";
import { Teacher } from "../../models/Teacher";
import { removeDuplicatesInArray, removeDuplicatesObjectsInArray } from "./generalHelpers";
import { getLastSubString } from "./stringHelpers";
import { IComments, IMttInterventions } from "../../models/MandatoryTargetedTutoring";
import { GradebookEntry } from "../../models/GradebookEntry";
import { readableDateFormat, readableDateTimeFormat } from "../../common/config";
import { pluralize } from "./stringHelpers";
import { OrderByType, SortByType, IFilterOptions, FilteringType } from "../../models/enums/Filter";
import moment from 'moment';

export const mttSessionIsActive = (mttStatus: MttStatus):boolean => {
    switch(mttStatus) {
        case MttStatus.ActiveMtt:
        case MttStatus.ActiveWCommentsMtt:
        case MttStatus.EligibleToExit:        
            return true;
        default:
            return false;
    }
};

export const getDatesTimes = ():any => {
    const currentDate = new Date();
    const now: moment.Moment = moment();
    const nowTime = now.toString();
    const monday = now.clone().weekday(1);

    //#region TechDebt: https://dev.azure.com/StevensonIT/IRC/_workitems/edit/280 #3
    // This code temporarily doubles the local timezone offset locally so that when the server receives the api call to set the startWeek & 
    //      converts the time from an offset to a fixed time (and in so doing, removes the offset, which results in utc time),
    //      the end result still ends up being the correctly offset value
    // ... and should therefore be removed during task 280 when the server will be set up to set these values correctly server-side
    const offset = now.utcOffset() / 60;
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

    monday.add(offset, 'hours');
    //#endregion

    const startOfSchoolWeek = monday.toString();


    return {
        startWeek: startOfSchoolWeek,
        startWeekDate: new Date(startOfSchoolWeek), 
        startDate: currentDate,
        currentDate: nowTime,
        currentDateISO: localISOTime,
        endDate: ''
    }
};

export const getDivisionsList = (mttArray: TutorView[]): IFilterOptions[] => { 

    const divisionsList:IFilterOptions[] = mttArray.map((mttStudentItem:TutorView) => { 
        if(mttStudentItem.sstPersonID){
            return {
                filterName: mttStudentItem.sstPersonID,
                filterType: FilteringType.MyStudents
            }
        }
        return {
            filterName: mttStudentItem.divisionName,
            filterType: FilteringType.Division
        }
    });
    // If No My Students are in an intervention, we add a fake one to the options only to show the option
    if (divisionsList.some(division => division.filterType === FilteringType.MyStudents) === false) {
        divisionsList.push(
            {
                filterName: -1,
                filterType: FilteringType.MyStudents
            }
        );
    }    

    // Sort by number first then strings alphbetically 
    const filterNameNumbers = divisionsList.filter(function (item) {
        return typeof item.filterName === 'number';
    }).sort();

    const filterNameStrings = divisionsList.filter(function (item) {
        return typeof item.filterName === 'string';
    }).sort((a,b) => a.filterName > b.filterName ? 1 : -1);

    const sortedDivisions = filterNameNumbers.concat(filterNameStrings);
 
    return removeDuplicatesObjectsInArray(sortedDivisions,'filterName');
}

export const convertInterventionsFromServerResponse = (serverResponse: any, selectedStudentGradebook: GradebookEntry): Array<IMttInterventions> => {
    return serverResponse.data.map((intervention) => {        
        const startWeekNum = getLastSubString(intervention.startWeekName);
        const exitComment: IComments = {
            comments: intervention.exitComment,
            commentAuthorName: intervention.modifiedByPerson,
            personId: intervention.exitCommentAuthorPersonId,
            commentDate: intervention.modifiedDate,
            interventionId: intervention.interventionId,
            isExitComment: true
        }
        let allComments = (intervention.interventionComments as Array<IComments>);
        if (intervention.status === MttStatus.ExitedMtt) {
            allComments = [...(intervention.interventionComments as Array<IComments>), exitComment];
        }
        const sortedComments: Array<IComments> = allComments.sort((c1, c2) => {
            return moment(c1.commentDate).isAfter(c2.commentDate) ? -1 : 1;
        });
        const result: IMttInterventions = {
            attendance: intervention.attendance,
            comments: sortedComments,
            currentSessionStatus: intervention.status,
            initialComment: intervention.initialComment,
            continuedMttTaskID:intervention.continuedMttTaskID,
            skippedMttTaskID:intervention.skippedMttTaskID,
            interventionId: intervention.interventionId,
            sectionId: intervention.sectionId,
            skills:  intervention.interventionSkills ? intervention.interventionSkills.map((skill:any) => { return { skillsOption: skill.skillsOption } }) : [],
            startDate: intervention.startDate,
            startWeek: intervention.startWeek,
            startWeekName: intervention.startWeekName,
            startWeekNum: startWeekNum,
            totalDays: intervention.totalDays,
            weeklyGrowthScore: selectedStudentGradebook.assessment.weeklyGrowth,
        };
        return result;
    }).sort((i1, i2) => {
        return moment(i1.startDate).isAfter(i2.startDate) ? -1 : 1;
    });
}

export const convertToStudentList = (mttArray: TutorView[]): Student[] => {    
    const studentList:Student[] = mttArray.map((mttStudentItem:TutorView) => {
        return {
            firstName:mttStudentItem.studentFirstName,
            lastName: mttStudentItem.studentLastName,
            personId: mttStudentItem.studentPersonId,
            studentNumber: mttStudentItem.studentPersonId,
            parentID:-1,
            childID:-1,
            currentIdentityID:-1,
            staffNumber:-1
        };
    });
    return studentList; 
}

export const convertToStudentObject = (mttStudent: TutorView): Student => {    
    const student:Student = {
        firstName:mttStudent.studentFirstName,
        lastName:mttStudent.studentLastName,
        personId:mttStudent.studentPersonId,
        studentNumber: mttStudent.studentPersonId,
        parentID:-1,
        childID:-1,
        currentIdentityID:-1,
        staffNumber:-1
    }        
    return student; 
}

export const convertToTeacherList = (mttArray: TutorView[]): Teacher[] => {    
    const teacherList:Teacher[] = mttArray.map((mttStudentItem:TutorView) => {
        const teacherNameArray:string[] = mttStudentItem.teacherName.split(',');
        return {
            personId: mttStudentItem.teacherPersonId,
            firstName:teacherNameArray[1],
            lastName:teacherNameArray[0],
            identityId: mttStudentItem.teacherPersonId,
            staffNumber:'',
            courses:[]
        };
    })
    return removeDuplicatesInArray(teacherList, teacher => teacher.personId);
}

export const convertToTeacherObject = (mttTeacher: TutorView): Teacher => {   
    const teacherNameArray:string[] = mttTeacher.teacherName.split(',');
    const student:Teacher = {
        personId: mttTeacher.teacherPersonId,
        firstName:teacherNameArray[1],
        lastName:teacherNameArray[0],
        identityId: mttTeacher.teacherPersonId,
        staffNumber:'',
        courses:[]
    }        
    return student; 
}

export const getMttSessionLabel = (obj?: IMttInterventions | IMttSessionStartWeek): string => {
    if (!obj) {
        return '';
    } else if ((obj as IMttInterventions).startWeekNum) {
        const intervention: IMttInterventions = (obj as IMttInterventions);
        return !intervention ? '' : `Week ${intervention.startWeekNum ? intervention.startWeekNum : ''}: ${moment(intervention.startWeek).format(readableDateFormat)} | ${intervention.currentSessionStatus}`;
    } else {
        const startWeek: IMttSessionStartWeek = (obj as IMttSessionStartWeek);
        return !startWeek ? '' : `Week ${startWeek.selectedSessionStartWeekNum ? startWeek.selectedSessionStartWeekNum : ''}: ${moment(startWeek.selectedSessionDate).format(readableDateFormat)} | ${startWeek.selectedSessionStatus}`;
    }
    
}

export const getMttSkillsValue = (intervention?: IMttInterventions): string => {
    let value = '';
    if (intervention) {
        intervention.skills.forEach((skill, skillIndex) => {
            if(skill.skillsOption && skill.skillsOption.trim()){
                value += `${skill.skillsOption}${intervention.skills.length - (skillIndex + 1) > 0 ? ', ' : ''}`;
            }  
        });        
    }
    return value;
}

export const getMttAttendanceValue = (intervention?: IMttInterventions): string => {
    return !intervention ? '' : `${intervention?.attendance} ${pluralize(`visit`, intervention?.attendance)} / ${intervention?.totalDays} ${pluralize(`day`, intervention?.totalDays)}`;
}

export const getMttCommentDate = (comment?: IComments): string => {
    return !comment ? '' : `${comment.commentDate ? moment(comment.commentDate).format(readableDateTimeFormat) : ''}`
}

/**  Returns the requested Enum String version from a string   */
export function getEnumFromString<T>(type: T, str: string): T[keyof T] {
    const casted = str as keyof T;
    return type[casted];
}

/**  Returns the mtt status as an enum value, this is usually only used in conjunction with  getEnumFromString  */
export const getMttEnumString = (mttStatus: string): string => {
    switch (mttStatus) {
        case 'Active':
        case 'active':
        case 'ActiveMtt':
            return 'ActiveMtt';
        case 'ActiveWComments':
        case 'activewcomments':
        case 'ActiveWCommentsMtt':
            return 'ActiveWCommentsMtt';
        case 'EligibleToExit':
        case 'eligibletoexit':
            return 'EligibleToExit';
        case 'Eligible':
        case 'eligible':
            return 'EligibleMtt';
        case 'Exit':
        case 'exit':
            return 'ExitedMtt';
        case 'ExitDropped':
        case 'exitdropped':
            return 'ExitedDroppedMtt';
        case 'NotActive':
        case 'notactive':
            return 'NotActvieMtt';
        case 'NotEligible':
        case 'noteligible':
            return 'NotEligible';
        case 'Skipped':
        case 'skipped':
            return 'SkippedMtt';
        case 'LoadingEligiblity':
        case 'loadingeligiblity':
            return 'LoadingEligiblity';
        default :
            return '';
    }
}


// Sort all incoming views by the specified sort method/order & apply appropriate secondary/tertiary sorting based on primary sort method
export const sortTutorViews = (tutorViews: Array<TutorView>, sortByType: SortByType, order: OrderByType): Array<TutorView> => {
    const sortByStudent = 'studentLastName';
    const sortByCourse = 'courseName';
    const sortByTeacher = 'teacherName';
    const primarySortMethod:string = (sortByType === SortByType.StudentName) ? sortByStudent : ((sortByType === SortByType.CourseName) ? sortByCourse : '');
    let secondarySortMethod: string = '';
    let tertiarySortMethod: string = '';
    let result = tutorViews;

    switch (sortByType) {
        case SortByType.StudentName:
            secondarySortMethod = sortByCourse;
            break;
        case SortByType.CourseName:
            secondarySortMethod = sortByTeacher;
            tertiarySortMethod = sortByStudent;
            break;
        default:
            // Do nothing
            break;
    }  
    switch (order) {
        case OrderByType.Ascending:
            result = tutorViews.sort((mttStudentA,mttStudentB) => {
                return (
                    mttStudentA[primarySortMethod] < mttStudentB[primarySortMethod] ? -1 : 
                    mttStudentA[primarySortMethod] > mttStudentB[primarySortMethod] ? 1 : 
                    
                    mttStudentA[secondarySortMethod] < mttStudentB[secondarySortMethod] ? -1 :
                    mttStudentA[secondarySortMethod] > mttStudentB[secondarySortMethod] ? 1 :
                    
                    !tertiarySortMethod ? 0 :
                    mttStudentA[tertiarySortMethod] < mttStudentB[tertiarySortMethod] ? -1 :
                    mttStudentA[tertiarySortMethod] > mttStudentB[tertiarySortMethod] ? 1 : 0
                );
            });    
            break;
        case OrderByType.Descending:
            result = tutorViews.sort((mttStudentA,mttStudentB) => {
                return (
                    mttStudentA[primarySortMethod] < mttStudentB[primarySortMethod] ? 1 : 
                    mttStudentA[primarySortMethod] > mttStudentB[primarySortMethod] ? -1 :
                    
                    mttStudentA[secondarySortMethod] < mttStudentB[secondarySortMethod] ? -1 :
                    mttStudentA[secondarySortMethod] > mttStudentB[secondarySortMethod] ? 1 :
                    
                    !tertiarySortMethod ? 0 :
                    mttStudentA[tertiarySortMethod] < mttStudentB[tertiarySortMethod] ? -1 :
                    mttStudentA[tertiarySortMethod] > mttStudentB[tertiarySortMethod] ? 1 : 0
                );
            });    
            break;
        default: 
        break;
    }
    return result;
}