import * as React from 'react';
import styled from "styled-components"
import { Row, Col } from '../layout/ResponsiveGrid';
import WarningIcon from '@mui/icons-material/Warning';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { roundNumber } from '../../utils/helpers/generalHelpers';

interface IncomingProps {
    assignmentList: Array<boolean>; 
}
 
const SvgRectangleBg = styled.rect`
    fill: ${({ theme: { colors } }) => colors.greens.xlight};
`;

interface SVGProps {
    isMissing?: boolean;
}
const SvgRectangle = styled.rect<SVGProps>`   
    fill: ${({ isMissing, theme: { colors } }) => isMissing ?  colors.gold.alert : colors.greens.xlight};
    stroke: ${({ theme: { colors } }) => colors.greens.xlight};
`;


const getWidthPercantage = (assignmentList: Array<boolean>): string => {
    const newWidth = roundNumber((100 / assignmentList.length), 2).toString();
    return newWidth;
}

const getXPosition = (index: number, width: string ): string => {
    const newXPostition =  (index * Number(width)).toString() + '%';
    return newXPostition;
}
 
const getIconType = (assignmentList: Array<boolean>): JSX.Element => {
    if(assignmentList.length === 0)
        return <></>;

    const containsMissing:boolean = assignmentList.includes(true);
    let iconType: any;
    switch(containsMissing) {
        case true:
            // Missing Assignment !!!                   
            iconType = <WarningIcon color='warning'/>;
            break;
        case false:
            // All Good :)            
            iconType = <CheckBoxIcon color='primary'/>;
            break;
        default:
            iconType = <></>;
        break;
    }
    return iconType;

}



const AssignmentBar = ({ ...props }: IncomingProps) => (
 
    <React.Fragment>
        <Row>
            <Col xs={10} style={{
                display:'flex',
                alignItems:'flex-start',
            }}>
                {props.assignmentList.length > 0 && (
                    <svg width='100%' height='20px' viewBox='0 0 600 20'>
                        <g className='bars'>
                            {/* Background Rect */}
                            <SvgRectangleBg width='100%' height='35'/> 
            
                            {/*Assignment Rect */}
                            {props.assignmentList.map((assignment, index) => {

                                    return(
                                        <SvgRectangle 
                                            key={index} 
                                            x={getXPosition(index,getWidthPercantage(props.assignmentList))} 
                                            height="35" width={getWidthPercantage(props.assignmentList) + '%'} 
                                            y="0"
                                            isMissing={assignment} 
                                        />
                                    ); 
                                }
                            )}

                        </g>
                    </svg>
                )}
            </Col>
            <Col xs={2} style={{textAlign:'center'}}>
                {getIconType(props.assignmentList)}
            </Col>
        </Row>
        
    </React.Fragment>
);

export default AssignmentBar;