import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import MessageIcon from '@mui/icons-material/Message';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { IMenuListItems } from '../../models/Menus';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import UndoIcon from '@mui/icons-material/Undo';
import { FeatureFlagState } from '../../store/featureflags/types';
import { connect, useSelector } from 'react-redux';
import FeatureGate from '../../FeatureGate';
import { globalSelector } from "../../store/global/globalReducer";
import { GradeStatus } from '../../models/Grade';
import Tooltip from '@mui/material/Tooltip';

export enum MenuLayout {
  ButtonModalLayout = `ButtonModalLayout`,
  ListLayout = `ListLayout`,
  ViewLayout = `ViewLayout`

}

interface IMenuDropDown {
  menuTitle: string;
  secondaryButtonText: string;
  menuId: string;
  menuItems?: IMenuListItems[];
  menuLayout: MenuLayout;
  buttonText?: string;
  disableButton?: boolean;
  updateGradeFunction?: (updatedWGScoreText: string, updatedWGScoreInitials: string, withComments: boolean) => void;
  viewWeeklyGrowthFunction?: () => void;
}

const EditWeeklyGrowthMenu = ({ menuTitle, buttonText, secondaryButtonText, disableButton, menuId, menuItems = [], menuLayout, updateGradeFunction, viewWeeklyGrowthFunction }: IMenuDropDown) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const { termIsEditable } = useSelector(globalSelector);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onUpdateWGScoreClick = (updatedScoreText: string, updatedScoreInitials: string, withComments: boolean): void => {
    if (updateGradeFunction)
      updateGradeFunction(updatedScoreText, updatedScoreInitials, withComments);

    closeMenu();
  };

  const viewWeeklyGrowthClick = (): void => {
    if (viewWeeklyGrowthFunction) {
      viewWeeklyGrowthFunction();
    }
    closeMenu();
  };


  const generateLayout = (layoutType: MenuLayout): JSX.Element => {
    switch (layoutType) {
      case MenuLayout.ButtonModalLayout:
        return (
          <>
            <Tooltip enterDelay={1000} title={`Assign student a weekly growth score`} >
              <span>
                <Button
                  variant="contained"
                  color='secondary'
                  style={{ color: '#212529' }}
                  sx={{ minWidth: '36px', minHeight: '36px' }}
                  id={`menu-button-${menuId}`}
                  aria-controls={open ? `menu-${menuId}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(event: any) =>
                    FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) && termIsEditable ?
                      openMenu(event) : viewWeeklyGrowthClick()
                  }
                  disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
                >
                  {buttonText ?
                    (buttonText === GradeStatus.LoadingGrade ? <CircularProgress size={19} color="inherit" /> : buttonText) :
                    (menuId === '0') ? <EditIcon /> : !termIsEditable ? <LaunchIcon color='info' fontSize="small" /> : ''
                  }
                </Button>
              </span>
            </Tooltip>
            <Menu id={`menu-${menuId}`}
              anchorEl={anchorEl}
              open={open}
              onClose={closeMenu}
              MenuListProps={{
                'aria-labelledby': `menu-button-${menuId}`,
              }}
              style={{ maxHeight: '580px' }}
            >
              <MenuList sx={{ width: '100%', maxWidth: 360, minWidth: 280, bgcolor: 'background.paper' }} dense>
                {menuTitle ? (<MenuItem disabled>{menuTitle}</MenuItem>) : null}
                {menuItems.length ? (

                  menuItems.map((item: IMenuListItems, index) => {
                    if (!item.primaryText || item.primaryText === '' || item.primaryIntials === '')
                      return;

                    // Dont Show Undo Button
                    if (item.primaryText && item.primaryText?.toLowerCase() !== 'undo')
                      return (
                        <ListItem
                          key={index}
                          secondaryAction={
                            <IconButton onClick={() => onUpdateWGScoreClick(item.primaryText, item.primaryIntials, true)} edge="end" aria-label="with comments">
                              <MessageIcon />
                            </IconButton>
                          }
                          disablePadding
                        >
                          <ListItemButton onClick={() => onUpdateWGScoreClick(item.primaryText, item.primaryIntials, false)}>
                            <ListItemText id={index.toString()} primary={`${item.primaryText}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                  })
                ) : (
                  <ListItem key={'1'} disabled>
                    <ListItemText id={'missing-options'} primary={`No options available`} />
                  </ListItem>
                )}

                {/* Secondary Menu Actions including Undo Final Grade and View Weekly Grade   */}
                {((secondaryButtonText || menuItems.length) && menuItems.some(item => item.primaryText.toLowerCase() === 'undo')) && (<Divider />)}

                {menuItems.length && menuItems.some(item => item.primaryText.toLowerCase() === 'undo') && (
                  <MenuItem onClick={() => onUpdateWGScoreClick('undo', 'undo', false)} style={{ marginTop: '15px' }}>
                    <Typography variant="body2" color="text.secondary" style={{ marginRight: '10px' }}>
                      <UndoIcon fontSize="small" />
                    </Typography>
                    Undo Final Grade
                  </MenuItem>
                )}

                {secondaryButtonText && (<Divider style={{ marginTop: '15px' }} />)}

                {secondaryButtonText && (
                    <MenuItem onClick={viewWeeklyGrowthClick} style={{ marginTop: '15px' }}>
                      <Typography variant="body2" color="text.secondary" style={{ marginRight: '10px' }}>
                        <LaunchIcon fontSize="small" />
                      </Typography>
                      {secondaryButtonText}
                    </MenuItem>
                )}

              </MenuList>
            </Menu>
          </>
        );
      case MenuLayout.ListLayout:
        return (
          <MenuList style={{ maxHeight: '580px' }} dense>
            {menuTitle ? (<MenuItem disabled>{menuTitle}</MenuItem>) : null}
            {menuItems.length ? (
              <div>
                {menuItems.map((item: IMenuListItems, index) => {
                  // Dont Show Undo Button
                  if (item.primaryText.toLowerCase() === 'undo')
                    return;

                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton onClick={() => onUpdateWGScoreClick(item.primaryText, item.primaryIntials, true)} edge="end" aria-label="with comments">
                          {item.secondaryIcon ? item.secondaryIcon : <MessageIcon />}
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton onClick={() => onUpdateWGScoreClick(item.primaryText, item.primaryIntials, false)}>
                        <ListItemText id={index.toString()} primary={`${item.primaryText}`} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}

                {menuItems.length && menuItems.some(item => item.primaryText.toLowerCase() === 'undo') && (
                  <MenuItem onClick={() => onUpdateWGScoreClick('undo', 'undo', false)} style={{ marginTop: '15px' }}>
                    <Typography variant="body2" color="text.secondary" style={{ marginRight: '10px' }}>
                      <UndoIcon fontSize="small" />
                    </Typography>
                    Undo Final Grade
                  </MenuItem>
                )}

              </div>
            ) : (
              <ListItem key={'1'}>
                <ListItemText id={'missing-options'} primary={`No options available`} />
              </ListItem>
            )}
          </MenuList>
        );
      case MenuLayout.ViewLayout:
        return (
          <Tooltip enterDelay={1000} title={`View student's weekly growth`} >
            <span>
              <Button
                variant="contained"
                color='secondary'
                style={{ color: '#212529' }}
                sx={{ minWidth: '36px', minHeight: '36px' }}
                id={`menu-button-${menuId}`}
                onClick={(event: any) => viewWeeklyGrowthClick()}
                disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
              >
                <LaunchIcon color='info' fontSize="small" />
              </Button>
            </span>
          </Tooltip>
        );
      default:
        return (<div></div>);
    }
  };

  return generateLayout(menuLayout);

};
export interface EditWeeklyGrowthStoreProps {
  features: FeatureFlagState;
}
function mapStateToProps(state: any): EditWeeklyGrowthStoreProps {
  return {
    features: state.featureFlags,
  };
}
export default connect<EditWeeklyGrowthStoreProps>(mapStateToProps)(EditWeeklyGrowthMenu);