import React from "react";
import { GradebookProps, GradebookState } from "./Gradebook";
import { PrintableCourseReportCard } from "./PrintableCourseReportCard";
import { Term } from "../models/Term";
import { getActiveAssignments, hasMissingActiveAssignments } from '../utils/helpers/generalHelpers';
import { IMttInterventions } from "../models/MandatoryTargetedTutoring";
import { GradebookEntry } from '../models/GradebookEntry';

export interface IPrintableRosterProps {
    gradebookProps: GradebookProps;
    gradebookState: GradebookState;
    studentIds: Array<number>;
    hideProjectedGrade:boolean;
    term?: Term;
    togglePrintableRoster: () => void;
}

export interface IPrintableRosterState {
    loaded: boolean;
    allInterventions: Array<IMttInterventions>;
}

export class PrintableRoster extends React.Component<IPrintableRosterProps, IPrintableRosterState> {
 
    state = {
        loaded: false,
        allInterventions: []
    }

    componentDidMount() {        
        // Note: in the future (https://dev.azure.com/StevensonIT/IRC/_workitems/edit/451), 
        // We'll want to nest this "setState" logic inside the "then" block of an async function 
        // similar to "GetAllInterventions()" on MttDetailsPanel.tsx, but instead of getting all the interventions for a single student, 
        // it should return the results for all students in the roster
        this.setState(prevState => ({ ...prevState,
            allInterventions: [],
            loaded: true
        }));
    }

    componentDidUpdate(prevProps: IPrintableRosterProps, prevState: IPrintableRosterState) {
        if (!prevState.loaded && this.state.loaded) {
            window.print();
        }
    }
 
    render() {   
        const modifiedGradebook: GradebookEntry[] = this.props.gradebookState.grades.map(gradebookItem => {
            const result: GradebookEntry =  {...gradebookItem, assessment: {...gradebookItem.assessment, standards: [...gradebookItem.assessment.standards]}}
            return result;
        });

        let filteredGradebook: GradebookEntry[] = modifiedGradebook;
        if (this.props.studentIds.length) {
            filteredGradebook = this.props.gradebookState.grades.filter(gbe => this.props.studentIds.some(sid => sid === gbe.student.personId));
        }      
        return (            
            <div key={`PrintableCourseReportCard`} style={{padding: '1rem 3.8rem' }}>
                <style>
                    {`@media print {
                        .MuiToolbar-root{display: none !important;}   
                        .navbar, .back-button {display: none;}                    
                        .studentReportCard {page-break-after: always;}
                        .reportCardRow {page-break-inside: avoid;} 
                    }`}
                </style>
                {filteredGradebook.map((gradebookEntry, gradebookEntryIndex) => {
                    const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(gradebookEntry));
                    return this.props.gradebookState.selectedTeacher ? (
                        <PrintableCourseReportCard
                            allInterventions={this.state.allInterventions.filter((i: IMttInterventions) => {
                                return i.sectionId === this.props.gradebookState.selectedCourse.sectionId;
                            })}
                            course={this.props.gradebookState.selectedCourse}               
                            isFinal={gradebookEntry.assessment.isVerified}
                            key={gradebookEntryIndex}
                            projectedGrade={gradebookEntry.assessment.projectedGrade}
                            hideProjectedGrade={this.props.hideProjectedGrade}
                            reportCardKey={gradebookEntryIndex}
                            showWarningIcon={hasMissingAssignment}
                            standards={gradebookEntry.assessment.standards}
                            student={gradebookEntry.student}
                            term={this.props.term}
                            togglePrintableCourseReportCard={this.props.togglePrintableRoster}
                            weeklyGrowth={gradebookEntry.assessment.weeklyGrowth}
                        />
                    ) : <span />;
                })}
            </div>
        );
    }
}