import React, { useEffect, useState } from 'react';
import { useSelector} from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { FilterableMenu } from '../inputs-elements/FilterableMenu';
import { globalSelector } from "../../store/global/globalReducer";
import { ToggleSwitch } from "components/inputs-elements/ToggleSwitch";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const defaultCommentDialogState = { isOpen: false, newScoreText: '', newScoreInitials: '' }
interface IDirectStudentData {
    currentFinalGrade: string,
    newFinalDirectGrade: string,
}

interface ICommentDialog {
    title?: string;
    titleButton?: JSX.Element;
    subheader?: string;
    helperText?:string;
    descriptionText?:string;
    commentPlaceholder?:string;
    commentValue?:string;
    submitBtnText:string;
    cancelBtnText?:string;
    isOpen:boolean;
    showCommentOptions?:boolean;
    isFinalCommentMode?:boolean;
    currentFinalGradeStatus?:boolean;
    directGradeStudentData?: IDirectStudentData;
    submitFunction: (commentText:string, finalGradeStatus?: boolean | undefined) => void;
    cancelFunction: () => void;
}

interface IDialogState {
    isDisabled: boolean,
    hasCorrectValue: boolean,
    commentTextValue:string,
    finalGradeStatus: boolean | undefined;
    finalGradeStatusChanged: boolean | undefined;
}
export const CommentDialog = ( {title, subheader,helperText, descriptionText, commentPlaceholder, commentValue, submitBtnText,cancelBtnText, isOpen,showCommentOptions, isFinalCommentMode, currentFinalGradeStatus, directGradeStudentData, submitFunction, cancelFunction, titleButton}:ICommentDialog) => {
    //#region Variables:
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [submitingInProcess, setSubmitingInProcess] = useState(false);
    const defaultDialogState:IDialogState = {
        isDisabled: true,
        hasCorrectValue:false,
        commentTextValue: '',
        finalGradeStatus:undefined,
        finalGradeStatusChanged :undefined

    }
    const [dialogState, setDialogState] = useState<IDialogState>(defaultDialogState);
    const { preDefinedCommentOptions } = useSelector(globalSelector);
    const regularStudentType:boolean = !directGradeStudentData || !directGradeStudentData.currentFinalGrade;
    //#endregion

    //#region Hooks:
    useEffect(() => {
        if(currentFinalGradeStatus !== undefined){
            setDialogState(prevState => ({
                ...prevState,
                finalGradeStatus: currentFinalGradeStatus
            }));  
        } 
    }, [currentFinalGradeStatus, dialogIsOpen]);

    useEffect(() => {
        if(isOpen){
            setDialogIsOpen(true);
        }else{
            setDialogIsOpen(false);
            setDialogState(defaultDialogState);
            setSubmitingInProcess(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if(isOpen){
            if(commentValue){
                setDialogState(prevState => ({
                    ...prevState,
                    commentTextValue: commentValue
                }));

            }
        }
    }, [isOpen,commentValue]);
    //#endregion

    //#region Functions:
    const handleClose = () => {
        cancelFunction()
        setDialogIsOpen(false);
    };

    const onSubmitBtn = (commentText:string):void => {
        setSubmitingInProcess(true);
        if(dialogState.finalGradeStatusChanged !== undefined){
            submitFunction(commentText, dialogState.finalGradeStatus);
        }else{
            submitFunction(commentText);
        } 
    };

    const onCommentTextAreaChange = (e) => {
        setDialogState(prevState => ({
            ...prevState,
            commentTextValue: e.target.value
        })); 
    };

    const onToggleSwitchChange = (newStatus:boolean) => {
        setDialogState(prevState => ({
            ...prevState,
            finalGradeStatus: newStatus,
            finalGradeStatusChanged: true
        })); 
    };
    //#endregion
 
    return (
      <Dialog open={dialogIsOpen} onClose={handleClose} maxWidth='md'>
        {/* Comment Title Header */}
        {isFinalCommentMode ? (
            <Grid container spacing={2}>
                <Grid item xs={regularStudentType ? 9 : 12}>
                    {title && (<>
                            <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>{title}</span>
                                {titleButton ? <div style={{ paddingLeft: '24px'}}>{titleButton}</div> : <></>}
                            </DialogTitle>
                            {descriptionText && (<p style={{padding:'0 0 0 24px'}}>{descriptionText}</p>)}                   
                        </>)}
                </Grid>

                {/* We only want to show the toggle options for regular students not direct grade students         */}
                {regularStudentType && (
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center',alignItems: 'center', flexDirection: 'column'}}>
                        <ToggleSwitch
                            currentStatus={dialogState.finalGradeStatus}
                            togglePrimaryFunction={(newStatus:boolean) => onToggleSwitchChange(newStatus)}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {`Toggle to ${dialogState.finalGradeStatus !== null && dialogState.finalGradeStatus !== undefined ? dialogState.finalGradeStatus ? 'Unverify' : 'Verify' : 'Update'}`}
                        </Typography>
                    </Grid>
                )}
                
            </Grid>
        ) : (
            title && (
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>{title}</span>
                    {titleButton ? <div style={{ paddingLeft: '24px'}}>{titleButton}</div> : <></>}
                </DialogTitle>
            )
        )}

        

        <DialogContent 
            sx={{ textAlign:'right',padding:'0px 24px 30px', width: '600px' }}
        >
            {subheader && (
                <DialogContentText>
                    {subheader}
                </DialogContentText>
            )}
            
            <TextField
                value={dialogState.commentTextValue ? dialogState.commentTextValue : ''}
                autoFocus
                multiline
                rows={3}
                margin="dense"
                id="commentTextField"
                label={commentPlaceholder ? commentPlaceholder : ''}
                type="text"
                fullWidth
                variant="outlined"
                onChange={onCommentTextAreaChange}
                sx={{ marginBottom:helperText ? '-66px' : '-44px'}}
                helperText={helperText ? helperText : ''}
            />
            {showCommentOptions ? (
                <FilterableMenu
                    menuId=''
                    menuItems={preDefinedCommentOptions}
                    filterBy='primaryIntials'
                    filterPlaceholder='Filter Comments by code'
                    onListItemClickFunction={(newText:string) => 
                        setDialogState(prevState => ({
                            ...prevState,
                            commentTextValue: `${(prevState.commentTextValue ? `${prevState.commentTextValue} | ` : '') + newText}`
                        }))
                    }
                />
            ) : <span>&nbsp;</span> }

        </DialogContent>
        <DialogActions sx={{ marginTop:'14px'}}>
            <Button size="large" variant='outlined' color='primary' onClick={() => cancelFunction()}>
                {cancelBtnText ? cancelBtnText : 'Cancel'}
            </Button>
            <>
                {submitingInProcess === false ? (
                    <Button 
                        onClick={() => { onSubmitBtn(dialogState.commentTextValue) }} 
                        size="large"
                        variant='contained'
                        disabled={dialogState.commentTextValue.length <= 0}
                    >
                        {submitBtnText ? submitBtnText : 'Submit'}
                    </Button>
                ) : (
                    <LoadingButton loading loadingPosition="start"startIcon={<SaveIcon />} variant="outlined">
                        {submitBtnText ? submitBtnText : 'Submit'}
                    </LoadingButton>
                )}
            </>
        </DialogActions>
      </Dialog>
  );
}