export interface TeacherStudentJson {
    courseName: string;
    endDate: Date;
    firstName: string;
    lastName: string;
    personId: number;
    sectionId: number;
    startDate: Date;
    isDropped: boolean;
    directGradeEligible: boolean;
    studentNumber: number;
    mttStatus: string;
}


export class TeacherStudent {

    public static fromJson = (teacherStudent: TeacherStudentJson) => {
        if (teacherStudent) {
            return new TeacherStudent(
                teacherStudent.personId,
                teacherStudent.sectionId,
                teacherStudent.startDate,
                teacherStudent.endDate,
                teacherStudent.firstName,
                teacherStudent.lastName,
                teacherStudent.courseName,
                teacherStudent.isDropped,
                teacherStudent.directGradeEligible,
                teacherStudent.studentNumber,
                teacherStudent.mttStatus,
            );
        }
        return new TeacherStudent();
    }

    constructor(
        public personId: number = -1,
        public sectionId: number = -1,
        public startDate: Date = new Date(),
        public endDate: Date = new Date(),
        public firstName: string = '',
        public lastName: string = '',
        public courseName: string = '',
        public isDropped: boolean = false,
        public directGradeEligible: boolean = true,
        public studentNumber: number = -1,
        public mttStatus: string = '',

    ) {}
}
 