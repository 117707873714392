import React, { useEffect, useState, useLayoutEffect  } from 'react';

import { SessionState } from '../store/session/types';
import { connect, useSelector } from 'react-redux';
import { Course } from '../models/Course';
import FeatureGate from '../FeatureGate';
import { SearchTeacherStudent, SearchType } from './inputs-elements/SearchTeacherStudent';
import { INewComment, IStudentInterventionEntry } from '../models/MandatoryTargetedTutoring';
import { Student } from '../models/Student';
import { Teacher } from '../models/Teacher';
import { GradebookEntry } from '../models/GradebookEntry';
import Drawer from '@mui/material/Drawer';
import { MttDetailsPanel } from './shared/MttDetailsPanel';
import Container from '@mui/material/Container';
import { ActionBar, ISelectableItem} from './inputs-elements/ActionBar';
import { MttButton } from './inputs-elements/MttButton';

import { MttStatus, MttParentView } from '../models/enums/Mtt';
import { FilterType, SortByType, ISortBy, OrderByType, IFilterOptions } from '../models/enums/Filter';
import { FeatureFlag, FeatureFlagState } from '../store/featureflags/types';
import { Redirect } from 'react-router';
import { SideBar, SideBarHeader } from './layout/SideBar';
import TabbedContentContainer, {TabContentProps}  from './layout/TabbedContentContainer';

import { defaultDateTimeFormat } from '../common/config';
import { notificationsSelector, NotificationsType, updateNotificationsAction } from "../store/notifications/notificationsReducer";
import { mandatoryTargetedTutoringSelector, updateMttStudentListAction, sortMttStudentListAction, resetMttStudentsAction, addMttStudentInterventionCommentAction } from "../store/student/mandatoryTargetedTutoringReducer";
import { updateTermIsEditableAction } from "../store/global/globalReducer";
import { addStudentInterventionComment, getStudentsInMtt } from '../api/interventionApi';
import { useDispatch} from "react-redux";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { StyledParagraph, StyledSpanContainer, StyledTableCell, StyledTableCellMain, StyledTableRow } from './layout/Tables';
import { TutorView } from '../models/TutorView';
import useDimensions from '../utils/useDimensions';
import { LoadingIcon } from '../utils/LoadingIcon';
import { convertToStudentList, convertToStudentObject, convertToTeacherList, getDatesTimes, getDivisionsList, sortTutorViews } from '../utils/helpers/mttHelpers';
import { MttColumnWidth } from '../models/enums/MttColumnWidth';
import { CommentDialog } from './layout/CommentDialog';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { pluralize } from '../utils/helpers/stringHelpers';
 

export enum FetchTypes{
  EBRGradebook = 'gradebook',
  TBGGradebook = 'gradebookTBG'
}

export interface GradebookStateProps {
  session: SessionState;   
  features: FeatureFlagState;
}     
 
export const MandatoryTargetedTutoring = ({GradebookStateProps}) => {
  //#region Constants
  // Retrieve Store / Selectors
  const dispatch = useDispatch();
  const session = (state) => state.session;
  const features = (state) => state.featureFlags;
  const activeSession = useSelector(session);
  const activeFeatures = useSelector(features);
  const { allNotifications } = useSelector(notificationsSelector);
  const { allMttStudents, newInterventionCommentAdded, sortingMttStudents } = useSelector(mandatoryTargetedTutoringSelector);

  // Default States
  const defaultStudent = { studentGradebook: new GradebookEntry(), course: new Course()}
  const [selectedStudent, setSelectedStudent] = useState<IStudentInterventionEntry>(defaultStudent);
  const [activeSearchedStudent, setActiveSearchedStudent] = useState<Student>(new Student());
  const [studentSearchList, setStudentSearchList] = useState<Student[]>([]);
  const [activeSearchedTeacher, setActiveSearchedTeacher] = useState<Teacher>(new Teacher());
  const [teacherSearchList, setTeacherSearchList] = useState<Teacher[]>([]);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [notificationState, setNotificationState] = useState({notificationStatus:null, notificationMessage:'', isNotificationVisible:false});
  const [sideBarOpen, setSideBarOpen] = React.useState(false);
  const [ mttRows, setMttRows] = React.useState<Array<TutorView>>([]);
  const { windowWidth } = useDimensions();
  const searchWidth = 64;
  const [maxCommentWidth, setMaxCommentWidth] = useState(windowWidth - searchWidth - MttColumnWidth.GeneralInformation - MttColumnWidth.WK - MttColumnWidth.ProjectedGrade - MttColumnWidth.MttStatus);
  const [selectableTutorViews, setSelectableTutorViews] = React.useState<Array<ISelectableItem<TutorView>>>([]);
  const [showMttCommentDialog, setShowMttCommentDialog] = useState(false);
  const [bulkCommentAttendance, setBulkCommentAttendance] = useState(false);
  const { currentDateISO } = getDatesTimes();
  const [selectionsDisabled, setSelectionsDisabled] = useState<boolean>(false);
  
  // Filter Default States 
  const [ mttRowsFilteredType, setMttRowsFilteredType] = React.useState<FilterType>(FilterType.Pagination);
  const defaultRowsPerPage = 25;
  const [paginateBy, setPaginateBy] = React.useState<number>(defaultRowsPerPage);
  const [currentPaginationStep, setCurrentPaginationStep] = useState<number>(0);
  const divisionsList:IFilterOptions[] = getDivisionsList(allMttStudents);
  const [activeDivisions, setActiveDivisions] = useState<string[]>([]);
  const [sortOrderBy, setSortOrderBy ] = React.useState<ISortBy>({sortBy:SortByType.StudentName, order:OrderByType.Descending});
  const [resultsNotification, setResultsNotification] = useState<string>("");
  //#endregion

  //#region Hooks
  useEffect(() => {
    // Currently MTT View is only showing Current Term Students
    // TODO: https://dev.azure.com/StevensonIT/IRC/_workitems/edit/916     
    dispatch(updateTermIsEditableAction(true));
  }, []);

  useEffect(() => { 
    if((activeSession.user && activeSession.isLoggedIn) && FeatureGate("CanAccessMtt", activeFeatures.featureFlags)) {
      PopulateMttData(activeSession.user.personId).then(r => {
        setDataIsLoading(false);
      });    
    } 
  }, [activeSession, activeFeatures.featureFlags]);

  useEffect(() => {    
    const mttHeading = document.getElementById('mttHeading');
    const combinedPadding = 40;
    if (mttHeading !== undefined && mttHeading !== null) {
      setMaxCommentWidth(mttHeading.offsetWidth - MttColumnWidth.MttStatus - combinedPadding);
    }
  }, [windowWidth, searchWidth]);

  useLayoutEffect(() => {
    // Update and Show Notifications
    if(allNotifications){
      setNotificationState({
        notificationStatus:allNotifications.notificationStatus,
        notificationMessage:allNotifications.notificationMessage,
        isNotificationVisible: true
      });
    }

  }, [allNotifications]);

    useLayoutEffect(() => {
        setMttRows(mttRowsFilteredType === FilterType.Pagination ? mttRows.slice(0, paginateBy) : mttRows);
        filterByPagination(currentPaginationStep,paginateBy);
    }, [paginateBy]);

  useLayoutEffect(() => {
    // Update components MttDataRows state based on FilterType if a new comment is added
    if(newInterventionCommentAdded){
      switch (mttRowsFilteredType) {
        case FilterType.SearchStudent:
          filterBySearch({value:{personId:activeSearchedStudent.personId}}, SearchType.Student);    
          break;
        case FilterType.SearchTeacher:
          filterBySearch({value:{personId:activeSearchedTeacher.personId}}, SearchType.Teacher);    
          break;
        case FilterType.Pagination:
          filterByPagination(currentPaginationStep,paginateBy); 
          break;
        case FilterType.Division:
          filterByDivision(activeDivisions); 
          break;
        default: 
          setMttRows(allMttStudents);
          break;
      }
      dispatch(resetMttStudentsAction());
    }
  }, [newInterventionCommentAdded]);

  useLayoutEffect(() => {
    // Update MttDataRows component state based on SORTING
    if(sortingMttStudents){
      switch (mttRowsFilteredType) {
        case FilterType.SearchStudent:
          filterBySearch({value:{personId:activeSearchedStudent.personId}}, SearchType.Student);    
          break;
        case FilterType.SearchTeacher:
          filterBySearch({value:{personId:activeSearchedTeacher.personId}}, SearchType.Teacher);    
          break;
        case FilterType.Pagination:
          filterByPagination(currentPaginationStep,paginateBy); 
          break;
        case FilterType.Division:
          filterByDivision(activeDivisions); 
          break;
        default: 
          setMttRows(allMttStudents);
          break;
      }
    }

  }, [allMttStudents,sortingMttStudents]);
  //#endregion

  //#region Funtions
  async function PopulateMttData(id: number) {
    const mttData = await getStudentsInMtt(id).then(response => {
      const results: Array<TutorView> = response.data.map(d => TutorView.fromJson(d));
      const sortedResults = results.sort((r1, r2) => {
        const name1 = `${r1.studentLastName}, ${r1.studentFirstName}`;
        const name2 = `${r2.studentLastName}, ${r2.studentFirstName}`;
        return name1.toUpperCase().localeCompare(name2.toUpperCase());
      });
      return sortedResults;
    }).catch(error => {
        dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error getting MTT Students'}));
    });

    if (mttData != null){
        const sortedData = sortTutorViews(mttData, SortByType.StudentName, OrderByType.Ascending);
        const newMttRows = mttRowsFilteredType === FilterType.Pagination ? sortedData.slice(0, paginateBy) : sortedData;
        setMttRows(newMttRows);
        setStudentSearchList(convertToStudentList(mttData));
        setTeacherSearchList(convertToTeacherList(mttData));
        setSelectableTutorViews(newMttRows.map(tv => getSelectableTutorView(tv)));

        // Save the MTT Students List in the store to be used when reseting the list after filtering is complete
        dispatch( updateMttStudentListAction(mttData));
    }    
  }
 
  const showMttPanel = async (mttRow: TutorView): Promise<void> => { 
    setSelectedStudent(defaultStudent); 
    setDrawerState(true);              
    const selectedMttEntry = TutorView.toStudentInterventionEntry(mttRow);
    setSelectedStudent(selectedMttEntry);
  }

  const searchTabContent = (featureFlags:FeatureFlag[]):TabContentProps[] => {
    let tabContent:TabContentProps[] = [];
    const hasMultipleTeachers:boolean = teacherSearchList && teacherSearchList.every((teacher:Teacher) => teacher.personId !== activeSession.user.personId);

    if(hasMultipleTeachers){
      tabContent = [
        {
            tabName: "Search Students",
            tabContent: 
              <SearchTeacherStudent
                hideTitle
                loading={dataIsLoading}
                searchType={SearchType.Student}
                selectedStudent={activeSearchedStudent}
                selectedStudentChanged={(selectedValue) => filterBySearch(selectedValue, SearchType.Student)}
                selectedTerm={0}
                studentList={studentSearchList}
                terms={[]}
            /> 
        },
        {
            tabName: "Search Teachers",
            tabContent: 
              <SearchTeacherStudent
                  hideTitle
                  loading={dataIsLoading}
                  searchType={SearchType.Teacher}
                  selectedTeacher={activeSearchedTeacher}
                  selectedTeacherChanged={(selectedValue) => filterBySearch(selectedValue, SearchType.Teacher)}
                  selectedTerm={0}
                  teacherList={teacherSearchList}
                  terms={[]}
              />
        }
      ]
    }else{
      tabContent = [
        {
          tabName: "Search Students",
          tabContent: 
            <SearchTeacherStudent
              hideTitle
              loading={dataIsLoading}
              searchType={SearchType.Student}
              selectedStudent={activeSearchedStudent}
              selectedStudentChanged={(selectedValue) => filterBySearch(selectedValue, SearchType.Student)}
              selectedTerm={0}
              studentList={studentSearchList}
              terms={[]}
          /> 
        },
      ]
    }
    return tabContent;
  }

  const filterBySearch = (selectedValue:any, searchType:SearchType):void => {
    let filteredDataRows:TutorView[] = [];
    const searchFieldCleared:boolean = selectedValue ? !selectedValue : true; 

    if(searchFieldCleared){
      // Reset MTT Student List based on Pagination Filter
      setActiveSearchedStudent(new Student());
      setActiveSearchedTeacher(new Teacher());
      setMttRowsFilteredType(FilterType.Pagination);
      filterByPagination(currentPaginationStep,paginateBy); 
    } else {
      // Filter MTT Student List based on selection searched
      switch (searchType) {
        case SearchType.Student:
            filteredDataRows = allMttStudents.filter(mttRow => mttRow.studentPersonId === selectedValue.value.personId);
            if(filteredDataRows.length){
                setActiveSearchedStudent(convertToStudentObject(filteredDataRows[0]));
                setMttRowsFilteredType(FilterType.SearchStudent);
                setMttRows(filteredDataRows);
                setSelectableTutorViews(filteredDataRows.map(tv => getSelectableTutorView(tv)));
            }
          break;
        case SearchType.Teacher:
            filteredDataRows = allMttStudents.filter(mttRow => mttRow.teacherPersonId === selectedValue.value.personId);
            if(filteredDataRows.length){
                const {...teacherSelected } = selectedValue.value;     
                setActiveSearchedTeacher(teacherSelected);
                setMttRowsFilteredType(FilterType.SearchTeacher);
                setMttRows(filteredDataRows);
                setSelectableTutorViews(filteredDataRows.map(tv => getSelectableTutorView(tv)));
            }
          break;
        default: 
          break;
      }   
    }

  }

  const filterByPagination = (currentPage: number, showPerPage:number):void => {
    let filteredDataRows:TutorView[] = [];
    filteredDataRows = allMttStudents.slice( currentPage * showPerPage, (currentPage + 1) * showPerPage);
    setMttRows(filteredDataRows);
    setSelectableTutorViews(filteredDataRows.map(tv => getSelectableTutorView(tv)));
    setCurrentPaginationStep(currentPage);
  };

  const filterByDivision = (selectedDivisions:string[]):void => {
    let filteredDataRows:TutorView[] = [];
 
    filteredDataRows = allMttStudents.filter((mttStudentRow:TutorView) => 
      selectedDivisions.map(division => division).includes(mttStudentRow.divisionName) || 
      selectedDivisions.map(division => division.toString()).includes(mttStudentRow.sstPersonID.toString())
    );
    
    if(filteredDataRows.length){
        setMttRowsFilteredType(FilterType.Division);
        setActiveDivisions(selectedDivisions);
        setMttRows(filteredDataRows);
        setSelectableTutorViews(filteredDataRows.map(tv => getSelectableTutorView(tv)));
        setResultsNotification("");
    // the following will only occur when the user has selected my students from the filter list but no students are in an active intervention.    
    }else if(selectedDivisions.includes("-1")){
        setMttRowsFilteredType(FilterType.Division);
        setActiveDivisions(selectedDivisions);
        setMttRows(filteredDataRows);
        setSelectableTutorViews(filteredDataRows.map(tv => getSelectableTutorView(tv)));
        setResultsNotification("None of your students are in an Active Intervention");
    }else{
        setMttRowsFilteredType(FilterType.Pagination);
        filterByPagination(currentPaginationStep,paginateBy);
        setResultsNotification(""); 
    }

  };

  const sortMttRows = (sortBy: SortByType):void => {

    dispatch(sortMttStudentListAction({sortBy:sortBy,order:sortOrderBy.order}));
    setSortOrderBy(prevState => (
      {
        sortBy:sortBy,
        order:(prevState.order === OrderByType.Descending) ? OrderByType.Ascending : OrderByType.Descending
      }
       
    ));

  };

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotificationState(prevState => ({
      ...prevState,
      isNotificationVisible: false
    }));
  };
  
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerState(newOpen);
  };

  const getSelectableTutorView = (tutorView: TutorView): ISelectableItem<TutorView> => {
    return {
        id: tutorView.interventionId.toString(),
        selected: false,
        item: tutorView,
    }
  }

  const updateInterventionSelection = (selections: Array<ISelectableItem<TutorView>>) => {
    let updatedSelectableTutorViews: Array<ISelectableItem<TutorView>> = []; 

    // Toggle All selections
    if (selectableTutorViews.length === selections.length) {
      // ALL entries are already selected, so deselect all
      if (selectableTutorViews.every(ie => ie.selected)) {
        updatedSelectableTutorViews = selectableTutorViews.map(ie => {
          ie.selected = false;
          return ie;
        });
      // Select all previously unselected entries
      } else {
        updatedSelectableTutorViews = selectableTutorViews.map(ie => {
          ie.selected = true;
          return ie;
        });
      }
    // Toggle Individual selection
    } else if (selections.length === 1) {
      updatedSelectableTutorViews = selectableTutorViews.map(ie => {
        if (ie.id === selections[0].id) {
          ie.selected = !ie.selected;
        }
        return ie;
      });
    } else {
      console.error('This type of selection is not currently supported');
    }
    setSelectableTutorViews(updatedSelectableTutorViews);
  }

  async function addBulkInterventionComments(newCommentText: string) {
    setSelectionsDisabled(true);
    const mttIds: Array<string> = selectableTutorViews.filter(stv => stv.selected).map(stv => stv.id); 
    /*
    const bulkComment: IBulkInterventionComment = {
            mttIds, 
            commentDate: currentDateISO,
            comments: newCommentText,
            attendance: bulkCommentAttendance,
            authorName: `${activeSession.user.lastName}, ${activeSession.user.firstName}`,
            authorPersonId: activeSession.user.personId,
            authorRelationshipType: activeSession.user.roles[0].toString()        
    };
    */
    // TODO: Create Bulk API call during https://dev.azure.com/StevensonIT/IRC/_workitems/edit/531
    // that aligns with the above data model

    // TODO: Replace the placeholder logic below (which triggers multiple api calls) in favor of 
    // leveraging a new api call once during https://dev.azure.com/StevensonIT/IRC/_workitems/edit/532
    const addInterventionCommentPromises: Array<Promise<any>> = [];
    for (let i = 0; i < mttIds.length; i++) {
        addInterventionCommentPromises.push(
            addStudentInterventionComment({
                interventionId: Number(mttIds[i]),
                commentDate: currentDateISO,
                personId: activeSession.user.personId,
                relationshipType: activeSession.user.roles[0].toString(),
                comments: newCommentText,
                attendance: bulkCommentAttendance,
            })
        )
    }
    
    Promise.all(addInterventionCommentPromises)
        .then(() => {
            const commentData: Array<INewComment> = selectableTutorViews.filter(stv => stv.selected).map((stv => {
                return {
                    studentId: stv.item.studentPersonId, 
                    newComment: {
                        comments: newCommentText,
                        commentAuthorName: `${activeSession.user.lastName}, ${activeSession.user.firstName}`,
                        personId:activeSession.user.personId,
                        commentDate:currentDateISO,
                        interventionId: stv.item.interventionId,
                        attendance: bulkCommentAttendance,
                    }
                }
            }));

            for (let i = 0; i < commentData.length; i++) {
                dispatch(addMttStudentInterventionCommentAction(commentData[i]));
            }
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Success, notificationMessage: 'Comments Updated'}));
            setSelectionsDisabled(false);
        })
        .catch(error => { 
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Updating Student Comments'}));            
            setSelectionsDisabled(false);
        });
  }
  //#endregion
  
  return !activeSession.isLoggedIn ? (<Redirect to="/login" />) : dataIsLoading ? <LoadingIcon text='Loading MTT Interventions, please wait ...'  /> : (
      <>
        <Box sx={{ display: 'flex',  }}>
              <SideBar variant="permanent" open={sideBarOpen}>
                <SideBarHeader>
                  <IconButton onClick={() => setSideBarOpen(false)}
                    sx={{
                      ...(!sideBarOpen && { display: 'none' }),
                    }}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="Open Mandatory Targeted Tutoring Panel"
                    onClick={() => setSideBarOpen(true)}
                    edge="start"
                    sx={{
                      ...(sideBarOpen && { display: 'none' }),
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </SideBarHeader>

                {/* SideBar Content     */}
                {sideBarOpen && (
                  <Container maxWidth="sm" disableGutters>
                    <TabbedContentContainer 
                        title={'Mandatory Targeted Tutoring Tabs'}
                        content={
                          searchTabContent(activeFeatures.featureFlags)
                        }
                        defaultTab={0}
                    />
                  </Container>
                )}
              </SideBar>
              <Box component="main" sx={{ flexGrow: 1, p: 0 }}>                
                <Table aria-label="Mandatory Targeted Tutoring Table Headings">
                <TableHead>
                    <TableRow>
                    <StyledTableCellMain width='576px' className={'show-border'}> General Information</StyledTableCellMain>
                    <StyledTableCellMain width='60px' className={'show-border'}>WK</StyledTableCellMain>
                    <StyledTableCellMain width='78px' className={'show-border'}>Projected Grade</StyledTableCellMain>
                    <StyledTableCellMain id={'mttHeading'}> MTT</StyledTableCellMain>
                    </TableRow>
                </TableHead>
                </Table>
                <ActionBar 
                    defaultRowsPerPage={defaultRowsPerPage} 
                    divisionFunction={(selectedDivisions) => filterByDivision(selectedDivisions)} 
                    divisions={divisionsList}
                    filterType={mttRowsFilteredType}
                    hideFilter={false} 
                    menuTitle='' 
                    paginationFunction={(paginationStep) => filterByPagination(paginationStep,paginateBy)} 
                    totalRowCount={allMttStudents.length}
                    filteredRowCount={mttRows.length} 
                    selectionItems={selectableTutorViews}
                    selectionLabel={`Interventions`}
                    selectionChange={(selections: Array<ISelectableItem<TutorView>>) => updateInterventionSelection(selections)}
                    selectionDisabled={selectionsDisabled}
                    selectionEditBtn={
                        <>
                            {selectableTutorViews.some(ie => ie.selected) && (
                                <>
                                    <Button
                                        variant="contained"
                                        color='secondary'
                                        style={{color:'#212529'}}
                                        sx={{minWidth:'36px',minHeight: '36px'}}
                                        aria-haspopup="true"
                                        onClick={(event:any) => setShowMttCommentDialog(true)}
                                        disabled={!selectableTutorViews.length}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <CommentDialog                                 
                                        title={`Add MTT Session Notes for ${selectableTutorViews.filter(ie => ie.selected).length} ${pluralize(`Student`, selectableTutorViews.filter(ie => ie.selected))}`}
                                        titleButton={
                                            <Tooltip title={`Mark student as attended`} arrow>
                                                <Checkbox 
                                                    style={{padding: '8px'}} 
                                                    inputProps={{ 'aria-label': 'Mark student as attended' }}
                                                    icon={<HowToRegOutlinedIcon style={{color: '#999999'}}/>} 
                                                    checkedIcon={<HowToRegIcon color='primary' />}
                                                    onChange={(event: any) => setBulkCommentAttendance(!bulkCommentAttendance)}
                                                    checked={bulkCommentAttendance} 
                                                />
                                            </Tooltip>
                                        }
                                        commentPlaceholder='Please add session notes'
                                        submitBtnText='Save'
                                        cancelBtnText='Cancel'
                                        isOpen={showMttCommentDialog}                                        
                                        cancelFunction={() => {
                                            setShowMttCommentDialog(false);
                                            setBulkCommentAttendance(false);
                                        }}
                                        submitFunction={(mttComment:string) =>  
                                            addBulkInterventionComments(mttComment)
                                        }
                                    />
                                </>
                            )}
                        </>
                    }
                    setCurrentPaginationStep={(val) => setCurrentPaginationStep(val)}
                    setPaginateBy={(val) => setPaginateBy(val)}
                    showPagination
                />

                {dataIsLoading ? 
                  (<LoadingIcon text="Loading MTT Students..."/>) 
                  : 
                  (<Table sx={{ minWidth: 650 }} aria-label="Mandatory Targeted Tutoring Table SubHeadings">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width='56px'>
                            &nbsp;
                          </StyledTableCell>
                          <StyledTableCell width='200px'>
                            {/* TECH DEBT: Create as seperate component and add to gradebook for consistiency https://dev.azure.com/StevensonIT/IRC/_workitems/edit/293  */}
                            <Tooltip title="Sort mtt students by students last name" enterDelay={500}>
                              <Button color='info' variant="outlined" onClick={() => sortMttRows(SortByType.StudentName)} endIcon={
                                (sortOrderBy.sortBy === SortByType.StudentName) && (
                                  (sortOrderBy.order === OrderByType.Descending) ?  
                                    <ArrowUpwardRoundedIcon fontSize='large' /> : <ArrowDownwardRoundedIcon  fontSize='large' />
                                )   
                              }>
                                Student Name 
                              </Button>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell width='300px' className={'show-border'}>
                            <Tooltip title="Sort mtt students by course name" enterDelay={500}>
                              <Button color='info' variant="outlined" onClick={() => sortMttRows(SortByType.CourseName)} endIcon={
                                (sortOrderBy.sortBy === SortByType.CourseName) && (
                                  (sortOrderBy.order === OrderByType.Descending) ?  
                                    <ArrowUpwardRoundedIcon fontSize='large' /> : <ArrowDownwardRoundedIcon  fontSize='large' />
                                )   
                              }>
                                Course &amp; Teacher 
                              </Button>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell width='60px' className={'show-border'}></StyledTableCell>
                          <StyledTableCell width='78px' className={'show-border'}></StyledTableCell>
                          <StyledTableCell>Latest Comments &amp; Attendance</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resultsNotification ? (<StyledTableRow key='Results Notification Key'><StyledTableCell colSpan={6}><h6 style={{textAlign:"center"}}>{resultsNotification}</h6></StyledTableCell></StyledTableRow>) : null}
                        {mttRows.map((mttRow, index) => {
                            const tutorViewId = mttRow.interventionId.toString();
                            const isChecked = selectableTutorViews.some(ie => ie.selected && ie.id === tutorViewId);
                            return (
                                <StyledTableRow key={index + `-${mttRow.studentPersonId}`}>                            
                                    <StyledTableCell width='56px'>
                                        <Checkbox 
                                            checked={isChecked}
                                            onChange={(e) => {
                                                updateInterventionSelection([{ id: tutorViewId, selected: isChecked, item: mttRow }]);
                                            }}
                                            disabled={selectionsDisabled}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> 
                                    <MttButton 
                                        menuPrimaryFunction={async () => {
                                        showMttPanel(mttRow);
                                        }}
                                        buttonTitle={`${mttRow.studentLastName}, ${mttRow.studentFirstName}`}
                                        buttonText={`${mttRow.studentLastName}, ${mttRow.studentFirstName}`}
                                        mttStatus={MttStatus.ActiveMtt} 
                                    />
                                    </StyledTableCell>
                                    <StyledTableCell className={'show-border'}>
                                    <StyledParagraph paragraph={true} className={'course-info'}>{mttRow.courseName}</StyledParagraph>
                                    <StyledSpanContainer paragraph={true}>
                                        <span>{mttRow.teacherName}</span>
                                        <span>{mttRow.coursePeriod} {mttRow.courseCode}</span>
                                    </StyledSpanContainer>                          
                                    </StyledTableCell>
                                    <StyledTableCell className={'show-border'}>{mttRow.weeklyGrowth}</StyledTableCell>
                                    <StyledTableCell className={'show-border'}>{mttRow.projectedGrade}</StyledTableCell>
                                    <StyledTableCell>
                                    <StyledParagraph paragraph={true} maxWidth={`${maxCommentWidth}px`}>{mttRow.latestComment}</StyledParagraph>
                                    <StyledSpanContainer paragraph={true}>
                                        <span>{mttRow.commenterName}</span>
                                        <span>{mttRow.latestCommentDate ? `${mttRow.latestCommentDate.format(defaultDateTimeFormat)}` : ''}</span>
                                    </StyledSpanContainer>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}</TableBody>
                    </Table>
                  )
                }    

              </Box>
            </Box>
          {/* ================= DRAWER COMPONENT ======================= */}
            <Drawer
              anchor={'right'}
              open={drawerState}
              onClose={toggleDrawer(false)}
            >
              <Container maxWidth="lg" sx={{ minWidth: '560px', height:'100vh', width: '560px'}} disableGutters>
                  <MttDetailsPanel 
                    course={selectedStudent.course}
                    currentUser={activeSession.user}
                    onCompletion={toggleDrawer(false)}
                    selectedStudentGradebook={selectedStudent.studentGradebook}
                    parentView={MttParentView.MandatoryTargetedTutoringView}
                    featureFlags={activeFeatures.featureFlags}
                  /> 
              </Container>
          </Drawer>

          {/* ===== Global Notifications =====*/}
          <Snackbar open={notificationState.isNotificationVisible} autoHideDuration={8000} onClose={handleNotificationClose}>
            <Alert severity={
              notificationState.notificationStatus === NotificationsType.Success ? 'success' : (
                notificationState.notificationStatus === NotificationsType.Error ? 'error' : 'warning'   
              )
            } 
            sx={{ width: '100%' }} variant="filled" >
              {notificationState.notificationMessage}
            </Alert>
          </Snackbar>
      </>
  );
  
}

function mapStateToProps(state: any): GradebookStateProps{
  return {
    session: state.session,
    features: state.featureFlags,
  };
}
export default connect<GradebookStateProps>(mapStateToProps)(MandatoryTargetedTutoring);