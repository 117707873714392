export const SET_FEATURE_FLAGS : string = 'SET_FEATURE_FLAGS';
export const CLEAR_FEATURE_FLAGS : string = 'CLEAR_FEATURE_FLAGS';
export const REMOVE_FEATURE_FLAG : string = 'REMOVE_FEATURE_FLAG';
export const ADD_FEATURE_FLAG : string = 'ADD_FEATURE_FLAG';
export const SET_INITIALIZED : string = 'SET_INTIALIZED';

export interface FeatureFlag {
    key: string;
    value: boolean;
    valueString:string;
}

export interface FeatureFlagState{
    featureFlags: FeatureFlag[],
    isInitialized: boolean
}

interface SetFlagsAction {
    type: typeof SET_FEATURE_FLAGS;
    payload: FeatureFlag[];
}

interface ClearFlagsAction {
    type: typeof CLEAR_FEATURE_FLAGS;
    payload: any;
}
interface AddFlagAction {
    type: typeof ADD_FEATURE_FLAG;
    payload: FeatureFlag;
}

interface RemoveFlagAction {
    type: typeof REMOVE_FEATURE_FLAG;
    payload: FeatureFlag;
}

interface SetInitializedAction {
    type: typeof SET_INITIALIZED;
    payload: boolean;
}
export type FeatureFlagActionTypes = SetFlagsAction | ClearFlagsAction | AddFlagAction | RemoveFlagAction | SetInitializedAction;