import styled from "styled-components"

interface RowProps {
  fullHeight?: boolean;
  borderBottom?: boolean;
  noWrap?: boolean;
}

interface ColProps {
  xs?: number;
  md?: number;
  minWidth?: string;
  padding?:string;
  displayFlex?: boolean;
  flexGrow?: boolean;
  filled?: boolean;
  border?: boolean;
  overFlow?: boolean;
  alignRight?: boolean;
  paddingMd?: boolean;
  paddingLgY?: boolean;
  paddingLgRight?: boolean;
}

export const Row = styled.div<RowProps>`
  display: flex; width: 100%;  
  ${({ noWrap }) => noWrap ?  'flex-wrap:nowrap' : 'flex-wrap:wrap' };
 
  box-sizing:border-box;justify-content: flex-start;margin: 0 auto;
  ${ props => props.fullHeight ? 'height:100%' : ''};
  ${({ borderBottom, theme: { colors } }) => borderBottom ?  'border-bottom:1px solid ' + colors.greys.xxlight : 'none' };  
`; 
 
export const Col = styled.div<ColProps>`

  ${ props => props.xs && `flex-basis: ${(props.xs / 12) *  100 + '%'};max-width: ${(props.xs / 12) *  100 + '%'};` };
  @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
    ${ props => props.xs && `flex-basis: ${(props.xs / 12) *  100 + '%'};max-width: ${(props.xs / 12) *  100 + '%'};` };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.tablet} {
    ${ props => props.md && `flex-basis: ${(props.md / 12) *  100 + '%'};max-width: ${(props.md / 12) *  100 + '%'};` };
  }

  position: relative;
  border-right: ${props => props.border ? '1px solid #ccc' : 'none'}; 
  padding: ${ props => props.padding ? props.padding : '0px'}; 
  display: ${ props => props.displayFlex ? 'flex' : 'initial'}; 
  align-items: ${ props => props.displayFlex ? 'center' : 'normal'};
  ${ props => props.overFlow ? 'overflow:hidden;overflow-x:scroll;' : ''};
  text-align: ${({ alignRight }) => alignRight ?  'right' :  'left' };
  ${({ paddingMd }) => paddingMd ?  'padding:8px' : null };
  ${({ paddingLgY }) => paddingLgY ?  'padding:8px 30px 8px' : null };
  ${({ paddingLgRight }) => paddingLgRight ?  'padding:8px 0px 8px 30px' : null };

  ${({ minWidth }) => minWidth ?  'min-width:' + minWidth : null };
  ${({ flexGrow }) => flexGrow ?  'flex-grow:1' : null };
`;