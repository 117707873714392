import React, { Component } from 'react';
import { SessionState, Authentication, User, UserRole } from '../store/session/types';
import { connect } from 'react-redux';
import { initLogin } from '../store/session/actions';
import { FeatureFlagState } from '../store/featureflags/types';
import {LoadingIcon} from '../utils/LoadingIcon';
import { Redirect } from 'react-router-dom';
import FeatureGate from '../FeatureGate';

import { Row, Col } from './layout/ResponsiveGrid';
type HomeProps = {

}

interface StateProps {
  session: SessionState;
  flags: FeatureFlagState;
}
     
interface DispatchProps {
  login: Function;
}

type Props = StateProps & DispatchProps & HomeProps;

class Home extends Component<Props, any> {
  static displayName = Home.name;

  render () {    
    const loggedIn: boolean = this.props.session.token.authorized;
    const user: User = this.props.session.user;
    const shouldShowDevHeader: boolean = FeatureGate("IsDev", this.props.flags.featureFlags);

    if(this.props.flags.isInitialized){   
      const  userRoles = (this.props.session.user && this.props.session.user.roles && this.props.session.user.roles.length) ? this.props.session.user.roles : [];
        if (userRoles.some(r => ((r === UserRole.Teacher) || (r === UserRole.Admin) || (r === UserRole.TeacherTutor) || (r === UserRole.Director) || (r === UserRole.SpecialEd)  ))) {
        return <Redirect to="/gradebook" />;
      } else if ( userRoles.some(r => (r === UserRole.Tutor) || (r === UserRole.GuidedStudy)) ) {
        return <Redirect to="/mandatory-targeted-tutoring" />;
      } else if (userRoles.some(r => ((r === UserRole.Parent) || (r === UserRole.Student) || (r === UserRole.SST) || (r === UserRole.ViewAllReportCards) ))) {
        return <Redirect to="/reportcard" />;
      } 
      return (
        <Row fullHeight style={{justifyContent:'center', padding:'20px'}}>
          <Col xs={12} md={6} >
            <h1>Welcome to Interactive Report Card Portal   </h1>
            {renderSubHeader(loggedIn)}
          </Col>
        </Row>
      ); 
    } else {
      return <LoadingIcon/>;
    }
  }
}

const renderSubHeader = (loggedIn: boolean) => {
  return loggedIn ? <p></p> : <p> Please log in <a href="/login">here</a>.</p>;
}
 
function mapStateToProps(state: any, ownProps: HomeProps): StateProps{
  return {
    session: state.session,
    flags: state.featureFlags
  };
}

function mapDispatchToProps(dispatch: any, ownProps: HomeProps): DispatchProps{
  return{
    login: (auth: Authentication) => dispatch(initLogin(auth))
  };
}

export default connect<StateProps, DispatchProps, HomeProps>(mapStateToProps, mapDispatchToProps)(Home);
