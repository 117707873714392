
import { AxiosResponse } from 'axios';
import { IWeekOptions } from 'models/Menus';
import { http } from 'utils/helpers/apiHelpers'

/**  Request to get a list of current weeks for the term */
export const getWeeksTerm = async (termId:number = 0): Promise<AxiosResponse<IWeekOptions[]>> => {
    return await http.get<any>(`schedule/weeks/${termId}`); 
};

/**  Request to get the current week*/
export const getCurrentWeekDate = async (termId:number = 0): Promise<any> => {
    return await http.get<any>(`schedule/CurrentWeek/${termId}`); 
};
