export default interface Grade {
    id: string;
    subject: string;
    studentId: string;
    teacherId: string;
    gradeValue: string;
}

export enum GradeStatus {
    LoadingGrade = `LoadingGrade`,
}

export enum SkillStatus {
  LoadingSkill = -1,
}