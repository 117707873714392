import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICalendarAction } from 'models/CalendarAction';
import { IMenuListItems } from 'models/Menus';
import { IWeekDate } from 'models/WeekDate';
import { WeekType } from 'models/WeeklyGrowth';

export interface IGlobalState {
    weeklyGradeOptions: IMenuListItems[];
    preDefinedCommentOptions: IMenuListItems[];
    currentTermId: number;
    currentWeekTask: string;
    currentWeekTaskId: number;
    currentWeekHideProjectedGrade: boolean;
    sendMTTEmails:boolean;
    skillOverridesAllowed:boolean;
    gradeMode:number;
    termIsEditable: boolean;
    calendarId: number;
    /** Will be true if the Current Semester Week ID that is active is considered a final week or
     * if the user manually sets a final week mode
     * 
    */
    isFinalWeekMode:boolean;
    /** What type of week is being viewed, regarless of Final week mode. This is determined by the Weekly Growth Column Dropdown selected week */
    viewingWeekType:WeekType;
    userTypes: string[];
}

export const initialState:IGlobalState = {
  weeklyGradeOptions: [],
  preDefinedCommentOptions: [],
  currentTermId:-1,
  currentWeekTask: '',
  currentWeekTaskId: -1,
  currentWeekHideProjectedGrade: true,
  sendMTTEmails: false,
  skillOverridesAllowed:false,
  gradeMode:0,
  termIsEditable:false,
  calendarId: -1,
  isFinalWeekMode:false,
  viewingWeekType: WeekType.CurrentWeek,
  userTypes:[]
};

const globalSlice = createSlice({
  name: `global`,
  initialState,
  reducers: {
    /** Reducer Action to update weekly grade options */ 
    updateWeeklyGradeOptionsAction: (state, action: PayloadAction<any[]>) => {
      state.weeklyGradeOptions = action.payload;
    },
    /** Reducer Action to update the active current week */ 
    updateCurrentWeekDateAction: (state, action: PayloadAction<IWeekDate>) => {
      const { taskId, task, termId } = action.payload
      state.currentWeekTask = task;
      // state.currentWeekHideProjectedGrade = hideProjectedGrade !== undefined ? hideProjectedGrade : true;
      state.currentWeekTaskId = taskId;
      state.currentTermId = termId ? termId : -1;
    },

    /** Reducer Action to update the Current week calendar actions */
    updateCalendarAction: (state, action: PayloadAction<ICalendarAction>) => {
        const { taskId, hideProjectedGrade, termId, skillOverridesAllowed, calendarId, gradeMode } = action.payload
      state.currentWeekHideProjectedGrade = hideProjectedGrade !== undefined ? hideProjectedGrade : true;
      state.currentTermId = termId;
      state.calendarId = calendarId;
      state.skillOverridesAllowed = skillOverridesAllowed;
      state.gradeMode = gradeMode;
    },
 
    /** Reducer Action to update weekly grade comment options */ 
    updateWeeklyGradeCommentOptionsAction: (state, action: PayloadAction<IMenuListItems[]>) => {
      state.preDefinedCommentOptions = action.payload;
    },
    updateTermIsEditableAction: (state, action: PayloadAction<boolean>) => {
      state.termIsEditable = action.payload;
    },
    /** Reducer Action to updated the global is final week mode */ 
    updateIsFinalWeekModeAction: (state, action: PayloadAction<boolean>) => {
      state.isFinalWeekMode = action.payload;
    },
    /** Reducer Action to updated the global is final week mode */ 
    updateUserTypesAction: (state, action: PayloadAction<string[]>) => {
      // const adminFlag:boolean = filteredFlags.includes(FeatureFlagType.admin);
      state.userTypes = action.payload;
    },
    updateViewingWeekTypeAction: (state, action: PayloadAction<WeekType>) => {
      state.viewingWeekType = action.payload;
    },

  },
});

export const { 
  updateWeeklyGradeOptionsAction,
  updateWeeklyGradeCommentOptionsAction,
  updateCurrentWeekDateAction,
  updateCalendarAction,
  updateTermIsEditableAction,
  updateIsFinalWeekModeAction,
  updateUserTypesAction,
  updateViewingWeekTypeAction
} = globalSlice.actions;
export const globalSelector = state => state.global;
export default globalSlice.reducer;