import React, { useEffect, useState, useRef  } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { MttStatus } from '../../models/enums/Mtt';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import Tooltip from '@mui/material/Tooltip';
import { User } from '../../store/session/types';
import { AttendanceIndicator } from '../shared/AttendanceIndicator';

interface IMttCommentCard {
    title?: string;
    subheader?: string;
    commentTitle?:string;
    commentText?:string;
    currentUser?:User;
    skillSelect?:boolean;
    skillsSelectItems?: Array<string>;
    isCommentReadOnly?:boolean;
    isActiveSessionComment?:boolean;
    hasAttended?:boolean;
    mttStatus:MttStatus;
    dense?:boolean;
    saveFunction?: (commentText:string,skills?:string[],hasAttended?:boolean) => void;
    updateOnChangeFunction?: (commentText:string,skills?:string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const CommentTextArea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    borderWidth:'1px',
    borderStyle:'solid',
    minHeight: '80px !important',
    borderColor:theme.palette.neutral.light,
    "&.isFocused, &:focus-visible": {
        borderColor:theme.palette.blueGrey.main,
    },
}));

export const MttCommentCard = ( {title, subheader, commentTitle,commentText, currentUser, skillSelect, skillsSelectItems,isCommentReadOnly,isActiveSessionComment,hasAttended, mttStatus, dense, saveFunction, updateOnChangeFunction}:IMttCommentCard) => {
    const [skills, setSkills] = React.useState<string[]>([]);
    const [submitingInProcess, setSubmitingInProcess] = useState(false);
    const [isReadyForSubmission, setIsReadyForSubmission] = useState(false);
    const commentTextAreaRef = useRef<HTMLTextAreaElement>(null);
    
    const [commentState, setcommentState] = React.useState({
        isFocused: false,
        hasCorrectValue:false,
        commentTextValue: '',
        hasAttended:true
    });

    useEffect(() => {
        if(commentTextAreaRef.current && (commentState.isFocused === true)){
            commentTextAreaRef.current.focus();
        }
        if(commentState.commentTextValue.length || skills.length){
            setIsReadyForSubmission(true);
        }else{
            setIsReadyForSubmission(false);
        }
    }, [commentState,commentTextAreaRef.current,skills]);
 
    const handleSkillSelect = (event: SelectChangeEvent<typeof skills>) => {
        const {
            target: { value },
        } = event;
        setSkills( typeof value === 'string' ? value.split(',') : value, );

        if(updateOnChangeFunction){
            updateOnChangeFunction(commentState.commentTextValue, typeof value === 'string' ? value.split(',') : value);
        } 
    };

    const onCommentSubmitBtn = (commentText:string, skills?:string[], hasAttended?:boolean):void => {
        setSubmitingInProcess(true);
        if(saveFunction){
            skills ? saveFunction(commentText,skills) : saveFunction(commentText,undefined,hasAttended) 
        }       
    };

    const textAreaFocus = () => {
        if(commentTextAreaRef.current){
            setcommentState(prevState => ({
                ...prevState,
                isFocused: true
            }));
        }
    };

    const onAttendanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setcommentState(prevState => ({
            ...prevState,
            hasAttended: event.target.checked
        }));
    };


    const onCommentTextAreaChange = (e) => {
        setcommentState(prevState => ({
            ...prevState,
            commentTextValue: e.target.value
        }));
        
        if(updateOnChangeFunction){
            skills ? updateOnChangeFunction(e.target.value,skills) : updateOnChangeFunction(e.target.value,undefined)
        } 
    };
    
    return (
        <Card sx={{  width: '100%' }}>

            {/* Editable Comment Header */}        
            {commentState.isFocused && isActiveSessionComment && (
                <CardHeader
                    action={
                        <Tooltip title={`Mark student as attended`} arrow>
                            <Checkbox 
                                style={{padding: '8px'}} 
                                inputProps={{ 'aria-label': 'Mark student as attended' }}
                                icon={<HowToRegOutlinedIcon style={{color: '#999999'}}/>} 
                                checkedIcon={<HowToRegIcon color='primary' />}
                                onChange={onAttendanceChange}
                                checked={commentState.hasAttended} 
                            />
                        </Tooltip>
                        
                    }
                    style={{padding: '10px 16px 0px'}}
                /> 
            )}      

            {/* Read Only Comment Header */}
            {(title || subheader) && (
                <CardHeader
                    title={title ? title : ''}
                    titleTypographyProps={{ fontSize: isActiveSessionComment ? '.8rem !important;' : '1.1rem'}}
                    subheader={subheader ? subheader : ''}
                    action={isActiveSessionComment ? <AttendanceIndicator attended={hasAttended ? true : false} /> : null}
                    sx={{
                        padding: dense ? '8px 16px 8px' : '13px 16px 10px',
                        '.MuiCardHeader-root': {
                            padding: dense ? '8px 16px 8px' : '13px 16px 10px'
                        },
                        '.MuiCardHeader-content': {
                            justifyContent: isActiveSessionComment ? 'space-between' : '',
                            display: isActiveSessionComment ? 'flex' : '', 
                        }
                    }}
                /> 
            )} 
            
            {(skillSelect && skillsSelectItems) && (
                <CardContent sx={{ 
                        backgroundColor: 'neutral.main',
                        padding: dense ? '8px 16px 8px' : '13px 16px 10px',
                        '.MuiCardContent-root': {
                            padding: dense ? '8px 16px 8px' : '13px 16px 10px'
                        }, 
                    }}>
                    <FormControl  sx={{ m: 1, width: '100%', margin:0, maxWidth: '420px' }}>
                        <InputLabel  sx={{ fontSize:'.9rem', top:'-6px' }} id="skill-select-label">Select Skill</InputLabel>
                        <Select
                            labelId="skill-select-label"
                            id="skill-select"
                            multiple
                            value={skills}
                            onChange={handleSkillSelect}
                            input={<OutlinedInput label="Selected Skill(s)" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            sx={{ width: '100%' }}
                            autoWidth={false}
                        >
                            {skillsSelectItems.map((skill) => (
                                <MenuItem key={skill} value={skill}>
                                    <Checkbox checked={skills.indexOf(skill) > -1} />
                                    <ListItemText secondary={skill} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
            )}

            {(commentText && isCommentReadOnly) && (
                <CardContent sx={{ backgroundColor: 'neutral.main' }}>
                    <p style={{margin:'0'}}>
                        {commentText}
                    </p>
                </CardContent>
            )}
            
            {/* Only Show Actions, Save and Text Area if Comments are editable */}
            {!isCommentReadOnly && (
                <>
                    <CardContent sx={{ position: 'relative', padding: '10px 16px 16px' }}>
                        <CommentTextArea
                            id="outlined-multiline-flexible"
                            aria-label={commentTitle ? commentTitle : ''}
                            minRows={1}
                            placeholder={commentTitle ? commentTitle : ''}
                            ref={commentTextAreaRef}
                            className={commentState.isFocused ?  'isFocused' : ''}
                            onChange={onCommentTextAreaChange}
                            onFocus={textAreaFocus}
                            sx={{
                                minHeight: `${dense ? '60' : '80'}px !important;`,
                            }}
                        />
                    </CardContent>
                    <CardActions 
                        disableSpacing 
                        style={{ justifyContent: 'flex-end' }} 
                        sx={{ display: (isReadyForSubmission && saveFunction) ? 'flex' : 'none'}}

                    >
                        {mttStatus === MttStatus.EligibleMtt || mttStatus === MttStatus.NotActvieMtt ? (
                            <>
                                {submitingInProcess === false ? (
                                    <Button 
                                        onClick={() => { onCommentSubmitBtn(commentState.commentTextValue,skills) }} 
                                        size="large"
                                        variant='contained'
                                        disabled={!isReadyForSubmission}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <LoadingButton loading loadingPosition="start"startIcon={<SaveIcon />} variant="outlined">
                                        Save
                                    </LoadingButton>
                                )}
                            </>
                        ):(<>
                                {!submitingInProcess ? (
                                    <Button 
                                        size="large" 
                                        onClick={() => { onCommentSubmitBtn(commentState.commentTextValue,undefined,commentState.hasAttended) } }
                                    >
                                        Send
                                    </Button>
                                ) : (
                                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                        Send

                                    </LoadingButton>
                                )}
                        </>)}
                    </CardActions>
                </>
            )}
            
        </Card>
    );
  };