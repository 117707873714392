import React, { useEffect, useState } from 'react';
import './Shared.css';
import { GradebookEntry, IStudentGradeBook } from '../../models/GradebookEntry';
import { INewComment } from '../../models/MandatoryTargetedTutoring';
import { MttStatus, MttParentView} from '../../models/enums/Mtt';

import {Course} from '../../models/Course';
import { getDatesTimes } from "../../utils/helpers/mttHelpers";
import Container from '@mui/material/Container';
import { PanelTitleTypes, PanelFlyoutHeader } from '../layout/PanelTitleBar';
import { MttCommentCard } from '../inputs-elements/MttCommentCard';

import { useDispatch, useSelector} from "react-redux";

import { updateNotificationsAction, NotificationsType } from "../../store/notifications/notificationsReducer";
import { enrollOrSkipInterventionAction} from "../../store/student/gradebookReducer";
 
import { IInterventionApi, IEnrollMandatoryTargetedTutoringAction } from "../../models/MandatoryTargetedTutoring";

import { enrollOrSkipIntervention } from '../../api/interventionApi';

import { SupervisedUserCircle } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';

import FeatureGate from '../../FeatureGate';
import { FeatureFlag } from '../../store/featureflags/types';
import { globalSelector } from "../../store/global/globalReducer";
import { ISelectableItem } from '../inputs-elements/ActionBar';
 

interface IMttDetails {
    studentGradebooks: GradebookEntry[];
    course:Course;
    onCompletion: () => void;
    addCommentToMttView?: (newData: INewComment) => void;
    parentView: MttParentView;
    featureFlags:FeatureFlag[];
    selectedStudents:  Array<ISelectableItem<IStudentGradeBook>>;
}


export const MttBulkDetailsPanel = ( { studentGradebooks, course, onCompletion, featureFlags, selectedStudents }:IMttDetails) => {

    // Default States
    const dispatch = useDispatch();
    const [readyForSubmission, setReadyForSubmission] = useState(false);
    const [submitingInProcess, setSubmitingInProcess] = useState(false);
    const [eligibleMttStudents, setEligibleMttStudents] = useState<Array<IInterventionApi>>([]);
    const { termIsEditable } = useSelector(globalSelector);
    const teacherName:string = course.teacherName !== null ? course.teacherName : course.teacherDisplay !== null ? course.teacherDisplay : '';
    const skillsList: Array<string> = studentGradebooks ? studentGradebooks[0].assessment.standards.filter((standardItem) => {
        const result = standardItem.assignments.some((assignment) => {
            return assignment.isHomework === false;
        });
        return result;
    }).map(element => element.standardName) : [];
    const { startWeek,startWeekDate, startDate } = getDatesTimes();

    // Effects
    // Create a list of eligible Students from selected items
    useEffect(() => {   
        const eligibleStudentsArray: IInterventionApi[] = [];
        selectedStudents.forEach((item:ISelectableItem<IStudentGradeBook>, index)=>{
            if(
                item.item.currentMTTStatus === MttStatus.EligibleMtt ||
                item.item.currentMTTStatus === MttStatus.ExitedMtt || 
                item.item.currentMTTStatus === MttStatus.NotActvieMtt || 
                item.item.currentMTTStatus === MttStatus.SkippedMtt
            ){
                eligibleStudentsArray.push({
                   
                    interventionId:0,
                    studentPersonId:item.item.studentPersonId, 
                    sectionId:course.sectionId,
                    status:item.item.currentMTTStatus,
                    startDate:startDate,
                    startWeek:startWeekDate,
                    initialComment: undefined,
                    interventionSkills:undefined,
                    weeklyGrowthScore:item.item.weeklyGrowthScore,
                    createdDate: startDate,

                })
            }
        });

        setEligibleMttStudents(eligibleStudentsArray);
    }, [selectedStudents]);

    // Functions
    const updateEligibleMttStudents = (studentId:number, commentText:string,skills?:string[]):void => {
        const updatedEligibleMttStudents:IInterventionApi[] = eligibleMttStudents.map(student => {
            if (student.studentPersonId === studentId) {
                return {
                    ...student,
                    status:MttStatus.ActiveMtt,
                    initialComment:commentText ? commentText : undefined,
                    interventionSkills:skills ? skills.map((skill:string) => { return { skillsOption:skill } }) : [],
                };
            }
            return student;
        });
      
        setEligibleMttStudents(updatedEligibleMttStudents);
        checkIfReadyforSubmission(updatedEligibleMttStudents);
    };

    const checkIfReadyforSubmission = (students:IInterventionApi[]):void => {
        const isReady:boolean = students.every(student => 
            (student.initialComment && student.initialComment.length) || 
            (student.interventionSkills && student.interventionSkills.length)
        );
        setReadyForSubmission(isReady);
    }
 
    async function BulkEnrollMtt() {
        setSubmitingInProcess(true);
        const response = await enrollOrSkipIntervention(eligibleMttStudents).catch(error => {
            dispatch(
                updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Updating Students Mtt Status'})
            );
        });

        if ((response.statusText === "OK") || (response.status === 200)) {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Success, notificationMessage: 'Students Mtt Status Updated'}));
            const eligibleMttStudentsPayload:IEnrollMandatoryTargetedTutoringAction[] = eligibleMttStudents.map(student => {
                return {
                        studentId: student.studentPersonId ? student.studentPersonId : -1,  
                        currentStudentStatus:MttStatus.ActiveMtt,
                        mttInterventions: {
                            currentSessionStatus: MttStatus.ActiveMtt,
                            startWeek:startWeek,
                            startDate:startDate,
                            weeklyGrowthScore: student.weeklyGrowthScore ? student.weeklyGrowthScore : '', 
                            comments: [],
                            skills:  student.interventionSkills ? student.interventionSkills : [],
                            initialComment: student.initialComment ? student.initialComment : '',
                        }
                    };

            });
            dispatch(enrollOrSkipInterventionAction(eligibleMttStudentsPayload));
            setSubmitingInProcess(false);
            onCompletion();
        }else{
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Updating Students Mtt Status'}));
            setSubmitingInProcess(false);
            onCompletion();
        }
    }
 
    return (
        <>
        <PanelFlyoutHeader
            mainTitle={course.courseName}
            isFinal={false}
            teacherArray={[teacherName, course.teacherEmail, course.teacherPhone]}
            toolBarType={PanelTitleTypes.Primary}
            showWarningIcon={false} 
        />
        <Container maxWidth="md" sx={{ width: '100%', paddingBottom:'24px' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                {termIsEditable && (<>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0'}}>
                        <Typography sx={{ alignItems: 'center', display: 'flex'}} variant="h6" gutterBottom component="div">
                            <SupervisedUserCircle sx={{ fontSize: 30, margin: '0 10px 0 0' }}  color='info'/> 
                            Bulk Mandatory Targeted Tutoring
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>

                        {FeatureGate("CanUpdateMttSession", featureFlags) ? (
                            selectedStudents.map(( item:ISelectableItem<IStudentGradeBook>, index) => {
                                if(item.item.currentMTTStatus === MttStatus.ActiveMtt || item.item.currentMTTStatus === MttStatus.ActiveWCommentsMtt){
                                    return (
                                        <MttCommentCard
                                            key={index + item.item.studentPersonId}
                                            subheader={item.item.studentName + ' is already in an Active Intervention.'} 
                                            mttStatus={MttStatus.ActiveMtt} 
                                            isCommentReadOnly={true}                                              
                                        />
                                    );
                                }
                                
                                if(
                                    item.item.currentMTTStatus === MttStatus.EligibleMtt ||
                                    item.item.currentMTTStatus === MttStatus.ExitedMtt || 
                                    item.item.currentMTTStatus === MttStatus.NotActvieMtt || 
                                    item.item.currentMTTStatus === MttStatus.SkippedMtt
                                ){
                                    return (
                                        <MttCommentCard
                                            key={index + item.item.studentPersonId} 
                                            title={item.item.studentName}
                                            commentTitle='Please add comments*' 
                                            skillSelect
                                            skillsSelectItems={skillsList}
                                            mttStatus={MttStatus.EligibleMtt}
                                            updateOnChangeFunction={(commentText:string,skills?:string[]) => updateEligibleMttStudents(item.item.studentPersonId,commentText,skills)}
                                            dense 
                                        />
                                    );
                                }
                                
                                return (
                                    <MttCommentCard
                                        key={index + item.item.studentPersonId}
                                        subheader={item.item.studentName + ' is not Eligible for MTT.'} 
                                        mttStatus={MttStatus.SkippedMtt} 
                                        isCommentReadOnly={true}                                              
                                    />
                                );

                            })

                            ) : (
                                // If Student is not in an active Session and User Role does not have CanUpdateMttSession feature
                                <MttCommentCard
                                    key={'1'} 
                                    subheader={'Permission needed to assign MTT Intervention'} 
                                    mttStatus={MttStatus.NotActvieMtt} 
                                    isCommentReadOnly={true}                                              
                                />

                            )                          
                        }
                    </Grid>
                </>)}


            </Grid>
        </Container>
        <Paper sx={{ position: 'fixed', bottom: 0, width: '560px'}} elevation={3}>    
            <CardActions 
                disableSpacing 
                style={{ justifyContent: 'flex-end' }} 
            >
                {submitingInProcess === false ? (
                    <Button 
                        onClick={() => { BulkEnrollMtt(); }} 
                        size="large"
                        variant='contained'
                        disabled={!readyForSubmission}
                    >
                        Save
                    </Button>
                ) : (
                    <LoadingButton loading loadingPosition="start"startIcon={<SaveIcon />} variant="outlined">
                        Save
                    </LoadingButton>
                )}
            </CardActions>
        </Paper>  
      </>
    );
  };