export const defaultDateTimeFormat = 'MM[-]DD[-]YYYY hh:mm a'; // 04/11/22 02:49 PM
export const defaultDateFormat = 'MM[-]DD[-]YYYY'; // 04/11/22
export const readableDateTimeFormat = 'LLLL'; // Monday, April 11, 2022 2:49 PM
export const readableDateFormat = 'dddd[,] MMMM DD[,] YYYY'; // Monday, April 11, 2022
export enum EnvironmentType {
    Dev,
    Prod
}

let ircGoogleClientID;
let googleAnalytics4IdString;
export let environment:EnvironmentType;
const hostname = window && window.location && window.location.hostname;

if (hostname === `localhost` ||
    hostname === `d125-irc2-test.azurewebsites.net` ||
    hostname === `d125-test-app.azurewebsites.net` ||
    hostname === `d125-qa-app.azurewebsites.net`) {
    // Localhost / Dev environment config
    ircGoogleClientID = `231991343782-ifa4fd1up22o24llhmv7lnc5droo2q8v`;
    environment = EnvironmentType.Dev;
    //Stevenson IRC Data stream - QA
    googleAnalytics4IdString = "G-4XV14FK5QP";

} else if (hostname === `irc.d125.org`) {
    // PROD environment config
    ircGoogleClientID = `231991343782-ifa4fd1up22o24llhmv7lnc5droo2q8v`;
    environment = EnvironmentType.Prod;
    // Stevenson IRC Data stream - Prod
    googleAnalytics4IdString = "G-EESM6KSJJX";
} else if (!hostname) {
    // Unit Testing environment
} else {
    throw new Error(`Environment not implemented: ${hostname}`);
}

export const googleAnalytics4Id = googleAnalytics4IdString;
export const googleClientId = ircGoogleClientID + `.apps.googleusercontent.com`;