export enum FeatureFlagType {
    canAccessGradebook = `canaccessgradebook`,
    canAccessReportCards = `canaccessreportcards`,
    canSccessParentReportCard = `canaccessparentreportcard`,
    canSearchTeachers = `cansearchteachers`,
    isDev = `isdev`,
    standardsDrawerEnabled = `standardsdrawerenabled`,
    canAccessStandardsDrawer = `canaccessstandardsdrawer`,
    canAccessMtt = `canaccessmtt`,
    canAddMttComment = `canaddmttcomment`,
    canEditWeeklyGrowthScore = `caneditweeklygrowthscore`,
    canUpdateMttSession = `canupdatemttsession`,
    canSearchStudents = `cansearchstudents`,
    canEditFauxFinalWeek = `caneditfauxfinalweek`,
    canViewFauxFinalWeek = `canviewfauxfinalweek`,
    admin = `admin`,
    parent = `parent`,
    teacher = `teacher`,
    sst = `sst`,
    tutor = `tutor`,
    teachertutor = `teachertutor`,
    director = `director`,
    specialed = `specialed`,
    guidedstudy = `guidedstudy`,
    student = `student`,
    qa = `qa`,
    dev = `dev`
}
