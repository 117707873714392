import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Theme from "./components/shared/Theme";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./store";
import { createLogger } from "redux-logger";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleClientId, googleAnalytics4Id }  from './common/config';
import ReactGA from "react-ga4";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const logger = createLogger({
  collapsed: true,
  timestamp: false,  
});
const store = createStore(reducer, applyMiddleware(
  thunk
  // Tech Debt add only on Dev
    // https://dev.azure.com/StevensonIT/IRC/_workitems/edit/601
  // ,logger
));

ReactGA.initialize(googleAnalytics4Id, {
  gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
})
 
// App Insights Tracking
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '386724d7-dceb-415c-bbf1-e6620b3c589f',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

ReactDOM.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <Theme>
          <App />
        </Theme>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
  rootElement
);
appInsights.loadAppInsights();
unregister();
