import React from "react";
import Button from "@mui/material/Button";
import { Term } from "../../models/Term";
import { Course } from "../../models/Course";
import "./Shared.css";

import { Row, Col } from "../layout/ResponsiveGrid";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { Teacher } from "../../models/Teacher";
import { TeacherStudent } from "../../models/TeacherStudent";
import PrintIcon from "@mui/icons-material/Print";
import ButtonGroup from "@mui/material/ButtonGroup";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Props coming from Store
export interface CourseSelectCardProps {
  terms: Term[];
  courses: Course[];
  selectedTerm: number;
  selectedCourse: Course;
  onCourseSelect: (course: Course) => Promise<void>;
  isParentCollapsed: boolean;
  // toggleAction: boolean;
  toggleAction: (isCollapsed: boolean) => any;
  togglePrintableGradebook: () => void;
  togglePrintableRoster: () => void;
  openSection?: () => void;
  teacherList: Teacher[];
  selectedTeacher?: Teacher;
  selectedStudent: TeacherStudent;
  studentList?: TeacherStudent[];
}

export enum ProductPanelType {
  CreateRangePanel = "CreateRangePanel",
  None = "None",
}

export enum AdminSearchType {
  Teacher = 0,
  Student = 1,
}

// Component States Types
interface CourseSelectCardState {
  sortDescending: boolean;
  sortField: string;
  isCollapsed: boolean;
}

class CourseSelectCard extends React.Component<CourseSelectCardProps, CourseSelectCardState> {
  static getDerivedStateFromProps(nextProps: CourseSelectCardProps, prevState: CourseSelectCardState) {
    if (nextProps.isParentCollapsed !== prevState.isCollapsed) {
      return {
        isCollapsed: nextProps.isParentCollapsed,
      };
    }

    return null;
  }

  constructor(props: any) {
    super(props);
    // Components States Values
    this.state = {
      sortDescending: false,
      sortField: "description",
      isCollapsed: false,
    };
  }

  render() {
    const truncatePrintButtons = this.props.isParentCollapsed ? true : window.innerWidth < 1200;
    return (
      <>
        <Row>
          <Col xs={12} padding={this.props.isParentCollapsed ? `` : `0px 14px 0`}>
            <Row style={{ display: "flex", justifyContent: `end` }}>
              <Col xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonGroup
                  fullWidth={this.props.isParentCollapsed ? true : false}
                  style={{ height: this.props.isParentCollapsed ? `` : `50px` }}
                  variant="text"
                  color="info"
                  aria-label="text button group"
                  size="large"
                  orientation={this.props.isParentCollapsed ? `vertical` : `horizontal`}
                >
                  {this.props.isParentCollapsed && (
                    <Button onClick={this._toggleCourse}>
                      <MdChevronRight size="38" />
                    </Button>
                  )}

                  {this.props.selectedCourse.courseId !== -1 && (
                    <>
                      <Button onClick={() => this.props.togglePrintableGradebook()}>
                        <span>
                          <PrintIcon style={{ fontSize: "18px" }} />
                          &nbsp;<span>{truncatePrintButtons ? "G" : "Gradebook"}</span>
                        </span>
                      </Button>
                      <Button onClick={() => this.props.togglePrintableRoster()}>
                        <span>
                          <PrintIcon style={{ fontSize: "18px" }} />
                          &nbsp;<span>{truncatePrintButtons ? "R" : "Roster"}</span>
                        </span>
                      </Button>
                    </>
                  )}
                  {!this.props.isParentCollapsed && (
                    <Button onClick={this._toggleCourse}>
                      <MdChevronLeft size="38" />
                    </Button>
                  )}
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={12} padding="10px 14px 0px">
            {!this.props.isParentCollapsed ? (
              <>
                {this.props.children}

                <div className="courseListContainer" style={{ minHeight: 700 }}>
                  {this.renderContents(this.props.courses, this.props.onCourseSelect, this.props.selectedCourse)}
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </>
    );
  }

  ///
  private _toggleCourse = (): void => {
    this.setState(
      {
        isCollapsed: !this.state.isCollapsed,
      },
      this.props.toggleAction(!this.state.isCollapsed)
    );
  };

  ///
  private renderContents = (courses: Course[], onCourseSelect: (course: any) => void, selectedCourse: Course) => {
    if (!this.props.selectedStudent || this.props.selectedStudent.personId !== -1) {
      return <span />;
    } else if (this.props.selectedTeacher !== undefined) {
      return (
        <table className="table table-striped gradebookTable" aria-labelledby="tabelLabel" style={{ border: "1px solid #ccc" }}>
          <thead>
            <tr>
              <th>Period</th>
              <th>Course #</th>
              <th>Course</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course: Course, index) => {
              const activeCourse: string = course.courseNumber === selectedCourse.courseNumber && course.periodId === selectedCourse.periodId ? "selected" : "";
              return (
                <tr className={`courseRow ${activeCourse}`} key={`${course.courseId}-${course.sectionId}-${index}`} onClick={() => onCourseSelect(course)}>
                  <td align="center">
                    <div className="gradebookTableRow">{course.periodName}</div>
                  </td>
                  <td>
                    <div className="gradebookTableRow">{course.courseNumber}</div>
                  </td>
                  <td>
                    <div className="gradebookTableRow">
                      {course.courseName}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <span />;
    }
  };
}

export default CourseSelectCard;
