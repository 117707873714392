import * as React from 'react';
import styled from "styled-components"
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Button from '../inputs-elements/Button'; 
import { MdClose, MdInfoOutline } from "react-icons/md";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import FlagIcon from '@mui/icons-material/Flag';


export enum HoverModalTypes {
    Primary = 'primary',
    Secondary = 'secondary',
    Third = 'third',
    Fourth = 'fourth',
    Fifth = 'fifth',
    Sixth = 'sixth',
    Teacher = 'teacher'
}

export enum HoverModalContent {
    projectedgrade = `<p style="font-weight: normal;">
    Teachers determine grades by reviewing the body of evidence for each standard and the pattern of growth over time. In order to earn a letter grade of an "A" a student must meet or exceed expectation in each Standard. If you have further questions review the Course Description or contact your teacher.
    </p>`,
    weeklygrowth = `<ul>
        <li>
            <strong>AG - Stands for <i>Appropriate Growth</i>.</strong> 
            This means that a student is growing at the normal or above normal learning trajectory, given the academic challenges of the course/unit. 
        </li>
        <li>
            <strong>MG - Stands for <i>Minimal Growth</i>.</strong> 
            This means that a student is growing at a flat or shallow learning trajectory, given the academic challenges of the course/unit.
        </li>
        <li>
            <strong>FG - Stands for <i>Failing to Grow</i>.</strong> 
            This means that a student is growing at a negative learning trajectory, given the academic challenges of the course/unit.
        </li>
        <li>
            <strong>I - Stands for <i>Insufficient Evidence/Incomplete</i>.</strong>
            This means that there is simply not enough evidence collected to determine progress or growth in a course/unit. Review of the grade book for missing work and conversation between teacher and student or parent is advised.    
        </li>
    </ul>`,
    pastWeek = `<p style="font-weight: normal;">You are currently viewing Growth scores from a past week</p>`,
    finalWeek = `<p style="font-weight: normal;">You are currently viewing Final Grades. Please verify as needed.</p>`,
    standard = `<ul>
                    <li>
                        <strong>Exceeds:</strong> 
                        The student submitted evidence of learning indicating they have a skill proficiency level that exceeds the standard.
                    </li>
                    <li>
                        <strong>Meets:</strong> 
                        The student submitted evidence of learning indicating they have a skill proficiency level
                        that meets the standard.
                    </li>
                    <li>
                        <strong>Approaching:</strong> 
                        The student submitted evidence of learning indicating they have an undeveloped
                        yet emerging proficiency level that does not quite meet the standard.</li>
                    <li>
                        <strong>Developing:</strong> 
                        Student's learning evidence indicates they are developing foundational knowledge and prerequisite skills.
                    </li>
                    <li>
                        <strong>Missing:</strong> 
                        The student is missing evidence, and there is time remaining to submit it.
                    </li>
                    <li>
                        <strong>Not Completed:</strong> 
                        - and the time has passed to complete this assessment.
                    </li>
                </ul>`,
    homeworkGraph = `The graph below shows practice/preparation completionover time, Green represents completed assignments, Gold represents incomplete assignments.`,
    homework = `<ul>
                    <li><strong>Complete</strong>
                        <ul>
                            <li><strong>Turned in -</strong> Student turned in Practice/Preparation</li>
                            <li><strong>Turned in Late -</strong> Student turned in Practice/Preparation Late.</li>
                            <li><strong>Prepared -</strong> Turned in, Student has completed Practice/Preparation in a manner that predicts success on any proficiency event.</li>
                            <li><strong>Partially Prepared -</strong> Turned in, Student has completed Practice/Preparation in a manner that predicts any proficiency event may be moderately challenging.</li>
                        </ul>
                    </li>
                    <li><strong>Incomplete</strong>
                        <ul>
                            <li><strong>Missing -</strong> there is time remaining to complete this Practice/Preparation.</li>
                            <li><strong>Not Completed -</strong> and the time has passed to complete this Practice/Preparation.</li>
                            <li><strong>Not Prepared -</strong> Turned in, Student has completed Practice/Preparation in a manner that predicts all proficiency events will be highly challenging.</li>
                        </ul>
                    </li>
                </ul>,
    `
}

export interface HoverModalContactInfo {
    email: string;
    phone: string;
}

// Props coming from Parent Components
interface IHoverModalProps {
    title: string;
    type: HoverModalTypes;
    content?: HoverModalContent;
    teacherContactInfo?: HoverModalContactInfo;
    fullHeight?: boolean;
    flexDirection?: string;
    justifyContent?: string;
    preventHeadlineWrap?: boolean;
}

// Component States Types
interface IHoverModalState {
    isActive: boolean;
}
 

// Styling ======================
const HoverModalStyled = styled.div<IHoverModalProps>`
   display: flex;  flex-wrap:wrap; box-sizing:border-box;justify-content: flex-start; cursor:pointer;
  ${ props => props.fullHeight ? 'height:100%' : ''}; 
  flex-direction: ${ props => props.flexDirection ? props.flexDirection : 'row'};
  justify-content: ${ props => props.justifyContent ? props.justifyContent : 'flex-start'};
 

 
`;

interface HoverModalContentProps {    
    primaryModalContent?: boolean;
}
const HoverModalContentStyled = styled.div<HoverModalContentProps>`
    background: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.darkGrey};  
    position: absolute;width: 300px;z-index: 200;
 
  
    ${({ primaryModalContent, theme: { colors } }) => primaryModalContent ? ''  : 'right:0' };



    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const LinkStyled = styled.a`
    display: flex;  flex-wrap:wrap; width:100%
`;


const ThirdHoverType = styled.div`
    display: flex;  justify-content: center;width: 100%;
`;

const ModalTitleStyled = styled.h5`
    display: flex; justify-content: space-between;padding:10px; background: ${({ theme: { colors } }) => colors.greys.xxlight};
`;
 
const ModalHTML = styled.div`
    padding:10px;
    text-align:left;
`;


class HoverModal extends React.Component<IHoverModalProps, IHoverModalState> {

    constructor(props: any) {
        super(props);
        // Components States Values
        this.state = {
            isActive: false,
        };
    }

    render() {
        
        return (
            <>
                <HoverModalStyled
                    title={this.props.title}
                    type={this.props.type}                    
                >
                    <LinkStyled onClick={() => this.onEventChange()} >
                        

                        {this.props.type === HoverModalTypes.Primary && 
                            <>
                                <h5>
                                    {this.props.title}
                                </h5>
                                <MdInfoOutline color='#7b7f8b'/>
                            </> 
                        }

                        {this.props.type === HoverModalTypes.Secondary && 
                            <>
                                <h6 style={{textAlign:'right',width:'100%', fontSize: '13px'}}>
                                    {this.props.title}
                                    <MdInfoOutline color='#FFFFFF'/>
                                </h6>
                                
                            </>
                        }
     
                        {this.props.type === HoverModalTypes.Third && 
                            <>
                                <ThirdHoverType>
                                    {this.props.title} <MdInfoOutline color='#7b7f8b'/>
                                 </ThirdHoverType>
                            </>
                        }

                        {this.props.type === HoverModalTypes.Fourth &&
                            <>
                                <h6 style={{textAlign:'right',width:'100%', fontSize: '13px'}}>
                                    {this.props.title}
                                    <MdInfoOutline color='#3c404a'/>
                                </h6>
                                
                            </>
                        }
                        {this.props.type === HoverModalTypes.Fifth && 
                            <>
                                <h4>
                                    {this.props.title}
                                </h4>
                                <MdInfoOutline color='#7b7f8b'/>
                            </> 
                        }

                        {this.props.type === HoverModalTypes.Sixth && 
                            <>
                                <ThirdHoverType>
                                    {this.props.title} <FlagIcon fontSize='small' color='warning'/>
                                 </ThirdHoverType>
                            </>
                        }    

                        {this.props.type === HoverModalTypes.Teacher &&
                            <>
                                <h6 style={{textAlign:'left',width:'100%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                    <ContactMailIcon sx={{marginRight: '10px'}}/> 
                                    {this.props.title}  
                                </h6> 
                            </>
                        }    
                        
                     </LinkStyled>
                    {this.state.isActive &&
                        <>
                            <HoverModalContentStyled
                                primaryModalContent={((this.props.type === HoverModalTypes.Primary) || (this.props.type === HoverModalTypes.Fifth) || (this.props.type === HoverModalTypes.Teacher))}
                            >     

                                {this.props.type === HoverModalTypes.Teacher ? 
                                    <>
                                        <ModalTitleStyled>
                                            Teacher Contact
                                            <MdClose color='#7b7f8b' onClick={() => this.onEventChange()}/>
                                        </ModalTitleStyled>    
                                        <ModalHTML>
                                            <h6>{this.props.title}</h6>
                                           
                                            {this.props.teacherContactInfo ? (
                                                <>                  
                                                    {this.props.teacherContactInfo.email && 
                                                        <Button 
                                                            onClick={() => this.props.teacherContactInfo ? this.onContact(`mailto:${this.props.teacherContactInfo.email}`) : ({})}
                                                            mainTitle={this.props.teacherContactInfo ? this.props.teacherContactInfo.email : 'email unavailable'} 
                                                            icon    
                                                        />
                                                    }
                                                    {this.props.teacherContactInfo.phone && 
                                                        <Button 
                                                            onClick={() => this.props.teacherContactInfo ? this.onContact(`tel:${this.props.teacherContactInfo.phone}`) : ({})}
                                                            mainTitle={this.props.teacherContactInfo ? this.props.teacherContactInfo.phone : 'phone unavailable'} 
                                                            icon    
                                                        />
                                                    }

                                                    {(this.props.teacherContactInfo.email === '' || this.props.teacherContactInfo.email === undefined ) && (this.props.teacherContactInfo.phone === '' || this.props.teacherContactInfo.phone === undefined ) && 
                                                
                                                        <h5 style={{ padding: '20px 0px', textAlign: 'center', color:'#babfce' }}>No Contact Info Found.</h5>
                                                    }

                                                </>
                                            ) : (
                                                <h5 style={{ padding: '20px 0px', textAlign: 'center', color:'#babfce' }}>No Contact Info Found.</h5> 
                                            )}

                                        </ModalHTML>              

                                    </>
                                : 
                                    <>
                                        <ModalTitleStyled>
                                            {this.props.title}

                                            <MdClose color='#7b7f8b' onClick={() => this.onEventChange()}/>
                                        </ModalTitleStyled>    
                                        <ModalHTML dangerouslySetInnerHTML={(this.props.content !== undefined) ? { __html: this.props.content } : {__html: ""}} />
                                    </> 
                                }
                                


                            </HoverModalContentStyled>
                        </>
                    }
                </HoverModalStyled>
            </>
        );
    }

    private onEventChange = (): void => {
        this.setState({
            isActive: !this.state.isActive
        })
    }

    private onContact = (link: string): void => {
        const a = document.createElement('a');
        a.href = link;
        a.click();
    }
}

export default HoverModal;