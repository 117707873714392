import * as React from 'react';
import styled from "styled-components"
import { ReactNode } from "react";
import HoverModal, {  HoverModalTypes, HoverModalContent } from './HoverModal';
import {ProjectedGradeInitials} from './ProjectedGradeInitials';

export enum PanelTitleTypes{
  Primary = 'primary',
  Secondary = 'secondary',
  Third = 'third',
  Fourth = 'fourth',
  Fifth = 'fifth',
  Sixth = 'sixth',
  Seventh = 'seventh',
  Both = 'both'
}
 
interface IncomingProps {
    mainTitle?: string;
    mainSubTitle?: string;
    secondaryTitle?: string;
    secondarySubTitle?: string;
    isFinal?: boolean;
    teacherArray?: Array<string>;
    toolBarType: PanelTitleTypes;
    children?: ReactNode;
    textAlign?: string;
    showWarningIcon?:boolean;
    // any other props that come into the component
}

interface IncomingPropsChild {
  children: JSX.Element;
  // any other props that come into the component
}

interface SubHeaderDisplayProps{
  title: string;
  subTitle: string;
  icon: JSX.Element;
  secondarySubTitle?: string;
  secondaryIcon?: JSX.Element;
  onClick?: (event: any) => void
}

// Styling ======================
const TitleBar = styled.div`
  color: ${({ theme: { colors } }) => colors.persianGreen};
  width: '100%';
`;

interface ToolbarProps {
  isMain?: boolean;
  isThird?: boolean;
  isFourth?: boolean;
  isFifth?: boolean;
  isSixth?: boolean;
  isSeventh?: boolean;
}
const Toolbar = styled.div<ToolbarProps>`
    width: '100%';padding:0 8px; 
    min-height: 60px;

    display: flex;justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: ${({ isMain, theme: { colors } }) => isMain ? colors.greens.light : colors.greys.dark };
    color: ${({ theme: { colors } }) => colors.white}; 
    
    ${({ isThird, theme: { colors } }) => isThird ? 'background-color:' + colors.white : '' };
    ${({ isThird, theme: { colors } }) => isThird ? 'color:' + colors.greys.xdark : '' };
    ${({ isThird, theme: { colors } }) => isThird ? 'padding:14px;height:auto;& h1{font-weight:600}' : '' };
    
    ${({ isFourth, theme: { colors } }) => isFourth ? 'background-color:' + colors.greys.xlight : '' };
    ${({ isFourth, theme: { colors } }) => isFourth ? 'color:' + colors.greys.xdark : '' };
    ${({ isFourth, theme: { colors } }) => isFourth ? 'padding:8px 14px;height:auto;& h6{cursor:pointer; font-weight:600}' : '' };
        
    ${({ isFifth, theme: { colors } }) => isFifth ? 'background-color:' + colors.gold.default : '' };
    ${({ isFifth, theme: { colors } }) => isFifth ? 'color:' + colors.greys.xdark : '' };
    ${({ isFifth, theme: { colors } }) => isFifth ? 'padding: 14px;& h6{cursor:pointer; font-weight:600}' : '' };

    ${({ isSixth, theme: { colors } }) => isSixth ? 'background-color:' + colors.greys.xxlight : '' };
    ${({ isSixth, theme: { colors } }) => isSixth ? 'color:' + colors.greys.xdark : '' };
    ${({ isSixth, theme: { colors } }) => isSixth ? 'padding:14px;height:64px;& h6{cursor:pointer; font-weight:600}' : '' };

    ${({ isSeventh, theme: { colors } }) => isSeventh ? "justify-content: center;" : ""};
    ${({ isSeventh, theme: { colors } }) => isSeventh ? "& > div { display: flex; flex-direction: column; justify-content: center; margin-bottom: 0; }" : ""};
    ${({ isSeventh, theme: { colors } }) => isSeventh ? "& > div > h1 { margin-bottom: 0; }" : ""};
    ${({ isSeventh, theme: { colors } }) => isSeventh ? "& > div > h1:last-child { text-align: center; font-size: 1rem; }" : ""};
`;  

interface TypographyProps {
  isHeading?: boolean;
  textAlign?: string;
}
const Typography = styled.h1<TypographyProps>`
    width:100%;
    font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.medium : fontSizes.titleSmall};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'initial'};    
`;

const TextOverflow = styled.span`
  display: block; font-weight:600;  
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
`;

interface SubTitleProps {
  isHeading?: boolean;
}
const SubTitle = styled.h5<SubTitleProps>`
    width:100%;
    font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.medium : fontSizes.small}; 
`;

const ChildLeftStyled = styled.div`
  max-width: 65%;
  flex-basis: 65%;

`;

const ChildRightStyled = styled.div`
max-width: 35%;
flex-basis: 35%; text-align:right;
`;

const ChildSplitStyled = styled.div`
  max-width: 50%;
  flex-basis: 50%;

`;
 

export const PanelSubHeaderDisplay = (props: SubHeaderDisplayProps) => {
    return(
        <>
          <Typography isHeading={false} onClick={props.onClick}>
            <TextOverflow>
              {props.title}
            </TextOverflow>
          </Typography>
          <SubTitle>
              {props.icon}
              &nbsp;{props.subTitle}            
          </SubTitle>
          {props.secondarySubTitle &&
            <SubTitle>
              {props.secondaryIcon}
              &nbsp;{props.secondarySubTitle}            
            </SubTitle>
          }
        </>  
    )
}

 

export const PanelFlyoutHeader = ({ ...props }: IncomingProps) => {

  const hasMissingAssignment: boolean | undefined = props.showWarningIcon;

  return(
      <>
        
        <PanelTitleBar toolBarType={PanelTitleTypes.Primary}>
            <PanelChildLeft>
              <>
                <TextOverflow>
                  {/* Course */}
                  {props.mainTitle}
                </TextOverflow>

              {props.teacherArray &&
                <HoverModal
                  title={props.teacherArray[0]}
                  type={HoverModalTypes.Teacher} 
                  teacherContactInfo={{
                    email: props.teacherArray[1],
                    phone: props.teacherArray[2]
                  }}
                />
              }


              </>
            </PanelChildLeft>
            <PanelChildRight>
                <h6>  
                  {/* Period */}
                  {props.mainSubTitle}
                  <span style={{marginLeft: '10px'}}> Period</span>
                </h6>
            </PanelChildRight>
        </PanelTitleBar>
        <PanelTitleBar toolBarType={PanelTitleTypes.Secondary}>
            <PanelChildLeft>
                <h6>
                  {/* Student Full Name */}
                  {props.secondaryTitle}   
                </h6> 
            </PanelChildLeft>
            <PanelChildRight>
                <>
                    <HoverModal title={props.isFinal ? 'Final Grade' : 'Projected Grade'} type={HoverModalTypes.Secondary} content={HoverModalContent.projectedgrade}/>
                    {/* Projected Grade */}
                    {props.secondarySubTitle && (<ProjectedGradeInitials projectedGrade={props.secondarySubTitle} showWarningIcon={hasMissingAssignment}/>)}

                </>
            </PanelChildRight>
        </PanelTitleBar>
      </>  
  )
}

export const PanelChildLeft = ({ children }: IncomingPropsChild) => (
  <ChildLeftStyled>
      {children}
  </ChildLeftStyled>
);

export const PanelChildRight = ({ children }: IncomingPropsChild) => (
  <ChildRightStyled>
      {children}
  </ChildRightStyled>
);

export const PanelChildSplit = ({ children }: IncomingPropsChild) => (
  <ChildSplitStyled>
      {children}
  </ChildSplitStyled>
);

const PanelTitleBar = ({ ...props }: IncomingProps) => (
<TitleBar>
  {/* ======== PRIMARY TOOLBAR ======= */}
  {props.toolBarType === 'primary' ? (
    <Toolbar isMain={true}>
      {props.children ? (
        props.children
      ) : (
        <>
          <Typography isHeading={true} >
            {props.mainTitle}
          </Typography>
          <Typography isHeading={false} >
            {props.mainSubTitle}
          </Typography>
        </>
      )}
    </Toolbar>

  /* ======== Secondary TOOLBAR ======= */
  ) : props.toolBarType === 'secondary' ? ( 
    <Toolbar isMain={false}>
      {props.children ? (
        props.children
      ) : (
        <>
          <Typography textAlign={props.textAlign}>
            {props.mainTitle}
          </Typography>

          {props.children ? (
            <Typography>
              {props.mainSubTitle}
            </Typography>
          ) : null}
        </>
      )}
    </Toolbar>
    
  /* ======== THIRD TOOLBAR ======= */
  ) : props.toolBarType === 'third' ? (
    
    <Toolbar isThird>
      {props.children ? (
        props.children
      ) : (
        <>
          <Typography textAlign={props.textAlign} isHeading={true} >
            {props.mainTitle}
          </Typography>

          {props.children ? (
            <Typography>
              {props.mainSubTitle}
            </Typography>
          ) : null}
        </>
      )}
    </Toolbar>

  /* ======== FOURTH TOOLBAR ======= */
  ) : props.toolBarType === 'fourth' ? (
  <Toolbar isFourth>
    {props.children ? (
      props.children
    ) : (
      <>
        <Typography textAlign={props.textAlign}>
          {props.mainTitle}
        </Typography>

        {props.children ? (
          <Typography>
            {props.mainSubTitle}
          </Typography>
        ) : null}
      </>
    )}
  </Toolbar>
  
  /* ======== FIFTH TOOLBAR ======= */ 
  ) : props.toolBarType === 'fifth' ? (
    <Toolbar isFifth>
      {props.children ? (
        props.children
      ) : (
        <>
          <Typography isHeading={true} >
            {props.mainTitle}
          </Typography>
          <Typography isHeading={false} >
            {props.mainSubTitle}
          </Typography>
        </>
      )}
    </Toolbar>

   /* ======== SIXTH TOOLBAR ======= */
    ) : props.toolBarType === 'sixth' ? (
    <React.Fragment>
      <Toolbar isSixth>
        {props.children}
      </Toolbar>
    </React.Fragment>
  ) : /* ======== SEVENTH TOOLBAR ======= */
		props.toolBarType === PanelTitleTypes.Seventh ? (
			<Toolbar isSeventh={true}>
				{props.children ? (
					props.children
				) : (
					<div>
						<Typography textAlign={props.textAlign}>
							{props.mainTitle}
						</Typography>
						<Typography isHeading={false}>
							{props.mainSubTitle}
						</Typography>
					</div>
				)}
			</Toolbar>
		/* ======== BOTH TOOLBAR ======= */
  ) : props.toolBarType === 'both' ? (
    <React.Fragment>
      <Toolbar isMain={true}>
        <Typography isHeading={true} >
          {props.mainTitle}
        </Typography>
        <Typography isHeading={false} >
          {props.mainSubTitle}
        </Typography>
      </Toolbar>
      <Toolbar isMain={false}>
        <Typography >
          {props.secondaryTitle}
        </Typography>
        <Typography textAlign='right'>

          {props.secondarySubTitle}
        </Typography>
      </Toolbar>
    </React.Fragment>
  ) : null}
</TitleBar>
);

export default PanelTitleBar;