import React, { useEffect } from 'react';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface ISkillSelect {
    skillsLabel: string;
    skillsList: string[];
    prevSelectedSkills: string[];
    onSkillSelectItem: (selectedSkills:string[]) => void;
}
 
export const SkillSelect = ( {skillsLabel, skillsList, prevSelectedSkills, onSkillSelectItem }:ISkillSelect) => {
    const [selectedSkills, setSelectedSkills] = React.useState<string[]>([]);

    const handleSkillSelect = (event: SelectChangeEvent<typeof selectedSkills>) => {
        const {
            target: { value },
        } = event;
        setSelectedSkills( typeof value === 'string' ? value.split(',') : value, );
        
    };

    useEffect(() => {
        if(prevSelectedSkills){
            setSelectedSkills(prevSelectedSkills);
        }
    }, [prevSelectedSkills]);

    useEffect(() => {
        if(selectedSkills){
            onSkillSelectItem(selectedSkills);
        }
    }, [selectedSkills]);

    return (
      <>
            <FormControl  sx={{ m: 1, width: '100%', margin:0, maxWidth: '420px', paddingTop: '16px' }}>
                <InputLabel  sx={{ fontSize:'.9rem', top:'unset' }} id="skill-select-label">{skillsLabel}</InputLabel>
                <Select
                    labelId="skill-select-label"
                    id="skill-select"
                    multiple
                    value={selectedSkills}
                    onChange={handleSkillSelect}
                    input={<OutlinedInput label="Selected Skill(s)" />}
                    renderValue={(selected) => selected.join(', ')}
                    sx={{ width: '100%' }}
                    autoWidth={false}
                >
                    {skillsList.map((skill) => (
                        <MenuItem key={skill} value={skill}>
                            <Checkbox checked={selectedSkills.indexOf(skill) > -1} />
                            <ListItemText secondary={skill} />
                        </MenuItem>
                    ))}
                    </Select>
            </FormControl>
      </>
    );
  
  };