import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export interface LoadingProps {
  text?: string;
}

export const LoadingIcon = (props: LoadingProps) => {
  return (
    <div style={{ textAlign: "center", height: "100%" }}>
      <Box sx={{ width: "100%", marginBottom: "100px", color: "#0077EE" }}>
        <LinearProgress 
          sx={{ 
            borderRadius: '0', 
            height: '6px',
          }} 
          color="inherit" 
        />
      </Box>
      {props.text ? <h5>{props.text}</h5> : <h5>Loading, please wait ... </h5>}
    </div>
  );
};
