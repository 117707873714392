import * as React from 'react';
import { Standard } from '../../models/Standard';
import { ScoreWords } from '../../models/enums/Standards';
import { GradebookEntry } from '../../models/GradebookEntry';
import {Course} from '../../models/Course';
import { Row, Col } from '../layout/ResponsiveGrid';
import { DropDown } from '../inputs-elements/DropDown';
import './Shared.css';
import  AccordionPanel from '../layout/AccordionPanel';
import { convertScoreToWord, convertStandardActiveToWord } from '../../utils/helpers/stringHelpers';
import { ProficiencyType,ScoreNumbers } from '../../models/enums/Standards';
import WarningIcon from '@mui/icons-material/Warning';
import { arrayIsEmpty } from '../../utils/helpers/generalHelpers';
import { getLineGraphData } from '../../utils/helpers/skillsHelpers';

import PanelTitleBar, { PanelTitleTypes, PanelChildLeft,  PanelFlyoutHeader } from '../layout/PanelTitleBar';
 


import { MdArrowBack } from "react-icons/md";
import ScrollerHolder, { ScrollHeader,  ScrollerArea } from '../layout/ScrollerArea';
import HoverModal, {  HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import { getActiveAssignments, hasMissingActiveAssignments } from '../../utils/helpers/generalHelpers';

import SkillProficiencyGraph from '../charts/SkillProficiencyGraph';
 
 



// Props coming from Parent Components
interface IStandardsReportCardProps {
    selectedStudentGradebook: GradebookEntry;
    selectedStandardProps: Standard;
    activeCourse: Course;
    breadCrumbNav?: boolean;
    onStudentSelect: Function;
    isReportCard?: boolean;
    onStandardsUpdated?: Function;

}

// Component States 
interface IStandardsReportCardState {
    sortDescending: boolean;
    sortField: string;
    selectedStandard: Standard;
    selectedGradebook: GradebookEntry;
    filteredAssignmentScores: string[];
    proficiencyScore: string;
     
}
 
class StandardsReportCard extends React.Component<IStandardsReportCardProps, IStandardsReportCardState> {

    static getDerivedStateFromProps(nextProps: IStandardsReportCardProps, prevState: IStandardsReportCardState) {
 
        if ((nextProps.selectedStandardProps !== prevState.selectedStandard && nextProps.selectedStudentGradebook !== prevState.selectedGradebook) || (nextProps.selectedStandardProps !== prevState.selectedStandard)) {           
            return { 
                selectedStandard: nextProps.selectedStandardProps, 
                proficiencyLevel:  convertScoreToWord(nextProps.selectedStandardProps.proficiency.proficiencyScore.toString()),
                selectedGradebook: nextProps.selectedStudentGradebook
            };
        }
       

        return null;
    }

    constructor(props: any) {
        super(props);
        // Components Defualt States Values
        this.state = {
            sortDescending: false,
            sortField: 'description',
            selectedStandard: new Standard(),
            selectedGradebook: new GradebookEntry(),
            filteredAssignmentScores: [],
            proficiencyScore: ''
        };
    }
 

    render() {

        const studentFullName: string = this.state.selectedGradebook.student.lastName + ', ' + this.state.selectedGradebook.student.firstName;
        
        const courseName: string = this.state.selectedGradebook.student.courseName;
        const projectedGrade: string = this.state.selectedGradebook.assessment.projectedGrade;
        const finalGrade: string = this.state.selectedGradebook.assessment.finalGrade;
        const teacherName: string = this.props.activeCourse.teacherName !== null ? this.props.activeCourse.teacherName : this.props.activeCourse.teacherDisplay !== null ? this.props.activeCourse.teacherDisplay : '';
        const teacherEmail: string = this.props.activeCourse.teacherEmail !== null ? this.props.activeCourse.teacherEmail : '';
        const teacherPhone: string = this.props.activeCourse.teacherPhone !== null ? this.props.activeCourse.teacherPhone : '';
        const period: string = this.props.activeCourse.periodName;

        const fullStandardList: Array<Standard> = this.state.selectedGradebook.assessment.standards.filter((standardItem) => 
            standardItem.assignments.some((subElement) => subElement.isHomework === false))
        .map(element => {
            const newElt = Object.assign({}, element); // copies element
            return newElt;
        });

        const selectedStandardNumber: number = this.standardNumber(fullStandardList, this.state.selectedStandard);
        const assignmentsGrouped:Array<any> = this.groupBy(this.state.selectedStandard.assignments);
        const hasMissingAssignment: boolean = hasMissingActiveAssignments(getActiveAssignments(this.state.selectedGradebook));
        const { proficiencyScore, proficiencyArray, graphLevelsArray } = getLineGraphData(this.state.selectedStandard);
 
        return (
            <>

                <ScrollerHolder>
                    <ScrollHeader>
                       
                        <>

                         {/* ============ Is Not Report Card Show the following ================== */}
                            {!this.props.isReportCard &&
                                <>
                                    <PanelFlyoutHeader
                                        mainTitle={courseName}
                                        mainSubTitle={period}
                                        secondaryTitle={studentFullName}
                                        secondarySubTitle={
                                            this.state.selectedGradebook.assessment.isVerified ? finalGrade : projectedGrade
                                        }
                                        isFinal={this.state.selectedGradebook.assessment.isVerified}
                                        teacherArray={[teacherName, teacherEmail, teacherPhone]}
                                        toolBarType={PanelTitleTypes.Primary}
                                        showWarningIcon={hasMissingAssignment} 
                                    />
                                </>
                            }

                            {this.props.breadCrumbNav && 
                                <>
                                    {/* ==== Return ====  */}
                                    <PanelTitleBar toolBarType={PanelTitleTypes.Fourth}>
                                        <PanelChildLeft>
                                            <h6 style={{marginBottom:0}} onClick={ () => this.props.onStudentSelect(this.state.selectedGradebook) } >
                                                <MdArrowBack size='24'/> Overview
                                            </h6>
                                        </PanelChildLeft>
                                    </PanelTitleBar>
                                </>
                            }

                        </>
                    </ScrollHeader>
                    <ScrollerArea>
                        <>
                            <Row>
                                <Col xs={8}>                                 
                                    <HoverModal title='Skills' type={HoverModalTypes.Fifth} content={HoverModalContent.standard}/>
                                     {this.renderStandardDropdown(fullStandardList, selectedStandardNumber, this._updateSelectedStandard)}  
                                </Col>
                                <Col xs={4} style={{textAlign:'right', paddingRight:'5px'}}>
                                    <a><h6 style={{textAlign: 'right',width: '100%',fontSize: '14px', marginBottom:'0'}}>Proficiency Level</h6></a>               
 
                                    {(proficiencyScore.toString() !== ScoreNumbers.Five) && (proficiencyScore.toString() !== "0") ? 
                                            (<h6>{convertScoreToWord(proficiencyScore.toString())}</h6>) : 
                                            (this.renderMissingMatrix(proficiencyScore.toString()))
                                    }  
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div style={{marginTop:'20px'}}>
                                        <div style={{width:'300px'}}>
                                            <SkillProficiencyGraph 
                                                proficiencyScore={proficiencyScore}
                                                proficiencyType={ProficiencyType.Initial}
                                                proficiencyArray={proficiencyArray}
                                                graphLevels={graphLevelsArray}
                                                showScoreIntials
                                                viewSkillProficienies={() => {
                                                    // do nothing in the reportcard
                                                 }}
                                                disableSkillMenu={true}
                                            />
                                        </div>
                                        {Object.keys(assignmentsGrouped).map(( assignment:any, index, array) => (
                                            <AccordionPanel accordionTitle={assignment} key={index}>
                                                <table className='table table-striped courseListTable' aria-labelledby="tabelLabel">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:'20%'}} >Date</th>
                                                            <th style={{width:'49%'}}>Event</th>
                                                            <th style={{width:'15%'}}>Score</th>
                                                            <th style={{width:'15%'}}>Active</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
 
                                                        {assignmentsGrouped[assignment]
                                                            .sort(function compare(subItemA:any, subItemB:any) {
                                                                const dateA = +new Date(subItemA['dueDate']);
                                                                const dateB = +new Date(subItemB['dueDate']);
                                                                return dateA - dateB;
                                                            })
                                                            .reverse()
                                                            .filter(( subItem:any, index:number ) =>  {
                                                                if(arrayIsEmpty(this.state.filteredAssignmentScores)){
                                                                    return subItem;
                                                                }
                                                                if( this.state.filteredAssignmentScores.some(score => score === convertScoreToWord(subItem['score'])) ){
                                                                    return subItem;
                                                                }
                                                                return; 
                                                            })
                                                            .map(( subItem:any, index:number ) =>  (
                                                                <tr key={index}>
                                                                    <td colSpan={4} style={{ padding: 0 + '!important'}}> 
                                                                        <table className='table courseListTable' aria-labelledby="tabelLabel">
                                                                            <tbody> 
                                                                                <tr style={{background: 'transparent'}} className='noBorder'>
                                                                                    <td style={{width:'20%'}}> 
                                                                                        {this.formatDate(subItem['dueDate'])}
                                                                                    </td>
                                                                                    <td style={{width:'50%'}}>
                                                                                        {subItem['activityName']}                           
                                                                                    </td>
                                                                                    <td style={{width:'15%'}}>
                                                                                        {convertScoreToWord(subItem['score'])}

                                                                                        {( (convertScoreToWord(subItem['score']) === ScoreWords.Missing ||
                                                                                            convertScoreToWord(subItem['score']) === ScoreWords.NotCompleted) && convertStandardActiveToWord(subItem['standardEventActive']).toLowerCase() === 'yes') && 
                                                                                            (<WarningIcon color='warning' style={{ marginLeft: '8px'}}/>  ) 
                                                                                        }
                                                                                        {((convertScoreToWord(subItem['score']) === ScoreWords.Missing ||
                                                                                            convertScoreToWord(subItem['score']) === ScoreWords.NotCompleted) && convertStandardActiveToWord(subItem['standardEventActive']).toLowerCase() === 'no') && 
                                                                                            (<WarningIcon style={{ marginLeft: '8px', opacity: '.5', color:'#7b7f8b'}}/>) 
                                                                                        }
                                                                                        
                                                                                    </td>
                                                                                    <td style={{width:'15%'}}> 
                                                                                        {convertStandardActiveToWord(subItem['standardEventActive'])} 
                                                                                    </td>
                                                                                </tr>
                                                                                {subItem['comments'] == '' || subItem['comments'] == null ? (
                                                                                    <></>
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            {subItem['comments']}
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody> 
                                                                        </table>
                                                                    </td> 
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </AccordionPanel>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ScrollerArea>   
                </ScrollerHolder>
                
               
    
            </>
        );
    }
   
    private groupBy = (array:Array<any>) => {

        const sortedArray = [...array].sort(function(a, b) {
            const textA = a.scoreGroupName.toLowerCase();
            const textB = b.scoreGroupName.toLowerCase();
            return textA.localeCompare(textB);
        });

        return sortedArray.reduce((result:any, currentValue:any) => {
            (result[currentValue.scoreGroupName] = result[currentValue.scoreGroupName] || []).push(currentValue);
            return result;
        }, {});
   
    };
 
    private standardNumber = (fullStandardList:Array<Standard>, selectedStandard: Standard ): number => {
        for(let i = 0; i < fullStandardList.length; i++) {
            if(fullStandardList[i].standardName === selectedStandard.standardName) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    private renderMissingMatrix = (proficiencyLevel:string): JSX.Element => {
        switch(proficiencyLevel) {
            case ScoreNumbers.Five:
                return <WarningIcon color='warning' style={{marginTop:'10px'}}/>;
            case ScoreNumbers.Zero:
                return <></>;
        }
        return <></>;
    }
    
    private _updateSelectedStandard = (standard: string): void => {
        const fullStandardList: Array<Standard> = this.state.selectedGradebook.assessment.standards.map(function(standard) { 
            return standard; 
        })
 
        const selectedStandard:any = fullStandardList.find(y => y.standardName == standard);
        const newProficiencyScore:string = selectedStandard.proficiency.proficiencyScore.toString();
        
        let breadCrumbNav: boolean = false;
        if(this.props.breadCrumbNav){
            breadCrumbNav = true;
        }
 
        this.setState({ 
            selectedStandard: selectedStandard,
            proficiencyScore: newProficiencyScore, 
        }, () => {

            if(this.props.onStandardsUpdated){
                this.props.onStandardsUpdated(this.state.selectedGradebook, selectedStandard, breadCrumbNav);
            }
            
        });
 
    }

    private renderStandardDropdown = (standards: Standard[], selectedPeriod: number, onPeriodSelect: Function) => {
        return <DropDown items={standards} selectedItem={selectedPeriod} text="standardName" closeOnMouseExit={true} onSelect={onPeriodSelect} idPath="standardName"/>     
    }

    private formatDate = (dateString: Date) : string => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
        return new Date(dateString).toLocaleDateString([],options);
    }

}


export default StandardsReportCard;