import { Course, CourseJson } from "./Course";

export interface TeacherJson {
    personId:    number;
    firstName:   string;
    lastName:    string;
    identityId:  number;
    staffNumber: string;
    courses:     Array<CourseJson>;
}

export class Teacher {

    public static fromJson = (teacher: TeacherJson) => {
        if (teacher) {
            return new Teacher(
                teacher.personId,
                teacher.firstName,
                teacher.lastName,
                teacher.identityId,
                teacher.staffNumber,
                teacher.courses ? teacher.courses.map(c => Course.fromJson(c)) : [],
            );
        }
        return new Teacher();
    }

    constructor(
        public personId: number = -1,
        public firstName: string = '',
        public lastName: string = '',
        public identityId: number = -1,
        public staffNumber: string = '',
        public courses: Array<Course> = []
    ) {}
}