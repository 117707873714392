import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const ExitMttIcon = createSvgIcon(<><path d="m18.828 8.8-.981.981 1.8 1.8h-7.082v1.392h7.078l-1.8 1.8.981.988 3.48-3.48ZM3.085 5.734h5.567V4.342H3.085a1.4 1.4 0 0 0-1.392 1.392v12.533a1.4 1.4 0 0 0 1.392 1.392h5.567v-1.392H3.085Z"/><path d="M6.005 6.85a5.241 5.241 0 0 0-3.28 1.18c-1.081.837-.67 2.312-.67 3.97a41.99 41.99 0 0 1-.1 3.17 5.062 5.062 0 0 0 4.05 1.98 5.15 5.15 0 1 0 0-10.3Zm1.863 3.265a.994.994 0 1 1-.994.994.991.991 0 0 1 .994-.994Zm-3.09-.814a1.215 1.215 0 1 1-1.215 1.215 1.218 1.218 0 0 1 1.216-1.215Zm0 4.7v1.931a4.123 4.123 0 0 1-2.646-2.552 4.32 4.32 0 0 1 2.647-.87 5.238 5.238 0 0 1 .978.113 1.556 1.556 0 0 0-.978 1.38Zm1.227 2.118a3.528 3.528 0 0 1-.407-.021v-2.1c0-.731 1.514-1.1 2.266-1.1a3.651 3.651 0 0 1 1.977.592 4.129 4.129 0 0 1-3.836 2.629Z"/></>,
  'ExitMttIcon',
);

export const GoogleIcon = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"><g fill="none"><g filter="url(#a)" transform="translate(3 3)"><use fill="#FFF"/><use/><use/><use/></g><path fill="#4285F4" d="M31.64 23.205c0-.639-.057-1.252-.164-1.841H23v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615Z"/><path fill="#34A853" d="M23 32c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0 0 23 32Z"/><path fill="#FBBC05" d="M17.964 24.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0 0 14 23c0 1.452.348 2.827.957 4.042l3.007-2.332Z"/><path fill="#EA4335" d="M23 17.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C27.463 14.891 25.426 14 23 14a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71Z"/><path d="M14 14h18v18H14V14Z"/></g></svg>,
  'GoogleIcon',
);
 
export const SingleGradeIcon = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.6 13.09"><path fill="currentColor" d="m1.37 9.34 1.38-6.12h2.36l1.38 6.12H5.25l-.24-1.11H2.87l-.24 1.11H1.39Zm2.34-5.1-.62 2.91h1.67l-.62-2.91h-.43ZM8.02 3.22h2.4c.64 0 1.11.13 1.43.38.32.25.48.67.48 1.24 0 .34-.05.61-.15.81-.1.2-.28.38-.52.54.27.11.47.28.6.5.13.22.19.52.19.9 0 .59-.17 1.03-.52 1.32-.35.29-.84.43-1.47.43H8.02V3.22Zm2.34 1.06h-1.1v1.46h1.11c.47 0 .7-.24.7-.73s-.24-.73-.71-.73Zm.03 2.5H9.26v1.49h1.13c.27 0 .47-.05.6-.16s.19-.31.19-.59c0-.49-.26-.74-.79-.74ZM18.1 9.24c-.64.14-1.21.21-1.72.21s-.92-.06-1.22-.19-.55-.33-.72-.6c-.17-.28-.29-.6-.36-.97s-.1-.84-.1-1.4c0-1.18.17-2.01.5-2.47.33-.47.95-.7 1.84-.7.52 0 1.11.08 1.79.24l-.04.99c-.59-.09-1.09-.13-1.48-.13s-.67.05-.84.16-.29.3-.37.6c-.08.29-.12.79-.12 1.5s.08 1.2.25 1.47c.16.27.49.41.99.41s1.02-.04 1.58-.13l.03 1.02Z"/></svg>,
  'SingleGradeIcon',
);

export const DoubleGradeIcon = createSvgIcon(<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.69 13.04"><g id="Layer_3"><path fill="currentColor" d="M3.05 3.15 1.67 9.27h1.24l.24-1.11h2.14l.24 1.11h1.24L5.39 3.15H3.03Zm.34 3.94.62-2.91h.43l.62 2.91H3.39ZM16.8 8.28c-.5 0-.83-.14-.99-.41-.17-.27-.25-.76-.25-1.47s.04-1.21.12-1.5c.08-.29.21-.49.37-.6s.45-.16.84-.16.89.04 1.48.13l.04-.99c-.68-.16-1.28-.24-1.79-.24-.89 0-1.51.23-1.84.7-.33.46-.5 1.29-.5 2.47 0 .24 0 .46.02.67l2.49 2.49c.48 0 1.01-.07 1.61-.2l-.03-1.02c-.56.08-1.08.13-1.58.13Z"/><path fill="currentColor" d="m3.87 1.27 4.46 4.46v3.54h2.44c.37 0 .67-.07.94-.16l3.93 3.93 1.27-1.27L5.14 0 3.87 1.27Z"/></g></svg>,
  'DoubleGradeIcon',
);




