import * as React from 'react';
import Tooltip from "@mui/material/Tooltip";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';

interface IAttendanceIndicator {
    attended: boolean;
}

export const AttendanceIndicator = ( { attended }:IAttendanceIndicator) => {
    return attended ? (
        <Tooltip title={`Student has attended session`} arrow>
            <HowToRegIcon style={{marginLeft:'10px'}} color='primary' fontSize='small' />
        </Tooltip>
    ) : (
        <Tooltip title={`Student has not attended session`} arrow>
            <HowToRegOutlinedIcon style={{color: '#999999', marginLeft:'10px'}} fontSize='small'/>
        </Tooltip>
    );
}