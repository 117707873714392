/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect  } from 'react';
import { SessionState} from 'store/session/types';
import { connect, useSelector,useDispatch } from 'react-redux';

import NavMenu from 'components/navigation/NavMenu';
import { BrowserRouter as Router, Route } from 'react-router-dom';
 
import Home from 'components/Home';
import Login from 'components/Login';
import Logout from 'components/Logout';
import Gradebook from 'components/Gradebook';
import { LocalStorageType } from 'models/enums/LocalStorage';
import { updateIsFinalWeekModeAction } from 'store/global/globalReducer';
import { FeatureFlagState } from 'store/featureflags/types';

import './custom.css'
import { retrivePermissions } from './store/featureflags/actions';
import ReportCard from './components/ReportCard';
import 'react-notifications-component/dist/theme.css'
import MandatoryTargetedTutoring from './components/MandatoryTargetedTutoring';

type AppProps = {
}


     
interface AppStateProps {
  session: SessionState;   
  features: FeatureFlagState;
}

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/mandatory-targeted-tutoring',
    component: MandatoryTargetedTutoring,
  },
  {
    path: '/gradebook',
    component: Gradebook,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/reportcard',
    component: ReportCard,
  },
] 
export const App = ({AppStateProps}) => {
// class App extends React.Component<Props, {}>{
  const dispatch = useDispatch();
  const session = (state) => state.session;
  const features = (state) => state.featureFlags;

  const activeSession:SessionState = useSelector(session);

  useEffect(() => {
    dispatch(retrivePermissions());
  }, []);

  // Determine if the app is in (Faux) final week mode, this will be overwritten in the Gradebook and Reportcard depending on week and term
  useEffect(() => {
    let isSubscribed = true;
    if(activeSession.isLoggedIn && isSubscribed && localStorage.getItem(LocalStorageType.FinalWeekMode)){
      const isFinalWeekMode = localStorage.getItem(LocalStorageType.FinalWeekMode);
      if(isFinalWeekMode === 'true'){
        dispatch(updateIsFinalWeekModeAction(true));
      }
    }
    return () => {
      isSubscribed = false;
    };

  }, [activeSession]);
 
  return (
    <Router>
      <>
        <NavMenu />

        {routes.map(({ path, component: Component }, index) => {
          switch (path) {
            case '/':
              return <Route exact path={path} key={index} render={(props) => <Component ReportCardStateProps={undefined} GradebookStateProps={undefined} {...props} key={index} />}  />;                                                               
            default:
            return <Route path={path} key={index} render={(props) => <Component ReportCardStateProps={undefined} {...props} GradebookStateProps={undefined} key={index} />} />;
          }
        })}

      </>
    </Router>
  );

}
 
 
function mapDispatchToProps(state: any, ownProps: AppProps): AppStateProps {
  return{
    session: state.session,
    features: state.featureFlags
  }
}

export default connect<AppStateProps>(mapDispatchToProps)(App);