import {
    SessionToken,
    User,
    SessionState,
    SAVE_TOKEN,
    DELETE_TOKEN,
    LOGIN_ERROR,
    INITIATE_LOGIN,
    LOGIN_SUCCESS,
    SessionTokenActionTypes,
    INITIATE_LOGOUT,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS
  } from "./types";
  
  const initialToken: SessionToken = {
    token: "",
    authorized: false,
  };
  const initialUser: User = {
    userId: "",
    username: "",
    roles: [],
    staffNumber: "",
    studentNumber: "",
    personId: "",
    identityId: "",
    firstName: "",
    lastName: ""
  }
  const initialState: SessionState = {
    token: initialToken,
    isLoggedIn: false,
    isLoggingIn: false,
    user: initialUser,
    isError: false,
    errorMessage: ""
  };
  
  export function sessionReducer(
    state = initialState,
    action: SessionTokenActionTypes
  ): SessionState {
    switch (action.type) {
      case SAVE_TOKEN:
        return {...state, token: action.payload};
      case DELETE_TOKEN:
        return {...state, token: initialToken};
      case INITIATE_LOGIN:
        return {...state, isLoggingIn: true, isLoggedIn: false, isError: false, errorMessage: ""};
      case LOGIN_SUCCESS:
        return {...state, isLoggedIn: true, isLoggingIn: false,  isError: false, user: action.payload, errorMessage: "", token: {token: "", authorized: true}};
      case LOGIN_ERROR:
        return {...state, isLoggedIn: false, isLoggingIn: false, isError: true, errorMessage: action.payload.message};
      case INITIATE_LOGOUT:
        return {...state, isLoggingIn: true, isError: false, errorMessage: ""};
      case LOGOUT_SUCCESS:
        return {...state, isLoggingIn: false, isLoggedIn: false, isError: false, user: initialUser, errorMessage: "", token: initialToken};
      case LOGOUT_ERROR:
        return {...state, isLoggingIn: false, isError: true};
      default:
        return state;
    }
  }