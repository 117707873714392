import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { initLogout } from '../store/session/actions';
import { SessionState } from '../store/session/types';
import {LoadingIcon} from '../utils/LoadingIcon';


type LogoutProps = {

}
  
type LogoutState = {

}
interface StateProps {
  session: SessionState;
}
     
interface DispatchProps {
  logout: Function;
}

type Props = StateProps & DispatchProps & LogoutProps;

class Logout extends Component<Props, LogoutState> {

    state = {
    };
    componentDidMount(){
      if(this.props.session.isLoggedIn){
        this.props.logout();
      }
    }
  
    render(){
      if(this.props.session.isLoggingIn || this.props.session.isLoggedIn){
        return(
          <LoadingIcon/>
        );
      }
      else{
        return (
          <Redirect to="/"/>
        )
      }
    }
  }
 
  function mapStateToProps(state: any, ownProps: LogoutProps): StateProps {
    return {
      session: state.session,
    };
  }
  function mapDispatchToProps(dispatch: any, ownProps: LogoutProps): DispatchProps {
    return{
      logout: () => dispatch(initLogout())
    }
  }
  export default connect<StateProps, DispatchProps, LogoutProps>(mapStateToProps, mapDispatchToProps)(Logout);