import React, { useEffect, useState, useLayoutEffect } from 'react';

import { Term } from '../models/Term';
import { Course } from '../models/Course';
import { AxiosResponse } from "axios";
import { SessionState, UserRole } from '../store/session/types';
import { connect, useSelector } from 'react-redux';

import CourseSelectCard from './shared/CourseSelectCard';
import { TeacherStudent } from '../models/TeacherStudent';
import Period from '../models/Period';
import { Column } from './shared/SortableTable';
import { GradebookEntry, IStudentGradeBook } from '../models/GradebookEntry';
import { MttParentView } from '../models/enums/Mtt';
import { IMenuListItems } from '../models/Menus';
import { Standard } from '../models/Standard';
import { Row, Col } from './layout/ResponsiveGrid';
import { DrawerTypes } from './layout/Drawer';
import Drawer from '@mui/material/Drawer';
import StudentSelectCard from './shared/StudentSelectCard';
import StudentSelectReportCard from './shared/StudentSelectReportCardView';
import HomeworkCard from './shared/HomeworkCard';
import HomeworkReportCard from './shared/HomeworkReportCardView';
import { WeeklyGrowthColumn } from './shared/WeeklyGrowthColumn';
import { MttDetailsPanel } from './shared/MttDetailsPanel';
import { MttBulkDetailsPanel } from './shared/MttBulkDetailsPanel';
import Container from '@mui/material/Container';

import { MttColumn } from './shared/MttColumn';
import WeeklyGrowthReportCard from './shared/WeeklyGrowthReportCardView';
import StandardsGradebookCard from './shared/StandardsGradebookCard';
import StandardsReportCard from './shared/StandardsReportCardView';
import { LoadingIcon } from '../utils/LoadingIcon';

import { Teacher } from '../models/Teacher';
import FeatureGate from '../FeatureGate';
import { FeatureFlagState } from '../store/featureflags/types';
import { SearchTeacherStudent, SearchType } from './inputs-elements/SearchTeacherStudent';
import { Redirect } from 'react-router';
import { PrintableGradebook } from './PrintableGradebook';
import { PrintableRoster } from './PrintableRoster';

import { notificationsSelector, NotificationsType, updateNotificationsAction, resetNotificationsAction } from "../store/notifications/notificationsReducer";
import { gradebooksSelector, updateGradebooksAction, updateWeeklyGrowthAction, updateMttEligibiltyAction, initializeEligibiltyAndGradeAction, initializeFinalVerificationAction, updateFinalGradeStatusAction, updateTeacherIdAction, initializeStudentAssessmentAction, updateStudentAssessmentAction} from "store/student/gradebookReducer";

import { updateCurrentWeekDateAction, updateWeeklyGradeCommentOptionsAction, updateTermIsEditableAction, globalSelector, updateIsFinalWeekModeAction, updateCalendarAction, updateViewingWeekTypeAction } from "store/global/globalReducer";
import { useDispatch } from "react-redux";
import { getGrowthScoringOptions, updateStudentsGrowth, postStudentsFinalGradeStatus, getStudentAssesments } from 'api/gradebookApi';
import { getWGCommentOptions } from 'api/globalApi';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { ActionBar, ISelectableItem, MessageType } from './inputs-elements/ActionBar';
import EditWeeklyGrowthMenu, { MenuLayout } from './navigation/EditWeeklyGrowthMenu';
import BulkEditMenu from './navigation/BulkEditMenu';
import { CommentDialog, defaultCommentDialogState } from './layout/CommentDialog';
import { IMttEligibilty } from '../models/MandatoryTargetedTutoring';
import { IWeekDate } from '../models/WeekDate';
import { getAOrAn, pluralize, getBooleanString } from '../utils/helpers/stringHelpers';
import { getCurrentWeekDate } from 'api/scheduleApi';
import { FeatureFlagType } from "models/enums/FeatureFlag";
import { axiosResponseValid } from 'utils/helpers/apiHelpers';
import { convertToWGOptionsToMenusList, getWeeklyGrowthDefaultItem, addDays, isFinalsWeek, getSelectedTermId } from 'utils/helpers/generalHelpers';
import { isUserType } from 'utils/helpers/permissionsHelpers';
import { LocalStorageType } from 'models/enums/LocalStorage';
import { Assessment } from 'models/Assessment';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { WeekType } from 'models/WeeklyGrowth';
import { ICalendarAction } from 'models/CalendarAction';

export interface GradebookState {
  breadCrumbNav: boolean;
  canSearchTeachers: boolean;
  courses: Course[];
  grades: GradebookEntry[];
  isAscending: boolean;
  isCourseSelectCollapsed: boolean;
  isCourseSelected: boolean;
  isDrawerOpen: boolean;
  isFiltering: boolean;
  isStudentSelected: boolean;
  isTeacherSelected: boolean;
  loading: boolean;
  periods: Period[];
  searchType: SearchType;
  secondaryLoader: boolean;
  selectableGradebookEntries: Array<ISelectableItem<IStudentGradeBook>>;
  selectedCourse: Course;
  selectedPeriod: number;
  selectedStudent: TeacherStudent;
  selectedStudentGradebook: GradebookEntry;
  selectedStudentId: number;
  selectedStudentStandard: Standard;
  selectedTeacher?: Teacher;
  selectedTeacherId: number;
  selectedTerm: number;
  showPrintableGradebook: boolean;
  showPrintableRoster: boolean;
  studentColumns: Column[];
  studentList?: TeacherStudent[];
  students: TeacherStudent[];
  teacherList: Teacher[];
  terms: Term[];
  viewingFlyout: DrawerTypes;
}

const defaultCourse: Course = {
  courseId: -1,
  courseName: "",
  courseNumber: "",
  ebrFlag: false,
  isDirectGradeSection: false,
  sectionId: -1,
  periodId: -1,
  teacherDisplay: "",
  teacherPersonID: -1,
  periodName: "",
  periodStart: new Date(),
  teacherName: "",
  teacherEmail: "",
  teacherPhone: "",
  isDropped: -1,
}

const defaultStudent = new TeacherStudent();

export enum FetchTypes {
  EBRGradebook = 'gradebook',
  TBGGradebook = 'gradebookTBG'
}

export interface GradebookStateProps {
  session: SessionState;
  features: FeatureFlagState;
}

type GradebookOwnProps = {}
export type GradebookProps = GradebookStateProps & GradebookOwnProps;

export const Gradebook = ({ GradebookStateProps }) => {
  //#region Variables:
  const dispatch = useDispatch();
  const session = (state) => state.session;
  const features = (state) => state.featureFlags;
  const activeSession = useSelector(session);
  const activeFeatures = useSelector(features);
  const { allNotifications } = useSelector(notificationsSelector);
  const { allGradebooks, selectedStudentGradebook, activeTeacherId } = useSelector(gradebooksSelector);
  const { currentWeekTaskId, currentTermId, isFinalWeekMode, termIsEditable, userTypes, viewingWeekType, currentWeekHideProjectedGrade } = useSelector(globalSelector);
  const [state, setState] = useState<GradebookState>({
    breadCrumbNav: false,
    canSearchTeachers: false,
    courses: [],
    grades: [],
    isAscending: true,
    isCourseSelectCollapsed: false,
    isCourseSelected: false,
    isDrawerOpen: false,
    isFiltering: false,
    isStudentSelected: false,
    isTeacherSelected: false,
    loading: true,
    periods: [],
    searchType: SearchType.Teacher,
    secondaryLoader: false,
    selectableGradebookEntries: [],
    selectedCourse: defaultCourse,
    selectedPeriod: 0,
    selectedStudent: defaultStudent,
    selectedStudentGradebook: new GradebookEntry(),
    selectedStudentId: defaultStudent.personId,
    selectedStudentStandard: new Standard(),
    selectedTeacher: undefined,
    selectedTeacherId: 0,
    selectedTerm: 0,
    showPrintableGradebook: false,
    showPrintableRoster: false,
    studentColumns: [],
    studentList: [],
    students: [],
    teacherList: [],
    terms: [],
    viewingFlyout: DrawerTypes.defaultDrawer
  });
  const [drawerState, setDrawerState] = useState(false);
  const [notificationState, setNotificationState] = useState({ notificationStatus: null, notificationMessage: '', isNotificationVisible: false });
  const [growthScoreOptions, setGrowthScoreOptions] = useState<IMenuListItems[]>([]);
  const [commentDialogState, setCommentDialogState] = useState(defaultCommentDialogState);
  const hasAdminRole: boolean = isUserType(userTypes, [FeatureFlagType.admin]);

  //#endregion

  //#region Hooks
  // Get and Dispatch Week of the viewing term,also set term modes and final week mode
  useEffect(() => {
    let isSubscribed = true;
    
    const fetchWeekData = async () => {
      const selectedTermId: number | undefined = getSelectedTermId(state.terms, state.selectedTerm);
      const response = await getCurrentWeekDate(selectedTermId).catch(error => { 
        dispatch(updateNotificationsAction({
          notificationStatus: NotificationsType.Error,
          notificationMessage: `Error: ${error.statusText} | Could not retrieve term's current week. Please reach out to IRC tech support`
        }));
      });

      if (axiosResponseValid(response)) {
        const latestSemesterWeek = response.data as IWeekDate;
        const weekCalendarAction = response.data as ICalendarAction;  

        const localStorageFinalWeekMode = localStorage.getItem(LocalStorageType.FinalWeekMode);
        if (isFinalsWeek(latestSemesterWeek.taskId)) {
          dispatch(updateIsFinalWeekModeAction(true));
        } else if (!hasAdminRole) {
          // Not admin and not Actual Final week
          dispatch(updateIsFinalWeekModeAction(false));
        } else if (hasAdminRole && !localStorageFinalWeekMode) {
          // Admin but not set to final week mode
          dispatch(updateIsFinalWeekModeAction(false));
        }

        dispatch(updateCurrentWeekDateAction(latestSemesterWeek));
        if(weekCalendarAction)
          dispatch(updateCalendarAction(weekCalendarAction));

      }else{
        dispatch(updateNotificationsAction({
          notificationStatus: NotificationsType.Error,
          notificationMessage: `Error: Could not retrieve a valid term's current week. Please reach out to IRC tech support`
        }));
      }
    }

    if (activeSession.isLoggedIn && isSubscribed && state.terms.length) 
      fetchWeekData().catch(console.error);
    
    return () => {
      isSubscribed = false;
    };
  }, [activeSession, state.courses, state.terms, state.selectedTerm]);
 

  useEffect(() => {
    if (activeSession.isLoggedIn) {
      // If Admin / Director fetch Students or Teachers List
      if (FeatureGate("CanSearchTeachers", activeFeatures.featureFlags)) {
        FetchTeacherList();
      }
      for (let i = 0; i < activeSession.user.roles.length; i++) {
        if ((activeSession.user.roles[i] === UserRole.Teacher) || (activeSession.user.roles[i] === UserRole.TeacherTutor) || (activeSession.user.roles[i] === UserRole.SpecialEd)) {
          PopulateGradeData(activeSession.user.personId);
        }
      }
    }

  }, [activeSession, activeFeatures]);

  useEffect(() => {
    if (selectedStudentGradebook) {
      setState(prevState => ({
        ...prevState,
        selectedStudentGradebook: selectedStudentGradebook
      }));
    }

  }, [selectedStudentGradebook]);

  //  Used to determine if the Term is Still Editable for the selected Term and Selected Course.  
  useEffect(() => {
    dispatch(updateTermIsEditableAction(isTermStillEditable()));
  }, [state.selectedTerm, state.selectedCourse]);

  useLayoutEffect(() => {
    // Update local Gradebook state based off of Gradebook store
    if (allGradebooks && allGradebooks.length >= 0) {
      setState(prevState => ({
        ...prevState,
        grades: allGradebooks,
        selectableGradebookEntries: getUpdatedSelectableItems(allGradebooks)
      }));
    }

  }, [allGradebooks]);

  // Sets the global notifications 
  useLayoutEffect(() => {
    if (allNotifications) {
      setNotificationState({
        notificationStatus: allNotifications.notificationStatus,
        notificationMessage: allNotifications.notificationMessage,
        isNotificationVisible: true
      });
      dispatch(resetNotificationsAction());
    }
  }, [allNotifications]);
  //#endregion

  //#region Funtions
 
  // Determines if the selected term is still editable by the Teacher / Admin. This includes grace Period.
  const isTermStillEditable = (terms = state.terms) => {
    if (hasAdminRole)
      return true;
    let termIsStillEditable = false;
    for (let i = 0; i < terms.length; i++) {

      if (i === state.selectedTerm) {
        const today = new Date().toISOString().substring(0, 10); // Get today's date without any time/hour data
        const gracedTermEndDate: string = addDays(terms[i].termEnd, terms[i].gracePeriod);
        termIsStillEditable = (
          moment(terms[i].termStart).isSameOrBefore(today) &&
          moment(gracedTermEndDate).isSameOrAfter(today)
        );
      }
    }
    return termIsStillEditable;
  }

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationState(prevState => ({
      ...prevState,
      isNotificationVisible: false
    }));
  };

  const togglePrintableGradebook = (): void => {
    setState(prevState => ({
      ...prevState,
      showPrintableGradebook: !state.showPrintableGradebook
    }));

  }

  const togglePrintableRoster = (): void => {
    setState(prevState => ({
      ...prevState,
      showPrintableRoster: !state.showPrintableRoster
    }));
  }

  const _onStudentSorting = (): void => {
    const gradeBookList: GradebookEntry[] = [...state.grades];
    let sortedGradebookList: GradebookEntry[];


    if (state.isAscending) {
      sortedGradebookList = gradeBookList.sort(function (a, b) {
        if (a.student.isDropped !== b.student.isDropped) {
          return a.student.isDropped ? 1 : -1;
        }

        const textA = a.student.lastName.toUpperCase();
        const textB = b.student.lastName.toUpperCase();
        return textB.localeCompare(textA);
      });

    } else {
      sortedGradebookList = gradeBookList.sort(function (a, b) {

        if (a.student.isDropped !== b.student.isDropped) {
          return a.student.isDropped ? 1 : -1;
        }
        const textA = a.student.lastName.toUpperCase();
        const textB = b.student.lastName.toUpperCase();
        return textA.localeCompare(textB);
      });

      // Update Gradebook Store with the latest Gradebooks
      dispatch(updateGradebooksAction(sortedGradebookList));

      // Update the rest of the local state
      setState(prevState => ({
        ...prevState,
        isAscending: !state.isAscending
      }));
    }

  }

  const _onStudentSelect = (selectedGradeEntry: GradebookEntry): void => {
    setState(prevState => ({
      ...prevState,
      viewingFlyout: DrawerTypes.StudentDrawer,
      selectedStudentGradebook: selectedGradeEntry
    }));
    setDrawerState(true);

  }

  const _onHomeworkSelect = (selectedGradeEntry: GradebookEntry, filtering: boolean, breadCrumbNav?: boolean): void => {
    const newbreadCrumbNav: boolean = breadCrumbNav ? true : false;

    setState(prevState => ({
      ...prevState,
      viewingFlyout: DrawerTypes.HomeworkDrawer,
      selectedStudentGradebook: selectedGradeEntry,
      isFiltering: filtering,
      breadCrumbNav: newbreadCrumbNav
    }));
    setDrawerState(true);
  }

  const _onViewWeeklySelect = (selectedGradeEntry: GradebookEntry, breadCrumbNav?: boolean): void => {
    const newbreadCrumbNav: boolean = breadCrumbNav ? true : false;
    setState(prevState => ({
      ...prevState,
      viewingFlyout: DrawerTypes.WeeklyDrawer,
      selectedStudentGradebook: selectedGradeEntry,
      breadCrumbNav: newbreadCrumbNav
    }));
    setDrawerState(true);
  }

  const _onStandardSelect = (studentGradeEntry: GradebookEntry, selectedStandard: Standard, breadCrumbNav?: boolean): void => {
    const newbreadCrumbNav: boolean = breadCrumbNav ? true : false;

    setState(prevState => ({
      ...prevState,
      viewingFlyout: DrawerTypes.StandardsDrawer,
      selectedStudentStandard: selectedStandard,
      selectedStudentGradebook: studentGradeEntry,
      breadCrumbNav: newbreadCrumbNav
    }));
    setDrawerState(true);
  }

  const _toggleCoursesPanel = (item: boolean): void => {
    switch (item) {
      case true:
        setState(prevState => ({
          ...prevState,
          isCourseSelectCollapsed: true
        }));
        break;
      case false:
        setState(prevState => ({
          ...prevState,
          isCourseSelectCollapsed: false
        }));
        break;
      default:
        setState(prevState => ({
          ...prevState,
          isCourseSelectCollapsed: false
        }));
        break;
    }
  }

  const toggleDrawer = (newOpen: boolean, flyOutType?: DrawerTypes) => () => {
    if (flyOutType) {
      setState(prevState => ({
        ...prevState,
        viewingFlyout: flyOutType,
      }));
    }

    setDrawerState(newOpen);
  };

  const showMttPanel = (selectedStudentGradeBook: GradebookEntry, breadCrumbNav?: boolean): void => {
    const newbreadCrumbNav: boolean = breadCrumbNav ? true : false;
    setState(prevState => ({
      ...prevState,
      viewingFlyout: DrawerTypes.MttDrawer,
      selectedStudentGradebook: selectedStudentGradeBook,
      breadCrumbNav: newbreadCrumbNav
    }));
    setDrawerState(true);
  }

  const getUpdatedSelectableItems = (updatedGradeBooks: GradebookEntry[]): ISelectableItem<IStudentGradeBook>[] => {



    const selectableGradebookEntries: Array<ISelectableItem<IStudentGradeBook>> = updatedGradeBooks.filter((gradebook: GradebookEntry) =>
      gradebook.student.isDropped !== true
    ).map((gradebook: GradebookEntry) => {
      return {
        id: gradebook.student.personId.toString(),
        selected: false,
        item: {
          studentPersonId: gradebook.student.personId,
          sectionId: gradebook.assessment.sectionID,
          studentName: gradebook.student.lastName + ", " + gradebook.student.firstName,
          weeklyGrowthScore: gradebook.assessment.weeklyGrowth,
          projectedGrade: gradebook.assessment.projectedGrade,
          finalGrade: gradebook.assessment.finalGrade,
          currentMTTStatus: gradebook.mandatoryTargetedTutoring.currentStudentStatus,
          taskId: currentWeekTaskId,
          termId: currentTermId
        }
      };
    });








    const updatedSelectableItems: ISelectableItem<IStudentGradeBook>[] = [];

    updatedGradeBooks.forEach((gradebook: GradebookEntry) => {
      let studentCanBeSelected: boolean = true;

      if (gradebook.student.directGradeEligible && viewingWeekType !== WeekType.FinalWeek)
        studentCanBeSelected = true;

      if (gradebook.student.directGradeEligible && viewingWeekType === WeekType.FinalWeek)
        studentCanBeSelected = false;

      if (gradebook.student.isDropped === false && studentCanBeSelected) {

        selectableGradebookEntries.map((selectableItem, index) => {
          if (selectableItem.item.studentPersonId === gradebook.student.personId) {
            updatedSelectableItems.push({
              ...selectableItem,
              selected: false,
              item: {
                ...selectableItem.item,
                currentMTTStatus: gradebook.mandatoryTargetedTutoring.currentStudentStatus
              }
            })
          }
        })

      }
    });
    return updatedSelectableItems;
  }

  const UnselectCourse = (): void => {
    setState(prevState => ({
      ...prevState,
      isCourseSelectCollapsed: false,
      isCourseSelected: false
    }));
  }

  const updateGradebookSelection = (selections: Array<ISelectableItem<IStudentGradeBook>>) => {
    let selectableGradebookEntries: Array<ISelectableItem<IStudentGradeBook>> = [];
    // Toggle All selections
    if (state.selectableGradebookEntries.length === selections.length) {
      // ALL entries are already selected, so deselect all
      if (state.selectableGradebookEntries.every(gbe => gbe.selected)) {
        selectableGradebookEntries = state.selectableGradebookEntries.map(sgbe => {
          sgbe.selected = false;
          return sgbe;
        });
        // Select all previously unselected entries
      } else {
        selectableGradebookEntries = state.selectableGradebookEntries.map(sgbe => {
          sgbe.selected = true;
          return sgbe;
        });
      }
      // Toggle Individual selection
    } else if (selections.length === 1) {
      selectableGradebookEntries = state.selectableGradebookEntries.map(sgbe => {
        if (sgbe.item.studentPersonId === selections[0].item.studentPersonId) {
          sgbe.selected = !sgbe.selected;
        }
        return sgbe;
      });
    }

    setState(prevState => ({
      ...prevState,
      selectableGradebookEntries
    }));

  }
  //#endregion

  //#region Async Functions
  async function loadWeeklyGrowthOptions(sectionId: number, taskId: number = currentWeekTaskId) {

    const wgCommentOptionsResponse = await getWGCommentOptions().catch();
    if (wgCommentOptionsResponse && wgCommentOptionsResponse.data) {
      const predefinedComments: IMenuListItems[] = wgCommentOptionsResponse.data.map((menuItem: any) => {
        return {
          primaryText: menuItem.comment,
          primaryIntials: menuItem.code
        }
      })
      dispatch(updateWeeklyGradeCommentOptionsAction(predefinedComments));
    }

    const wgScoringOptionsResponse = await getGrowthScoringOptions(sectionId, taskId);

    if (wgScoringOptionsResponse && wgScoringOptionsResponse.status === 200) {
      const allOptions: IMenuListItems[] = convertToWGOptionsToMenusList(wgScoringOptionsResponse);
      setGrowthScoreOptions(allOptions);
    }
  }

  async function SelectedCourseChanged(course: Course) {
    setState(prevState => ({
      ...prevState,
      secondaryLoader: true
    }));
    // Reset viewing week to always be current on intial load, this will get updated if in final week.
    dispatch(updateViewingWeekTypeAction(WeekType.CurrentWeek));

    fetch(`student/gradebook?sectionId=${course.sectionId}&teacherId=${course.teacherPersonID}&termId=${state.terms[state.selectedTerm].termId}&isFinalWeekMode=${isFinalWeekMode}&isDirectGradeSection=${course.isDirectGradeSection}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    ).then((response) => response.json())
      .then((responseJson) => {

        const gradeBookList: GradebookEntry[] = responseJson;
        // Take Response and Sort
        const sortedGradebookList = gradeBookList.sort(function (a, b) {
          if (a.student.isDropped !== b.student.isDropped) {
            return a.student.isDropped ? 1 : -1;
          }
          const textA = a.student.lastName.toUpperCase();
          const textB = b.student.lastName.toUpperCase();
          return textA.localeCompare(textB);
        });

        const defaultTaskId: number = getWeeklyGrowthDefaultItem(sortedGradebookList, 'taskID');
        const defaultTask: string = getWeeklyGrowthDefaultItem(sortedGradebookList, 'task');

        const periods: Period[] = [];
        state.courses.forEach((c: Course) => {
          periods.push({
            periodId: c.periodId,
            periodName: c.periodName,
            periodStart: c.periodStart,
            course: c,
            courseName: c.courseName,
            courseNumber: c.courseNumber
          })
        });

        const selectedPeriod: number = periods.findIndex(period => period.courseNumber === course.courseNumber && period.periodName === course.periodName);

        const selectedStudentsItems: Array<ISelectableItem<IStudentGradeBook>> = sortedGradebookList.filter((gradebook: GradebookEntry) =>
          gradebook.student.isDropped !== true
        ).map((gradebook: GradebookEntry) => {
          return {
            id: gradebook.student.personId.toString(),
            selected: false,
            item: {
              studentPersonId: gradebook.student.personId,
              sectionId: gradebook.assessment.sectionID,
              studentName: gradebook.student.lastName + ", " + gradebook.student.firstName,
              weeklyGrowthScore: gradebook.assessment.weeklyGrowth,
              projectedGrade: gradebook.assessment.projectedGrade,
              finalGrade: gradebook.assessment.finalGrade,
              currentMTTStatus: gradebook.mandatoryTargetedTutoring.currentStudentStatus,
              taskId: currentWeekTaskId,
              termId: currentTermId
            }
          };
        });
 
        dispatch(updateGradebooksAction(sortedGradebookList));
        
        // Update the rest of the local state 
        setState(prevState => ({
          ...prevState,
          selectedCourse: course,
          isCourseSelected: true,
          selectedStudent: defaultStudent,
          periods: periods,
          selectableGradebookEntries: selectedStudentsItems,
          selectedPeriod: selectedPeriod,
          isCourseSelectCollapsed: true,
          secondaryLoader: false
        }));
      }).catch(function (error) {
        console.error(error);
      });
  }

  async function onPeriodChanged(selectedPeriodId: number, courseNumber?: string) {
    setState(prevState => ({
      ...prevState,
      secondaryLoader: true
    }));
    const periods: Period[] = state.periods;

    for (let i = 0; i < periods.length; i++) {

      if (periods[i].periodId === selectedPeriodId && periods[i].course.courseNumber === courseNumber) {

        let fetchString: FetchTypes;
        if (periods[i].course.ebrFlag === true) {
          fetchString = FetchTypes.EBRGradebook;
        } else {
          fetchString = FetchTypes.TBGGradebook;
        }





        fetch(`student/${fetchString}?sectionId=${periods[i].course.sectionId}&teacherId=${periods[i].course.teacherPersonID}&termId=${state.terms[state.selectedTerm].termId}&isFinalWeekMode=${isFinalWeekMode}&isDirectGradeSection=${periods[i].course.isDirectGradeSection}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          }
        ).then((response) => response.json())
          .then((responseJson) => {
            const gradeBookList: GradebookEntry[] = responseJson;

            // Take Response and Sort
            const sortedGradebookList = gradeBookList.sort(function (a, b) {
              if (a.student.isDropped !== b.student.isDropped) {
                return a.student.isDropped ? 1 : -1;
              }
              const textA = a.student.lastName.toUpperCase();
              const textB = b.student.lastName.toUpperCase();
              return textA.localeCompare(textB);
            });

            // Update Gradebook Store with the latest Gradebook entries
            // Tech Debt create function
            const selectedStudentsItems: Array<ISelectableItem<IStudentGradeBook>> = sortedGradebookList.filter((gradebook: GradebookEntry) =>
              gradebook.student.isDropped !== true
            ).map((gradebook: GradebookEntry) => {
              return {
                id: gradebook.student.personId.toString(),
                selected: false,
                item: {
                  studentPersonId: gradebook.student.personId,
                  sectionId: gradebook.assessment.sectionID,
                  studentName: gradebook.student.lastName + ", " + gradebook.student.firstName,
                  weeklyGrowthScore: gradebook.assessment.weeklyGrowth,
                  projectedGrade: gradebook.assessment.projectedGrade,
                  finalGrade: gradebook.assessment.finalGrade,
                  currentMTTStatus: gradebook.mandatoryTargetedTutoring.currentStudentStatus,
                  taskId: currentWeekTaskId,
                  termId: currentTermId
                }
              };
            });
            dispatch(updateGradebooksAction(sortedGradebookList));

            // Update the rest of the local state
            setState(prevState => ({
              ...prevState,
              selectableGradebookEntries: selectedStudentsItems,
              selectedCourse: periods[i].course,
              isCourseSelected: true,
              selectedStudent: defaultStudent,
              selectedPeriod: i,
              secondaryLoader: false
            }));

          }).catch(function (error) {

          });
        return;
      }
    }
  }

  async function FetchTeacherList() {
    const teacherResponse = await fetch('teacher',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        credentials: 'include'
      });
    const teachers: Teacher[] = await teacherResponse.json();

    setState(prevState => ({
      ...prevState,
      loading: false,
      teacherList: teachers,
      canSearchTeachers: true
    }));
  }

  async function PopulateGradeData(id: string, newlySelectedTeacher?: Teacher) {

    const termsResponse = await fetch(`schedule/terms`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    );
    let terms: Term[] = await termsResponse.json();
    terms = terms.sort(x => x.termStart.valueOf());

    const coursesResponse = await fetch(`course/teacher?pid=${id}&cid=${terms[state.selectedTerm].calendarId}&tid=${terms[state.selectedTerm].termId}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    );
    const courses: Course[] = await coursesResponse.json();

    let selectedTeacher: Teacher | undefined = newlySelectedTeacher ? newlySelectedTeacher : state.selectedTeacher;

    if (selectedTeacher === undefined && activeSession.user.roles.some(role => UserRole.Teacher)) {
      const user = activeSession.user;
      selectedTeacher = new Teacher(
        Number(user.personId),
        user.firstName,
        user.lastName,
        Number(user.identityId),
        user.staffNumber,
        courses
      );
    }

    setState(prevState => ({
      ...prevState,
      loading: false,
      terms: terms,
      courses: courses,
      isCourseSelected: false,
      students: [],
      selectedStudent: defaultStudent,
      selectedTeacher
    }));

    const termIsEditable = isTermStillEditable(terms);

    dispatch(updateTermIsEditableAction(termIsEditable));

    dispatch(updateTeacherIdAction(+id));

    // dispatch(updateCalendarAction(terms[state.selectedTerm].calendarId));
    

  }

  async function onSelectedTermChanged(selectedTermId: number) {
    const id = state.canSearchTeachers ? state.selectedTeacherId : activeSession.user.personId;
    const terms: Term[] = state.terms;

    for (let i = 0; i < terms.length; i++) {
      if (terms[i].termId === selectedTermId) {
        const coursesResponse = await fetch(`course/teacher?pid=${id}&cid=${terms[i].calendarId}&tid=${terms[i].termId}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          });
        const courses: Course[] = await coursesResponse.json();

        setState(prevState => ({
          ...prevState,
          courses: courses,
          selectedTerm: i,
          selectedCourse: defaultCourse,
          isCourseSelected: false,
          students: [],
          selectedStudent: defaultStudent
        }));
      }
    }
  }

  async function onSelectedTeacherChanged(selection: any) {
    if (!selection) {
      setState(prevState => ({
        ...prevState,
        selectedTeacher: undefined
      }));
      return;
    }
    const teacher: Teacher = selection.value;

    if (teacher !== null && teacher !== undefined) {
      PopulateGradeData(teacher.personId.toString(), teacher);
      setState(prevState => ({
        ...prevState,
        isStudentSelected: false,
        isTeacherSelected: true,
        selectedStudent: new TeacherStudent(),
        selectedStudentId: 0,
        selectedTeacher: teacher,
        selectedTeacherId: teacher.personId,
      }));
    }
  }

  async function updateStudentsWeeklyGrowthScore(newScoreText: string, newScoreInitials: string, newCommentText?: string) {
    const selectedStudents: IStudentGradeBook[] = state.selectableGradebookEntries.filter(sgbe => sgbe.selected).map(sgbe => sgbe.item);
    const selectedStudentsEligibilty: IMttEligibilty[] = selectedStudents.map(student => {
      return { studentId: student.studentPersonId };
    });
    dispatch(initializeEligibiltyAndGradeAction(selectedStudentsEligibilty));

    const selectedStudentsRequest: IStudentGradeBook[] = selectedStudents.map(student => {
      return {
        studentPersonId: student.studentPersonId,
        sectionId: student.sectionId,
        projectedGrade: student.projectedGrade,
        finalGrade: student.finalGrade,
        weeklyGrowthScore: newScoreInitials,
        currentMTTStatus: student.currentMTTStatus ? student.currentMTTStatus : undefined,
        comment: newCommentText ? newCommentText : '',
        taskId: currentWeekTaskId,
        termId: currentTermId
      };
    });

    const response = await updateStudentsGrowth(selectedStudentsRequest).catch(error => {
      dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Error, notificationMessage: 'Error: Updating Students Growth / Eligibilty' }));
      setCommentDialogState(defaultCommentDialogState);
    });

    if (response && response.data) {
      // Based on the response we also update local store Eligibilty and Growth
      const selectedStudentsPayload: IStudentGradeBook[] = selectedStudents.map(student => {
        const correctStudent = response.data.find(i => i.studentPersonId === student.studentPersonId);
        return {
          studentPersonId: student.studentPersonId,
          sectionId: student.sectionId,
          weeklyGrowthScore: newScoreInitials,
          scoreText: newScoreText,
          projectedGrade: correctStudent ? correctStudent['projectedGrade'] : '',
          finalGrade: correctStudent ? correctStudent['finalGrade'] : '',
          comment: newCommentText ? newCommentText : '',
          currentWeek: correctStudent ? correctStudent['currentWeek'] : '',
          taskId: currentWeekTaskId,
          termId: currentTermId,
          sequence: correctStudent['seq']
        };
      });
      dispatch(updateWeeklyGrowthAction(selectedStudentsPayload));
      dispatch(updateMttEligibiltyAction(
        response.data.map(student => {
          return { studentId: student.studentPersonId, mttStatus: student.eligiblityStatus };
        })
      ));
      dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: 'Growth / Eligibilty Updated' }));

      // Reset State of Comment and Uncheck all items after successful response
      setCommentDialogState(defaultCommentDialogState);
    }

  }

  /**  Used during the finals week to verify || unverify || comments final grades in bulk */
  async function updateStudentsFinalGradeStatus(verifyOrNot: string, commentText: string | undefined = undefined, statusChanged: boolean | undefined = undefined) {

    const selectedStudents: IStudentGradeBook[] = state.selectableGradebookEntries.filter(sgbe => sgbe.selected).map(sgbe => sgbe.item);
    const selectedStudentsEligibilty: IMttEligibilty[] = selectedStudents.filter(student => student.finalGrade).map(student => {
      return { studentId: student.studentPersonId };
    });
  
    dispatch(initializeFinalVerificationAction(selectedStudentsEligibilty));

    // API request
    const selectedStudentsRequest: IStudentGradeBook[] = selectedStudents.filter(student => student.finalGrade).map(student => {
      return {
        studentPersonId: student.studentPersonId,
        sectionId: student.sectionId,
        isVerified: verifyOrNot === 'true' ? true : verifyOrNot === 'false' ? false : undefined,
        finalGrade: student.finalGrade,
        comment: commentText ? commentText : undefined,
        taskId: currentWeekTaskId,
      };
    });
    // API Failure reset
    const selectedStudentsReset: IStudentGradeBook[] = selectedStudents.filter(student => student.finalGrade).map(student => {
      return {
        studentPersonId: student.studentPersonId,
        sectionId: student.sectionId,
        isVerified: undefined,
        finalGrade: student.finalGrade,
        comment: undefined,
        taskId: -1,
      };
    });

    const response: AxiosResponse<IStudentGradeBook[]> = await postStudentsFinalGradeStatus(selectedStudentsRequest, activeTeacherId, currentTermId, statusChanged !== undefined ? true : false).catch(error => {
      dispatch(updateFinalGradeStatusAction(selectedStudentsReset));
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Updating Students Final Grade`
      }));
      // TODO : Add App Insights error
    });

    if (response && response.data) {



      // add sequence for ordering now that we have it
      const firstStudent = response.data.find(i => i.studentPersonId !== null);
      selectedStudentsRequest.map((student) => {
        student.sequence = firstStudent !== undefined ? firstStudent['seq'] : -1;
        return student;
      });

 
      // If a final grade status is UNVERIFIED, then we need to get the latest assessment that may have been updated from Infinite Campus,
      if (firstStudent && firstStudent.isVerified === false && statusChanged) {
        fetchUpdatedStudentAssesment(firstStudent.sectionId, response.data.flatMap(item => item.studentPersonId));
      } else {
        dispatch(updateFinalGradeStatusAction(selectedStudentsRequest));
        dispatch(updateNotificationsAction({
          notificationStatus: NotificationsType.Success,
          notificationMessage: `Successfully 
                ${statusChanged !== undefined ?
              verifyOrNot === 'true' ? 'Verified Students Final Grade' : 'UnVerified Students Final Grade' : ''} 
                ${commentText ? '| Updated Comment' : ''}
              `
        }));
      }
 
      setCommentDialogState(defaultCommentDialogState);

    } else {
      // If there is an Error reset final grade.
      dispatch(updateFinalGradeStatusAction(selectedStudentsReset));
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Updating Students Final Grade`
      }));
    }
  }



  async function fetchUpdatedStudentAssesment(sectionId: number, studentIds: number[]) {
 
    dispatch(initializeStudentAssessmentAction(
      studentIds.map((studentId) => {
        return { studentId: studentId };
      })
    ));

    const response: AxiosResponse<Assessment[]> = await getStudentAssesments(sectionId, studentIds).catch(error => { 
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Getting Student(s) Assessment`
      }));

    });

    if (response && response.data) {
      dispatch(updateStudentAssessmentAction(
        response.data.map((assessment) => {
          return {
            studentPersonID: assessment.studentPersonID,
            sectionID: assessment.sectionID,
            isVerified: assessment.isVerified,
            finalGrade: assessment.finalGrade,
            projectedGrade: assessment.projectedGrade,
            standards: assessment.standards,
            weeklyGrowth: '',
            // TODO REVIEW
            isFinal: false,
            gradeMode: assessment.gradeMode
          };
        })
      ));

      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Success,
        notificationMessage: `Successfully unverified the final grade & retrieved latest Skills`
      }));
    } 

  }

  //#endregion Async Functions

  //#region : Render
  return !activeSession.isLoggedIn ? (<Redirect to="/login" />) : (
    state.loading ? <LoadingIcon text='Loading Gradebook, please wait ...' /> :
      state.showPrintableGradebook ?
      <PrintableGradebook 
        gradebookProps={GradebookStateProps}
        gradebookState={state}
        togglePrintableGradebook={() => togglePrintableGradebook()}
        hideProjectedGrade={currentWeekHideProjectedGrade}
        studentIds={state.selectableGradebookEntries.filter(sgbe => sgbe.selected).map(sgbe => sgbe.item.studentPersonId)}
      /> : 
      state.showPrintableRoster ?
      <PrintableRoster 
        gradebookProps={GradebookStateProps}
        gradebookState={state}
        term={state.terms[state.selectedTerm]}
        togglePrintableRoster={() => togglePrintableRoster()}
        hideProjectedGrade={currentWeekHideProjectedGrade}
        studentIds={state.selectableGradebookEntries.filter(sgbe => sgbe.selected).map(sgbe => sgbe.item.studentPersonId)}
      /> : 
      // Otherwise, render the page normally
      <React.Fragment>        
        <Row fullHeight>
              <Col xs={state.isCourseSelectCollapsed ? 0.5 : 3 } border>
                <CourseSelectCard                   
                  courses={state.courses} 
                  isParentCollapsed={state.isCourseSelectCollapsed}
                  onCourseSelect={(course) => SelectedCourseChanged(course)}
                  openSection={() => UnselectCourse()}
                  selectedCourse={state.selectedCourse}
                  selectedStudent={state.selectedStudent}
                  selectedTeacher={state.selectedTeacher}
                  selectedTerm={state.selectedTerm}
                  studentList={state.studentList}
                  teacherList={state.teacherList}
                  terms={state.terms}
                  toggleAction={(item) => _toggleCoursesPanel(item)}
                  togglePrintableGradebook={() => togglePrintableGradebook()}
                  togglePrintableRoster={() => togglePrintableRoster()}
                >
                  <SearchTeacherStudent
                    hideAlternateSearch={!state.canSearchTeachers}
                    loading={state.loading}
                    searchType={state.searchType}
                    selectedStudent={state.selectedStudent}
                    selectedTeacher={state.selectedTeacher}
                    selectedTeacherChanged={(selection) => onSelectedTeacherChanged(selection)}
                    selectedTerm={state.selectedTerm}
                    selectedTermChanged={(selectedTermId) => onSelectedTermChanged(selectedTermId)}
                    teacherList={state.teacherList}
                    terms={state.terms}
                  />
                </CourseSelectCard>
              </Col>
              {/* ================Secondary Loader ==================== */}
              {state.secondaryLoader && (
                <Col md={state.isCourseSelectCollapsed ? 11.5 : 9} border>
                  <LoadingIcon text="Calculating please wait..." />
                </Col>
              )}
              {/* Check to see if Course Selected First */}
              {state.isCourseSelected && !state.secondaryLoader ? (
                <Col md={state.isCourseSelectCollapsed ? 11.5 : 9} style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', width: '100%', top: '60px', overflow: 'hidden', borderBottom: '1px solid #5b606e', zIndex: 10 }}>
                    <ActionBar
                      menuTitle=''
                      hideFilter={true}
                      selectionHide={
                        !FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) ||
                        !isTermStillEditable() ||
                        state.selectableGradebookEntries.length == 0
                      }
                      selectionItems={state.selectableGradebookEntries}
                      selectionLabel={'Students'}
                      selectionChange={(selections: Array<ISelectableItem<IStudentGradeBook>>) => updateGradebookSelection(selections)}
                      selectionEditBtn={
                        <>
                          {state.selectableGradebookEntries.some(gbe => gbe.selected) && (
                            <BulkEditMenu
                              menuId="bulk-edit-1"
                              menuItems={[
                                ...isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) && termIsEditable ? [
                                  {
                                    id: 0,
                                    primaryIntials: 'true',
                                    primaryText: 'Verify',
                                    secondaryIcon: <CheckOutlinedIcon fontSize='small' color='info' />,
                                    onClickFunction: () => {
                                      updateStudentsFinalGradeStatus('true', undefined, true);
                                    }
                                  },
                                  {
                                    id: 1,
                                    primaryIntials: 'false',
                                    primaryText: 'Unverify',
                                    secondaryIcon: <PriorityHighOutlinedIcon fontSize='small' color='info' />,
                                    onClickFunction: () => {
                                      updateStudentsFinalGradeStatus('false', undefined, true);
                                    }
                                  },
                                  {
                                    primaryText: 'Add comments',
                                    primaryIntials: "MTT",
                                    id: 2,
                                    secondaryIcon: <CommentOutlinedIcon fontSize='small' color='info' />,
                                    onClickFunction: () => {
                                      setCommentDialogState({ isOpen: true, newScoreText: '', newScoreInitials: '' });
                                    }
                                  }
                                ] :
                                  isFinalWeekMode && (viewingWeekType !== WeekType.FinalWeek) && termIsEditable ? [
                                    {
                                      primaryText: 'Assign Grade',
                                      primaryIntials: "Wk",
                                      id: 2,
                                      subMenuContent:
                                        <EditWeeklyGrowthMenu
                                          secondaryButtonText={``}
                                          menuTitle={`Assign Growth`}
                                          menuId={`0`}
                                          menuItems={growthScoreOptions}
                                          menuLayout={MenuLayout.ListLayout}
                                          updateGradeFunction={(newScoreText: string, newScoreInitials: string, withComments: boolean) => {
                                            if (withComments) {
                                              setCommentDialogState({ isOpen: true, newScoreText, newScoreInitials });
                                            } else {
                                              setCommentDialogState(defaultCommentDialogState);
                                              updateStudentsWeeklyGrowthScore(newScoreText, newScoreInitials)
                                            }
                                          }
                                          }
                                        />
                                    }
                                  ] : [
                                    {
                                      primaryText: 'Assign Grade',
                                      primaryIntials: "Wk",
                                      id: 2,
                                      subMenuContent:
                                        <EditWeeklyGrowthMenu
                                          secondaryButtonText={``}
                                          menuTitle={`Assign Growth`}
                                          menuId={`0`}
                                          menuItems={growthScoreOptions}
                                          menuLayout={MenuLayout.ListLayout}
                                          updateGradeFunction={(newScoreText: string, newScoreInitials: string, withComments: boolean) => {
                                            if (withComments) {
                                              setCommentDialogState({ isOpen: true, newScoreText, newScoreInitials });
                                            } else {
                                              setCommentDialogState(defaultCommentDialogState);
                                              updateStudentsWeeklyGrowthScore(newScoreText, newScoreInitials)
                                            }
                                          }
                                          }
                                        />
                                    },
                                    {
                                      primaryText: 'Assign Mandatory Target Tutoring',
                                      primaryIntials: "MTT",
                                      id: 3,
                                      onClickFunction: () => {
                                        setState(prevState => ({
                                          ...prevState,
                                          viewingFlyout: DrawerTypes.MttBulkDrawer,
                                        }));
                                        setDrawerState(true);
                                      }
                                    },
                                  ]
                              ]
                              }
                            />
                          )}

                          {/* Weekly Comments / Final Grade Comment */}


                          <CommentDialog
                            title={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
                              `Add a Final Comment for ${state.selectableGradebookEntries.filter(sgbe => sgbe.selected).length} Students` :
                              `Assign ${state.selectableGradebookEntries.filter(sgbe => sgbe.selected).length} ${pluralize(`Student`, state.selectableGradebookEntries.filter(ie => ie.selected))} ${getAOrAn(commentDialogState.newScoreText)}
                                        "${commentDialogState.newScoreText}" with comments`
                            }
                            descriptionText={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
                              `You can also verify or unverify the final grade by toggling the switch` : ``}
                            commentPlaceholder={`${isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? `Enter a final comment or` : `Enter a weekly comment or`}  choose from the picklist`}
                            submitBtnText='Save'
                            cancelBtnText='Cancel'
                            isOpen={commentDialogState.isOpen}
                            showCommentOptions
                            isFinalCommentMode={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? true : false}
                            currentFinalGradeStatus={undefined}
                            cancelFunction={() => setCommentDialogState(defaultCommentDialogState)}
                            submitFunction={(comment: string, finalGradeStatus?: boolean | undefined) => {
                              isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
                                updateStudentsFinalGradeStatus(getBooleanString(finalGradeStatus), comment, finalGradeStatus) :
                                updateStudentsWeeklyGrowthScore(commentDialogState.newScoreText, commentDialogState.newScoreInitials, comment)
                            }}
                          />

                        </>
                      }
                      message={
                        (termIsEditable && isFinalWeekMode && hasAdminRole && viewingWeekType === WeekType.FinalWeek) ? {
                          // Admin
                          type: MessageType.Warning,
                          message: "Currently viewing the final week of an active course. (Admin)"
                        } : (termIsEditable && isFinalWeekMode && hasAdminRole && viewingWeekType !== WeekType.FinalWeek) ? {
                          // Admin not viewing final week
                          type: MessageType.Information,
                          message: "Currently viewing a previous week. This term has a Final Week. (Admin)"
                        } : (termIsEditable && isFinalWeekMode && !hasAdminRole && viewingWeekType === WeekType.FinalWeek) ? {
                          // Teacher || Tutor || Director
                          type: MessageType.Warning,
                          message: `Currently viewing the final week of an active course. Please verify final grades`
                        } : (termIsEditable && isFinalWeekMode && !hasAdminRole && viewingWeekType !== WeekType.FinalWeek) ? {
                          // Teacher || Tutor || Director not viewing final week
                          type: MessageType.Information,
                          message: "Currently viewing a previous week. This term has a Final Week."
                        } : (termIsEditable && !isFinalWeekMode) ? {
                          // Admin || Teacher || Tutor || Director
                          type: MessageType.Information,
                          message: "Currently viewing an active course"
                        } : !termIsEditable ? {
                          type: MessageType.Warning,
                          message: "This term is closed and no longer editable. "
                        } : {
                          type: undefined,
                          message: ""
                        }
                      }
                    />
                  </div>
                  <Row fullHeight noWrap>
                    <Col md={2.5} minWidth='270px' border>
                      <StudentSelectCard
                        course={state.selectedCourse}
                        grades={state.grades}
                        onPeriodChanged={(selectedPeriodId, courseNumber) => onPeriodChanged(selectedPeriodId, courseNumber)}
                        onSorting={_onStudentSorting}
                        onStudentSelect={_onStudentSelect}
                        onWeeklySelect={_onViewWeeklySelect}
                        periods={state.periods}
                        selectableGradebookEntries={state.selectableGradebookEntries}
                        selectionChange={(selections: Array<ISelectableItem<IStudentGradeBook>>) => updateGradebookSelection(selections)}
                        selectedPeriod={state.selectedPeriod}
                        selectedStudent={state.selectedStudent}
                        sortOrder={state.isAscending}
                      />
                    </Col>

                    <Col md={0.65} minWidth='60px' border>
                      <WeeklyGrowthColumn
                        grades={state.grades}
                        onWeeklySelect={_onViewWeeklySelect}
                        onCurrentWeekChange={(taskId: number) =>
                          loadWeeklyGrowthOptions(state.selectedCourse.sectionId, taskId)
                        }
                        availableOptions={growthScoreOptions}
                        disableWeeklyGrowthButton={
                          state.selectableGradebookEntries.some(wg => wg.selected === true)
                        }
                        selectedTerm={state.terms[state.selectedTerm].termId}
                        termIsEditable={termIsEditable}
                      />
                    </Col>

                    <Col md={0.5} minWidth='60px' border>
                      <MttColumn
                        grades={state.grades}
                        onMttButtonClick={showMttPanel}
                        disableMttButton={
                          state.selectableGradebookEntries.some(wg => wg.selected === true)
                        }
                      />
                    </Col>
                    <Col md={6.1} flexGrow border overFlow>
                      <StandardsGradebookCard
                        course={state.selectedCourse}
                        grades={state.grades}
                        onStandardSelect={_onStandardSelect}
                      />
                    </Col>
                    <Col md={2.2} minWidth='180px' border>
                      <HomeworkCard
                        ableToEditProduct={true}
                        grades={state.grades}
                        onHomeworkSelect={_onHomeworkSelect}
                        studentsList={state.students}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : null}

            </Row>

            {/* ================= DRAWER COMPONENT ======================= */}
            <Drawer
              anchor={'right'}
              open={drawerState}
              onClose={toggleDrawer(false)}
            >
              <Container maxWidth="md" sx={{ minWidth: '560px', width: '560px', height: '100vh' }} disableGutters>
                {state.viewingFlyout === DrawerTypes.MttDrawer &&
                  <MttDetailsPanel
                    selectedStudentGradebook={state.selectedStudentGradebook}
                    course={state.selectedCourse}
                    onCompletion={toggleDrawer(false)}
                    currentUser={activeSession.user}
                    parentView={MttParentView.GradebookView}
                    featureFlags={activeFeatures.featureFlags}
                  />
                }

                {state.viewingFlyout === DrawerTypes.MttBulkDrawer &&
                  <MttBulkDetailsPanel
                    studentGradebooks={state.grades}
                    selectedStudents={state.selectableGradebookEntries.filter(studentItem => studentItem.selected === true)}
                    course={state.selectedCourse}
                    onCompletion={toggleDrawer(false)}
                    parentView={MttParentView.GradebookView}
                    featureFlags={activeFeatures.featureFlags}
                  />
                }

                {state.viewingFlyout === DrawerTypes.StudentDrawer &&
                  <StudentSelectReportCard
                    activeCourse={state.selectedCourse}
                    isHomeworkFiltering={false}
                    onHomeworkAllSelect={_onHomeworkSelect}
                    onStandardSelect={_onStandardSelect}
                    onWeeklySelect={_onViewWeeklySelect}
                    selectedGradeBookEntry={state.selectedStudentGradebook}
                    isViewingFinalWeek={viewingWeekType === WeekType.FinalWeek ? true : false}
                  />
                }

                {state.viewingFlyout === DrawerTypes.WeeklyDrawer &&
                  <WeeklyGrowthReportCard selectedGradeBookEntry={state.selectedStudentGradebook} activeCourse={state.selectedCourse} breadCrumbNav={state.breadCrumbNav} onStudentSelect={_onStudentSelect} />
                }

                {state.viewingFlyout === DrawerTypes.StandardsDrawer &&
                  <StandardsReportCard
                    activeCourse={state.selectedCourse}
                    breadCrumbNav={state.breadCrumbNav}
                    onStudentSelect={_onStudentSelect}
                    selectedStandardProps={state.selectedStudentStandard}
                    selectedStudentGradebook={state.selectedStudentGradebook}
                    onStandardsUpdated={_onStandardSelect}
                  />
                }

                {state.viewingFlyout === DrawerTypes.HomeworkDrawer &&
                  <HomeworkReportCard
                    activeCourse={state.selectedCourse}
                    breadCrumbNav={state.breadCrumbNav}
                    isFiltering={state.isFiltering}
                    onStudentSelect={_onStudentSelect}
                    selectedGradeBookEntry={state.selectedStudentGradebook}
                  />
                }
              </Container>
            </Drawer>

            {/* ===== Global Notifications =====*/}
            <Snackbar open={notificationState.isNotificationVisible} autoHideDuration={8000} onClose={handleNotificationClose}>
              <Alert severity={
                notificationState.notificationStatus === NotificationsType.Success ? 'success' : (
                  notificationState.notificationStatus === NotificationsType.Error ? 'error' : 'warning'
                )
              }
                sx={{ width: '100%' }} variant="filled" >
                {notificationState.notificationMessage}
              </Alert>
            </Snackbar>

          </React.Fragment>
  );
  //#endregion

}

function mapStateToProps(state: any, ownProps: GradebookOwnProps): GradebookStateProps {
  return {
    session: state.session,
    features: state.featureFlags,
  };
}
export default connect<GradebookStateProps>(mapStateToProps)(Gradebook);