import * as React from 'react';
import styled from "styled-components"
import { ReactNode } from "react";

interface IncomingProps {
    children: ReactNode;
    maxWidth?: string;
}
 
interface FlexLabelStyledProps {
  isHeading?: boolean;
  maxWidth?: string;
}

interface FlexTitleStyledProps {
  showScoreIntials?: boolean;
}

const FlexLabelStyled = styled.div<FlexLabelStyledProps>`
  font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.large : fontSizes.small};
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 'none'};
  float:right;
  
`;



export const FlexTitle = styled.div<FlexTitleStyledProps>`
  flex-direction: ${ props => props.showScoreIntials ? 'column' : 'unset'};
  display: flex;width: 100%;padding: 0 6px;
  ${ props => props.showScoreIntials ? 'padding: 0 16px; div:nth-child(2){ font-size:1.2em }' : ''};
  
`;


export const TextOverflow = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  
 
  font-size: ${({ theme: { fontSizes } }) => fontSizes.titleSmaller}; 

  text-align: initial;
  margin-bottom: .5rem;
line-height: 1.2;

`;

const FlexLabel = ({ children, ...props }: IncomingProps) => (
    <FlexLabelStyled maxWidth={props.maxWidth}>
        {children}
    </FlexLabelStyled>
);

interface FlexRowProps {
  fullHeight?: boolean;
  flexDirection?: string;
  justifyContent?: string;
}
export const FlexRow = styled.div<FlexRowProps>`
  display: flex; width: 100%; flex-wrap:wrap; box-sizing:border-box;justify-content: flex-start;
  ${ props => props.fullHeight ? 'height:100%' : ''};
  flex-direction: ${ props => props.flexDirection ? props.flexDirection : 'row' };
  justify-content: ${ props => props.justifyContent ? props.justifyContent : 'flex-start' };
`;

interface FlexColProps {
  order?: number;
  textAlign?: string;
  restrictHeight?: boolean;
  maxWidth?: string;
}
export const FlexCol = styled.div<FlexColProps>`
    order: ${ props => props.order ? props.order : 0 };
    text-align: ${ props => props.textAlign ? props.textAlign : 'left' };

    ${ props => props.restrictHeight ? 'display: block;overflow: hidden;width:100%;text-overflow: ellipsis;text-align: center;line-height: 1.2;height: 36px;' : ''};
`;

export default FlexLabel;