import {
    FeatureFlag,
    FeatureFlagState,
    ADD_FEATURE_FLAG,
    REMOVE_FEATURE_FLAG,
    SET_FEATURE_FLAGS, 
    CLEAR_FEATURE_FLAGS, 
    FeatureFlagActionTypes, 
    SET_INITIALIZED
  } from "./types";
  
  const initialState: FeatureFlagState = {
    featureFlags: [], 
    isInitialized: false,
  };
  
  export function featureFlagReducer(
    state = initialState,
    action: FeatureFlagActionTypes
  ): FeatureFlagState {
    switch (action.type) {
      case ADD_FEATURE_FLAG:
        return {
          ...state,
          featureFlags: [...state.featureFlags, action.payload]
        };
      case REMOVE_FEATURE_FLAG:
        return {
          ...state,
          featureFlags: state.featureFlags.filter(
            flag => flag.key !== action.payload.key
          )
        };
      case SET_FEATURE_FLAGS:
        return {
            ...state,
            featureFlags: action.payload
        };
      case SET_INITIALIZED:
        return {
          ...state,
          isInitialized: action.payload,
        }
      case CLEAR_FEATURE_FLAGS:
        return initialState;  
      default:
        return state;
    }
  }
  