import * as React from 'react';
import styled from "styled-components"
import { ReactNode } from "react";

import { MdClose } from "react-icons/md";

export enum DrawerTypes{
  StudentDrawer = 'studentdrawer',
  WeeklyDrawer = 'weeklygrowthdrawer',
  StandardsDrawer = 'standardsdrawer',
  MttDrawer = 'mttdrawer',
  MttBulkDrawer = 'mttbulkdrawer',
  HomeworkDrawer = 'homeworkdrawer',
  defaultDrawer = ''
}

interface IncomingProps {
    children: ReactNode;
    isOpen: boolean;
    onDismiss: () => void;
}
 
interface DrawerStyledProps {
  isHeading?: boolean;
}
const DrawerStyled = styled.div<DrawerStyledProps>`
  font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.large : fontSizes.small};
  width: 45%;
  min-width: 580px;
  border: ${props => `1px solid ${props.theme.colors.onyx}`};
  background-color: ${({ theme: { colors } }) => colors.white};
  z-index: 10;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%; 
`;

const CloseButton = styled.button`
  width:40px; height: 40px; top:9px;left:-60px;position: absolute; font-size: 29px; border:none; background:none; 
 
  color:${({ theme: { colors } }) => colors.white};
`;

const DrawerBG = styled.div`
  width:100%; height: 100%; top: 0;bottom: 0;right: 0;left: 0;display: flex;position: fixed; opacity: 0.5;
  z-index:1;
  background-color: ${({ theme: { colors } }) => colors.darkGrey};
`;
 
const Drawer = ({ children, ...props }: IncomingProps) => (
    <React.Fragment>
        {(props.isOpen) && (
          <div>
            <DrawerStyled>
              
              <CloseButton onClick={() => { props.onDismiss(); }} >
                
                <MdClose color='#FFFFFF' />
              </CloseButton>

              {/* Show Child Components Below */}
              {children}
            </DrawerStyled>
            <DrawerBG onClick={() => { props.onDismiss(); }} />
          </div>
        )}
    </React.Fragment>
);

export default Drawer;