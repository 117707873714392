import { User, UserRole } from "../../store/session/types";

export const canTrackMTTAttendance = (currentUser?: User): boolean => {    
    const rolesWithTrackMTTAccess: Array<UserRole> = [
        UserRole.Admin, 
        UserRole.Teacher,
        UserRole.Tutor,
        UserRole.TeacherTutor
    ];
    return (
        currentUser !== undefined && 
        currentUser.roles.length > 0 && 
        currentUser.roles.some(ur => rolesWithTrackMTTAccess.some(r => r === ur))
    );
}

/**
   * Returns True if all User typesToChecks are available
*/
export const isUserType = (userTypes: string[] = [], typesToCheck:string[] = []): boolean => {    
    const userRoleTypes: string[] = typesToCheck;
    return (
        userTypes !== undefined && 
        userTypes.length > 0 && 
        userTypes.some(ur => userRoleTypes.every(r => r === ur))
    );
}
