import {
  SessionToken,
  User,
  SAVE_TOKEN,
  DELETE_TOKEN,
  LOGIN_ERROR,
  INITIATE_LOGIN,
  INITIATE_GOOGLELOGIN,
  INITIATE_LOGOUT,
  LOGIN_SUCCESS,
  Authentication,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR
} from "./types";
import * as Redux from 'redux';
import { SET_FEATURE_FLAGS, FeatureFlag } from "../featureflags/types";
// import { store } from 'react-notifications-component';
export function saveToken(newToken: SessionToken) {
  return {
    type: SAVE_TOKEN,
    payload: newToken
  };
}

export function deleteToken(payload: any) {
  return {
    type: DELETE_TOKEN,
    payload: payload
  };
}

export function initLogin(payload: Authentication){
  return (dispatch: Redux.Dispatch<any>) => {
    dispatch({
      type: INITIATE_LOGIN, 
      payload}
    );    
    fetch('/account/login', { // Calls "Login()" method in AccountController.cs to obtain a session cookie and user information
      method: 'GET',
      headers: {
        'Authorization': `Basic ${btoa(`${payload.username}:${payload.password}`)}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(async (response)=> {
        //dispatch({ type: LOGIN_ERROR, payload: { message: "Invalid Credentials" } });
      if(response.ok){
        return response.json();
      } else if (response.status == 400) {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson ? await response.json() : null;
          throw new Error((data && data.message) || response.status);
      }
      else {
          throw new Error("Invalid Credentials");
      }
    })
    .then((user: User)=>{
      dispatch({type: LOGIN_SUCCESS, payload: user});
      fetch('/clientflags', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      })
      .then((response)=> {
        if(response.ok){
          return response.json();
        }
        else{
          throw new Error(response.statusText);
        }
      })
      .then((flags: FeatureFlag[])=>{
        dispatch({type: SET_FEATURE_FLAGS, payload: flags})
      })
      .catch(error=>{        
        console.group("initLogin > FeatureFlags Error:");
        console.error(error);
        console.groupEnd();
      })
    })
    .catch(error=>{
      console.group("initLogin > Authentication Response Error");
      console.error(error);
      console.groupEnd();
      dispatch({type: LOGIN_ERROR, payload: error });
    })
  }
}

export function initGoogleLogin(options: any) {
  return (dispatch: Redux.Dispatch<any>) => {
    dispatch({type: INITIATE_GOOGLELOGIN, options});
    fetch('/account/google-login', options)
    .then(async (response)=> {
      if(response.ok){
        return response.json();
      } else if (response.status == 400) {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson ? await response.json() : null;
          return new Error((data && data.message) || response.status);
      }
      else{
        return new Error("Invalid Credentials");
      }
    })
    .then((user: User)=>{
      dispatch({type: LOGIN_SUCCESS, payload: user});
      fetch('/clientflags', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      })
      .then((response)=> {
        if(response.ok){
          return response.json();
        }
        else{
          throw new Error(response.statusText);
        }
      })
      .then((flags: FeatureFlag[])=>{        
        dispatch({type: SET_FEATURE_FLAGS, payload: flags})
      })
      .catch(error=>{        
        console.group("initLogin > FeatureFlags Error:");
        console.error(error);
        console.groupEnd();
      })
    })
    .catch(error => {
      dispatch({ type: LOGIN_ERROR, payload: { message: error}});
      // store.addNotification({
      //   title: "Invalid Credentials",
      //   message: "Please retry",
      //   type: "warning",
      //   insert: "top",
      //   container: "top-right",
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true
      //   }
      // });

    })
  }
}
 
export function initLogout(){

  return (dispatch: Redux.Dispatch<any>) => {
    dispatch({type: INITIATE_LOGOUT});
    fetch('/account/logout', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
    .then((response)=> {

      if(response.status === 200){
        return "success";
      }
      else{
        throw new Error(response.statusText);
      }
    })
    .then((success: any)=>{
      dispatch({type: LOGOUT_SUCCESS, payload: { isLoggingIn: false, isLoggedIn:false }})
      fetch('/clientflags', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      })
      .then((response)=> {
        if(response.ok){
          return response.json();
        }
        else{
          throw new Error(response.statusText);
        }
      })
      .then((flags: FeatureFlag[])=>{
        dispatch({type: SET_FEATURE_FLAGS, payload: flags})
      })
      .catch(error=>{
        
      })
    })
    .catch(error=>{
      dispatch({type: LOGOUT_ERROR, payload: error});
    })
  }
}
