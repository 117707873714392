import { TeacherStudent } from "./TeacherStudent";
import { Assessment } from "./Assessment";
import { WeeklyGrowth } from "./WeeklyGrowth";
import { IMandatoryTargetedTutoring, ISkills } from "./MandatoryTargetedTutoring";
import { MttStatus } from "./enums/Mtt";
import { TutorView } from "./TutorView";
import { Standard } from "./Standard";
import { Assignment } from "./Assignment";

 
export interface IStudentGradeBook{
    studentPersonId: number;
    sectionId: number;
    studentName?:string;
    scoreText?:string;
    weeklyGrowthScore?: string;
    comment?: string;
    projectedGrade?: string;
    finalGrade?: string;
    isVerified?: boolean;
    isFinal?: boolean;
    gradeMode?: number;
    directGradeEligible?:boolean;
    sequence?:number;
    currentMTTStatus?:string;
    currentWeek?: string;
    taskId?: number;
    termId?:number;
}

export interface IStudentSkillProficiency{
    studentPersonId: number;
    sectionId: number;
    standardTaskId: number;
    stdMatrix: number;
    finalGrade?: string;
    projectedGrade?: string;
    isVerified?: boolean;
    isSkillOveride?: boolean;   
}
 
export interface GradebookEntryJson {
    student: TeacherStudent;
    assessment: Assessment;
    weeklyGrowth: WeeklyGrowth[];
    mandatoryTargetedTutoring: IMandatoryTargetedTutoring;
}

export class GradebookEntry {
    public static fromJson = (gradebookEntry: GradebookEntryJson) => {
        if (gradebookEntry) {
            return new GradebookEntry(
                gradebookEntry.student,
                gradebookEntry.assessment,
                gradebookEntry.weeklyGrowth,
                gradebookEntry.mandatoryTargetedTutoring 
            );
        }
        return new GradebookEntry();
    }

    // Tech Debt: Flip this around as follows...
    // 1) MttDetailsPanel.tsx should take a "TutorView" model as props rather than a gradebook & course
    // 2) This "fromTutorView" method should be inverted into a "fromGradebookEntry" method on the "TutorView" model
    // 3) MandatoryTargetedTutoring.tsx should then be able to pass it's existing "TutorView" models into the MttDetailsPanel directly without conversions
    // 4) Gradebook.tsx should then be wired to send a TutorView object into the MttDetailsPanel using the new "fromGradebookEntry" method above
    public static fromTutorView = (tutorView: TutorView) => { 
        // Set Initial Values       
        const gradebookEntry = new GradebookEntry();
        gradebookEntry.assessment.projectedGrade = tutorView.projectedGrade;        
        gradebookEntry.assessment.weeklyGrowth = tutorView.weeklyGrowth;
        gradebookEntry.mandatoryTargetedTutoring.currentStudentStatus = MttStatus.ActiveMtt;        
        gradebookEntry.student.courseName = tutorView.courseName;
        gradebookEntry.student.firstName = tutorView.studentFirstName;
        gradebookEntry.student.lastName = tutorView.studentLastName;
        gradebookEntry.student.personId = tutorView.studentPersonId;
        gradebookEntry.student.personId = tutorView.studentPersonId;
        gradebookEntry.student.sectionId = tutorView.sectionId;

        // Populate data used to determine full list of skill options
        gradebookEntry.assessment.standards = tutorView.availableSkills.map(s => {
            const standardsItem = new Standard();
            const assignment: Assignment = {
                activityName: "",
                scoreGroupName: "",
                score: "",
                isHomework: false,
                isMissing: false,
                dueDate: new Date(),
                comments: "",
            };
            standardsItem.assignments = [assignment];
            standardsItem.standardName = s;
            return standardsItem;
        });

        // Populate data used to determine active/selected skills for the current MTT session
        const selectedMttInterventionSkills: Array<ISkills> = tutorView.skills.map(s => { return {skillsOption: s }});
        gradebookEntry.mandatoryTargetedTutoring.mttInterventions = [{
            currentSessionStatus: MttStatus.ActiveMtt,
            interventionId: tutorView.interventionId,
            startDate: new Date(),
            startWeek: '',
            weeklyGrowthScore: '',
            comments: [],
            skills: selectedMttInterventionSkills,
        }];

        return gradebookEntry;
    }

    constructor(
        public student: TeacherStudent = new TeacherStudent(),
        public assessment: Assessment = new Assessment(),
        public weeklyGrowth: WeeklyGrowth[] = [],
        // TODO Should be updated to new IMandatoryTargetedTutoring class
        public mandatoryTargetedTutoring: IMandatoryTargetedTutoring = { currentStudentStatus:MttStatus.EligibleMtt, mttInterventions: [] }
    ) {}
}